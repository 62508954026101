import type { SVGProps } from "@/shared.types";

export const TranslationIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M3.64427 2.92805C3.83933 3.12351 3.83902 3.44009 3.64356 3.63516C2.52449 4.752 1.83301 6.29478 1.83301 8.00002C1.83301 9.72519 2.54076 11.2841 3.68303 12.4039C3.88022 12.5972 3.88337 12.9138 3.69006 13.111C3.49675 13.3082 3.18018 13.3113 2.98299 13.118C1.65677 11.8179 0.833008 10.0047 0.833008 8.00002C0.833008 6.01855 1.63781 4.2241 2.93716 2.92734C3.13262 2.73228 3.4492 2.73259 3.64427 2.92805Z" />
    <path d="M12.4106 2.97631C12.6078 2.78299 12.9244 2.78614 13.1177 2.98333C14.3844 4.27551 15.1663 6.04695 15.1663 8.00002C15.1663 9.97631 14.3657 11.7666 13.0724 13.0625C12.8773 13.258 12.5607 13.2583 12.3652 13.0632C12.1698 12.8682 12.1695 12.5516 12.3645 12.3561C13.4785 11.24 14.1663 9.70082 14.1663 8.00002C14.1663 6.31915 13.4945 4.7962 12.4036 3.68338C12.2103 3.48618 12.2134 3.16962 12.4106 2.97631Z" />
    <path d="M5.53916 4.99173C5.72785 5.19336 5.71735 5.50977 5.51573 5.69845C4.88312 6.29045 4.49967 7.10018 4.49967 7.98817C4.49967 8.8865 4.89214 9.70487 5.53805 10.2986C5.74135 10.4855 5.75466 10.8018 5.56778 11.0051C5.38091 11.2084 5.0646 11.2217 4.8613 11.0348C4.02612 10.2671 3.49967 9.18828 3.49967 7.98817C3.49967 6.80201 4.014 5.73421 4.83245 4.9683C5.03407 4.77961 5.35048 4.79011 5.53916 4.99173Z" />
    <path d="M10.4949 5.01708C10.6858 4.81753 11.0023 4.81049 11.2019 5.00136C12 5.76478 12.4997 6.81907 12.4997 7.98817C12.4997 9.17122 11.988 10.2366 11.1733 11.0021C10.9721 11.1911 10.6556 11.1813 10.4665 10.98C10.2775 10.7788 10.2873 10.4623 10.4886 10.2733C11.1182 9.68167 11.4997 8.87387 11.4997 7.98817C11.4997 7.11281 11.1271 6.31366 10.5106 5.72401C10.3111 5.53314 10.3041 5.21664 10.4949 5.01708Z" />
    <path
      fillRule="evenodd"
      d="M7.99967 6.16671C6.98715 6.16671 6.16634 6.98752 6.16634 8.00004C6.16634 9.01256 6.98715 9.83337 7.99967 9.83337C9.0122 9.83337 9.83301 9.01256 9.83301 8.00004C9.83301 6.98752 9.0122 6.16671 7.99967 6.16671ZM7.16634 8.00004C7.16634 7.5398 7.53944 7.16671 7.99967 7.16671C8.45991 7.16671 8.83301 7.5398 8.83301 8.00004C8.83301 8.46028 8.45991 8.83337 7.99967 8.83337C7.53944 8.83337 7.16634 8.46028 7.16634 8.00004Z"
    />
  </svg>
);
