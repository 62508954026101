import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenAlert } from "@/components";
import { ROUTES } from "@/router";
import { Button } from "@/ui";

export const ResetPasswordLinkUsed = () => {
  const navigate = useNavigate();

  return (
    <FullScreenAlert
      title={t`Oops! It seems there's been a little hiccup`}
      description={t`It appears that the password reset link you're trying to use has already been used or has expired. No worries though, we can get this sorted out for you.`}
      subtext={t`Please try the password reset process again by requesting a new link. If the issue persists, don't hesitate to reach out to our support team for further assistance.`}
      customAction={
        <Button
          onClick={() => navigate(ROUTES.LOGIN)}
          size="lg"
          className="w-full sm:w-fit"
        >
          {t`Back to Login`}
        </Button>
      }
    />
  );
};
