import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import {
  useAcceptRequestedPlanChange,
  useUserSubscription,
} from "@/domains/billing/hooks";
import type { ModalProps } from "@/shared.types";
import {
  Button,
  DetailsCard,
  Dialog,
  IconWrapper,
  JarOfPillsIcon,
  PillIcon,
  ShieldWarningIconMono,
} from "@/ui";

interface KeepCurrentModalProps extends ModalProps {
  onConfirm: () => void;
}

export const KeepCurrentSubscriptionModal = ({
  show,
  onClose,
  onConfirm,
}: KeepCurrentModalProps) => {
  const { data: userSubscription } = useUserSubscription();

  const acceptChangeMutation = useAcceptRequestedPlanChange();

  const handleKeepCurrentPlan = () => {
    acceptChangeMutation.mutate(false, { onSuccess: onConfirm });
  };

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal" className="h-fit">
        <div className="flex flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <ShieldWarningIconMono />
            </Dialog.Icon>

            <Dialog.Title>{t`Keep current subscription`}</Dialog.Title>
            <Dialog.Description>
              {t`By rejecting the subscription change requested by your provider, you won't receive the medication they believe you need.
Are you sure you want to continue?`}
            </Dialog.Description>
          </Dialog.Header>

          <DetailsCard.Root>
            <DetailsCard.Item>
              <DetailsCard.Label>
                <IconWrapper size="sm" as="span">
                  <JarOfPillsIcon />
                </IconWrapper>
                {t`Current Plan:`}
              </DetailsCard.Label>

              {userSubscription?.currentPeriodPlan && (
                <DetailsCard.Description>
                  {userSubscription.currentPeriodPlan.name}
                  <DetailsCard.Separator />$
                  {userSubscription.currentPeriodPlan.price}/{t`month`}
                </DetailsCard.Description>
              )}
            </DetailsCard.Item>

            <DetailsCard.Item>
              <DetailsCard.Label>
                <IconWrapper size="sm" as="span">
                  <PillIcon />
                </IconWrapper>
                {t`New required Plan:`}
              </DetailsCard.Label>

              {userSubscription?.requestedPlanChange && (
                <DetailsCard.Description>
                  {userSubscription.requestedPlanChange.name}
                  <DetailsCard.Separator />$
                  {userSubscription.requestedPlanChange.price}/{t`month`}
                </DetailsCard.Description>
              )}
            </DetailsCard.Item>
          </DetailsCard.Root>

          <Dialog.Footer>
            <Button
              onClick={onClose}
              size="lg"
              variant="secondary"
              className="hidden sm:block"
            >
              {t`Go back`}
            </Button>

            <Button size="lg" onClick={handleKeepCurrentPlan}>
              {t`Keep my subscription`}
            </Button>
          </Dialog.Footer>
        </div>

        {acceptChangeMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
