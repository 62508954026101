import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { Button, illustratedIcons, Modal } from "@/ui";

export const AlreadyScheduled = ({ show, onClose }: ModalProps) => (
  <Modal
    show={show}
    onClose={onClose}
    icon={<illustratedIcons.Warning />}
    title={t`You have a previous consultation already scheduled`}
    description={t`It looks like you already have a consultation scheduled. To continue, please cancel your existing appointment first. We're here to help if you need any assistance!`}
  >
    <Button asChild className="mx-auto w-full sm:w-fit">
      <Link to={ROUTES.CONSULTATIONS.OVERVIEW}>{t`Review consultations`}</Link>
    </Button>
  </Modal>
);
