import { Route, Routes } from "react-router-dom";

import { BillingRouter, ConsultationsRouter, MyMedsRouter } from "@/domains";
import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import { BASE_ROUTE } from "../constants";
import { LegacyRouter } from "./LegacyRouter";

export const PatientUserRouter = () => {
  const activeSubscription = usePortalUserStore(
    ({ portalUser }) =>
      portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );
  const intakeFormCompleted = usePortalUserStore(({ portalUser }) =>
    Boolean(portalUser?.intakeFormCompleted),
  );

  return (
    <Routes>
      <Route path="*" element={<LegacyRouter />} />

      <Route path={`${BASE_ROUTE.BILLING}/*`} element={<BillingRouter />} />

      {activeSubscription && intakeFormCompleted && (
        <>
          <Route path={`${BASE_ROUTE.MY_MEDS}/*`} element={<MyMedsRouter />} />

          <Route
            path={`${BASE_ROUTE.CONSULTATIONS}/*`}
            element={<ConsultationsRouter />}
          />
        </>
      )}
    </Routes>
  );
};
