import type { InternalAxiosRequestConfig } from "axios";

import { authStore } from "@/stores";

export const privateRequest = (config: InternalAxiosRequestConfig) => {
  const token = authStore.getToken();

  if (token) {
    config.headers.set("Authorization", token);
  }

  return config;
};
