import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PATIENT_DOMAIN, PORTAL_DOMAIN } from "@/api";
import { errorToast } from "@/ui";
import { billingQueriesStore, changeSubscriptionPlan } from "../api";

export const useChangeSubscriptionPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changeSubscriptionPlan,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: billingQueriesStore.stripe._def,
      });
      void queryClient.invalidateQueries({
        queryKey: [PORTAL_DOMAIN],
      });
      void queryClient.invalidateQueries({
        queryKey: [PATIENT_DOMAIN],
      });
    },
    onError: errorToast,
  });
};
