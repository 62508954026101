import { t } from "ttag";

import { BASE_ROUTE, ROUTES } from "@/router";
import { AddCircleIcon, CalendarMinimalisticIcon, Header } from "@/ui";

export const ScheduleConsultationHeader = () => {
  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: BASE_ROUTE.CONSULTATIONS,
              icon: CalendarMinimalisticIcon,
              label: t`Consultations`,
            },
            {
              href: ROUTES.CONSULTATIONS.SCHEDULE,
              icon: AddCircleIcon,
              label: t`Schedule new consultation`,
            },
          ]}
        />

        <Header.Title>{t`New Consultation`}</Header.Title>
      </Header.Content>
    </Header.Root>
  );
};
