import { useState } from "react";
import { t } from "ttag";

import { RadioGroupCards } from "@/domains/consultations/components";
import { useBoolean } from "@/hooks";
import { AltArrowLeftIcon, AltArrowRightIcon, Button, Label } from "@/ui";
import { ReminderModal } from "./components";
import type { InjectionValue } from "./utils";
import { getFollowUpInjectionFormOptions } from "./utils";

interface FollowUpInjectionsFormProps {
  onCancel: () => void;
  onSubmit: () => void;
}

export const FollowUpInjectionsForm = ({
  onCancel,
  onSubmit,
}: FollowUpInjectionsFormProps) => {
  const [selectedInjectionValue, setSelectedInjectionValue] =
    useState<InjectionValue>();

  const injectionOptions = getFollowUpInjectionFormOptions();

  const {
    value: showReminderModal,
    setTrue: openReminderModal,
    setFalse: closeReminderModal,
  } = useBoolean(false);

  const handleSubmit = () => {
    const disqualifierOption = injectionOptions.some(
      (option) =>
        option.value === selectedInjectionValue && option.disqualifier,
    );

    if (disqualifierOption) {
      return openReminderModal();
    }

    onSubmit();
  };

  return (
    <div className="flex grow flex-col gap-8">
      <div className="flex grow flex-col gap-4">
        <Label size="md" htmlFor="follow-up-injections-radio-group">
          {t`How many injections of the current level of medication do you have left?`}
        </Label>

        <RadioGroupCards
          value={selectedInjectionValue}
          onValueChange={(value) =>
            setSelectedInjectionValue(value as InjectionValue)
          }
          id="follow-up-injections-radio-group"
          options={injectionOptions}
        />
      </div>

      <div className="flex flex-col-reverse items-stretch justify-between gap-4 md:flex-row md:items-center">
        <Button variant="secondary" size="lg" onClick={onCancel}>
          <AltArrowLeftIcon className="hidden size-4 md:block" />
          {t`Back`}
        </Button>
        <Button
          size="lg"
          disabled={!selectedInjectionValue}
          onClick={handleSubmit}
        >
          {t`Next`}
          <AltArrowRightIcon className="hidden size-4 md:block" />
        </Button>
      </div>

      {showReminderModal && (
        <ReminderModal
          show={showReminderModal}
          onClose={closeReminderModal}
          onContinue={onSubmit}
        />
      )}
    </div>
  );
};
