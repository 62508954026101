import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const Shape3 = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 178 178"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path d="M91.37 3V134.6C91.37 157.08 109.6 175.31 132.08 175.31C154.56 175.31 172.79 157.08 172.79 134.6V3H91.37Z" />
    <path d="M6 84.61C6 95.85 6 170.31 6 170.31H87.42C87.42 170.31 87.42 25.49 87.42 3C28.11 3 6 51.18 6 84.61Z" />
  </svg>
);
