import { useQuery } from "@tanstack/react-query";

import { queriesStore } from "@/domains/embeddables-onboarding/api";

export const useUserValidation = (userCode?: string) => {
  return useQuery({
    ...queriesStore.user.validation(userCode),
    staleTime: 0,
    gcTime: 0,
  });
};
