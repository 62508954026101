import { t } from "ttag";

export const getSubscriptionBenefits = () =>
  [
    {
      label: t`nutrition coaching`,
      detail: t`Personalized guidance and support to improve your dietary habits and overall nutrition.`,
    },
    {
      label: t`mental health coaching`,
      detail: t`Professional assistance and strategies to enhance mental well-being and cope with challenges.`,
    },
    {
      label: t`prescription ordering`,
      detail: t`If appropriate, receive medication approved by clinician which may include prescription to a GLP-1.`,
    },
    {
      label: t`insurance concierge`,
      detail: t`Our insurance concierge team works with your insurance to ensure you're receiving optimal coverage.`,
    },
    {
      label: t`movement coaching`,
      detail: t`Guidance and encouragement to incorporate physical activity and movement into your lifestyle.`,
    },
    {
      label: t`virtual visits`,
      detail: t`1:1 personalized care by a licensed healthcare provider.`,
    },
    {
      label: t`support groups`,
      detail: t`Opportunities to connect with others, share experiences, and receive encouragement and support.`,
    },
  ] as const;
