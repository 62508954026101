import type { ComponentProps, ElementRef } from "react";
import { forwardRef } from "react";
import { t } from "ttag";

import type { Plan } from "@/domains/billing/models";
import { getPeriodDurationText } from "@/domains/billing/utils";
import { RadioGroupCards } from "@/ui";

interface CoachingSubscriptionOptionsProps
  extends ComponentProps<typeof RadioGroupCards.Root> {
  subscriptionPlans: Plan[];
}

export const CoachingSubscriptionOptions = forwardRef<
  ElementRef<typeof RadioGroupCards.Root>,
  CoachingSubscriptionOptionsProps
>(({ subscriptionPlans, ...props }: CoachingSubscriptionOptionsProps, ref) => {
  return (
    <RadioGroupCards.Root ref={ref} className="grow" {...props}>
      {subscriptionPlans.map((plan) => {
        const period = getPeriodDurationText(plan.durationInMonths);
        return (
          <RadioGroupCards.Item
            asChild
            id={`radio-group-item-${plan.subscriptionPriceId}`}
            key={plan.subscriptionPriceId}
            value={plan.subscriptionPriceId}
            className="gap-2.5 pr-4"
          >
            <div>
              <div className="flex flex-col gap-1">
                <RadioGroupCards.ItemLabel
                  htmlFor={`radio-group-item-${plan.subscriptionPriceId}`}
                >
                  {plan.description}
                </RadioGroupCards.ItemLabel>

                <RadioGroupCards.ItemDescription>
                  {t`Pay $${plan.price} per ${period}`}
                </RadioGroupCards.ItemDescription>
              </div>

              <RadioGroupCards.ItemIndicator className="absolute right-4 top-4" />
            </div>
          </RadioGroupCards.Item>
        );
      })}
    </RadioGroupCards.Root>
  );
});
CoachingSubscriptionOptions.displayName = "CoachingSubscriptionOptions";
