import { t } from "ttag";

import type { ModalProps } from "@/shared.types";
import { DeliveryIcon, IconWrapper, illustratedIcons, Modal } from "@/ui";

export const MedicationRefillModal = ({ show, onClose }: ModalProps) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<illustratedIcons.Form />}
      title={t`Medication refills`}
      description={t`Your provider will review your responses and prescribe as appropriate. Simply fill out the following intake without the need to schedule an appointment! We will reach out if we have any questions.`}
    >
      <div className="flex items-center gap-2 rounded-2xl bg-brown-03 p-4.5">
        <IconWrapper size="sm" className="fill-nature-10">
          <DeliveryIcon />
        </IconWrapper>
        <p className="text-sm font-medium text-nature-10">{t`Your refill will be approved between 2-3 business days after completing this form.`}</p>
      </div>
    </Modal>
  );
};
