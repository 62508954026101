import type { ComponentProps, ElementRef } from "react";
import { forwardRef } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { tv } from "tailwind-variants";
import { t } from "ttag";

import { JOURNEY } from "@/shared.constants";
import { Badge, Label, RadioIndicator } from "@/ui";

const journeyRadioGroupVariance = tv({
  slots: {
    root: "flex w-full flex-col gap-2 sm:gap-4",
    item: "relative flex items-center gap-2 rounded-2xl border border-brown-05 bg-brown-01 p-4 py-5 text-left hover:bg-brown-03 data-[state=checked]:bg-brown-04 [&>*]:cursor-pointer",
    badge: "absolute right-4 top-1/2 -translate-y-1/2",
  },
});

const { root, item, badge } = journeyRadioGroupVariance();

export const JourneyRadioGroup = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentProps<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Root
    ref={ref}
    className={root({ className })}
    {...props}
  >
    <RadioGroupPrimitive.Item
      value={JOURNEY.MEDICATION_REQUEST}
      id={`journey-radio-group-${JOURNEY.MEDICATION_REQUEST}`}
      className={item({ className: "pr-28" })}
    >
      <RadioIndicator />

      <Label
        size="md"
        htmlFor={`journey-radio-group-${JOURNEY.MEDICATION_REQUEST}`}
      >
        {t`Order meds without video visit`}
      </Label>

      <Badge size="md" className={badge()}>{t`Fastest`}</Badge>
    </RadioGroupPrimitive.Item>

    <RadioGroupPrimitive.Item
      value={JOURNEY.VIDEO_CONSULTATION}
      id={`journey-radio-group-${JOURNEY.VIDEO_CONSULTATION}`}
      className={item()}
    >
      <RadioIndicator />

      <Label
        size="md"
        htmlFor={`journey-radio-group-${JOURNEY.VIDEO_CONSULTATION}`}
      >
        {t`Order meds with video visit`}
      </Label>
    </RadioGroupPrimitive.Item>
  </RadioGroupPrimitive.Root>
));
JourneyRadioGroup.displayName = "JourneyRadioGroup";
