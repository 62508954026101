import type { ReactNode } from "react";

interface SectionHeaderProps {
  title: string;
  description: string;
  children?: ReactNode;
}
export const SectionHeader = ({
  title,
  description,
  children,
}: SectionHeaderProps) => {
  return (
    <div className="flex flex-col gap-4.5">
      <h2 className="max-w-3xl font-serif text-2xl text-brown-10 sm:text-3xl sm:leading-snug">
        {title}
      </h2>

      <p className="px-7 text-lg font-medium text-brown-08">{description}</p>

      {children}
    </div>
  );
};
