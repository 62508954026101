import { FILTER_TYPE } from "@/shared.constants";
import type { FilterType } from "@/shared.types";

export const evaluateFilterCondition = (
  filterType: FilterType,
  value1: string,
  value2: string,
) => {
  switch (filterType) {
    case FILTER_TYPE.IS_LESS_THAN:
      return value1 < value2;
    case FILTER_TYPE.IS_LEES_THAN_OR_EQUAL_TO:
      return value1 <= value2;
    case FILTER_TYPE.IS_GREATER_THAN:
      return value1 > value2;
    case FILTER_TYPE.IS_GREATER_THAN_OR_EQUAL_TO:
      return value1 >= value2;
    case FILTER_TYPE.IS_EQUAL_TO:
      return value1 === value2;
    case FILTER_TYPE.IS_NOT_EQUAL_TO:
      return value1 !== value2;
    case FILTER_TYPE.INCLUDES:
      return value1.includes(value2);
    case FILTER_TYPE.CONTAINS:
      return value1.includes(value2);
    default:
      return false;
  }
};
