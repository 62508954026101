import { t } from "ttag";

import { CardIconFixedMono, EmptyState } from "@/ui";
import { CreditCard } from ".";

export const EmptyPaymentMethod = () => (
  <CreditCard.Root>
    <CreditCard.Chip className="bg-brown-03" />

    <CreditCard.Shapes className="text-brown-03" />

    <CreditCard.Content>
      <EmptyState.Root className="gap-1 border-transparent">
        <CardIconFixedMono className="size-20" />
        <EmptyState.Description className="text-brown-08">
          {t`You haven't added a payment method yet.`}
        </EmptyState.Description>
      </EmptyState.Root>
    </CreditCard.Content>
  </CreditCard.Root>
);
