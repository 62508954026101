import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { DollarIcon, DollarMinimalisticIcon, Header } from "@/ui";

export const ChangeSubscriptionHeader = () => {
  const navigate = useNavigate();

  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.BILLING.OVERVIEW,
              icon: DollarMinimalisticIcon,
              label: t`Billing`,
            },
            {
              href: ROUTES.BILLING.CHANGE_SUBSCRIPTION,
              icon: DollarIcon,
              label: t`Change subscription`,
            },
          ]}
        />

        <div className="flex items-center gap-2">
          <Header.GoBackButton
            onClick={() => navigate(ROUTES.BILLING.OVERVIEW)}
            className="md:hidden"
          />

          <Header.Title>{t`Change subscription`}</Header.Title>
        </div>
      </Header.Content>
    </Header.Root>
  );
};
