import type { PortalUser } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const PORTAL_DOMAIN = "portal";

export const getPortalUserQuery = () => ({
  queryKey: [PORTAL_DOMAIN, "getPortalUserQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<PortalUser>>(`/user`);

    return data.data;
  },
  staleTime: Infinity,
});
