import type { ElementRef } from "react";
import { forwardRef, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";

import { uploadTempFile } from "@/api";
import { ACCEPTED_IMAGE_TYPES } from "@/shared.constants";
import type { OnboardingImageUploadsValues } from "@/shared.types";
import {
  CheckIcon,
  errorToast,
  IconWrapper,
  Loader,
  ReloadIcon,
  UploadedFileCard,
} from "@/ui";
import { formatBytes, tw } from "@/utils";

type UploadedFile =
  OnboardingImageUploadsValues["uploadedFiles"]["labs"][number];

interface UploadedFileProps {
  uploadedFile: UploadedFile;
  onSuccess?: (fileId: string) => void;
  onError?: () => void;
  onRetry?: () => void;
}

export const UploadedTempFile = forwardRef<
  ElementRef<typeof UploadedFileCard.Root>,
  UploadedFileProps
>(({ uploadedFile, onSuccess, onError, onRetry }, ref) => {
  const { file, id } = uploadedFile;
  const filePreview = useMemo(
    () => ACCEPTED_IMAGE_TYPES.includes(file.type) && URL.createObjectURL(file),
    [file],
  );

  const uploadTempFileMutation = useMutation({
    mutationFn: uploadTempFile.mutation,
    onSuccess: ({ fileId }) => {
      onSuccess?.(fileId);
    },
    onError: (err) => {
      errorToast(err);
      onError?.();
    },
  });

  useEffect(() => {
    if (!id) {
      uploadTempFileMutation.mutate(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRetry = () => {
    onRetry?.();
    uploadTempFileMutation.mutate(file);
  };

  return (
    <UploadedFileCard.Root
      ref={ref}
      tabIndex={uploadTempFileMutation.isError ? 0 : -1}
      className="focus:outline-none focus:ring-0"
    >
      <UploadedFileCard.Container
        className={tw(
          uploadTempFileMutation.isError && "border-red-07 bg-red-01",
        )}
      >
        {filePreview &&
          (file?.type.includes("image") ? (
            <UploadedFileCard.Preview
              src={filePreview}
              alt={file.name}
              loading="lazy"
            />
          ) : (
            <embed
              src={filePreview}
              className="aspect-square h-full rounded-md object-cover"
            />
          ))}

        <div className="flex grow items-start justify-between">
          {file && (
            <UploadedFileCard.Content>
              <UploadedFileCard.Title>{file.name}</UploadedFileCard.Title>

              <UploadedFileCard.Description>
                {formatBytes(file.size, { decimals: 2 })}
              </UploadedFileCard.Description>
            </UploadedFileCard.Content>
          )}

          <UploadedFileCard.Actions>
            {uploadTempFileMutation.isPending && (
              <Loader className="size-6 fill-salmon-10 stroke-salmon-06" />
            )}

            {uploadTempFileMutation.isError && (
              <IconWrapper
                as="button"
                type="button"
                size="md"
                onClick={handleRetry}
                className="cursor-pointer"
              >
                <ReloadIcon />
              </IconWrapper>
            )}

            {uploadTempFileMutation.isSuccess && (
              <IconWrapper
                size="md"
                className="rounded-full border-2 border-salmon-06 bg-salmon-05 text-salmon-10"
              >
                <CheckIcon />
              </IconWrapper>
            )}
          </UploadedFileCard.Actions>
        </div>
      </UploadedFileCard.Container>
    </UploadedFileCard.Root>
  );
});

UploadedTempFile.displayName = "UploadedTempFile";
