import { FadeOut } from "@/ui";
import { Background } from "./Background";
import { LoginForm } from "./LoginForm";
import { Splash } from "./Splash";

export const Login = () => {
  return (
    <FadeOut className="flex h-dvh w-screen overflow-hidden bg-salmon-01 sm:after:absolute sm:after:right-0 sm:after:h-full sm:after:w-1/2 sm:after:bg-salmon-04">
      <div className="relative z-10 flex w-desktop sm:mx-auto">
        <div className="h-full w-152 bg-salmon-01" />
        <div className="flex h-full grow bg-salmon-04">
          <Background />
        </div>
      </div>
      <div className="fixed z-20 h-dvh w-screen overflow-hidden">
        <Splash />
        <div className="relative mx-auto flex h-full max-w-desktop sm:items-center">
          <div className="absolute h-full w-full overflow-y-auto overflow-x-hidden sm:max-h-190">
            <LoginForm />
          </div>
        </div>
      </div>
    </FadeOut>
  );
};
