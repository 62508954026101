// TODO: Review this, seems weird. Reference: https://app.clickup.com/t/86duvkd6b
import { create } from "zustand";

import type { Plan } from "@/domains/billing/models";
import type { BMIData } from "@/domains/onboarding/screens/StartingPoint/startingPoint.types";
import type {
  AvailableLanguage,
  BirthData,
  OnboardingUser,
  TimeSlot,
} from "@/shared.types";

interface Appointment extends TimeSlot {
  language: AvailableLanguage;
}

const LIMITED_COUPON = {
  CODE: "DEC24",
  EXPIRE_DATE: "2025-01-02",
} as const;

const isCouponValid = (code?: string) => {
  const currentDate = new Date();
  const expireDate = new Date(LIMITED_COUPON.EXPIRE_DATE);
  return code === LIMITED_COUPON.CODE && currentDate <= expireDate;
};

export interface OnboardingStoreState {
  birthInfo: BirthData | null;
  bmi: BMIData | null;
  user: OnboardingUser | null;
  appointment: Appointment | null;
  healthieUserToken: string | null;
  patientId: string | null;
  subscriptionPlan: Plan | null;
  limitedCouponCode?: string;
  setCouponCode: (limitedCouponCode?: string) => void;
  setUser: (user: OnboardingUser) => void;
  setUserBirthInfo: (birthInfo: BirthData) => void;
  setBmi: (bmi: BMIData) => void;
  setAppointment: (appointment: Appointment | null) => void;
  setHealthieUserToken: (token: string) => void;
  setPatientId: (id: string) => void;
  setSubscriptionPlan: (subscriptionPlan: Plan | null) => void;
  clearAll: () => void;
}

export const useOnboardingStore = create<OnboardingStoreState>()((set) => ({
  birthInfo: null,
  user: null,
  appointment: null,
  healthieUserToken: null,
  patientId: null,
  bmi: null,
  subscriptionPlan: null,
  limitedCouponCode: isCouponValid(LIMITED_COUPON.CODE)
    ? LIMITED_COUPON.CODE
    : undefined,
  setCouponCode: (limitedCouponCode) => {
    set(() => ({ limitedCouponCode }));
  },
  setUser: (user: OnboardingUser) => {
    set(() => ({ user }));
  },
  setUserBirthInfo: (birthInfo: BirthData) => {
    set(() => ({ birthInfo }));
  },
  setBmi: (bmi: BMIData) => {
    set(() => ({ bmi }));
  },
  setAppointment: (appointment) => {
    set(() => ({ appointment }));
  },
  setHealthieUserToken: (healthieUserToken: string) => {
    set(() => ({ healthieUserToken }));
  },
  setPatientId: (patientId: string) => {
    set(() => ({ patientId }));
  },
  setSubscriptionPlan: (subscriptionPlan) => {
    set(() => ({ subscriptionPlan }));
  },
  clearAll: () => {
    set({
      appointment: null,
      birthInfo: null,
      bmi: null,
      healthieUserToken: null,
      patientId: null,
      user: null,
    });
  },
}));
