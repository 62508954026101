import { useFormContext } from "react-hook-form";

import { SubscriptionRadioGroup } from "@/components";
import { usePatientPlans, useUserSubscription } from "@/domains/billing/hooks";
import type { Plan } from "@/domains/billing/models";
import { Form } from "@/ui";
import type { SubscriptionFormValues } from "../../../shared";

export const PortalSubscriptionPlans = ({
  durationInMonths,
}: Pick<Plan, "durationInMonths">) => {
  const { control } = useFormContext<SubscriptionFormValues>();

  const {
    data: userSubscription,
    isLoading: isLoadingUserSubscription,
    isSuccess: isSuccessUserSubscription,
  } = useUserSubscription();

  const {
    data: plans,
    isLoading: isLoadingPlans,
    isSuccess: isSuccessPlans,
  } = usePatientPlans({ durationInMonths });

  const isLoading = isLoadingUserSubscription || isLoadingPlans;
  const isSuccess = isSuccessUserSubscription && isSuccessPlans;

  const pendingSubscription = plans?.find(
    (plan) =>
      plan.subscriptionPriceId ===
      userSubscription?.nextPeriodPlan?.subscriptionPriceId,
  );

  return (
    <>
      {isLoading && (
        <div className="flex w-full flex-wrap justify-center gap-4">
          {Array.from({ length: 2 }, (_, index) => `${index}`).map((i) => (
            <SubscriptionRadioGroup.ItemSkeleton
              key={`subscription-card-skeleton-${i}`}
              className="border-brown-04"
            />
          ))}
        </div>
      )}

      {isSuccess && (
        <Form.Field
          control={control}
          name="subscriptionPriceId"
          render={({ field: { value, onChange, ...rest } }) => (
            <Form.Item>
              <Form.Control>
                <SubscriptionRadioGroup.Root
                  value={value}
                  onValueChange={onChange}
                  {...rest}
                >
                  {plans?.map((plan) => {
                    const { currentPeriodPlan, nextPeriodPlan } =
                      userSubscription;

                    const isCurrentPlan =
                      plan.subscriptionPriceId ===
                      currentPeriodPlan?.subscriptionPriceId;
                    const isPendingPlan =
                      plan.subscriptionPriceId ===
                      nextPeriodPlan?.subscriptionPriceId;

                    return (
                      <SubscriptionRadioGroup.Item
                        key={plan.subscriptionPriceId}
                        value={plan.subscriptionPriceId}
                        plan={plan}
                        className="border-brown-04"
                        isCurrentPlan={isCurrentPlan}
                        isPendingPlan={isPendingPlan}
                        disabled={
                          pendingSubscription ? isPendingPlan : isCurrentPlan
                        }
                      />
                    );
                  })}
                </SubscriptionRadioGroup.Root>
              </Form.Control>
              <Form.Message className="text-center" />
            </Form.Item>
          )}
        />
      )}
    </>
  );
};
