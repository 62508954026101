import type { SVGProps } from "@/shared.types";

export const WarningCircleIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M7.99967 4.16536C8.27582 4.16536 8.49967 4.38922 8.49967 4.66536V8.66536C8.49967 8.94151 8.27582 9.16536 7.99967 9.16536C7.72353 9.16536 7.49967 8.94151 7.49967 8.66536V4.66536C7.49967 4.38922 7.72353 4.16536 7.99967 4.16536Z" />
    <path d="M7.99967 11.332C8.36786 11.332 8.66634 11.0336 8.66634 10.6654C8.66634 10.2972 8.36786 9.9987 7.99967 9.9987C7.63148 9.9987 7.33301 10.2972 7.33301 10.6654C7.33301 11.0336 7.63148 11.332 7.99967 11.332Z" />
    <path
      fillRule="evenodd"
      d="M0.833008 7.9987C0.833008 4.04066 4.04163 0.832031 7.99967 0.832031C11.9577 0.832031 15.1663 4.04066 15.1663 7.9987C15.1663 11.9567 11.9577 15.1654 7.99967 15.1654C4.04163 15.1654 0.833008 11.9567 0.833008 7.9987ZM7.99967 1.83203C4.59392 1.83203 1.83301 4.59294 1.83301 7.9987C1.83301 11.4045 4.59392 14.1654 7.99967 14.1654C11.4054 14.1654 14.1663 11.4045 14.1663 7.9987C14.1663 4.59294 11.4054 1.83203 7.99967 1.83203Z"
    />
  </svg>
);
