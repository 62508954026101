import { t } from "ttag";

import { MEDICATION_REQUEST_STATUS } from "@/shared.constants";
import type { MedicationRequestStatus } from "@/shared.types";

export const getMedRequestDescription = (status: MedicationRequestStatus) => {
  const DESCRIPTION = {
    [MEDICATION_REQUEST_STATUS.PENDING]: t`Your request has been sent to your provider, it will take a few days for them to review it.`,
    [MEDICATION_REQUEST_STATUS.PAID]: t`The medication has been approved and charged to your account. It is being prepared for delivery.`,
    [MEDICATION_REQUEST_STATUS.APPROVED]: t`The medication request has been approved by your provider.`,
    [MEDICATION_REQUEST_STATUS.ON_ITS_WAY]: t`Your medication is on its way to your address.`,
    [MEDICATION_REQUEST_STATUS.CANCELLED]: t`The request has been canceled. Please reach out to our support team for further clarification.`,
    [MEDICATION_REQUEST_STATUS.PROCESS_COMPLETE]: t`Your order process has been completed. For any inquiries, reach out to our support team.`,
    [MEDICATION_REQUEST_STATUS.DENIED]: t`Unfortunately, your request has been denied. Please reach out to our support team for further assistance.`,
    [MEDICATION_REQUEST_STATUS.SHIPPED]: t`Shipped`,
    [MEDICATION_REQUEST_STATUS.REFUND_REQUESTED]: t`Your request is being processed. For any inquiries, reach out to our support team.`,
    [MEDICATION_REQUEST_STATUS.BRANDED_MEDICATION]: t`Your provider has ordered branded medication. It will be sent to your local pharmacy`,
  } as const;

  return DESCRIPTION[status];
};
