import { t } from "ttag";

import { SIZE, VARIANT } from "@/shared.constants";
import { tw } from "@/utils";

interface LoadingProps {
  message?: string;
  size?: "sm" | "md" | "lg";
  variant?: "primary" | "secondary";
}

export const Loading = ({
  message = t`Loading...`,
  size = SIZE.MEDIUM,
  variant = VARIANT.PRIMARY,
}: LoadingProps) => {
  const textSize = `text-${size}`;

  const colorVariant = variant === VARIANT.PRIMARY ? "#606d5b" : "#beac9c";

  const css = `
    .loader {
      width: 35px;
      aspect-ratio: 1;
      --_g: no-repeat radial-gradient(circle closest-side,${colorVariant} 90%,#0000);
      background: 
        var(--_g) 0    0,
        var(--_g) 0    100%,
        var(--_g) 100% 100%;
      background-size: 40% 40%;
      animation:l11 1s infinite linear;
    }
    @keyframes l11 {
        25% {background-position:100% 0   ,0 100%,100% 100%}
        50% {background-position:100% 0   ,0 0   ,100% 100%}
        75% {background-position:100% 0   ,0 0   ,0    100%}
        100%{background-position:100% 100%,0 0   ,0    100%}
    }
  `;

  return (
    <div className="flex h-fit w-fit flex-col items-center gap-3 p-4">
      <style>{css}</style>
      <div className="loader"></div>
      <div
        className={tw(
          "opacity-90",
          textSize,
          variant === VARIANT.PRIMARY && "text-nature-08",
          variant === VARIANT.SECONDARY && "text-brown-06",
        )}
      >
        {message}
      </div>
    </div>
  );
};
