import type { ComponentPropsWithoutRef } from "react";
import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { AlertDialog, Button, ShieldWarningIconMono } from "@/ui";

export const DisqualifiedAlert = ({
  show,
}: ComponentPropsWithoutRef<typeof AlertDialog>) => {
  return (
    <AlertDialog
      show={show}
      icon={<ShieldWarningIconMono />}
      title={t`Almost there!`}
      description={
        <p>
          {t`Looks like you've got 3 or more injections left.`}
          <br />
          {t`Please come back when you have 2 or fewer, and we'll be ready to help you out!`}
        </p>
      }
      renderActions={() => (
        <Button asChild size="lg" className="w-fit self-center">
          <Link to={ROUTES.MY_MEDS}>{t`Got it, back to My Meds`}</Link>
        </Button>
      )}
    />
  );
};
