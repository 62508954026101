import type { ComponentPropsWithoutRef, ForwardedRef } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import { ORIENTATION } from "@/shared.constants";
import { forwardRef, tw } from "@/utils";
import type { InputGroup } from "./form.types";
import { Label } from "./Label";
import { Message } from "./Message";
import { RadioIndicator } from "./RadioIndicator";

type RadioGroup = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> &
  InputGroup & { onChange: (value: string) => void };

export const RadioGroup = forwardRef(
  (
    {
      className,
      compact = true,
      containerClassName,
      disabled,
      error,
      id,
      label,
      message,
      options,
      orientation = ORIENTATION.VERTICAL,
      onChange,
      ...rest
    }: RadioGroup,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div className={tw("flex flex-col gap-1.5", containerClassName)}>
        {!!label && (
          <Label htmlFor={id} className="pb-1">
            {label}
          </Label>
        )}
        <RadioGroupPrimitive.Root
          ref={ref}
          disabled={disabled}
          onValueChange={onChange}
          className={tw(
            "flex",
            orientation === ORIENTATION.VERTICAL && "flex-col gap-2.5",
            orientation === ORIENTATION.HORIZONTAL && "items-center gap-6",
            className,
          )}
          id={id}
          {...rest}
        >
          {options.map((option) => (
            <RadioGroupPrimitive.Item
              key={option.value}
              value={option.value}
              id={`${id}-${option.value}`}
              className="flex gap-1.5"
            >
              <RadioIndicator className="mt-0.5" />
              <Label
                htmlFor={`${id}-${option.value}`}
                size="sm"
                className="text-left font-normal"
              >
                {option.label}
              </Label>
            </RadioGroupPrimitive.Item>
          ))}
        </RadioGroupPrimitive.Root>

        {(!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);
