import type { Conversation } from "@/shared.types";
import { icons, IconWrapper } from "@/ui";
import { tw } from "@/utils";

export const ChatTitle = ({
  conversation,
  isSelected,
  onClick,
}: {
  conversation: Conversation;
  isSelected: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className={tw(
        "flex cursor-pointer items-center justify-between gap-2 rounded-2xl border-2 border-brown-01 bg-brown-02 p-4 md:bg-brown-01",
        isSelected && "bg-brown-04 md:bg-brown-04",
      )}
    >
      <div className="flex items-center gap-2">
        <div className="w-fit rounded-full bg-salmon-08 p-2">
          <IconWrapper size="sm" className="fill-nature-01">
            <icons.Document />
          </IconWrapper>
        </div>
        {conversation.title}
      </div>
      <div className="flex flex-col items-center gap-1">
        <div className="self-end text-xs text-brown-07">
          {conversation.lastMessageTimestamp}
        </div>
        {conversation.newMessages > 0 && (
          <div className="flex h-4 w-4 items-center justify-center rounded-full bg-green-06 text-xs text-brown-01">
            {conversation.newMessages}
          </div>
        )}
      </div>
    </button>
  );
};
