import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { MedicationRequestFilter, OnboardingHeader } from "@/components";
import {
  EmbeddablesOnboardingScreenLayout,
  SubscriptionUpdateModal,
} from "@/domains/embeddables-onboarding/components";
import { useJourneyRequirements } from "@/domains/embeddables-onboarding/hooks";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { useBoolean } from "@/hooks";
import { NatureBackground } from "@/ui";
import { JourneyRestricted, JourneySelection } from "./sections";

export const ChooseJourney = () => {
  const {
    value: isDisqualifiedByRequestFiler,
    setValue: setRequestFilterDisqualify,
  } = useBoolean(false);

  const {
    requiresAppointment,
    hasPreviousRequestFilter: needsPreviousRequestFilter,
    actions: { removeMedicationRequestFilter },
  } = useJourneyRequirements();

  const {
    value: showSubscriptionUpdateModal,
    setTrue: openSubscriptionUpdateModal,
    setFalse: closeSubscriptionUpdateModal,
  } = useBoolean();

  const navigate = useNavigate();

  if (needsPreviousRequestFilter) {
    return (
      <EmbeddablesOnboardingScreenLayout>
        <OnboardingHeader title={t`Begin filling out your request form`} />

        <MedicationRequestFilter
          onBack={() =>
            navigate(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_BMI_SURVEY)
          }
          onNext={(isDisqualified) => {
            if (isDisqualified) {
              openSubscriptionUpdateModal();
            } else {
              removeMedicationRequestFilter();
            }

            setRequestFilterDisqualify(isDisqualified);
          }}
        />

        <SubscriptionUpdateModal
          show={showSubscriptionUpdateModal}
          onClose={closeSubscriptionUpdateModal}
          onContinue={removeMedicationRequestFilter}
        />
      </EmbeddablesOnboardingScreenLayout>
    );
  }

  return (
    <EmbeddablesOnboardingScreenLayout
      variant="secondary"
      background={<NatureBackground />}
    >
      {requiresAppointment || isDisqualifiedByRequestFiler ? (
        <JourneyRestricted />
      ) : (
        <JourneySelection />
      )}
    </EmbeddablesOnboardingScreenLayout>
  );
};
