import { useState } from "react";

import type { Answer, Question } from "@/shared.types";

interface AnswerHistory {
  questionId: Question["id"];
  selectedAnswerIds: Answer["id"][];
}

export const useAnswerHistory = () => {
  const [answersHistory, setAnswersHistory] = useState<AnswerHistory[]>([]);

  const addNewAnswersHistory = ({
    questionId,
    selectedAnswerIds,
  }: AnswerHistory) => {
    if (!questionId || !selectedAnswerIds) {
      return;
    }

    setAnswersHistory((prev) => [
      ...prev,
      {
        questionId,
        selectedAnswerIds,
      },
    ]);
  };

  const removeLastAnswerHistory = () => {
    const updatedAnswersHistory = answersHistory.slice(0, -1);
    const lastAnswerHistory = answersHistory.at(-1);

    setAnswersHistory(updatedAnswersHistory);

    return lastAnswerHistory;
  };

  return {
    answersHistory,
    actions: { addNewAnswersHistory, removeLastAnswerHistory },
  };
};
