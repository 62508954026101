import { t } from "ttag";

import {
  DOCUMENT_FOLDER,
  PROVIDER_FOLDER,
  READ_ONLY_DOCUMENT_FOLDER,
} from "@/shared.constants";
import type { DocumentFolder, ProviderFolder } from "@/shared.types";
import {
  DocumentMedicineIcon,
  FolderCheckIcon,
  LogoCircleIcon,
  MeditationRoundIcon,
  StarRainbowIcon,
  StarsIcon,
  StethoscopeIcon,
  TestTubeIcon,
  TranslateIcon,
} from "@/ui";

export const COACHING_SESSION_ICON = {
  [PROVIDER_FOLDER.NUTRITIONAL]: <StarsIcon />,
  [PROVIDER_FOLDER.MINDSET]: <StarRainbowIcon />,
  [PROVIDER_FOLDER.SPANISH]: <TranslateIcon />,
  [PROVIDER_FOLDER.MOVEMENT]: <MeditationRoundIcon />,
} as const;

export const getCoachingSessionLabel = (providerFolder: ProviderFolder) => {
  const COACHING_SESSION_LABEL = {
    [PROVIDER_FOLDER.NUTRITIONAL]: t`nutrition`,
    [PROVIDER_FOLDER.MINDSET]: t`mindset`,
    [PROVIDER_FOLDER.SPANISH]: '"español"',
    [PROVIDER_FOLDER.MOVEMENT]: t`movement`,
    [PROVIDER_FOLDER.FRIDAYS_RESOURCES]: t`Fridays' resources`,
  } as const;

  return COACHING_SESSION_LABEL[providerFolder];
};

export const getDropzoneLabel = (folder: DocumentFolder) => {
  const DROPZONE_LABEL = {
    [DOCUMENT_FOLDER.LABS]: t`Lab test here`,
    [DOCUMENT_FOLDER.PRESCRIPTIONS]: t`Previous prescriptions here`,
    [DOCUMENT_FOLDER.OTHER]: t`Medical records here`,
  } as const;

  return DROPZONE_LABEL[folder];
};

export const getDocumentEditNameLabel = (documentFolder: DocumentFolder) => {
  const DOCUMENT_EDIT_NAME_LABEL = {
    [DOCUMENT_FOLDER.LABS]: t`Test name`,
    [DOCUMENT_FOLDER.PRESCRIPTIONS]: t`Prescription name`,
    [DOCUMENT_FOLDER.OTHER]: t`Record name`,
  } as const;

  return DOCUMENT_EDIT_NAME_LABEL[documentFolder];
};

export const getDocumentLabel = (documentFolder: DocumentFolder) => {
  const DOCUMENT_LABEL = {
    [DOCUMENT_FOLDER.LABS]: t`lab tests`,
    [DOCUMENT_FOLDER.PRESCRIPTIONS]: t`prescriptions`,
    [DOCUMENT_FOLDER.OTHER]: t`medical records`,
  } as const;

  return DOCUMENT_LABEL[documentFolder];
};

export const DOCUMENT_ICON = {
  ...COACHING_SESSION_ICON,
  [DOCUMENT_FOLDER.LABS]: <TestTubeIcon />,
  [DOCUMENT_FOLDER.PRESCRIPTIONS]: <StethoscopeIcon />,
  [DOCUMENT_FOLDER.OTHER]: <FolderCheckIcon />,
  [READ_ONLY_DOCUMENT_FOLDER.DOCTOR_NOTES]: <DocumentMedicineIcon />,
  [READ_ONLY_DOCUMENT_FOLDER.FRIDAYS_RESOURCES]: (
    <LogoCircleIcon className="fill-brown-01" />
  ),
} as const;
