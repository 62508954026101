import { t } from "ttag";

import { ROUTES } from "@/router";
import { DollarMinimalisticIcon, Header } from "@/ui";

export const BillingHeader = () => {
  return (
    <Header.Root>
      <Header.Background />

      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: DollarMinimalisticIcon,
              label: t`Billing`,
            },
          ]}
        />

        <Header.Title>{t`Billing`}</Header.Title>
      </Header.Content>
    </Header.Root>
  );
};
