import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { ConfettiFixedIconMono, FullScreenAlert } from "@/ui";

export const CoachingSuccessScreenAlert = () => {
  return (
    <FullScreenAlert.Root open={true} color="nature">
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          <ConfettiFixedIconMono
            color="nature"
            className="size-16 md:size-20"
          />
          <FullScreenAlert.Title>
            {t`You're now part of the Fridays Coaching crew!`}
          </FullScreenAlert.Title>
        </FullScreenAlert.Header>
        <FullScreenAlert.Description>
          {t`Congratulations! We're thrilled to support you on your wellness journey. Let's make every day feel like a Friday! 🎉`}
        </FullScreenAlert.Description>

        <FullScreenAlert.Footer>
          <FullScreenAlert.Action asChild>
            <Link to={ROUTES.COACHING_HUB.OVERVIEW}>
              {t`Explore Coaching sessions`}
            </Link>
          </FullScreenAlert.Action>
        </FullScreenAlert.Footer>
      </FullScreenAlert.Content>
    </FullScreenAlert.Root>
  );
};
