import { t } from "ttag";

import { Alert } from "@/ui";

export const RefillRequirementsAlert = () => (
  <Alert>
    <p className="flex flex-col gap-5 font-medium">
      <span className="inline-block">{t`Important note: Refills will only be provided after you've taken at least 3 injections.`}</span>
      <span>{t`Your lab results (CMP, TSH, HbA1c, and Lipid Panel) must be on record within the past 12 months to receive medication. Patients who qualify may receive up to one refill of their current dosage without these labs.`}</span>
    </p>
  </Alert>
);
