import type { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { Breadcrumb, shapes } from "@/ui";
import { tw } from "@/utils";
import type { BreadCrumbLink } from "../ui.types";
import { GoBackButton } from "./GoBackButton";

interface ScreenHeaderProps {
  title: string;
  paths: BreadCrumbLink[];
  actions?: ReactNode;
  description?: ReactNode;
  variant?: Variant;
  hidden?: boolean;
}

/**
 * @deprecated Use the new `Header` component for V2 features instead.
 */
export const ScreenHeader = ({
  title,
  paths,
  actions,
  description,
  variant = VARIANT.PRIMARY,
  hidden = false,
}: ScreenHeaderProps) => {
  const location = useLocation();

  const isSubPath = location.pathname.split("/").length > 2;

  return (
    <header
      className={tw(
        "relative grid shrink-0 grid-cols-[auto_1fr] items-center overflow-hidden rounded-2xl p-5 md:gap-3",
        variant === VARIANT.PRIMARY && "bg-nature-08 text-brown-01",
        variant === VARIANT.SECONDARY && "px-2 text-brown-09 md:px-0",
        hidden && "hidden md:grid",
      )}
    >
      {variant === VARIANT.PRIMARY && (
        <div className="-right-14 top-0 hidden h-full items-center opacity-90 md:absolute md:flex">
          <shapes.PartialDoughnut2
            direction="left"
            className="scale-95 opacity-40"
            shapeColor="quaternary"
          />
          <shapes.CompositeSquareBy3
            direction="bottom"
            className="scale-95 opacity-20"
          />
        </div>
      )}

      <Breadcrumb links={paths} color={variant} />

      <h1 className="row-start-1 flex items-start gap-1 font-serif text-2xl md:row-start-2 md:text-3xl md:leading-10">
        {isSubPath && <GoBackButton />}
        {title}
      </h1>
      {description && (
        <div className="row-start-2 text-sm font-medium md:row-start-3">
          {description}
        </div>
      )}
      <div className="z-10 col-start-2 row-span-1 row-start-1 flex items-end justify-end pb-0.5 md:row-span-2">
        {actions}
      </div>
    </header>
  );
};
