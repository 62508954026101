import { t } from "ttag";

import type { ObjectValue } from "@/shared.types";

const INJECTION_VALUE = {
  TWO_OR_LESS: "2-or-less",
  THREE_OR_MORE: "3-or-more",
  TALK_TO_PROVIDER: "talk-to-provider",
} as const;

export type InjectionValue = ObjectValue<typeof INJECTION_VALUE>;

export const getFollowUpInjectionFormOptions = () =>
  [
    {
      disqualifier: false,
      value: INJECTION_VALUE.TWO_OR_LESS,
      label: t`2 or fewer injections left`,
    },
    {
      disqualifier: true,
      value: INJECTION_VALUE.THREE_OR_MORE,
      label: t`3 or more injections left`,
    },
    {
      disqualifier: false,
      value: INJECTION_VALUE.TALK_TO_PROVIDER,
      label: t`No meds, just want to talk to a provider`,
    },
  ] as const;
