import { t } from "ttag";

import { AvailableDaysCalendar } from "@/components";
import { useScheduleConsultationContext } from "@/contexts";
import { PROVIDER_PREFERENCE } from "@/shared.constants";
import { useOnboardingStore } from "@/stores";
import { CardPrimitive as Card } from "@/ui";
import { tw } from "@/utils";

export const AvailableDays = () => {
  const { providerPreference, provider } = useScheduleConsultationContext();

  const userId = useOnboardingStore((state) => state.user?.id);

  const disabled =
    providerPreference === PROVIDER_PREFERENCE.CUSTOM && !provider;

  const availability = provider ? t`on ${provider.fullName} calendar` : t`ones`;
  return (
    <Card.Root
      className={tw(
        "basis-1/2 gap-4 bg-transparent p-0",
        disabled && "cursor-not-allowed opacity-50",
      )}
    >
      <Card.Title>{t`1. Choose an available day:`}</Card.Title>
      <Card.Description>{t`The highlighted dates are the available ${availability}`}</Card.Description>

      <div className={tw(disabled && "pointer-events-none")}>
        <AvailableDaysCalendar userId={userId} />
      </div>
    </Card.Root>
  );
};
