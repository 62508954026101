import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { t } from "ttag";

import { LanguageSwitch } from "@/components";
import { ROUTES } from "@/router";
import type { Variant } from "@/shared.types";
import { StepProgressBar } from "@/ui";

const getOnboardingSteps = () =>
  [
    {
      value: ROUTES.ONBOARDING.MAIN_INFO,
      label: t`Your main info`,
    },
    {
      value: ROUTES.ONBOARDING.JOURNEY_BEGIN,
      label: t`Choose your journey`,
    },
    {
      value: ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT,
      label: t`Payment & Account`,
    },
  ] as const;

export const OnboardingProgressBar = ({ variant }: { variant?: Variant }) => {
  const { pathname: currentPath } = useLocation();
  const queryClient = useQueryClient();
  return (
    <div className="flex w-full flex-col-reverse items-center justify-center gap-9 md:relative md:flex-row">
      <StepProgressBar
        steps={getOnboardingSteps()}
        currentStep={currentPath}
        variant={variant}
      />

      <LanguageSwitch
        onCheckedChange={() => queryClient.invalidateQueries()}
        className="right-0 self-end md:absolute md:-top-2"
      />
    </div>
  );
};
