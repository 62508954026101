import type { QueryClient } from "@tanstack/query-core";

import type { ChatMessage, Conversation, Tag } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "messages";

export const getConversationsQuery = () => ({
  queryKey: [DOMAIN, "getConversationsQuery"],
  queryFn: async () => {
    const { data } =
      await privateAPI.get<ServiceResponse<Conversation[]>>("/conversations");

    return data.data;
  },
});

export interface Chat {
  id: string;
  title: string;
  createdAt: string;
  messages: ChatMessage[];
}

export const getChatQuery = (conversationId?: string | null) => ({
  queryKey: [DOMAIN, "getChatQuery", conversationId],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<Chat>>(
      `/conversations/${conversationId}`,
    );

    return data.data;
  },
});

export const sendMessageMutation = {
  mutation: async (data: {
    conversationId: string;
    body: string;
    attachmentFiles?: File;
  }) => {
    const { data: response } = await privateAPI.post<ServiceResponse<void>>(
      `/conversations/${data.conversationId}/send-message`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } },
    );

    return response.data;
  },
  invalidates: (queryClient: QueryClient, conversationId?: string | null) => {
    void queryClient.invalidateQueries({
      queryKey: [DOMAIN, "getChatQuery", conversationId],
    });
  },
};

export const markConversationAsRead = {
  mutation: async (conversationId: string) => {
    const { data: response } = await privateAPI.put<ServiceResponse<void>>(
      `/conversations/${conversationId}`,
    );

    return response.data;
  },
};

export const getConversationTagsQuery = () => ({
  queryKey: [DOMAIN, "getConversationTagsQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<Tag[]>>("/tags");

    return data.data;
  },
});

export const createConversationMutation = {
  mutation: async (tag: Tag) => {
    const { data: response } = await privateAPI.post<
      ServiceResponse<{ id: string }>
    >("/conversations", { tag });

    return response.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const checkNewMessagesQuery = () => ({
  queryKey: [DOMAIN, "checkNewMessagesQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<
      ServiceResponse<{ conversationsWithNewMessages: string[] }>
    >("/conversations/has-new-messages");

    return data.data;
  },
  refetchInterval: 10000,
  refetchIntervalInBackground: true,
});
