import { DIRECTION, VARIANT } from "@/shared.constants";
import { shapes } from "@/ui";

export const JourneyBeginBackground = () => {
  return (
    <div className="fixed h-desktop w-desktop bg-nature-08">
      <div className="relative h-full w-full">
        <shapes.PartialDoughnut2
          direction={DIRECTION.BOTTOM}
          shapeVariant={VARIANT.QUATERNARY}
          shapeColor={VARIANT.TERTIARY}
          className="absolute right-3/4 top-3/4 -mt-10 mr-20 scale-[6.7] opacity-40"
        />
        <shapes.CompositeSquareBy2
          shapeColor={VARIANT.TERTIARY}
          direction={DIRECTION.LEFT}
          className="absolute bottom-2/3 left-3/4 ml-40 translate-y-6 scale-[5] opacity-40"
        />
        <shapes.CompositeSquareBy3
          shapeColor={VARIANT.TERTIARY}
          shapeVariant={VARIANT.TERTIARY}
          className="absolute bottom-20 right-1/4 scale-x-[-1.66] scale-y-[1.66] opacity-40"
        />
      </div>
    </div>
  );
};
