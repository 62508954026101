import { useQuery } from "@tanstack/react-query";

import { billingQueriesStore } from "@/domains/billing/api";
import { env } from "@/env";

export const useSessionsOverview = () => {
  const canBookCounselingQuery = useQuery({
    ...billingQueriesStore.stripe.userSubscription(),
    select: (data) => {
      const { currentPeriodPlan, nextPeriodPlan } = data;

      const hasNewSubscription = Boolean(
        currentPeriodPlan &&
          currentPeriodPlan.subscriptionPriceId !==
            env.VITE_STRIPE_BASE_PRICE_ID,
      );

      const hasScheduledLegacyChange =
        nextPeriodPlan &&
        nextPeriodPlan.subscriptionPriceId !== env.VITE_STRIPE_BASE_PRICE_ID;

      return Boolean(hasNewSubscription || hasScheduledLegacyChange);
    },
  });

  return {
    canBookCounselingQuery,
  };
};
