import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PATIENT_DOMAIN } from "@/api";
import { updateEmbeddablesPatient } from "@/domains/embeddables-onboarding/api";
import { errorToast } from "@/ui";

export const useUpdatePatientMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateEmbeddablesPatient,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [PATIENT_DOMAIN],
      }),
    onError: errorToast,
  });
};
