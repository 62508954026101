import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  FullScreenAlert,
  MedicationRequestFilter,
  OnboardingHeader,
} from "@/components";
import { EmbeddablesOnboardingScreenLayout } from "@/domains/embeddables-onboarding/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { useBoolean } from "@/hooks";
import { JOURNEY } from "@/shared.constants";
import { Button, CalendarFixedIconMono } from "@/ui";

export const MedicationRequest = () => {
  const {
    actions: {
      setChooseJourneyFormValues,
      setScheduleVideoConsultationFormValues,
    },
  } = useEmbeddablesForms();
  const {
    value: showDisqualifiedScreen,
    setTrue: openDisqualifiedScreen,
    setFalse: closeDisqualifiedScreen,
  } = useBoolean(false);

  const navigate = useNavigate();

  const handleNext = (isDisqualified: boolean) => {
    if (isDisqualified) {
      return openDisqualifiedScreen();
    }

    setChooseJourneyFormValues({ journey: JOURNEY.MEDICATION_REQUEST });
    setScheduleVideoConsultationFormValues(undefined);

    navigate(EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_QUESTIONS);
  };

  return (
    <EmbeddablesOnboardingScreenLayout>
      <OnboardingHeader title={t`Begin filling out your request form`} />

      <MedicationRequestFilter
        onBack={() => navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY)}
        onNext={handleNext}
      />

      {showDisqualifiedScreen && (
        <FullScreenAlert
          customIcon={
            <CalendarFixedIconMono color="salmon" className="size-24" />
          }
          title={t`Prior Consultation with Provider Required`}
          description={t`Due to your current medical situation, at this time we cannot make a refill request, you must see a provider first.`}
          customAction={
            <div className="flex flex-col-reverse items-center gap-2.5 sm:flex-row">
              <Button
                onClick={closeDisqualifiedScreen}
                variant="secondary"
                size="lg"
                className="w-full sm:w-fit"
              >
                {t`Go Back`}
              </Button>

              <Button
                onClick={() =>
                  navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE)
                }
                size="lg"
                className="w-full sm:w-fit"
              >
                {t`Continue`}
              </Button>
            </div>
          }
        />
      )}
    </EmbeddablesOnboardingScreenLayout>
  );
};
