import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import {
  Button,
  ConfettiFixedIconMono,
  IconWrapper,
  Shape7,
  Shape11,
} from "@/ui";
import { tw } from "@/utils";

interface SuccessFullScreenAlertProps {
  title: string;
  description: string;
  customShapes?: JSX.Element;
  customAction?: JSX.Element;
  className?: string;
  subtext?: string;
}

export const SuccessFullScreenAlert = ({
  title,
  description,
  customAction,
  className,
  subtext,
}: SuccessFullScreenAlertProps) => (
  <div className="fixed left-0 top-0 z-50">
    <div
      className={tw(
        "flex h-dvh w-screen items-center justify-center overflow-hidden bg-nature-02",
        className,
      )}
    >
      <div className="flex h-full w-desktop overflow-y-auto overflow-x-hidden sm:items-center">
        <div className="fixed hidden h-desktop w-desktop justify-end sm:flex">
          <div className="relative w-full max-w-xl shrink-0 text-nature-08">
            <div className="absolute top-1/2 h-dvh w-screen -translate-y-1/2 bg-nature-09" />
            <IconWrapper className="absolute right-48 top-10 size-112">
              <Shape7 />
            </IconWrapper>
            <IconWrapper className="absolute -bottom-32 -right-20 size-112 -scale-x-100">
              <Shape11 />
            </IconWrapper>
          </div>
        </div>
        <div className="z-10 flex max-w-4xl flex-col gap-6 px-6 pt-12 text-nature-10 sm:gap-8 sm:p-20 sm:pb-0">
          <header className="relative flex flex-col items-center gap-2 pb-16 text-salmon-01 sm:items-start sm:gap-0 sm:pb-0 sm:text-inherit">
            <div className="size-20">
              <ConfettiFixedIconMono color="nature" />
            </div>
            <h1 className="text-center font-serif text-4xl leading-snug sm:text-left sm:text-6xl sm:leading-snug">
              {title}
            </h1>

            <div className="absolute bottom-0 -z-10 h-219 w-219 rounded-full bg-nature-09 sm:hidden" />
          </header>

          <p className="max-h-72 grow text-xl sm:max-h-fit sm:grow-0">
            {description}
          </p>
          {subtext && (
            <p className="max-h-72 grow text-xl sm:max-h-fit sm:grow-0">
              {subtext}
            </p>
          )}

          <div className="pb-10 sm:pb-20">
            {customAction ?? (
              <Button asChild size="lg" className="w-full sm:w-fit">
                <Link to={ROUTES.BASE}>{t`Back to home`}</Link>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
