import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import { tw } from "@/utils";
import { CardPrimitive, Skeleton as SkeletonPrimitive } from "../common";
import { Label as LabelPrimitive } from "./Label";
import { RadioIndicator } from "./RadioIndicator";

const ItemIndicator = RadioIndicator;
const ItemDescription = CardPrimitive.Description;

const Root = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={tw("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
Root.displayName = RadioGroupPrimitive.Root.displayName;

const Item = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={tw(
        "relative flex cursor-pointer flex-col rounded-2xl border border-brown-04 bg-brown-01 p-4 pr-12 text-left shadow-sm focus:outline-none focus-visible:ring-nature-03 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-nature-02",
        className,
      )}
      {...props}
    />
  );
});
Item.displayName = RadioGroupPrimitive.Item.displayName;

const ItemLabel = forwardRef<
  ElementRef<typeof LabelPrimitive>,
  ComponentPropsWithoutRef<typeof LabelPrimitive>
>(({ className, ...props }, ref) => {
  return (
    <LabelPrimitive
      ref={ref}
      size="md"
      className={tw("cursor-pointer font-bold leading-5", className)}
      {...props}
    />
  );
});
ItemLabel.displayName = LabelPrimitive.displayName;

const Skeleton = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={tw("grid gap-2", className)} {...props}>
        {Array.from({ length: 3 }, (_, index) => `${index}`).map((i) => (
          <div
            key={`radio-group-skeleton-${i}`}
            className="flex flex-col gap-3 rounded-2xl border border-brown-04 bg-brown-01 p-4 shadow-sm"
          >
            <div className="flex items-center justify-between gap-4">
              <SkeletonPrimitive className="h-4 w-2/5" />
              <SkeletonPrimitive className="size-5" />
            </div>

            <SkeletonPrimitive className="h-3 w-3/5" />
          </div>
        ))}
      </div>
    );
  },
);
Skeleton.displayName = "RadioGroupCards.Skeleton";

export { Root, Item, ItemIndicator, ItemLabel, ItemDescription, Skeleton };
