import type { ServiceResponse } from "@/api";
import { MEDICATIONS_DOMAIN, privateAPI } from "@/api";
import type { MedicationRequest } from "@/shared.types";

export const getPastMedicationsQuery = () => ({
  queryKey: [MEDICATIONS_DOMAIN, "getPastMedicationsQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<MedicationRequest[]>>(
      "/patients/past-medications",
    );

    return data.data;
  },
});
