import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { Button, DocumentMedicineFixedIcon, Shape8, Shape9 } from "@/ui";
import { DocumentCard } from ".";

export const DocumentCenterDoctorNotes = () => {
  return (
    <DocumentCard.Root>
      <DocumentCard.Banner className="bg-salmon-03">
        <DocumentCard.IconWrapper>
          <DocumentMedicineFixedIcon />
        </DocumentCard.IconWrapper>

        <div className="absolute -z-10 flex h-full w-full justify-center text-salmon-04">
          <Shape8 className="h-64 -translate-x-20 -translate-y-3.5 self-start opacity-70" />
          <Shape9 className="w-64 -translate-y-3 translate-x-2 self-center opacity-70" />
        </div>
      </DocumentCard.Banner>

      <DocumentCard.Content>
        <DocumentCard.Title>{t`Doctor's Notes`}</DocumentCard.Title>
        <DocumentCard.Description>{t`Access your doctor's notes and recommendations.`}</DocumentCard.Description>
      </DocumentCard.Content>

      <DocumentCard.Actions>
        <Button asChild variant="secondary" size="lg">
          <Link
            to={ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES}
          >{t`View details`}</Link>
        </Button>
      </DocumentCard.Actions>
    </DocumentCard.Root>
  );
};
