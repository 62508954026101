import { useBoolean } from "@/hooks";
import { usePatientConditions } from "./usePatientConditions";

export const useJourneyRequirements = () => {
  const { isOverAge, hasAppointmentRequiredState, hasBundleSubscription } =
    usePatientConditions();

  const {
    value: requiresSubscriptionUpdate,
    setTrue: requireSubscriptionUpdate,
  } = useBoolean(hasBundleSubscription && isOverAge);

  const {
    value: hasPreviousRequestFilter,
    setFalse: removeMedicationRequestFilter,
  } = useBoolean(hasBundleSubscription && !isOverAge);

  return {
    requiresAppointment: isOverAge || hasAppointmentRequiredState,
    hasPreviousRequestFilter,
    requiresSubscriptionUpdate,

    actions: {
      requireSubscriptionUpdate,
      removeMedicationRequestFilter,
    },
  };
};
