import { t } from "ttag";

import { ROUTES } from "@/router";
import { Header, LightbulbMinimalisticIcon, Shape10 } from "@/ui";

export const OverviewHeader = () => {
  return (
    <Header.Root>
      <Header.Background>
        <Shape10 className="size-40 -translate-y-3 text-nature-10 opacity-30" />
      </Header.Background>

      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.COACHING_HUB.OVERVIEW,
              icon: LightbulbMinimalisticIcon,
              label: t`Coaching hub`,
            },
          ]}
        />

        <Header.Title>{t`Coaching hub`}</Header.Title>
      </Header.Content>
    </Header.Root>
  );
};
