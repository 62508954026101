import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { Button, illustratedIcons, Modal } from "@/ui";

export const UrgentCareModal = ({ show, onClose }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<illustratedIcons.Warning />}
      title={t`You might need urgent care`}
      description={t`It seems your needs require more specialized attention than what we currently offer. Please seek care immediately at the nearest ER or urgent care in your community for the specific assistance you require.`}
    >
      <Button
        variant="primary"
        size="lg"
        onClick={() => navigate(ROUTES.MESSAGES)}
      >
        {t`Contact Fridays support`}
      </Button>
    </Modal>
  );
};
