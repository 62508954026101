export const SKELETON_DATA = [
  { x: 1, y: 350 },
  { x: 3, y: 300 },
  { x: 12, y: 240 },
  { x: 15, y: 250 },
  { x: 18, y: 230 },
];

export const data = [
  { x: 1, y: 150 },
  { x: 2, y: 152 },
  { x: 3, y: 148 },
  { x: 4, y: 151 },
  { x: 5, y: 153 },
  { x: 6, y: 149 },
  { x: 7, y: 150 },
  { x: 8, y: 154 },
  { x: 9, y: 155 },
  { x: 10, y: 153 },
  { x: 11, y: 151 },
  { x: 12, y: 150 },
  { x: 13, y: 152 },
  { x: 14, y: 149 },
  { x: 15, y: 148 },
  { x: 16, y: 151 },
  { x: 17, y: 152 },
  { x: 18, y: 150 },
  { x: 19, y: 153 },
  { x: 20, y: 154 },
  { x: 21, y: 152 },
  { x: 22, y: 150 },
  { x: 23, y: 149 },
  { x: 24, y: 151 },
  { x: 25, y: 150 },
  { x: 26, y: 152 },
  { x: 27, y: 153 },
  { x: 28, y: 149 },
  { x: 29, y: 150 },
  { x: 30, y: 151 },
];
