import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { useSubscriptionStatus } from "@/hooks";
import { MODAL_ROUTES, ROUTES, useNavigateModal } from "@/router";
import { DocumentsIcon, Header, IconWrapper, TestTubeIcon } from "@/ui";

export const LabsDetailsHeader = () => {
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();

  const { isActiveUser } = useSubscriptionStatus();

  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: DocumentsIcon,
              label: t`Document center`,
            },
            {
              href: ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES,
              icon: TestTubeIcon,
              label: t`Labs`,
            },
          ]}
        />

        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-2">
            <Header.GoBackButton
              onClick={() => navigate(ROUTES.DOCUMENT_CENTER.OVERVIEW)}
              className="md:hidden"
            />
            <Header.Title>{t`Labs`}</Header.Title>
          </div>
          <Header.Actions>
            {isActiveUser && (
              <Header.Button
                onClick={() => navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.LABS)}
                className="hidden md:flex"
              >
                <IconWrapper size="sm">
                  <TestTubeIcon />
                </IconWrapper>
                {t`Add lab result`}
              </Header.Button>
            )}
          </Header.Actions>
        </div>

        <Header.Description>{t`Browse through your Lab Results to find specific documents. Tap on a document to view it in detail.`}</Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
