import type { SVGProps } from "@/shared.types";

export const TranslateIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M22.5 20.25L17.25 9.75L12 20.25"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 17.25H21"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 3V5.25"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 5.25H15"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5.25C12 7.63695 11.0518 9.92613 9.36396 11.614C7.67613 13.3018 5.38695 14.25 3 14.25"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5127 8.25C7.13319 10.005 8.2826 11.5245 9.8026 12.599C11.3226 13.6736 13.1384 14.2504 14.9999 14.25"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
