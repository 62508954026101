import type { HTMLAttributes, ReactNode } from "react";
import { createContext, forwardRef, useContext, useState } from "react";
import { t } from "ttag";

import { tw } from "@/utils";
import { Button } from "./Button";

interface Step {
  value: string;
  children?: ReactNode;
}

interface StepperProps {
  steps: readonly Step[];
}

interface StepperContextValue extends StepperProps {
  activeStep: number;
  isLastStep: boolean;
  isFirstStep: boolean;
  nextStep: () => void;
  prevStep: () => void;
}

const StepperContext = createContext<StepperContextValue | null>(null);

export const useStepper = () => {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error("useStepper must be used within a <Stepper.Root />");
  }

  return context;
};

const Root = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & StepperProps
>(({ className, children, steps, ...props }, ref) => {
  const [activeStep, setActiveStep] = useState(0);

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;

  const nextStep = () => {
    if (!isLastStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const prevStep = () => {
    if (!isFirstStep) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <StepperContext.Provider
      value={{ steps, activeStep, isFirstStep, isLastStep, nextStep, prevStep }}
    >
      <div
        ref={ref}
        className={tw("flex w-full flex-col items-center", className)}
        {...props}
      >
        {children}
      </div>
    </StepperContext.Provider>
  );
});
Root.displayName = "Stepper.Root";

const Bar = forwardRef<HTMLOListElement, HTMLAttributes<HTMLOListElement>>(
  (props, ref) => {
    const { steps, activeStep } = useStepper();
    return (
      <ol ref={ref} {...props} className="flex gap-1.5 py-2">
        {steps.map((step, index) => (
          <li
            key={step.value}
            className={tw(
              "h-2 w-11 rounded-full border border-nature-05 transition-colors duration-500",
              activeStep === index && "border-nature-10 bg-nature-10",
              (activeStep - 1 === index || activeStep + 1 === index) &&
                "border-nature-03 bg-nature-03",
            )}
          />
        ))}
      </ol>
    );
  },
);
Bar.displayName = "Stepper.Bar";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { steps, activeStep } = useStepper();
    return (
      <div ref={ref} className={tw("w-full", className)} {...props}>
        {steps[activeStep]?.children}
      </div>
    );
  },
);
Content.displayName = "Stepper.Content";

const Actions = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    onComplete?: () => void;
    completeLabel?: string;
  }
>(({ onComplete, completeLabel, ...props }, ref) => {
  const { prevStep, nextStep, isFirstStep, isLastStep } = useStepper();

  const completeButtonLabel = isLastStep && completeLabel;
  return (
    <div
      className="flex w-full flex-col-reverse items-center justify-center gap-3.5 md:flex-row"
      ref={ref}
      {...props}
    >
      {!isFirstStep && (
        <Button
          onClick={prevStep}
          size="lg"
          variant="secondary"
          className="w-full md:w-fit"
        >
          {t`Previous step`}
        </Button>
      )}
      {!isLastStep && (
        <Button
          size="lg"
          onClick={nextStep}
          className="w-full md:w-fit"
        >{t`Next step`}</Button>
      )}
      {onComplete && completeButtonLabel && (
        <Button size="lg" onClick={onComplete} className="w-full md:w-fit">
          {completeButtonLabel}
        </Button>
      )}
    </div>
  );
});
Actions.displayName = "Stepper.Actions";

export { Root, Bar, Content, Actions };
