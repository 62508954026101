import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import {
  CalendarMinimalisticIcon,
  DocumentAddIcon,
  HandShakeIcon,
  Header,
} from "@/ui";

interface FollowUpFormHeaderProps {
  description: string;
}

export const FollowUpFormHeader = ({
  description,
}: FollowUpFormHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Header.Root variant="secondary" className="max-w-2xl">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.CONSULTATIONS.OVERVIEW,
              icon: CalendarMinimalisticIcon,
              label: t`Consultations`,
            },
            {
              href: ROUTES.CONSULTATIONS.PURPOSE,
              icon: HandShakeIcon,
              label: t`Consultation Purpose`,
            },
            {
              href: ROUTES.CONSULTATIONS.FOLLOW_UP_FORM,
              icon: DocumentAddIcon,
              label: t`Follow up form`,
            },
          ]}
        />

        <div className="flex items-center gap-2">
          <Header.GoBackButton
            onClick={() => navigate(ROUTES.CONSULTATIONS.PURPOSE)}
            className="md:hidden"
          />
          <Header.Title>{t`Follow up form`}</Header.Title>
        </div>

        <Header.Description>{description}</Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
