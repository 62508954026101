import { useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { getUpcomingConsultationsQuery } from "@/api";
import {
  HealthieForm,
  OnboardingHeader,
  OpenLoopConsentCheckbox,
} from "@/components";
import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import { HEALTHIE_FORM_TYPE } from "@/shared.constants";
import { usePortalUserStore } from "@/stores";
import { AltArrowRightIcon, Button, Checkbox } from "@/ui";

export const MissingIntakeForm = () => {
  const [openLoopConsent, setOpenLoopConsent] = useState<CheckedState>();
  const [medicationConsent, setMedicationConsent] = useState<CheckedState>();
  const { data: consultations } = useQuery(getUpcomingConsultationsQuery());

  const formType = consultations?.length
    ? HEALTHIE_FORM_TYPE.ONBOARDING_VIDEO_CONSULTATION
    : HEALTHIE_FORM_TYPE.ONBOARDING_REFILL;

  const navigate = useNavigate();

  const patientId = usePortalUserStore((state) => state.portalUser?.patientId);

  if (!patientId) {
    return null;
  }

  return (
    <ScreenLayout>
      <OnboardingHeader
        title={t`Intake form questions`}
        description={t`(*) Mandatory field`}
      />

      <HealthieForm
        onSubmit={() => navigate(ROUTES.HOME)}
        formType={formType}
        patientId={patientId}
        className="self-center"
      >
        <div className="flex flex-col gap-3 text-brown-10">
          <OpenLoopConsentCheckbox
            checked={openLoopConsent}
            onCheckedChange={setOpenLoopConsent}
          />
          <Checkbox
            checked={medicationConsent}
            onCheckedChange={setMedicationConsent}
            id="medication-consent-checkbox"
            label={t`I consent to only get weight-loss medication from this platform`}
          />
        </div>

        <div className="flex items-center justify-end py-7">
          <Button
            type="submit"
            disabled={!(openLoopConsent && medicationConsent)}
            size="lg"
            className="mb-4 w-full sm:w-fit"
          >
            {t`Next`}

            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </HealthieForm>
    </ScreenLayout>
  );
};
