import type { SVGProps } from "@/shared.types";

export const LikeIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.29214 2.35293C8.19543 2.32186 8.08968 2.32939 7.99985 2.37268C7.9015 2.42007 7.8365 2.50278 7.81316 2.59279L7.49602 3.81536C7.38511 4.24291 7.22363 4.6553 7.01565 5.04464C6.69277 5.6491 6.20422 6.11399 5.75098 6.50455L4.79182 7.33108C4.64317 7.45918 4.56493 7.6512 4.58188 7.84718L5.12332 14.109C5.15068 14.4253 5.41496 14.6673 5.7309 14.6673H8.82998C10.9209 14.6673 12.6825 13.2125 13.0204 11.2587L13.4908 8.53891C13.5555 8.16459 13.2676 7.82345 12.8899 7.82345H9.43579C8.78438 7.82345 8.28959 7.23903 8.39476 6.59713L8.83655 3.90075C8.89706 3.53141 8.87978 3.15347 8.7858 2.79121C8.73812 2.60738 8.59306 2.4496 8.38878 2.38397L8.29214 2.35293L8.44506 1.87689L8.29214 2.35293ZM7.56575 1.47181C7.88748 1.31678 8.25817 1.29168 8.59799 1.40085L8.69463 1.43189L8.54171 1.90793L8.69464 1.43189C9.21264 1.5983 9.6168 2.01211 9.75377 2.54012C9.8827 3.03716 9.90641 3.55569 9.82339 4.06244L9.3816 6.75881C9.37595 6.79332 9.40254 6.82345 9.43579 6.82345H12.8899C13.8894 6.82345 14.6463 7.72499 14.4761 8.70931L14.0058 11.4292C13.5805 13.888 11.3808 15.6673 8.82998 15.6673H5.7309C4.89513 15.6673 4.19901 15.0275 4.12704 14.1951L3.5856 7.93332C3.541 7.41751 3.74681 6.91152 4.13903 6.57354L5.09819 5.74701C5.535 5.3706 5.90269 5.00576 6.13361 4.57348C6.30466 4.25327 6.43715 3.9147 6.52805 3.56427L6.84519 2.3417C6.94468 1.95816 7.21109 1.64271 7.56575 1.47181ZM1.97844 6.82392C2.24595 6.81237 2.47507 7.01361 2.49814 7.28038L3.14587 14.7714C3.18748 15.2526 2.80846 15.6673 2.32447 15.6673C1.86859 15.6673 1.5 15.2975 1.5 14.8425V7.32345C1.5 7.05569 1.71093 6.83546 1.97844 6.82392Z"
    />
  </svg>
);
