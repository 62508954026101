import { jt, t } from "ttag";

import {
  ChangeToCoachingModal,
  FullRefundModal,
} from "@/domains/embeddables-onboarding/components";
import { useBoolean } from "@/hooks";
import {
  AltArrowLeftIcon,
  FullScreenAlert,
  HeartsCircleFixedIconMono,
  IconWrapper,
} from "@/ui";

interface AgeDQScreenAlertProps {
  onGoBack: () => void;
}

export const BMISurveyDQScreenAlert = ({ onGoBack }: AgeDQScreenAlertProps) => {
  const {
    value: showRefundModal,
    setTrue: openRefundModal,
    setFalse: closeRefundModal,
  } = useBoolean(false);
  const {
    value: showChangeCoachingSubscriptionModal,
    setTrue: openChangeCoachingSubscriptionModal,
    setFalse: closeChangeCoachingSubscriptionModal,
  } = useBoolean(false);

  const fridaysCoachingStrong = (
    <strong key="fridays-coaching-bmi-survey-dq">{t`Fridays Coaching`}</strong>
  );

  return (
    <FullScreenAlert.Root open={true}>
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          <IconWrapper
            onClick={onGoBack}
            as="button"
            className="absolute left-0 translate-y-5 md:static md:-translate-y-5"
          >
            <AltArrowLeftIcon />
          </IconWrapper>

          <HeartsCircleFixedIconMono className="size-16 md:size-20" />
          <FullScreenAlert.Title>
            {t`Treatments Not a Fit?`}
            <br />
            {t`Discover Fridays Coaching!`}
          </FullScreenAlert.Title>
        </FullScreenAlert.Header>

        <FullScreenAlert.Description>
          {jt`While our treatments may not meet your current needs, we encourage you to explore ${fridaysCoachingStrong}—an amazing way to kickstart your wellness journey through sessions designed to help you stay motivated and thriving. If you decide it’s not the right fit, no worries—we’re happy to provide a full refund.`}
        </FullScreenAlert.Description>

        <FullScreenAlert.Footer>
          <FullScreenAlert.Cancel onClick={openRefundModal}>
            {t`Get full refund`}
          </FullScreenAlert.Cancel>

          <FullScreenAlert.Action onClick={openChangeCoachingSubscriptionModal}>
            {t`Explore Fridays Coaching`}
          </FullScreenAlert.Action>
        </FullScreenAlert.Footer>
      </FullScreenAlert.Content>

      <FullRefundModal show={showRefundModal} onClose={closeRefundModal} />

      <ChangeToCoachingModal
        show={showChangeCoachingSubscriptionModal}
        onClose={closeChangeCoachingSubscriptionModal}
      />
    </FullScreenAlert.Root>
  );
};
