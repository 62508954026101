import {
  ComingSoonCard,
  NutritionCounseling,
  SeshLinkCard,
} from "./components";

import "./components/NutritionCounseling";

export const MySessionsOverview = () => {
  return (
    <div className="flex flex-col gap-4 py-5">
      <div className="flex flex-col gap-4 lg:flex-row">
        <ComingSoonCard />

        <SeshLinkCard />
      </div>

      <NutritionCounseling />
    </div>
  );
};
