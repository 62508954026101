import { t } from "ttag";

import { usePrefetchPlans } from "@/domains/billing/hooks/usePrefetchPatientPlans";
import { PLAN_DURATION } from "@/domains/billing/models";
import { Tabs } from "@/ui";

export const TabList = () => {
  const { prefetchPatientPlans } = usePrefetchPlans();

  return (
    <Tabs.List>
      <Tabs.Trigger
        value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
        className="flex-col"
        onMouseEnter={() =>
          prefetchPatientPlans({
            durationInMonths: PLAN_DURATION.MONTHLY.VALUE,
          })
        }
      >
        <h3 className="text-sm font-medium">{t`Monthly`}</h3>
        <p className="hidden text-xs lg:block">{t`Get a 1 month supply`}</p>
      </Tabs.Trigger>

      <Tabs.Trigger
        value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
        className="flex-col"
        onMouseEnter={() =>
          prefetchPatientPlans({
            durationInMonths: PLAN_DURATION.QUARTERLY.VALUE,
          })
        }
      >
        <h3 className="text-sm font-medium">{t`Bundle`}</h3>
        <p className="hidden text-xs lg:block">
          {t`Cover all 3 months of doses with a single order`}
        </p>
      </Tabs.Trigger>
    </Tabs.List>
  );
};
