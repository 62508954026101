import type { ServiceResponse } from "@/api";
import { privateAPI, publicAPI, STRIPE_DOMAIN } from "@/api";
import type { Payment, PaymentMethod } from "@/shared.types";
import type { Coupon, CouponParams, Plan, PlansParams } from "../models";

export const getOnboardingPatientPlans = async (params: PlansParams) => {
  const { data } = await publicAPI.get<ServiceResponse<Plan[]>>(`/plans`, {
    params,
  });

  return data.data;
};

export const getPatientPlans = async (params: PlansParams) => {
  const { data } = await privateAPI.get<ServiceResponse<Plan[]>>(`/plans`, {
    params,
  });

  return data.data;
};

export const getCoachingPlans = async () => {
  const { data } =
    await privateAPI.get<ServiceResponse<Plan[]>>(`/coaching-plans`);

  return data.data;
};

// TODO: add to query store
export const getPaymentHistoryQuery = (lastPayment: string | undefined) => ({
  queryKey: [STRIPE_DOMAIN, "getPaymentHistoryQuery", lastPayment],
  queryFn: async () => {
    const response = await privateAPI.get<ServiceResponse<Payment[]>>(
      `/patients/payment-information`,
      { params: { startAfter: lastPayment } },
    );

    return response.data;
  },
});

// // TODO: add to query store
export const getPaymentMethodQuery = () => ({
  queryKey: [STRIPE_DOMAIN, "getPaymentMethodQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<PaymentMethod>>(
      `/patients/payment-method`,
    );

    return data.data;
  },
});

export const refundAndCancelSubscription = async () => {
  const { data } =
    await privateAPI.delete<ServiceResponse<null>>("/patients/plans");

  return data.data;
};

export const getMonthlySubscriptionPlan = async (
  subscriptionPriceId: Plan["subscriptionPriceId"],
) => {
  const { data } = await publicAPI.get<ServiceResponse<Plan>>(
    `/plans/${subscriptionPriceId}`,
    {
      params: { months: 1 },
    },
  );

  return data.data;
};

export const changeSubscriptionPlan = async (
  subscriptionPriceId: Plan["subscriptionPriceId"],
) => {
  const { data } = await privateAPI.put<ServiceResponse<null>>(
    `/patients/plans/change-subscription`,
    { subscriptionPriceId },
  );

  return data.data;
};

export const applyCouponCode = async (params: CouponParams) => {
  const { data } = await publicAPI.post<ServiceResponse<Coupon>>(
    `/validate-coupon-code`,
    params,
  );

  return data.data;
};
