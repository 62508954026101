import type { ComponentPropsWithoutRef } from "react";

import { tw } from "@/utils";

export interface MessageProps extends ComponentPropsWithoutRef<"p"> {
  message?: string;
  error?: string | boolean;
}

export const Message = ({ message, error, className }: MessageProps) => (
  <p
    className={tw(
      "block text-left text-sm text-brown-07",
      className,

      !!error && "text-red-07",
    )}
  >
    {error === true ? "\u200b" : !error ? (message ?? "\u200b") : error}
  </p>
);
