import { ScreenLayout } from "@/layouts";
import { tw } from "@/utils";
import {
  BillingHeader,
  CurrentSubscriptionCard,
  PaymentHistory,
  PaymentMethod,
} from "./sections";

export const Overview = () => {
  return (
    <ScreenLayout>
      <BillingHeader />

      <div className={tw("flex flex-col-reverse gap-3 xl:flex-row")}>
        <CurrentSubscriptionCard />
        <PaymentMethod />
      </div>

      <PaymentHistory />
    </ScreenLayout>
  );
};
