import { t } from "ttag";

import { DocumentUploads } from "@/components";
import { DOCUMENT_FOLDER } from "@/shared.constants";
import type { ModalProps } from "@/shared.types";
import { FolderCheckFixedIconMono, Modal } from "@/ui";

export const MedicalRecordUploadsModal = ({ show, onClose }: ModalProps) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<FolderCheckFixedIconMono />}
      title={t`Add medical records`}
    >
      <DocumentUploads
        folder={DOCUMENT_FOLDER.OTHER}
        onCancel={onClose}
        onSuccess={onClose}
      />
    </Modal>
  );
};
