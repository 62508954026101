import { t } from "ttag";

import type { ObjectValue } from "@/shared.types";

export const REFILL_FORM_STEP = {
  SYMPTOMS_FORM: "symptoms",
  INJECTIONS_FORM: "injections",
  FILTER_FORM: "filter",
  HEALTHIE_FORM: "healthie",
} as const;

export type RefillFormStep = ObjectValue<typeof REFILL_FORM_STEP>;

export const getRefillFormHeaderDescription = (step: RefillFormStep) =>
  ({
    [REFILL_FORM_STEP.SYMPTOMS_FORM]: t`These questions are required before further medication can be prescribed. Keep in mind that if you select any of the symptoms listed on this screen, you won't be able to continue with the Fridays program.`,
    [REFILL_FORM_STEP.INJECTIONS_FORM]: t`This question is required before further medication can be prescribed.`,
    [REFILL_FORM_STEP.FILTER_FORM]: t`Please spare a moment to fill out the medication refill request form. Your input is vital in facilitating a smooth and efficient refill process customized to your requirements.`,
    [REFILL_FORM_STEP.HEALTHIE_FORM]: t`Please spare a moment to fill out the medication refill request form. Your input is vital in facilitating a smooth and efficient refill process customized to your requirements.`,
  })[step];
