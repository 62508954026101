import { Navigate } from "react-router-dom";
import { jt, t } from "ttag";

import {
  ChangeToCoachingModal,
  FullRefundModal,
} from "@/domains/embeddables-onboarding/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { useBoolean } from "@/hooks";
import {
  AltArrowLeftIcon,
  FullScreenAlert,
  HeartsCircleFixedIconMono,
  IconWrapper,
} from "@/ui";
import { calculateAgeByDOB } from "@/utils";

interface AgeDQScreenAlertProps {
  onGoBack: () => void;
}

export const AgeDQScreenAlert = ({ onGoBack }: AgeDQScreenAlertProps) => {
  const {
    value: showRefundModal,
    setTrue: openRefundModal,
    setFalse: closeRefundModal,
  } = useBoolean(false);
  const {
    value: showChangeCoachingSubscriptionModal,
    setTrue: openChangeCoachingSubscriptionModal,
    setFalse: closeChangeCoachingSubscriptionModal,
  } = useBoolean(false);

  const { mainInfoFormValues } = useEmbeddablesForms();

  if (!mainInfoFormValues) {
    return <Navigate to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO} replace />;
  }

  const underAge = calculateAgeByDOB(mainInfoFormValues.dob) < 18;

  const title = underAge ? (
    t`It looks like you're outside the age range for Fridays`
  ) : (
    <>
      {t`Not in the age range?`}
      <br />
      {t`Discover Fridays Coaching!`}
    </>
  );

  const fridaysCoachingStrong = (
    <strong key="fridays-coaching-age-dq">{t`Fridays Coaching`}</strong>
  );

  const description = underAge
    ? t`Fridays is available only to individuals aged 18 and older. If you're under 18, you're not eligible to participate, and we'll issue a refund for your purchase. Keep that positive energy going—there are other great options out there to support your wellness journey!`
    : jt`While our treatments may not meet your current needs, we encourage you to explore ${fridaysCoachingStrong}—an amazing way to kickstart your wellness journey through sessions designed to help you stay motivated and thriving. If you decide it's not the right fit, no worries—we're happy to provide a full refund.`;

  const underAgeFooter = (
    <FullScreenAlert.Footer>
      <FullScreenAlert.Cancel onClick={onGoBack}>
        {t`Go back`}
      </FullScreenAlert.Cancel>

      <FullScreenAlert.Action onClick={openRefundModal}>
        {t`Get full refund`}
      </FullScreenAlert.Action>
    </FullScreenAlert.Footer>
  );

  const normalFooter = (
    <FullScreenAlert.Footer>
      <FullScreenAlert.Cancel onClick={openRefundModal}>
        {t`Get full refund`}
      </FullScreenAlert.Cancel>

      <FullScreenAlert.Action onClick={openChangeCoachingSubscriptionModal}>
        {t`Explore Fridays Coaching`}
      </FullScreenAlert.Action>
    </FullScreenAlert.Footer>
  );

  return (
    <FullScreenAlert.Root open={true}>
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          {!underAge && (
            <IconWrapper
              onClick={onGoBack}
              as="button"
              className="absolute left-0 translate-y-5 md:static md:-translate-y-5"
            >
              <AltArrowLeftIcon />
            </IconWrapper>
          )}
          <HeartsCircleFixedIconMono className="size-16 md:size-20" />
          <FullScreenAlert.Title>{title}</FullScreenAlert.Title>
        </FullScreenAlert.Header>

        <FullScreenAlert.Description>{description}</FullScreenAlert.Description>

        {underAge ? underAgeFooter : normalFooter}
      </FullScreenAlert.Content>

      <FullRefundModal show={showRefundModal} onClose={closeRefundModal} />

      <ChangeToCoachingModal
        show={showChangeCoachingSubscriptionModal}
        onClose={closeChangeCoachingSubscriptionModal}
      />
    </FullScreenAlert.Root>
  );
};
