import type { ComponentPropsWithoutRef } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import type { AnswerGroupOption } from "@/shared.types";
import { RadioIndicator } from "@/ui";
import { tw } from "@/utils";

type RadioGroupProps = ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Root
>;

type AnswerRadioGroupProps<TValue> = Omit<
  RadioGroupProps,
  "id" | "value" | "onValueChange"
> & {
  options: readonly AnswerGroupOption<TValue>[];
  id: string;
  warning?: JSX.Element;
  value?: TValue;
  onValueChange: (value: TValue) => void;
};

export const AnswerRadioGroup = <TValue extends string | number>({
  id,
  options,
  warning,
  className,
  value,
  onValueChange,
  ...rest
}: AnswerRadioGroupProps<TValue>) => {
  return (
    <div className={tw("flex flex-col gap-1.5")}>
      <RadioGroupPrimitive.Root
        className={tw(
          "grid gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
          className,
        )}
        value={value as string}
        onValueChange={(v) => onValueChange(v as TValue)}
        {...rest}
      >
        {options.map(({ value, label, clarification, showWarning }) => (
          <RadioGroupPrimitive.Item
            asChild
            id={`${id}-${value}`}
            key={value}
            // Although RadioGroupPrimitive.Root expects a `value` of type `string`,
            // it is compatible with `number` types as expected for `radio inputs`.
            // Since the `radio input` can be either `string` or `number`, casting to `string`
            // is necessary for compatibility with the radix library component's type.
            value={value as string}
          >
            <div className="group flex grow cursor-pointer gap-2 rounded-2xl border border-brown-05 bg-brown-01 p-4 data-[state=checked]:border-brown-04 data-[state=checked]:bg-brown-04">
              <RadioIndicator className="mt-1" />

              <label
                htmlFor={`${id}-${value}`}
                className="flex grow cursor-pointer flex-col items-start gap-0.5 text-left text-brown-10"
              >
                {label}
                <span className="text-xs font-medium text-brown-07">
                  {clarification}
                </span>
              </label>

              {showWarning && warning}
            </div>
          </RadioGroupPrimitive.Item>
        ))}
      </RadioGroupPrimitive.Root>
    </div>
  );
};
