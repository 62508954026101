import { forwardRef } from "react";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

import { Label, RadioIndicator } from "@/ui";

interface RadioGroupCardsProps
  extends ComponentPropsWithoutRef<typeof RadioGroup.Root> {
  options: readonly {
    value: string;
    label: string;
  }[];
}

export const RadioGroupCards = forwardRef<
  ElementRef<typeof RadioGroup.Root>,
  RadioGroupCardsProps
>(({ options, id, ...props }, ref) => {
  return (
    <RadioGroup.Root
      ref={ref}
      id={id}
      className="flex flex-col flex-wrap items-center gap-6 md:flex-row"
      {...props}
    >
      {options.map((option, idx) => (
        <RadioGroup.Item
          key={option.value}
          value={option.value}
          id={`${id}-${idx}-${option.value}`}
          className="flex w-full grow items-center gap-2 rounded-2xl border border-transparent bg-brown-01 p-4 hover:border-brown-05 hover:bg-brown-01 data-[state=checked]:border-brown-06 data-[state=checked]:bg-white md:w-fit"
        >
          <RadioIndicator />
          <Label
            htmlFor={`${id}-${idx}-${option.value}`}
            className="cursor-pointer"
          >
            {option.label}
          </Label>
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
});

RadioGroupCards.displayName = "RadioGroupCards";
