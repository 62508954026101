import type { SVGProps } from "@/shared.types";

export const LinkIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M3.78633 3.8102C6.1623 1.4054 10.0138 1.4054 12.3897 3.8102C14.7667 6.21601 14.7667 10.1173 12.3897 12.5232C10.0138 14.928 6.1623 14.928 3.78633 12.5232C3.5302 12.2639 3.11417 12.2639 2.85804 12.5232C2.60291 12.7814 2.60291 13.1993 2.85804 13.4576C5.74628 16.3808 10.4298 16.3808 13.318 13.4576C16.2053 10.5353 16.2053 5.79808 13.318 2.8758C10.4298 -0.0474763 5.74628 -0.0474763 2.85804 2.8758C2.60291 3.13403 2.60291 3.55197 2.85804 3.8102C3.11417 4.06944 3.5302 4.06944 3.78633 3.8102ZM8.3682 4.80527C8.11207 4.54604 7.69604 4.54604 7.43991 4.80527C7.18478 5.0635 7.18477 5.48144 7.43991 5.73967L9.18412 7.50505L0.505617 7.50505C0.141897 7.50505 -0.149878 7.80298 -0.149878 8.16668C-0.149878 8.53037 0.141897 8.82831 0.505616 8.82831L9.18412 8.8283L7.43991 10.5937C7.18477 10.8519 7.18477 11.2698 7.43991 11.5281C7.69604 11.7873 8.11207 11.7873 8.3682 11.5281L11.2277 8.63388C11.4828 8.37565 11.4828 7.95771 11.2277 7.69948L8.3682 4.80527Z"
      strokeWidth="0.3"
      strokeLinecap="round"
    />
  </svg>
);
