import { Link } from "react-router-dom";
import { t } from "ttag";

import { useSubscriptionStatus } from "@/hooks";
import { MODAL_ROUTES, ROUTES, useNavigateModal } from "@/router";
import { Button, Shape3, Shape7, StethoscopeFixedIcon } from "@/ui";
import { DocumentCard } from ".";

export const DocumentCenterPrescriptions = () => {
  const navigateModal = useNavigateModal();

  const { isActiveUser } = useSubscriptionStatus();

  return (
    <DocumentCard.Root>
      <DocumentCard.Banner className="bg-brown-03">
        <DocumentCard.IconWrapper>
          <StethoscopeFixedIcon />
        </DocumentCard.IconWrapper>

        <div className="absolute -z-10 flex h-full w-full justify-center gap-20 text-brown-04">
          <Shape3 className="w-44 translate-y-8 -scale-x-100 self-start opacity-60" />
          <Shape7 className="w-48 translate-x-6 translate-y-5 self-center opacity-60" />
        </div>
      </DocumentCard.Banner>

      <DocumentCard.Content>
        <DocumentCard.Title>{t`Previous Prescriptions`}</DocumentCard.Title>
        <DocumentCard.Description>{t`Upload your past prescriptions for your provider to access.`}</DocumentCard.Description>
      </DocumentCard.Content>

      <DocumentCard.Actions>
        <Button asChild variant="secondary" size="lg">
          <Link
            to={ROUTES.DOCUMENT_CENTER.PRESCRIPTIONS}
          >{t`View details`}</Link>
        </Button>

        {isActiveUser && (
          <Button
            onClick={() =>
              navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.PRESCRIPTIONS)
            }
            size="lg"
          >
            {t`Upload prescription`}
          </Button>
        )}
      </DocumentCard.Actions>
    </DocumentCard.Root>
  );
};
