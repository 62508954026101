import { t } from "ttag";

import { ROUTES } from "@/router";
import { DocumentsIcon, Header } from "@/ui";

export const DocumentCenterHeader = () => {
  return (
    <Header.Root>
      <Header.Background />

      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: DocumentsIcon,
              label: t`Document center`,
            },
          ]}
        />

        <Header.Title>{t`My documents`}</Header.Title>

        <Header.Description>
          {t`Welcome to your Document Center! Here, you can view, manage, and upload all your health documents easily.`}
          <br />
          {t`Navigate through different folders to find specific information quickly.`}
        </Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
