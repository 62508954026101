import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { Shape8 } from "@/ui";
import { tw } from "@/utils";

const Root = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "relative z-10 flex flex-col gap-4 rounded-2xl bg-salmon-03 p-4 px-4 py-5 text-salmon-10 lg:flex-row lg:items-center lg:gap-7 lg:px-8 lg:py-6",
        className,
      )}
      {...props}
    >
      <div className="pointer-events-none absolute inset-0 -z-10 flex h-full w-full flex-row justify-end gap-2.5 overflow-hidden rounded-2xl bg-nature-03 text-nature-05 lg:justify-end">
        <Shape8 className="size-60 translate-x-6 translate-y-12 -scale-x-100 self-start text-nature-04 opacity-40 lg:-translate-y-6" />
      </div>

      {children}
    </div>
  ),
);
Root.displayName = "CoachingCard.Root";

const Content = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex grow flex-col gap-4 pt-2.5 text-salmon-10 lg:pt-0",
        className,
      )}
      {...props}
    />
  ),
);
Content.displayName = "CoachingCard.Content";

const Title = forwardRef<ElementRef<"h3">, ComponentPropsWithoutRef<"h3">>(
  ({ className, children, ...props }, ref) => (
    <h3
      ref={ref}
      className={tw("text-2xl font-bold text-nature-09", className)}
      {...props}
    >
      {children}
    </h3>
  ),
);
Title.displayName = "CoachingCard.Title";

const Tagline = forwardRef<ElementRef<"h4">, ComponentPropsWithoutRef<"h4">>(
  ({ className, children, ...props }, ref) => (
    <h4 ref={ref} className={tw("text-sm", className)} {...props}>
      {children}
    </h4>
  ),
);
Tagline.displayName = "CoachingCard.Tagline";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p ref={ref} className={tw("text-sm", className)} {...props} />
  ),
);
Description.displayName = "CoachingCard.Description";

export { Root, Content, Title, Tagline, Description };
