import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { login } from "@/api";
import { ROUTES } from "@/router";
import { getEmailSchema, getPasswordSchema } from "@/shared.schemas";
import type { InferSchema } from "@/shared.types";
import { authStore, useAppSettingsStore } from "@/stores";
import { Alert, Button, icons, Input, validateError } from "@/ui";
import { AnimatedLogo } from "./AnimatedLogo";

const getLoginSchema = () => getEmailSchema().merge(getPasswordSchema());

export const LoginForm = () => {
  const setLanguage = useAppSettingsStore((state) => state.setLanguage);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const { mutate: loginMutation, isPending } = useMutation({
    mutationFn: login.mutation,
    onSuccess: (data) => {
      authStore.setToken(data.accessToken);
      setLanguage(data.user.preferredLanguage);
      navigate(ROUTES.BASE);
    },
    onError: (error) => {
      const validatedError = validateError(error);

      if (validatedError) {
        setErrorMessage(validatedError.response.data.error.message);
      }
    },
  });

  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
  } = useForm<InferSchema<typeof getLoginSchema>>({
    resolver: zodResolver(getLoginSchema()),
  });
  return (
    <div className="flex h-full flex-col sm:w-152 sm:justify-between">
      <AnimatedLogo />
      <header className="relative flex flex-col gap-3 px-6 pb-20 pt-14 text-nature-02 sm:h-full sm:max-h-80 sm:justify-between sm:gap-5 sm:px-14 sm:pb-8 sm:text-brown-09">
        <div className="h-20" />

        <div className="flex flex-col gap-2 text-center sm:text-left">
          <h2 className="text-2xl tracking-wide sm:text-brown-10">
            {t`Login`}
          </h2>
          <p className="font-light sm:font-normal">
            {t`It's nice to see you again!`}
            <br />
            {t`Enter your login details below to continue.`}
          </p>
        </div>

        <div className="absolute bottom-0 left-1/2 -z-10 h-219 w-219 -translate-x-1/2 rounded-full bg-nature-07 sm:hidden" />
      </header>
      <form
        onSubmit={handleSubmit((values) => loginMutation(values))}
        className="flex flex-col gap-16 px-6 py-10 sm:gap-10 sm:px-14 sm:pt-0"
      >
        <div className="flex flex-col gap-4">
          <Input
            compact={false}
            id="login-email"
            autoComplete="email"
            label={t`Email`}
            placeholder={t`email@example.com`}
            left={<icons.CheckBadge />}
            {...register("email")}
            error={errors.email?.message}
          />

          <div className="relative">
            <Input
              compact={false}
              id="login-password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label={t`Password`}
              placeholder={t`your password here`}
              left={<icons.LockOpen />}
              right={
                showPassword ? (
                  <icons.OpenedEye
                    onClick={() => setShowPassword(false)}
                    className="cursor-pointer"
                  />
                ) : (
                  <icons.ClosedEye
                    onClick={() => setShowPassword(true)}
                    className="cursor-pointer"
                  />
                )
              }
              {...register("password")}
              error={errors.password?.message}
            />
            <Link
              to={ROUTES.FORGOT_PASSWORD}
              className="absolute bottom-0 right-0 text-sm text-brown-07"
            >
              {t`Forgot your password?`}
            </Link>
          </div>

          {errorMessage && <Alert type="error">{errorMessage}</Alert>}
        </div>

        <div className="flex flex-col gap-3 py-2">
          <Button
            type="submit"
            className="w-full"
            size="lg"
            disabled={!isDirty || isPending}
            onClick={() => setErrorMessage("")}
          >
            {t`Log In`}
          </Button>
          <Button
            size="lg"
            variant="tertiary"
            onClick={() => navigate(ROUTES.ONBOARDING.MAIN_INFO)}
            className="w-full"
          >
            {t`Create my account`}
          </Button>
        </div>
      </form>
    </div>
  );
};
