import { t } from "ttag";

import { PaymentStatusChip } from "@/components";
import type { Payment } from "@/shared.types";
import { icons, TableCard } from "@/ui";
import { formatBackendDate } from "@/utils";

interface PaymentHistoryCardsProps {
  payments?: Payment[];
}

export const PaymentHistoryCards = ({ payments }: PaymentHistoryCardsProps) => {
  if (!payments?.length) {
    return null;
  }

  return (
    <TableCard.Root className="bg-white">
      {payments?.map(
        ({
          id,
          status,
          detail,
          amount,
          hostedInvoiceUrl,
          date,
          resolution,
        }) => (
          <TableCard.Row key={`past-payment-${id}`}>
            {resolution && (
              <p className="inline-flex gap-1 text-left text-sm font-medium leading-4 text-red-07">
                {resolution}
              </p>
            )}

            <div className="flex gap-2">
              <div className="flex flex-1 flex-col gap-2">
                <TableCard.Cell className="justify-between">
                  <TableCard.Data className="text-lg">{detail}</TableCard.Data>
                </TableCard.Cell>

                <TableCard.Cell>
                  <TableCard.Label>{t`Amount:`}</TableCard.Label>
                  <TableCard.Data>${amount}</TableCard.Data>
                </TableCard.Cell>

                <TableCard.Cell>
                  <TableCard.Label>{t`Date:`}</TableCard.Label>
                  <TableCard.Data>{formatBackendDate(date)}</TableCard.Data>
                </TableCard.Cell>
              </div>

              <div className="flex flex-col items-center justify-between gap-2 sm:items-end">
                <PaymentStatusChip status={status} className="w-fit" />

                {hostedInvoiceUrl && (
                  <a
                    href={hostedInvoiceUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-0.5 self-end text-nature-08"
                    onClick={() => window.open(hostedInvoiceUrl)}
                  >
                    <icons.Invoice className="size-4" />
                    {t`Invoice`}
                  </a>
                )}
              </div>
            </div>
          </TableCard.Row>
        ),
      )}
    </TableCard.Root>
  );
};
