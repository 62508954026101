import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { useScheduleConsultationContext } from "@/contexts";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";

export const Submit = () => {
  const {
    actions: { setScheduleVideoConsultationFormValues },
  } = useEmbeddablesForms();

  const {
    language,
    scheduleDate: { timeSlot },
  } = useScheduleConsultationContext();

  const navigate = useNavigate();

  const handleContinue = () => {
    if (timeSlot) {
      setScheduleVideoConsultationFormValues({ timeSlot, language });
      navigate(EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_QUESTIONS);
    }
  };

  return (
    <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
      <Button
        onClick={() => navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY)}
        variant="secondary"
        size="lg"
        className="w-full sm:w-fit"
      >
        <AltArrowLeftIcon className="hidden size-4 sm:block" />
        {t`Back`}
      </Button>

      <Button
        disabled={!timeSlot}
        onClick={handleContinue}
        size="lg"
        className="w-full sm:w-fit"
      >
        {t`Confirm Consultation`}
        <AltArrowRightIcon className="hidden size-4 sm:block" />
      </Button>
    </div>
  );
};
