import type { QueryClient } from "@tanstack/react-query";

import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";
import { PATIENT_DOMAIN } from "./patient";

export const STRIPE_DOMAIN = "stripe";

interface CreateStripeSubscriptionParams {
  userId: number;
  couponCode: string | null;
  subscriptionPriceId: string;
}

export const createStripeSubscription = {
  mutation: async ({
    userId,
    couponCode,
    subscriptionPriceId,
  }: CreateStripeSubscriptionParams) => {
    const { data } = await publicAPI.post<
      ServiceResponse<{ subscriptionId: string; clientSecret: string }>
    >(`/patients/${userId}/create-subscription`, {
      couponCode,
      subscriptionPriceId,
    });

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [STRIPE_DOMAIN] });
  },
};

export const updatePaymentMethod = {
  mutation: async (params: { paymentMethodId?: string; userId?: number }) => {
    const response = await privateAPI.put<ServiceResponse<void>>(
      `/patients/payment-method`,
      params,
    );

    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [STRIPE_DOMAIN] });
  },
};

export const scheduleSubscription = {
  mutation: async ({ paymentMethodId }: { paymentMethodId?: string }) => {
    const response = await privateAPI.post<ServiceResponse<void>>(
      `/patients/schedule-subscription`,
      { paymentMethodId },
    );

    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [PATIENT_DOMAIN] });
  },
};
