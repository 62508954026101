import { useState } from "react";
import { useDatePicker } from "@rehookify/datepicker";

import {
  AltArrowLeftIcon,
  AltArrowRightIcon,
  IconWrapper,
  YearSelect,
} from "@/ui";
import { tw } from "@/utils";

interface DatePickerProps {
  date: Date;
  onChange: (date: Date) => void;
  mode: "yearly" | "monthly";
}
export const PeriodPicker = ({ date, onChange, mode }: DatePickerProps) => {
  const [initialDate] = useState(date);

  const {
    data: { calendars, years },
    propGetters: { addOffset, subtractOffset },
  } = useDatePicker({
    selectedDates: [date],
    onDatesChange: () => null,
    offsetDate: date,
    onOffsetChange: onChange,
    years: {
      mode: "exact",
      numberOfYears: 10,
      step: 0,
    },
    dates: {
      mode: "single",
      maxDate: initialDate,
      minDate: new Date(
        initialDate.getFullYear() - 9,
        initialDate.getMonth(),
        1,
      ),
    },
  });

  const handleNewYear = (year: number) => {
    const updatedOffsetDate = new Date(year, date.getMonth());
    onChange(updatedOffsetDate);
  };

  const { month } = calendars[0]!;

  return (
    <div className="flex w-fit items-center gap-4">
      <IconWrapper
        as="button"
        type="button"
        {...subtractOffset(mode === "monthly" ? { months: 1 } : { years: 1 })}
        aria-label="Previous month"
        className="disabled:opacity-30"
      >
        <AltArrowLeftIcon />
      </IconWrapper>

      <div
        className={tw(
          "flex grow items-center justify-center gap-1",
          mode === "monthly" && "w-42",
        )}
      >
        {mode === "monthly" && (
          <span className="font-medium leading-5 text-salmon-10">{month}</span>
        )}
        <YearSelect
          years={years}
          year={date.getFullYear()}
          onChange={handleNewYear}
          className="bg-salmon-03 hover:bg-salmon-04 focus:bg-salmon-04 data-[state=open]:bg-salmon-04"
        />
      </div>

      <IconWrapper
        as="button"
        type="button"
        {...addOffset(mode === "monthly" ? { months: 1 } : { years: 1 })}
        aria-label="Next month"
        className="disabled:opacity-30"
      >
        <AltArrowRightIcon />
      </IconWrapper>
    </div>
  );
};
