import { t } from "ttag";

import { ScreenLayout } from "@/layouts";
import { Badge, Tabs } from "@/ui";
import {
  MySessionsOverview,
  OverviewHeader,
  PastSessionsOverview,
} from "./sections";

const COACHING_TAB = {
  MY_SESSIONS: "my-sessions",
  PAST_SESSIONS: "past-sessions",
} as const;

export const CoachingHubOverview = () => (
  <ScreenLayout>
    <OverviewHeader />

    <Tabs.Root defaultValue={COACHING_TAB.MY_SESSIONS}>
      <Tabs.List>
        <Tabs.Trigger value={COACHING_TAB.MY_SESSIONS} className="gap-x-2">
          {t`My sessions`}
          <Badge
            variant="secondary"
            className="-my-1 border-brown-06 bg-brown-06 group-data-[state=active]:border-nature-07 group-data-[state=active]:bg-nature-07"
          >
            {t`soon`}
          </Badge>
        </Tabs.Trigger>
        <Tabs.Trigger
          value={COACHING_TAB.PAST_SESSIONS}
        >{t`Past sessions`}</Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value={COACHING_TAB.MY_SESSIONS}>
        <MySessionsOverview />
      </Tabs.Content>
      <Tabs.Content value={COACHING_TAB.PAST_SESSIONS}>
        <PastSessionsOverview />
      </Tabs.Content>
    </Tabs.Root>
  </ScreenLayout>
);
