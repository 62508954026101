import { Link } from "react-router-dom";
import { jt, t } from "ttag";

import { useUserSubscription } from "@/domains/billing/hooks";
import { env } from "@/env";
import { useBoolean } from "@/hooks";
import { ROUTES } from "@/router";
import {
  Alert,
  Button,
  ConfettiFixedIconMono,
  DetailsCard,
  Dialog,
  IconWrapper,
  JarOfPillsIcon,
  PillIcon,
} from "@/ui";
import { ChangeSubscriptionConfirmationModal } from "./ChangeSubscriptionConfirmationModal";
import { KeepCurrentSubscriptionModal } from "./KeepCurrentSubscriptionModal";

export const ChangePlanOpenLoopModal = () => {
  const { data: userSubscription } = useUserSubscription();

  const showRequestedChangeByOpenLoop = Boolean(
    userSubscription?.requestedPlanChange?.subscriptionPriceId !==
      env.VITE_STRIPE_BRANDED_MONTHLY_PRICE_ID,
  );

  const { value: showModal, setFalse: onClose } = useBoolean(true);
  const {
    value: showConfirmationModal,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
  } = useBoolean(false);
  const {
    value: showKeepModal,
    setTrue: openKeepModal,
    setFalse: closeKeepModal,
  } = useBoolean(false);

  const br = <br key="br" />;
  const messagesLink = (
    <Link key="link-to-messages" to={ROUTES.MESSAGES} className="underline">
      {t`send us a message`}
    </Link>
  );

  if (!userSubscription) {
    return null;
  }

  const { requestedPlanChange, currentPeriodPlan } = userSubscription;

  return (
    <Dialog.Root
      open={showRequestedChangeByOpenLoop && showModal}
      onOpenChange={onClose}
    >
      {requestedPlanChange && currentPeriodPlan && (
        <Dialog.Content size="modal">
          <div className="flex h-full flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <ConfettiFixedIconMono color="salmon" />
              </Dialog.Icon>
              <Dialog.Title>{t`Subscription Update Needed`}</Dialog.Title>
              <Dialog.Description>
                {/* TODO: BE - send the prescribed medication & update the description */}
                {t`You have been prescribed the medication for the ${requestedPlanChange.name} plan, but you are currently subscribed to the ${currentPeriodPlan.name} plan. To receive your medication, you will need to change your subscription.`}
              </Dialog.Description>
            </Dialog.Header>

            <div className="flex grow flex-col gap-2">
              <DetailsCard.Root>
                <DetailsCard.Item>
                  <DetailsCard.Label>
                    <IconWrapper size="sm" as="span">
                      <JarOfPillsIcon />
                    </IconWrapper>
                    {t`Current Plan:`}
                  </DetailsCard.Label>

                  <DetailsCard.Description>
                    {currentPeriodPlan.name}
                    <DetailsCard.Separator />${currentPeriodPlan.price}/
                    {t`month`}
                  </DetailsCard.Description>
                </DetailsCard.Item>

                <DetailsCard.Item>
                  <DetailsCard.Label>
                    <IconWrapper size="sm" as="span">
                      <PillIcon />
                    </IconWrapper>
                    {t`New Plan:`}
                  </DetailsCard.Label>

                  <DetailsCard.Description>
                    {requestedPlanChange.name}
                    <DetailsCard.Separator />${requestedPlanChange.price}/
                    {t`month`}
                  </DetailsCard.Description>
                </DetailsCard.Item>
              </DetailsCard.Root>

              <Alert>
                {jt`Approving this change will update your subscription and charge $${requestedPlanChange.price} to your card on file. If your new subscription is less expensive, a partial refund may be issued. If you don't approve, you'll continue to receive medications according to your current subscription.${br}If you need additional help or prefer to cancel all orders, please ${messagesLink} in the portal.`}
              </Alert>
            </div>

            <Dialog.Footer variant="sticky">
              <Button
                onClick={openKeepModal}
                size="lg"
                variant="secondary"
              >{t`Don't approve change`}</Button>
              <Button
                onClick={openConfirmationModal}
                size="lg"
              >{t`Approve change`}</Button>
            </Dialog.Footer>
          </div>

          <KeepCurrentSubscriptionModal
            show={showKeepModal}
            onClose={closeKeepModal}
            onConfirm={() => {
              onClose();
              closeKeepModal();
            }}
          />
          <ChangeSubscriptionConfirmationModal
            show={showConfirmationModal}
            onClose={closeConfirmationModal}
            onConfirm={() => {
              onClose();
              closeConfirmationModal();
            }}
          />
        </Dialog.Content>
      )}
    </Dialog.Root>
  );
};
