import { t } from "ttag";

import { OnboardingHeader } from "@/components";
import { EmbeddablesOnboardingScreenLayout } from "@/domains/embeddables-onboarding/components";
import { MeasurementsForm } from "./sections";

export const Measurements = () => (
  <EmbeddablesOnboardingScreenLayout>
    <OnboardingHeader
      title={t`Your main info`}
      description={t`(*) Mandatory field`}
    />

    <MeasurementsForm />
  </EmbeddablesOnboardingScreenLayout>
);
