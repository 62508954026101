import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPastConsultationsQuery } from "@/api";
import { getDocumentQuery } from "@/api/documents";
import { VARIANT } from "@/shared.constants";
import {
  icons,
  IconWrapper,
  illustratedIcons,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/ui";
import { Loading } from "@/ui/common/Loading";
import { Pagination } from "@/ui/common/Pagination";
import { formatBackendDate, formatConsultationTime, tw } from "@/utils";
import { PastConsultationCard } from "./PastConsultationCard";

export const PastVideoConsultations = () => {
  const [consultationsOffset, setConsultationsOffset] = useState(0);
  const { data, isLoading } = useQuery({
    ...getPastConsultationsQuery(consultationsOffset),
  });

  const consultations = data?.data;
  const pagination = data?.pagination;
  const [documentId, setDocumentId] = useState<string | null>(null);

  const { data: documentUrl } = useQuery({
    ...getDocumentQuery(documentId),
  });

  useEffect(() => {
    if (documentUrl) {
      window.open(documentUrl.url, "_blank");
      setDocumentId(null);
    }
  }, [documentUrl]);

  return (
    <section className="flex flex-1 flex-col gap-4 pb-10 text-brown-10">
      <h3 className="flex items-center gap-2 text-lg font-bold">
        <IconWrapper size="sm">
          <icons.VideoCamera />
        </IconWrapper>
        {t`Past Video Consultations`}
      </h3>
      {isLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <Loading variant={VARIANT.PRIMARY} />
        </div>
      ) : !consultations?.length ? (
        <div className="flex flex-col items-center justify-center p-4">
          <illustratedIcons.LightBulb />
          <p className="text-xl font-medium text-brown-05">
            {t`You haven't had any consultation yet`}
          </p>
        </div>
      ) : (
        <div>
          <Table className="hidden md:table">
            <TableHeader>
              <TableRow>
                <TableHead>{t`Provider`}</TableHead>
                <TableHead>{t`Date`}</TableHead>
                <TableHead>{t`Time`}</TableHead>
                <TableHead className="w-44">{t`Status`}</TableHead>
                <TableHead className="w-24">{t`Notes`}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {consultations.map((consultation) => (
                <TableRow key={`past-consultation-${consultation.id}`}>
                  <TableCell>{consultation.provider.fullName}</TableCell>
                  <TableCell>
                    {formatBackendDate(consultation.startTime)}
                  </TableCell>
                  <TableCell>
                    {`${formatConsultationTime(
                      consultation.startTime,
                    )} - ${formatConsultationTime(consultation.endTime)} ${
                      consultation.timezone
                    }`}
                  </TableCell>
                  <TableCell
                    className={tw(
                      "font-bold",
                      consultation.pmStatus === "Cancelled" && "text-red-07",
                    )}
                  >
                    {consultation.pmStatus}
                  </TableCell>
                  <TableCell>
                    {consultation.documentId ? (
                      <IconWrapper
                        size="xl"
                        className="cursor-pointer rounded-full bg-salmon-06 fill-nature-01 p-2"
                      >
                        <icons.Document
                          onClick={() =>
                            setDocumentId(consultation.documentId!)
                          }
                        />
                      </IconWrapper>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="mb-2 flex flex-col items-stretch gap-2 md:hidden">
            {consultations.map((consultation) => (
              <PastConsultationCard
                key={consultation.id}
                consultation={consultation}
                setDocumentId={setDocumentId}
              />
            ))}
          </div>
          {pagination && pagination?.totalPages > 1 && (
            <Pagination
              {...pagination}
              onPageChange={(page) => {
                setConsultationsOffset((page - 1) * pagination.perPage);
              }}
            />
          )}
        </div>
      )}
    </section>
  );
};
