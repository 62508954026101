import { t } from "ttag";

import { COACHING_SESSION_TYPE } from "@/shared.constants";
import { getEnumValues } from "@/utils";
import { CoachingSessionCard } from "./components";

export const PastSessionsOverview = () => {
  return (
    <div className="flex flex-col gap-4 py-5">
      <h3 className="text-center font-medium leading-5 text-nature-08 md:text-left">{t`Check out the sessions you missed! Stay updated on everything.`}</h3>
      <div className="flex flex-col gap-4.5">
        {getEnumValues(COACHING_SESSION_TYPE).map((sessionType) => (
          <CoachingSessionCard key={sessionType} sessionType={sessionType} />
        ))}
      </div>
    </div>
  );
};
