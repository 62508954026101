import { t } from "ttag";

import { usePrefetchPlans } from "@/domains/billing/hooks";
import { PLAN_DURATION } from "@/domains/billing/models";
import { useOnboardingStore } from "@/stores";
import { Tabs } from "@/ui";
import { OnboardingPatientPlans } from "./components";

export const SubscriptionContent = () => {
  const setSubscriptionPlan = useOnboardingStore(
    (state) => state.setSubscriptionPlan,
  );
  const couponCode = useOnboardingStore((state) => state.limitedCouponCode);

  const { prefetchOnboardingPlans } = usePrefetchPlans();

  return (
    <Tabs.Root
      defaultValue={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
      onValueChange={() => setSubscriptionPlan(null)}
      className="flex h-full flex-col"
    >
      <Tabs.List>
        <Tabs.Trigger
          value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
          className="flex-col"
          onMouseEnter={() =>
            prefetchOnboardingPlans({
              durationInMonths: PLAN_DURATION.MONTHLY.VALUE,
              couponCode,
            })
          }
        >
          <h4 className="text-sm font-medium capitalize">
            {PLAN_DURATION.MONTHLY.TEXT_VALUE}
          </h4>
        </Tabs.Trigger>

        <Tabs.Trigger
          value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
          className="flex-col"
          onMouseEnter={() =>
            prefetchOnboardingPlans({
              durationInMonths: PLAN_DURATION.QUARTERLY.VALUE,
              couponCode,
            })
          }
        >
          <h4 className="text-sm font-medium">{t`Bundle`}</h4>
        </Tabs.Trigger>
      </Tabs.List>

      <div className="grow lg:py-4">
        <Tabs.Content
          value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
          className="flex flex-col gap-6"
        >
          <p className="pt-2 text-center text-sm">
            {t`Unlock exclusive benefits with our easy-access subscription. Join today and start enjoying the perks!`}
          </p>
          <OnboardingPatientPlans
            durationInMonths={PLAN_DURATION.MONTHLY.VALUE}
          />
        </Tabs.Content>

        <Tabs.Content
          value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
          className="flex flex-col gap-6"
        >
          <p className="pt-2 text-center text-sm">
            <strong>{t`Cover all 3 months of doses with a single order!`}</strong>
            {t` No fuss—place your order and check in with us monthly to track your progress.`}
          </p>
          <OnboardingPatientPlans
            durationInMonths={PLAN_DURATION.QUARTERLY.VALUE}
          />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
};
