import { t } from "ttag";

import { DocumentUploads } from "@/components";
import { DOCUMENT_FOLDER } from "@/shared.constants";
import type { ModalProps } from "@/shared.types";
import { Modal, TestTubeFixedIconMono } from "@/ui";

export const LabUploadsModal = ({ show, onClose }: ModalProps) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<TestTubeFixedIconMono />}
      title={t`Add lab test`}
      description={t`Upload your lab tests!`}
    >
      <DocumentUploads
        folder={DOCUMENT_FOLDER.LABS}
        onCancel={onClose}
        onSuccess={onClose}
      />
    </Modal>
  );
};
