import type { HTMLAttributes, LabelHTMLAttributes } from "react";
import { forwardRef } from "react";

import { tw } from "@/utils";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "rounded-2xl border border-salmon-05 bg-salmon-01 p-4 md:hidden",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "TableCard.Root";

const Row = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex flex-col gap-2 border-b border-brown-04 p-4 last:border-b-0",
        className,
      )}
      {...props}
    />
  ),
);
Row.displayName = "TableCard.Row";

const Cell = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("flex gap-2", className)} {...props} />
  ),
);
Cell.displayName = "TableCard.Cell";

const Label = forwardRef<
  HTMLLabelElement,
  LabelHTMLAttributes<HTMLLabelElement>
>(({ className, htmlFor, ...props }, ref) => (
  <label
    htmlFor={htmlFor}
    ref={ref}
    className={tw("text-salmon-09", className)}
    {...props}
  />
));
Label.displayName = "TableCard.Label";

const Data = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={tw("font-bold text-salmon-10", className)}
    {...props}
  />
));
Data.displayName = "TableCard.Data";

export { Root, Row, Cell, Label, Data };
