import { Shape8, Shape10, Shape11 } from "@/ui";

export const NatureBackground = () => {
  return (
    <div className="fixed -z-10 m-auto h-desktop w-desktop bg-nature-10 text-nature-09">
      <div className="relative h-full w-full">
        <Shape8 className="absolute -left-1/3 top-52 size-[1180px] rotate-180 opacity-40" />
        <Shape10 className="absolute bottom-2 right-72 size-[298px] rotate-180 opacity-40" />
        <Shape10 className="absolute -top-44 left-72 size-[298px] rotate-90 -scale-x-[1] opacity-40" />
        <Shape11 className="absolute -right-96 -top-44 size-[889px] -rotate-90 opacity-40" />
      </div>
    </div>
  );
};
