import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { jt, t } from "ttag";

import { IMAGE_FILE_TYPES } from "@/shared.constants";
import type { ModalProps } from "@/shared.types";
import { Dialog } from "@/ui";

interface PreviewPDFProps extends ModalProps {
  url: string;
  title?: string;
}

export const PreviewPDF = ({ show, onClose, url, title }: PreviewPDFProps) => {
  const externalLink = (
    <a
      key={url}
      href={url}
      rel="noreferrer noopener"
      target="_blank"
      className="text-nature-07 underline underline-offset-2"
    >
      {t`here`}.
    </a>
  );

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content>
        <div className="flex h-full flex-col gap-4">
          <VisuallyHidden asChild>
            <Dialog.Description>
              {t`This modal provides a preview of the selected pdf, allowing you to view its content without downloading`}
            </Dialog.Description>
          </VisuallyHidden>

          {title ? (
            <Dialog.Title className="shrink-0 px-3 md:px-0">
              {title}
            </Dialog.Title>
          ) : (
            <VisuallyHidden asChild>
              <Dialog.Title>{t`Preview PDF`}</Dialog.Title>
            </VisuallyHidden>
          )}

          <div className="flex grow flex-col gap-2">
            <div className="grow rounded-lg bg-salmon-03">
              <iframe
                title="test"
                src={url}
                itemType={IMAGE_FILE_TYPES.PDF}
                className="size-full"
              />
            </div>

            <p className="w-fit text-sm text-salmon-10 md:mt-0 md:self-end md:text-right md:text-base">
              {jt`Can't view the file correctly? Open it ${externalLink}`}
            </p>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
