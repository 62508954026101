import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import type { Measurements } from "@/domains/embeddables-onboarding/models";
import { getMeasurementsSchema } from "@/domains/embeddables-onboarding/models";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { AltArrowLeftIcon, AltArrowRightIcon, Button, Form, Input } from "@/ui";
import { BMIScoreAlert } from "./components";

export const MeasurementsForm = () => {
  const {
    measurementsFormValues,
    actions: { setMeasurementsFormValues },
  } = useEmbeddablesForms();

  const form = useForm<Measurements>({
    resolver: zodResolver(getMeasurementsSchema()),
    defaultValues: measurementsFormValues,
  });

  const navigate = useNavigate();

  const onSubmit = form.handleSubmit((values) => {
    setMeasurementsFormValues(values);
    navigate(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_BMI_SURVEY);
  });

  return (
    <Form.Provider {...form}>
      <form
        onSubmit={onSubmit}
        className="mx-auto flex w-full max-w-3xl grow flex-col gap-y-8"
      >
        <div className="flex grow flex-col gap-3">
          <Form.Item>
            <Form.Label>{t`Height (feet) *`}</Form.Label>
            <Form.Control>
              <Input
                type="number"
                {...form.register("heightFeet", { setValueAs: Number })}
                placeholder={t`E.g. 5`}
              />
            </Form.Control>
            <Form.Message compact={false}>
              {form.formState.errors.heightFeet?.message}
            </Form.Message>
          </Form.Item>

          <Form.Item>
            <Form.Label>{t`Height (inches) *`}</Form.Label>
            <Form.Control>
              <Input
                type="number"
                {...form.register("heightInches", { setValueAs: Number })}
                placeholder={t`E.g. 3`}
              />
            </Form.Control>
            <Form.Message compact={false}>
              {form.formState.errors.heightInches?.message}
            </Form.Message>
          </Form.Item>

          <Form.Item>
            <Form.Label>{t`Weight (pounds) *`}</Form.Label>
            <Form.Control>
              <Input
                type="number"
                {...form.register("weight", { setValueAs: Number })}
                placeholder={t`E.g. 286`}
              />
            </Form.Control>
            <Form.Message compact={false}>
              {form.formState.errors.weight?.message}
            </Form.Message>
          </Form.Item>

          <BMIScoreAlert control={form.control} />
        </div>

        <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
          <Button
            variant="secondary"
            size="lg"
            className="w-full sm:w-fit"
            asChild
          >
            <Link to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO}>
              <AltArrowLeftIcon className="hidden size-4 sm:block" />
              {t`Back`}
            </Link>
          </Button>

          <Button type="submit" size="lg" className="w-full sm:w-fit">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </form>
    </Form.Provider>
  );
};
