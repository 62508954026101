import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useRefundAndCancelSubscription } from "@/domains/billing/hooks";
import type { ModalProps } from "@/shared.types";
import { BrokenHeartFixedIconMono, Button, Dialog } from "@/ui";

export const FullRefundModal = ({ show, onClose }: ModalProps) => {
  const refundAndCancelSubscriptionMutation = useRefundAndCancelSubscription();

  const handleCancelAccount = () =>
    refundAndCancelSubscriptionMutation.mutate();

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal" className="h-fit">
        <div className="flex size-full flex-col gap-6">
          <div className="flex grow flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <BrokenHeartFixedIconMono />
              </Dialog.Icon>
              <Dialog.Title>{t`We're sorry to see you go`}</Dialog.Title>
              <Dialog.Description>
                {t`A full refund will be issued to the card on file. This will cancel your account permanently and cannot be undone.`}
              </Dialog.Description>
            </Dialog.Header>
          </div>

          <Dialog.Footer>
            <Button onClick={onClose} variant="secondary" size="lg">
              {t`Go back`}
            </Button>
            <Button size="lg" onClick={handleCancelAccount}>
              {t`Cancel account`}
            </Button>
          </Dialog.Footer>
        </div>

        {refundAndCancelSubscriptionMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
