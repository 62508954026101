import type { QueryClient } from "@tanstack/react-query";

import type {
  DocumentFolder,
  OnboardingImageUploadsValues,
  OnboardingUser,
  UploadFilesValues,
} from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { publicAPI } from "./axios";

const DOMAIN = "onboarding";

const parseUploadedFilesParams = (params: UploadFilesValues[]) =>
  params.map((uploadedFolder) => ({
    type: uploadedFolder.type,
    files: uploadedFolder.files.map((field) => ({
      id: field.id,
      name: field.name,
    })),
  }));

export const submitOnboardingUploadedDocuments = {
  mutation: async (
    params: UploadFilesValues[],
    code: OnboardingUser["code"],
  ) => {
    const { data } = await publicAPI.post<ServiceResponse<null>>(
      "/temp-files/upload-files-onboarding",
      {
        uploadedFiles: parseUploadedFilesParams(params),
      },
      { headers: { Authorization: code } },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

interface UploadIndividualOnboardingFileParams {
  userId: OnboardingUser["id"];
  file: Record<string, File | null>;
}
export const uploadIndividualOnboardingFile = {
  mutation: async (params: UploadIndividualOnboardingFileParams) => {
    const response = await publicAPI.post<ServiceResponse<null>>(
      `/patients/${params.userId}/onboarding-files`,
      params.file,
      { headers: { "Content-Type": "multipart/form-data" } },
    );

    return response.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

interface UploadOnboardingImagesParams extends OnboardingImageUploadsValues {
  userId: OnboardingUser["id"];
  code: OnboardingUser["code"];
}

export const uploadOnboardingImages = {
  mutation: async (params: UploadOnboardingImagesParams) => {
    const { userId, code, uploadedFiles, ...files } = params;

    const individualFilesPromises = Object.entries(files)
      .filter(([_, file]) => Boolean(file))
      .map(([key, value]) =>
        uploadIndividualOnboardingFile.mutation({
          file: { [key]: value },
          userId,
        }),
      );

    const uploadedFilesPromise = submitOnboardingUploadedDocuments.mutation(
      Object.entries(uploadedFiles)
        .filter(([_, files]) => Boolean(files?.length))
        .map(([key, value]) => ({ type: key as DocumentFolder, files: value })),
      code,
    );

    const results = await Promise.all([
      ...individualFilesPromises,
      uploadedFilesPromise,
    ]);

    return results;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};
