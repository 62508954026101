export interface Rewardful {
  convert: (email?: string) => void;
}

declare const rewardful: (event: string, data: unknown) => void;

export const convert = (email?: string) => {
  rewardful("ready", function () {
    // @ts-expect-error - rewardful is a global variable
    if ((Rewardful as unknown).referral) {
      rewardful("convert", { email });
    }
  });
};

export const getCouponCode = () => {
  let couponCode: string | null = null;

  rewardful("ready", function () {
    // @ts-expect-error - rewardful is a global variable
    if ((Rewardful as unknown).coupon) {
      // @ts-expect-error - rewardful is a global variable
      couponCode = ((Rewardful as unknown).coupon as unknown).id as string;
    }
  });
  return couponCode;
};

export const getDiscountText = () => {
  let text: string | null = null;

  rewardful("ready", function () {
    // @ts-expect-error - rewardful is a global variable
    if ((Rewardful as unknown).coupon) {
      // @ts-expect-error - rewardful is a global variable
      if (((Rewardful as unknown).coupon as unknown).amount_off as string) {
        text = `$${
          // @ts-expect-error - rewardful is a global variable
          (((Rewardful as unknown).coupon as unknown).amount_off as number) /
          100
        }`;
      }
      // @ts-expect-error - rewardful is a global variable
      if (((Rewardful as unknown).coupon as unknown).percent_off as string) {
        text = `${
          // @ts-expect-error - rewardful is a global variable
          ((Rewardful as unknown).coupon as unknown).percent_off as string
        }%`;
      }
    }
  });

  return text;
};
