import { motion } from "framer-motion";

import { shapes } from "@/ui";

const insideShapesAnimation = {
  scale: [0, 1, 0],
  opacity: [0.4, 1, 0],
  transition: { duration: 2.7, delay: 0.7, ease: "circOut" },
};

const connerShapesTransition = {
  duration: 3.2,
  times: [0.22, 0.66, 1],
  ease: ["circOut", "easeInOut"],
};

export const AnimatedBackground = () => {
  return (
    <motion.div
      animate={{
        opacity: 0,
        transitionEnd: { display: "none" },
      }}
      transition={{ duration: 1.5, ease: "easeInOut", delay: 2 }}
      className="absolute h-full w-full bg-nature-10"
    >
      <motion.div
        animate={{
          translate: ["-100% -100%", "0% 0%", "-100% -100%"],
          transition: connerShapesTransition,
        }}
        className="absolute hidden sm:block"
      >
        <shapes.PartialDoughnut2 direction="right" shapeColor="tertiary" />
      </motion.div>

      <motion.div
        animate={{
          translate: ["100% -100%", "0% 0%", "100% -100%"],
          transition: connerShapesTransition,
        }}
        className="absolute -right-10 -top-1"
      >
        <shapes.CompositeSquareBy3
          direction="bottom"
          shapeVariant="tertiary"
          shapeColor="tertiary"
        />
      </motion.div>

      <motion.div
        animate={{
          translate: ["-100% 100%", "0% 0%", "-100% 100%"],
          transition: connerShapesTransition,
        }}
        className="absolute -bottom-3 -left-10"
      >
        <shapes.CompositeSquareBy2
          direction="top"
          shapeVariant="tertiary"
          shapeColor="tertiary"
        />
      </motion.div>

      <motion.div
        animate={insideShapesAnimation}
        className="absolute bottom-14 right-32 hidden sm:block"
      >
        <shapes.Doughnut shapeVariant="tertiary" shapeColor="tertiary" />
      </motion.div>

      <motion.div
        animate={insideShapesAnimation}
        className="absolute left-[14%] top-[30%] hidden sm:block"
      >
        <shapes.CompositeSquareBy2
          direction="right"
          shapeColor="tertiary"
          className="rotate-12 scale-90"
        />
      </motion.div>

      <motion.div
        animate={insideShapesAnimation}
        className="absolute right-[16%] top-[22%] hidden sm:block"
      >
        <shapes.CompositeSquareBy3
          direction="bottom"
          shapeColor="tertiary"
          className="rotate-[9deg] scale-90"
        />
      </motion.div>

      <motion.div
        animate={insideShapesAnimation}
        className="absolute left-[32%] top-[62%] hidden sm:block"
      >
        <shapes.CompositeCircleBy2
          shapeColor="tertiary"
          className="absolute rotate-[31deg] scale-90"
        />
      </motion.div>
    </motion.div>
  );
};
