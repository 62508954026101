import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { OnboardingHeader } from "@/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { JOURNEY } from "@/shared.constants";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";

export const JourneyRestricted = () => {
  const {
    actions: { setChooseJourneyFormValues },
  } = useEmbeddablesForms();

  const navigate = useNavigate();

  const handleNext = () => {
    setChooseJourneyFormValues({ journey: JOURNEY.VIDEO_CONSULTATION });
    navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE);
  };

  return (
    <>
      <OnboardingHeader title={t`Begin your journey`} variant="secondary" />

      <div className="flex h-full flex-col items-center gap-14">
        <div className="flex w-full max-w-xl grow flex-col gap-6">
          <div className="flex flex-col gap-5 text-center text-nature-02 sm:text-xl">
            <p>
              {t`Unlock the door to your health journey with our platform. Schedule your inaugural video call with a medical professional, marking the beginning of your personalized treatment plan. Let's embark together on this path towards improved well-being and vitality.`}
            </p>
          </div>
        </div>

        <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
          <Button
            onClick={() => navigate(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO)}
            variant="cover-secondary"
            size="lg"
          >
            <AltArrowLeftIcon className="hidden size-4 sm:block" />
            {t`Back`}
          </Button>

          <Button onClick={handleNext} size="lg" variant="cover-primary">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </div>
    </>
  );
};
