import type { HTMLAttributes } from "react";
import { forwardRef } from "react";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import { VARIANT } from "@/shared.constants";
import { tw } from "@/utils";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <section
      ref={ref}
      className={tw(
        "flex flex-col gap-2 rounded-2xl bg-salmon-02 p-4 text-salmon-10",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "StepCard.Root";

const Title = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={tw("font-semibold md:font-bold md:leading-9", className)}
    {...props}
  >
    {children}
  </h3>
));
Title.displayName = "StepCard.Title";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex flex-col gap-2 text-sm font-medium md:text-base",
        className,
      )}
      {...props}
    />
  ),
);
Content.displayName = "Header.Content";

const Item = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "rounded-2xl bg-salmon-03 p-3 text-base md:p-4.5 md:leading-5",
        className,
      )}
      {...props}
    />
  ),
);
Item.displayName = "StepCard.Item";

const ItemTitle = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h4
    ref={ref}
    className={tw(
      "text-sm font-semibold text-salmon-10 md:text-base md:font-bold",
      className,
    )}
    {...props}
  >
    {children}
  </h4>
));
ItemTitle.displayName = "StepCard.ItemTitle";

type ItemContentVariant = typeof VARIANT.PRIMARY | typeof VARIANT.SECONDARY;

const ItemContent = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement> & { variant?: ItemContentVariant }
>(({ className, variant = VARIANT.PRIMARY, ...props }, ref) => (
  <div
    ref={ref}
    className={tw(
      "text-sm md:text-base",
      variant === VARIANT.SECONDARY && "rounded-lg bg-salmon-02 p-2",
      className,
    )}
    {...props}
  />
));
ItemContent.displayName = "StepCard.ItemContent";

const StepCardLink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, ...props }, ref) => (
    <Link
      ref={ref}
      {...props}
      className={tw(
        "font-semibold text-nature-08 underline underline-offset-1 hover:underline md:font-bold md:no-underline",
        className,
      )}
    />
  ),
);
StepCardLink.displayName = "StepCard.Link";

export {
  StepCardLink as Link,
  Root,
  Content,
  Title,
  Item,
  ItemTitle,
  ItemContent,
};
