import type { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { Navbar } from "./Navbar";

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-dvh flex-col overflow-hidden bg-salmon-01 md:flex-row">
      <Navbar />
      <main className="flex grow overflow-y-auto" id="scrollElement">
        {children ? children : <Outlet />}
      </main>
    </div>
  );
};
