import { create } from "zustand";

import { CONSULTATION_PURPOSE } from "@/shared.constants";
import type { ConsultationPurpose } from "@/shared.types";

export interface ConsultationStoreState {
  purpose: ConsultationPurpose;
  setPurpose: (purpose: ConsultationPurpose) => void;
}

export const useConsultationStore = create<ConsultationStoreState>()((set) => ({
  purpose: CONSULTATION_PURPOSE.REFILL,

  setPurpose: (purpose: ConsultationPurpose) => {
    set(() => ({ purpose }));
  },
}));
