import { create } from "zustand";

import { PORTAL_USER_TYPE } from "@/shared.constants";
import type { PortalUser } from "@/shared.types";

interface PortalUserStoreState {
  portalUser: PortalUser | null;
  setPortalUser: (portalUser: PortalUser) => void;
}

export const usePortalUserStore = create<PortalUserStoreState>()((set) => ({
  portalUser: null,

  setPortalUser: (portalUser) => {
    if (portalUser.type !== "") {
      set({ portalUser });
      return;
    }

    let type: PortalUser["type"] = null;

    if (portalUser.patientId) {
      type = PORTAL_USER_TYPE.PATIENT;
    }

    if (!portalUser.patientId && portalUser.isFromEmbeddables) {
      type = PORTAL_USER_TYPE.COACHING;
    }

    set({ portalUser: { ...portalUser, type } });
  },
}));
