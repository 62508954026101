import type { USState } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "locations";

export const getStatesQuery = () => ({
  queryKey: [DOMAIN, "getStatesQuery"],
  queryFn: async () => {
    const { data } =
      await privateAPI.get<ServiceResponse<USState[]>>(`/states`);

    return data.data;
  },
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});
