import { t } from "ttag";
import { z } from "zod";

import {
  DISCOUNT_DURATION,
  DISCOUNT_TYPE,
  DURATION_IN_MONTHS,
} from "./constants";

const durationInMonthsSchema = z.nativeEnum(DURATION_IN_MONTHS);

export const getCouponSchema = () =>
  z.object({
    code: z.string(),
    isValid: z.boolean(),
    duration: z.nativeEnum(DISCOUNT_DURATION),
    type: z.nativeEnum(DISCOUNT_TYPE),
    amountOff: z.number(),
    percentOff: z.number(),
    durationInMonths: durationInMonthsSchema.nullable(),
    priceAfterDiscount: z.number(),
  });

export const getPlanSchema = () =>
  z.object({
    name: z.string(),
    subscriptionPriceId: z.string(),
    price: z.number(),
    features: z.array(z.string()),
    isCompound: z.boolean(),
    description: z.string().optional(),
    disclaimer: z.string().optional(),
    thumbnail: z.string().url(),
    discount: getCouponSchema().optional(),
    durationInMonths: durationInMonthsSchema,
  });

export const getUserSubscriptionSchema = () =>
  z.object({
    currentPeriodPlan: getPlanSchema().nullable(),
    nextPeriodPlan: getPlanSchema().nullable(),
    requestedPlanChange: getPlanSchema().nullable(),
  });

export const getPlansParamsSchema = () =>
  z.object({
    durationInMonths: durationInMonthsSchema,
    couponCode: z.string().optional(),
  });

export const getCouponParamsSchema = () =>
  z.object({
    subscriptionPriceId: getPlanSchema().shape.subscriptionPriceId,
    couponCode: z.string(),
  });

export const getChangeSubscriptionSchema = () =>
  z.object({
    subscriptionPriceId: z
      .string()
      .min(1, { message: t`Please choose one of the available plans` }),

    hasConsent: z.literal<boolean>(true, {
      errorMap: () => ({
        message: t`To continue, please check the "I agree" box`,
      }),
    }),
  });
