import type { ComponentPropsWithoutRef, ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

const onboardingHeaderVariance = tv({
  slots: {
    root: "flex w-full flex-col items-center gap-2 text-center",
    heading:
      "max-w-3xl font-serif text-2xl text-brown-10 sm:text-3xl sm:leading-snug",
    paragraph: "text-sm font-medium text-brown-08 sm:text-lg sm:leading-6",
  },
  variants: {
    variant: {
      secondary: {
        root: "flex-col-reverse gap-0",
        heading: "text-4xl text-nature-01 sm:text-6xl sm:leading-snug",
        paragraph: "text-lg text-nature-04 sm:text-2xl",
      },
    },
  },
});

type OnboardingHeaderVariance = VariantProps<typeof onboardingHeaderVariance>;

interface OnboardingHeaderProps
  extends Omit<ComponentPropsWithoutRef<"header">, "title">,
    OnboardingHeaderVariance {
  title: ReactNode;
  description?: ReactNode;
}

export const OnboardingHeader = ({
  title,
  description,
  variant,
  className,
  ...props
}: OnboardingHeaderProps) => {
  const { root, heading, paragraph } = onboardingHeaderVariance({ variant });
  return (
    <header className={root({ className })} {...props}>
      <h1 className={heading()}>{title}</h1>

      {description && typeof description === "string" ? (
        <p className={paragraph()}>{description}</p>
      ) : (
        description
      )}
    </header>
  );
};
