import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { t } from "ttag";

import { setFridaysPassword } from "@/api";
import { PasswordTips } from "@/components/PasswordTips";
import { getConfirmPasswordSchema } from "@/shared.schemas";
import type { PasswordFormValues } from "@/shared.types";
import { usePortalUserStore } from "@/stores";
import {
  AlertDialog,
  Button,
  errorToast,
  icons,
  illustratedIcons,
  Input,
} from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

export const SetUpFridaysPasswordModal = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const portalUser = usePortalUserStore((state) => state.portalUser);

  const [show, setShow] = useState(Boolean(portalUser?.needsChangePassword));
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<PasswordFormValues>({
    resolver: zodResolver(getConfirmPasswordSchema()),
  });

  const queryClient = useQueryClient();

  const { mutate: setFridaysPasswordMutation, isPending } = useMutation({
    mutationFn: setFridaysPassword.mutation,
    onSuccess: () => {
      setFridaysPassword.invalidates(queryClient);
      setShow(false);
    },
    onError: (error) => {
      handleAxiosFieldErrors(error, setError);
      errorToast(error);
    },
  });

  return (
    <AlertDialog
      show={show}
      onClose={() => setShow(false)}
      icon={<illustratedIcons.Warning />}
      title={t`Please, confirm your password`}
      description={
        <p className="flex flex-col">
          <span className="font-normal">{t`At Fridays, protecting your information is a top priority.`}</span>
          <span className="font-normal">{t`We have added an additional layer of authentication to our log in.`}</span>
          <span className="font-medium">{t`Please create and verify a new password.`}</span>
        </p>
      }
      renderActions={() => (
        <div className="flex w-full flex-col gap-2 md:flex-row-reverse md:justify-center">
          <Button
            onClick={handleSubmit((values) =>
              setFridaysPasswordMutation(values),
            )}
            disabled={isPending}
          >{t`Save`}</Button>
        </div>
      )}
    >
      <form className="flex flex-col gap-1.5">
        <Input
          readOnly
          id="email"
          type="text"
          name="email"
          value={portalUser?.email}
          autoComplete="email"
          className="hidden"
        />
        <Input
          compact={false}
          id="password"
          autoComplete="new-password"
          type={showPassword ? "text" : "password"}
          label={t`Password *`}
          placeholder={t`Password`}
          left={<icons.LockOpen />}
          right={
            showPassword ? (
              <icons.OpenedEye
                onClick={() => setShowPassword(false)}
                className="cursor-pointer"
              />
            ) : (
              <icons.ClosedEye
                onClick={() => setShowPassword(true)}
                className="cursor-pointer"
              />
            )
          }
          {...register("password")}
          error={errors.password?.message}
        />
        <Input
          compact={false}
          id="password-confirmation"
          autoComplete="new-password"
          type={showPasswordConfirmation ? "text" : "password"}
          label={t`Confirm password *`}
          placeholder={t`Confirm password`}
          left={<icons.LockOpen />}
          right={
            showPasswordConfirmation ? (
              <icons.OpenedEye
                onClick={() => setShowPasswordConfirmation(false)}
                className="cursor-pointer"
              />
            ) : (
              <icons.ClosedEye
                onClick={() => setShowPasswordConfirmation(true)}
                className="cursor-pointer"
              />
            )
          }
          {...register("passwordConfirmation")}
          error={errors.passwordConfirmation?.message}
        />

        <PasswordTips />
      </form>
    </AlertDialog>
  );
};
