import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { changeNextPeriodSubscription } from "@/domains/billing/api";
import { errorToast, useToastStore } from "@/ui";

export const useChangeNextPeriodSubscriptionPlan = () => {
  const { pushToast } = useToastStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changeNextPeriodSubscription.mutation,
    onSuccess: () => {
      changeNextPeriodSubscription.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Subscription plan successfully updated!`,
      });
    },
    onError: errorToast,
  });
};
