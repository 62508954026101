import { useQueryClient } from "@tanstack/react-query";

import { billingQueriesStore } from "@/domains/billing/api";
import type { PlansParams } from "@/domains/billing/models";

export const usePrefetchPlans = () => {
  const queryClient = useQueryClient();

  const prefetchPatientPlans = (params: PlansParams) => {
    void queryClient.prefetchQuery({
      ...billingQueriesStore.stripe.plans._ctx.portal._ctx.patient(params),
      staleTime: Infinity,
    });
  };

  const prefetchOnboardingPlans = (params: PlansParams) => {
    void queryClient.prefetchQuery({
      ...billingQueriesStore.stripe.plans._ctx.onboarding._ctx.patient(params),
      staleTime: Infinity,
    });
  };

  return { prefetchPatientPlans, prefetchOnboardingPlans };
};
