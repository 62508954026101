import type { HTMLProps } from "react";
import { useState } from "react";
import type { DropzoneProps } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { t } from "ttag";

import { MAX_UPLOAD_SIZE_PLACEHOLDER, SIZE } from "@/shared.constants";
import { icons, IconWrapper } from "@/ui";
import { forwardRef, tw } from "@/utils";

const acceptDefault = {
  "image/*": [],
};
interface FileDropzoneProps
  extends Omit<HTMLProps<HTMLInputElement>, "onDrop" | "accept"> {
  onDrop?: (file: File[]) => void;
  label: string;
  uploadedLabel?: string;
  value?: string;
  className?: string;
  fileExists?: boolean;
  accept?: DropzoneProps["accept"];
}

/**
 * @deprecated use ControlledDropzone instead
 */
export const FileDropzone = forwardRef<HTMLInputElement, FileDropzoneProps>(
  (
    {
      label,
      uploadedLabel,
      onDrop,
      value,
      fileExists,
      className,
      accept = acceptDefault,
    },
    ref,
  ) => {
    const [fileName, setFileName] = useState<string | null>(null);
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles: File[]) => {
        setFileName(acceptedFiles[0]!.name);
        void onDrop?.(acceptedFiles);
      },
      accept,
    });

    return (
      <div className={tw(className)} {...getRootProps()}>
        <input ref={ref} {...getInputProps()} />
        <div
          className={tw(
            "flex h-full w-full cursor-pointer flex-col items-center gap-2 rounded-2xl border border-dashed border-salmon-09 bg-salmon-01 p-6 font-medium",
            (value ?? fileExists) && "bg-salmon-02",
          )}
        >
          {(value ?? fileExists) ? (
            <IconWrapper
              size={SIZE.X_LARGE}
              className="items-center justify-center rounded-full bg-salmon-05 stroke-salmon-10 p-1"
            >
              <icons.Check className="h-3 w-3" />
            </IconWrapper>
          ) : (
            <IconWrapper size={SIZE.X_LARGE}>
              <icons.Images />
            </IconWrapper>
          )}
          <div className="font-medium text-brown-10">
            {(value ?? fileExists) ? uploadedLabel : label}
          </div>
          <div className="text-sm text-brown-06">
            {(fileName ?? fileExists)
              ? t`Image already uploaded, upload another file to change it`
              : MAX_UPLOAD_SIZE_PLACEHOLDER}
          </div>
          <IconWrapper
            size={SIZE.LARGE}
            className="flex items-center justify-center rounded border border-salmon-08 stroke-salmon-08"
          >
            <icons.Plus className="h-3 w-3" />
          </IconWrapper>
        </div>
      </div>
    );
  },
);
