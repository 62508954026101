import { t } from "ttag";

import { MODAL_ROUTES, useNavigateModal } from "@/router";
import type { DocumentFolder, UploadedFile } from "@/shared.types";
import {
  DropdownMenu,
  IconWrapper,
  MenuDotsIcons,
  PenIcon,
  TrashBin2Icon,
} from "@/ui";

interface EditActionsProps {
  folder: DocumentFolder;
  fileId: UploadedFile["id"];
}

export const EditActions = ({ folder, fileId }: EditActionsProps) => {
  const navigateModal = useNavigateModal();
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger className="flex rotate-90 items-center justify-center p-1 text-nature-06 focus:outline-nature-04">
        <MenuDotsIcons className="size-4" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end">
        <DropdownMenu.Item
          onClick={() =>
            navigateModal(
              `${MODAL_ROUTES.DOCUMENT_CENTER.EDIT_DOCUMENT}/${folder}/${fileId}`,
            )
          }
          className="gap-2"
        >
          <IconWrapper size="sm" className="text-brown-08">
            <PenIcon />
          </IconWrapper>
          {t`Edit document name`}
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Item
          onClick={() =>
            navigateModal(
              `${MODAL_ROUTES.DOCUMENT_CENTER.REMOVE_DOCUMENT}/${folder}/${fileId}`,
            )
          }
          className="gap-2"
        >
          <IconWrapper size="sm" className="text-brown-08">
            <TrashBin2Icon />
          </IconWrapper>
          {t`Remove document`}
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
