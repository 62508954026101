import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";

import { tw } from "@/utils";

const Root = TabsPrimitive.Root;

const List = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={tw(
      "inline-flex w-full items-end justify-center text-brown-09",
      className,
    )}
    {...props}
  />
));
List.displayName = "Tabs.List";

const Trigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={tw(
      "group inline-flex flex-1 items-center justify-center whitespace-nowrap border-b-2 border-brown-05 px-3 py-2 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-nature-04 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-nature-10 data-[state=active]:font-semibold data-[state=active]:text-nature-10",
      className,
    )}
    {...props}
  />
));
Trigger.displayName = "Tabs.Trigger";

const Content = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={tw(
      "ring-offset-nature-03 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-nature-04 focus-visible:ring-offset-2",
      className,
    )}
    {...props}
  />
));
Content.displayName = "Tabs.Content";

export { Root, List, Trigger, Content };
