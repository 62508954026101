import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getDocumentsQuery } from "@/api/documents";
import { UploadedDocument } from "@/components";
import { ScreenLayout } from "@/layouts";
import { READ_ONLY_DOCUMENT_FOLDER } from "@/shared.constants";
import { EmptyState, UploadedFilesSkeleton } from "@/ui";
import { DoctorNotesHeader } from "./components";

const FOLDER = READ_ONLY_DOCUMENT_FOLDER.DOCTOR_NOTES;

export const DoctorNotes = () => {
  const { data, isLoading } = useQuery(getDocumentsQuery(FOLDER));
  return (
    <ScreenLayout>
      <DoctorNotesHeader />

      {isLoading && <UploadedFilesSkeleton />}

      {!!data?.length && (
        <div className="flex flex-col gap-4">
          {data.map((file) => (
            <UploadedDocument key={file.id} folder={FOLDER} file={file} />
          ))}
        </div>
      )}

      {!data?.length && !isLoading && (
        <EmptyState.Root>
          <EmptyState.Icon />
          <EmptyState.Description>
            {t`No documents found in this folder.`}
          </EmptyState.Description>
        </EmptyState.Root>
      )}
    </ScreenLayout>
  );
};
