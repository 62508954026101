import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { t } from "ttag";

import { deleteDocument, getDocumentQuery } from "@/api";
import { getDocumentLabel } from "@/components";
import type { DocumentFolder, ModalProps } from "@/shared.types";
import {
  Button,
  errorToast,
  IconWrapper,
  Label,
  Loader,
  Modal,
  ShieldWarningIconMono,
  UploadedFileCard,
  useToastStore,
} from "@/ui";
import { tw } from "@/utils";

export const RemoveDocument = ({ show, onClose }: ModalProps) => {
  const { pushToast } = useToastStore();
  const { documentId, folder } = useParams<{
    documentId: string;
    folder: DocumentFolder;
  }>();
  const { data, isLoading } = useQuery(getDocumentQuery(documentId));

  const queryClient = useQueryClient();
  const { mutate: deleteDocumentMutation, isPending } = useMutation({
    mutationFn: deleteDocument.mutation,
    onSuccess: () => {
      onClose();
      deleteDocument.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Document successfully deleted!`,
      });
    },
    onError: errorToast,
  });

  if (!folder || !documentId) {
    return null;
  }

  const documentLabel = getDocumentLabel(folder);

  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<ShieldWarningIconMono />}
      title={t`Remove ${documentLabel}`}
      className="min-h-80 text-salmon-10"
    >
      {isLoading && <Loader className="mx-auto mt-5" />}

      {data && (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Label
              htmlFor={data.id}
            >{t`Are you sure you want to remove the next file?`}</Label>

            <UploadedFileCard.Container id={data.id}>
              <UploadedFileCard.Preview
                src={data.url}
                alt={data.name}
                loading="lazy"
              />

              <UploadedFileCard.Content>
                <UploadedFileCard.Title>{data.name}</UploadedFileCard.Title>
              </UploadedFileCard.Content>
            </UploadedFileCard.Container>
          </div>

          <div className="flex items-center justify-center gap-3.5">
            <Button onClick={onClose} size="lg" variant="secondary">
              {t`Keep file`}
            </Button>

            <Button
              onClick={() => deleteDocumentMutation(documentId)}
              disabled={isPending}
              size="lg"
              className={tw("relative", isPending && "text-transparent")}
            >
              {t`Remove file`}
              {isPending && (
                <IconWrapper className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
                  <Loader variant="dark" />
                </IconWrapper>
              )}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
