import type { Coupon } from "../models";
import { DISCOUNT_TYPE } from "../models";

export const getFinalChargedAmount = (price: number, coupon: Coupon) => {
  switch (coupon.type) {
    case DISCOUNT_TYPE.PERCENTAGE:
      return (price - (price * coupon.percentOff) / 100).toFixed(2);
    case DISCOUNT_TYPE.FIXED_AMOUNT:
      return Math.max(price - coupon.amountOff, 0);
    default:
      return price;
  }
};
