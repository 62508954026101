import { useNavigate } from "react-router";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { AlertDialog, Button } from "@/ui";

export const ErrorAlert = ({ show, onClose }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <AlertDialog
      show={show}
      onClose={onClose}
      title={t`Consultation creation failed`}
      description={t`We're sorry, but we were unable to create your consultation due to a system error. Please try again later.`}
      renderActions={({ AlertDialogAction, AlertDialogCancel }) => (
        <div className="flex w-full flex-col-reverse items-stretch gap-4 md:flex-row md:items-center">
          <AlertDialogAction asChild>
            <div className="md:w-1/2">
              <Button
                onClick={() => navigate(ROUTES.CONSULTATIONS.OVERVIEW)}
                variant="secondary"
                className="w-full"
              >
                {t`Back to consultations`}
              </Button>
            </div>
          </AlertDialogAction>

          <AlertDialogCancel asChild>
            <div className="md:w-1/2">
              <Button className="w-full">{t`Add new consultation`}</Button>
            </div>
          </AlertDialogCancel>
        </div>
      )}
    />
  );
};
