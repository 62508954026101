import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { Button, Dialog, ShieldWarningIconMono } from "@/ui";

interface ReminderModalProps extends ModalProps {
  onContinue: () => void;
}

export const ReminderModal = ({
  show,
  onClose,
  onContinue,
}: ReminderModalProps) => {
  const navigate = useNavigate();

  const handleCancelRequest = () => {
    onClose();
    navigate(ROUTES.CONSULTATIONS.OVERVIEW);
  };
  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal">
        <div className="flex h-full flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <ShieldWarningIconMono />
            </Dialog.Icon>

            <Dialog.Title>{t`Just a friendly reminder`}</Dialog.Title>

            <Dialog.Description>
              {t`It appears you have 3 or more injections left. We will only process a refill if you have 2 or fewer doses left at the time of your session`}
            </Dialog.Description>
            <Dialog.Description>
              {t`Would you like to proceed with your consultation anyway, or cancel and return later?`}
            </Dialog.Description>
          </Dialog.Header>

          <Dialog.Footer variant="sticky" className="mt-auto sm:flex-row">
            <Button onClick={onContinue} size="lg" variant="secondary">
              {t`Proceed anyway`}
            </Button>
            <Button onClick={handleCancelRequest} size="lg">
              {t`Cancel request`}
            </Button>
          </Dialog.Footer>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
