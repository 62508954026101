import { useFieldArray, useFormContext } from "react-hook-form";
import { t } from "ttag";

import { DOCUMENT_ICON } from "@/components";
import {
  ACCEPT_IMAGE_FILE,
  DOCUMENT_FOLDER,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
} from "@/shared.constants";
import type { OnboardingImageUploadsValues } from "@/shared.types";
import {
  CardPrimitive as Card,
  Dropzone,
  Form,
  IconWrapper,
  Input,
  TrashBin2Icon,
} from "@/ui";
import { SectionHeader, UploadedTempFile } from "../components";
import { handleLocalUploads } from "../utils";

export const GLPMedicationUploads = () => {
  const form = useFormContext<OnboardingImageUploadsValues>();

  const {
    fields: medications,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "uploadedFiles.other",
    keyName: "fieldId",
  });

  return (
    <section className="flex flex-col gap-6">
      <SectionHeader
        title={t`Would you like to upload a picture of your current GLP-1 medication pen or vial?`}
        description={t`While optional, to continue your current GLP-1 medication dosage, please provide a photo of your most recent prescription. Your last dose must be within 2 weeks of receiving your medication from us to keep the same dosage.`}
      />

      <Card.Root className="bg-brown-03">
        <Card.Title>{t`GLP-1 medication pen or vial`}</Card.Title>
        <Card.Content>
          <Form.Field
            control={form.control}
            name="uploadedFiles.other"
            render={() => (
              <Form.Item>
                <Form.Control>
                  <Dropzone
                    accept={ACCEPT_IMAGE_FILE}
                    onDrop={(files) =>
                      handleLocalUploads(files, medications, append)
                    }
                    multiple
                    compact
                    label={t`Drop your medication here`}
                    placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
                  />
                </Form.Control>
                <Form.Message />

                {medications.map((medication, fieldIndex) => (
                  <div
                    key={medication.fieldId}
                    className="relative flex flex-col gap-2.5 rounded-2xl border border-brown-04 bg-salmon-01 p-4 text-salmon-09"
                  >
                    <IconWrapper
                      onClick={() => remove(fieldIndex)}
                      size="sm"
                      as="button"
                      type="button"
                      className="absolute right-5 top-4 text-red-07"
                    >
                      <TrashBin2Icon />
                    </IconWrapper>

                    <Form.Field
                      control={form.control}
                      name={`uploadedFiles.other.${fieldIndex}.name`}
                      render={({ field }) => (
                        <Form.Item>
                          <Form.Control>
                            <Input
                              {...field}
                              label={t`File name`}
                              left={DOCUMENT_ICON[DOCUMENT_FOLDER.OTHER]}
                            />
                          </Form.Control>
                          <Form.Message />
                        </Form.Item>
                      )}
                    />

                    <Form.Field
                      control={form.control}
                      name={`uploadedFiles.other.${fieldIndex}`}
                      render={({ field }) => (
                        <Form.Item>
                          <Form.Control>
                            <UploadedTempFile
                              {...field}
                              uploadedFile={field.value}
                              onSuccess={(fileId) => {
                                form.setValue(`${field.name}.id`, fileId);
                                form.clearErrors(`${field.name}.id`);
                              }}
                              onError={() => {
                                form.setError(
                                  field.name,
                                  {
                                    type: "backend",
                                    message: t`There was an error, and the file couldn't be uploaded correctly.`,
                                  },
                                  { shouldFocus: true },
                                );
                              }}
                              onRetry={() => {
                                form.clearErrors(field.name);
                              }}
                            />
                          </Form.Control>
                          <Form.Message>
                            {
                              form.formState.errors.uploadedFiles?.other?.[
                                fieldIndex
                              ]?.id?.message
                            }
                          </Form.Message>
                        </Form.Item>
                      )}
                    />
                  </div>
                ))}
              </Form.Item>
            )}
          />
        </Card.Content>
      </Card.Root>
    </section>
  );
};
