import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import {
  EMBEDDABLES_ONBOARDING_ROUTE,
  requiresJourneyInfoData,
  requiresMainInfoData,
  requiresMeasurementsData,
  requiresScheduleInfoData,
} from "@/domains/embeddables-onboarding/router";

export const RequireFormData = () => {
  const { pathname } = useLocation();
  const {
    mainInfoFormValues,
    measurementsFormValues,
    chooseJourneyFormValues,
    scheduleVideoConsultationFormValues,
  } = useEmbeddablesForms();

  if (requiresMainInfoData(pathname) && !mainInfoFormValues) {
    return <Navigate replace to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  if (requiresMeasurementsData(pathname) && !measurementsFormValues) {
    return (
      <Navigate
        replace
        to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS}
      />
    );
  }

  if (requiresJourneyInfoData(pathname) && !chooseJourneyFormValues) {
    return (
      <Navigate replace to={EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY} />
    );
  }

  if (
    requiresScheduleInfoData(pathname, chooseJourneyFormValues?.journey) &&
    !scheduleVideoConsultationFormValues
  ) {
    <Navigate
      replace
      to={EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE}
    />;
  }

  return <Outlet />;
};
