import { forwardRef } from "react";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import { icons, IconWrapper } from "@/ui/common";
import { tw } from "@/utils";

const { ChevronDown, ChevronUp } = icons;

const Select = SelectPrimitive.Root;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={tw(
      "flex items-center gap-1 rounded-lg border border-transparent bg-brown-02 p-1 pl-2 leading-5 text-nature-10 hover:bg-brown-03 focus:border-salmon-09 focus:bg-brown-03 focus:outline-none focus:ring-1 focus:ring-salmon-09 disabled:cursor-not-allowed disabled:opacity-50 data-[state=open]:border-salmon-09 data-[state=open]:bg-brown-03 [&>span]:line-clamp-1 [&[data-state=open]>svg:last-child]:rotate-180",
      className,
    )}
    {...props}
  >
    <span className="grow">{children}</span>
    <IconWrapper
      size="sm"
      className="cursor-pointer fill-brown-09 stroke-nature-10 duration-150"
    >
      <ChevronDown />
    </IconWrapper>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={tw(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <IconWrapper size="xs" className="fill-brown-09 stroke-brown-09">
      <ChevronUp />
    </IconWrapper>
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={tw(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <IconWrapper size="xs" className="fill-brown-09 stroke-brown-09">
      <ChevronDown />
    </IconWrapper>
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    variant?: "grid" | "flex";
  }
>(
  (
    { className, children, position = "popper", variant = "flex", ...props },
    ref,
  ) => (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={tw(
          "relative z-50 max-h-72 overflow-hidden rounded-md border border-brown-03 bg-salmon-01 text-brown-09 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          position === "popper" &&
            "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
          className,
        )}
        position={position}
        {...props}
      >
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport
          className={tw(
            "p-4",
            variant === "flex" && "flex flex-col gap-2",
            variant === "grid" && "grid grid-cols-4 gap-2",
            position === "popper" &&
              "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  ),
);
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={tw(
      "flex w-full cursor-pointer select-none items-center justify-center rounded-lg p-2 text-sm outline-none focus:bg-salmon-02 data-[disabled]:pointer-events-none data-[state=checked]:bg-salmon-03 data-[disabled]:opacity-50",
      className,
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

export { Select, SelectValue, SelectTrigger, SelectContent, SelectItem };
