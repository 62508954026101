import type { SVGProps } from "@/shared.types";

export const ClockIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M7.99967 2.70825C4.59392 2.70825 1.83301 5.46916 1.83301 8.87492C1.83301 12.2807 4.59392 15.0416 7.99967 15.0416C11.4054 15.0416 14.1663 12.2807 14.1663 8.87492C14.1663 5.46916 11.4054 2.70825 7.99967 2.70825ZM0.833008 8.87492C0.833008 4.91688 4.04163 1.70825 7.99967 1.70825C11.9577 1.70825 15.1663 4.91688 15.1663 8.87492C15.1663 12.833 11.9577 16.0416 7.99967 16.0416C4.04163 16.0416 0.833008 12.833 0.833008 8.87492ZM7.99967 5.70825C8.27582 5.70825 8.49967 5.93211 8.49967 6.20825V8.66781L10.0199 10.188C10.2152 10.3833 10.2152 10.6999 10.0199 10.8951C9.82463 11.0904 9.50805 11.0904 9.31279 10.8951L7.64612 9.22847C7.55235 9.1347 7.49967 9.00753 7.49967 8.87492V6.20825C7.49967 5.93211 7.72353 5.70825 7.99967 5.70825Z"
    />
  </svg>
);
