import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { Folder } from "@/shared.types";
import { Button, HeartsCircleFixedIconMono } from "@/ui";
import { isCoachingSessionFile } from "@/utils";

interface ErrorStateProps {
  folder: Folder;
  onTryAgain: () => void;
}

export const ErrorState = ({ folder, onTryAgain }: ErrorStateProps) => {
  const navigate = useNavigate();

  const isCoachingFile = isCoachingSessionFile(folder);
  return (
    <div className="flex grow flex-col items-center justify-center gap-2 rounded-lg bg-salmon-03 p-6 pt-12 text-center text-salmon-10 md:pt-6">
      <HeartsCircleFixedIconMono className="size-16 md:size-20" />

      <div className="flex max-h-96 grow flex-col gap-6 md:grow-0">
        <h2 className="font-serif text-3xl md:text-6xl">{t`Oops! Something went wrong`}</h2>

        <p className="max-w-4xl text-lg md:text-xl">
          {t`We couldn't generate a preview for this file. Please try refreshing the page or come back later. If the issue persists, feel free to contact our support team.`}
        </p>
      </div>

      <div className="flex w-full flex-col-reverse justify-center gap-2.5 py-6 md:flex-row">
        <Button
          size="lg"
          variant="secondary"
          onClick={() =>
            isCoachingFile
              ? navigate(ROUTES.COACHING_HUB.OVERVIEW)
              : navigate(ROUTES.DOCUMENT_CENTER.OVERVIEW)
          }
        >
          {isCoachingFile
            ? t`Go back to Coaching Hub`
            : t`Go back to Document Center`}
        </Button>
        <Button size="lg" onClick={onTryAgain}>{t`Try again`}</Button>
      </div>
    </div>
  );
};
