import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const Shape11 = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 178 178"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path d="M3.799 43.0633V176.684H86.4691V43.0633C86.4691 20.2381 67.9592 1.72821 45.134 1.72821C22.3089 1.72821 3.799 20.2381 3.799 43.0633Z" />
    <path d="M90.4797 84.5912C90.4797 96.0037 90.4797 176.5 90.4797 176.5H173.15C173.15 176.5 173.15 24.5533 173.15 1.72812C112.929 1.72812 90.4797 50.6479 90.4797 84.5912Z" />
  </svg>
);
