import { CheckboxIndicator as CheckboxIndicatorPrimitive } from "@radix-ui/react-checkbox";

import { SIZE, VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { tw } from "@/utils";
import { icons } from "../common";
import type { IndicatorProps } from "./form.types";

interface CheckboxIndicatorProps extends IndicatorProps {
  variant?: Variant;
}

export const CheckboxIndicator = ({
  className,
  size = SIZE.SMALL,
  variant,
}: CheckboxIndicatorProps) => {
  return (
    <div
      className={tw(
        "flex shrink-0 items-center justify-center overflow-hidden rounded-[0.25rem] border border-brown-04 bg-salmon-01 hover:border-nature-09 group-focus:ring-2 group-focus:ring-nature-09",
        size === SIZE.SMALL && "h-4 w-4",
        size === SIZE.MEDIUM && "h-5 w-5",

        variant === VARIANT.SECONDARY &&
          "border-nature-01 bg-transparent hover:border-nature-03",
        className,
      )}
    >
      <CheckboxIndicatorPrimitive
        className={tw(
          "flex items-stretch justify-stretch bg-nature-09 p-0.5",
          size === SIZE.SMALL && "h-3.5 w-3.5",
          size === SIZE.MEDIUM && "h-4.5 w-4.5",

          variant === VARIANT.SECONDARY && "bg-transparent",
        )}
      >
        <icons.Check className="fill-salmon-01" />
      </CheckboxIndicatorPrimitive>
    </div>
  );
};
