import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import { STRIPE_DOMAIN } from "@/api";
import type { Plan } from "@/domains/billing/models";
import type { PlansParams } from "../models";
import {
  getCoachingPlans,
  getMonthlySubscriptionPlan,
  getOnboardingPatientPlans,
  getPatientPlans,
} from "./stripe";
import { getUserSubscription } from "./subscription";

export const billingQueriesStore = createQueryKeyStore({
  [STRIPE_DOMAIN]: {
    userSubscription: () => ({
      queryKey: ["getUserSubscription"],
      queryFn: getUserSubscription,
    }),

    planForMonthlyPeriod: (
      subscriptionPriceId?: Plan["subscriptionPriceId"],
    ) => ({
      queryKey: [subscriptionPriceId],
      queryFn: subscriptionPriceId
        ? () => getMonthlySubscriptionPlan(subscriptionPriceId)
        : (skipToken as never),
    }),

    plans: {
      queryKey: null,
      contextQueries: {
        portal: {
          queryKey: null,
          contextQueries: {
            coaching: () => ({
              queryKey: ["getCoachingPlans"],
              queryFn: getCoachingPlans,
            }),

            patient: (params: PlansParams) => ({
              queryKey: [params],
              queryFn: () => getPatientPlans(params),
            }),
          },
        },

        onboarding: {
          queryKey: null,
          contextQueries: {
            patient: (params: PlansParams) => ({
              queryKey: [params],
              queryFn: () => getOnboardingPatientPlans(params),
            }),
          },
        },
      },
    },
  },
});
