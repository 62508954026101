import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import type { DateOfBirthValues } from "@/shared.types";
import { Input } from "./Input";

interface FormType {
  dob: DateOfBirthValues;
}

export const DateOfBirthInputs = () => {
  const { register } = useFormContext<FormType>();

  return (
    <div className="grid grid-cols-3 gap-x-1.5">
      <Input
        type="number"
        step={1}
        placeholder={t`MM`}
        autoComplete="bday-month"
        {...register("dob.month", { setValueAs: Number })}
      />
      <Input
        type="number"
        step={1}
        placeholder={t`DD`}
        autoComplete="bday-day"
        {...register("dob.day", { setValueAs: Number })}
      />
      <Input
        type="number"
        step={1}
        placeholder={t`YYYY`}
        autoComplete="bday-year"
        {...register("dob.year", { setValueAs: Number })}
      />
    </div>
  );
};
