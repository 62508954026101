import type { SVGProps } from "@/shared.types";

export const InfoCircleIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 17 17"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M8.49967 12.4517C8.77582 12.4517 8.99967 12.2278 8.99967 11.9517V7.95166C8.99967 7.67552 8.77582 7.45166 8.49967 7.45166C8.22353 7.45166 7.99967 7.67552 7.99967 7.95166V11.9517C7.99967 12.2278 8.22353 12.4517 8.49967 12.4517Z" />
    <path d="M8.49967 5.28499C8.86786 5.28499 9.16634 5.58347 9.16634 5.95166C9.16634 6.31985 8.86786 6.61833 8.49967 6.61833C8.13148 6.61833 7.83301 6.31985 7.83301 5.95166C7.83301 5.58347 8.13148 5.28499 8.49967 5.28499Z" />
    <path
      fillRule="evenodd"
      d="M1.33301 8.61833C1.33301 4.66029 4.54163 1.45166 8.49967 1.45166C12.4577 1.45166 15.6663 4.66029 15.6663 8.61833C15.6663 12.5764 12.4577 15.785 8.49967 15.785C4.54163 15.785 1.33301 12.5764 1.33301 8.61833ZM8.49967 2.45166C5.09392 2.45166 2.33301 5.21257 2.33301 8.61833C2.33301 12.0241 5.09392 14.785 8.49967 14.785C11.9054 14.785 14.6663 12.0241 14.6663 8.61833C14.6663 5.21257 11.9054 2.45166 8.49967 2.45166Z"
    />
  </svg>
);
