import type { OnboardingUser, Question } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "questions";

export const getInitialQuestionQuery = (bmi?: number) => ({
  enabled: !!bmi,
  queryKey: [DOMAIN, "getInitialQuestionQuery", bmi],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<Question>>(
      `/answer-groups/?bmi=${bmi}`,
    );

    return data.data;
  },
});

export const getQuestionQuery = (questionId: number | null) => ({
  enabled: questionId !== null,
  queryKey: [DOMAIN, "getQuestionQuery", questionId],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<Question>>(
      `/answer-groups/${questionId}`,
    );

    return data.data;
  },
});

export const qualifiesUser = {
  mutation: async (params: {
    gender: string;
    dateOfBirth: string;
    weight: number;
    heightFeet: number;
    heightInches: number;
    onboardingAnswers: Question["id"][];
  }) => {
    const { data } = await privateAPI.post<ServiceResponse<OnboardingUser>>(
      "/onboarding-answers/qualifies",
      params,
    );

    return data.data;
  },
};
