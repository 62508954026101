import type { PropsWithChildren } from "react";
import { Navigate, Outlet, Routes } from "react-router-dom";

import { Layout } from "@/layouts";
import { useAuthStore } from "@/stores";
import { ROUTES } from "../constants";

const RequireLoggedIn = ({ children }: PropsWithChildren) => {
  const isLoggedIn = useAuthStore((state) => Boolean(state.token));

  if (!isLoggedIn) {
    return <Navigate replace to={ROUTES.LOGIN} />;
  }

  return <>{children}</>;
};

export const PortalRoutesWrapper = ({ children }: PropsWithChildren) => {
  return (
    <RequireLoggedIn>
      <Layout>
        {children && <Routes>{children}</Routes>}
        <Outlet />
      </Layout>
    </RequireLoggedIn>
  );
};
