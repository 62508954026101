import { useMutation } from "@tanstack/react-query";

import { markConversationAsRead } from "@/api/messages";
import type { Conversation } from "@/shared.types";
import { errorToast } from "@/ui";
import { tw } from "@/utils";
import { ChatTitleList } from "./ChatTitleList";

export default function ChatsList({
  conversations,
  setSelectedConversation,
  selectedConversation,
}: {
  conversations?: Conversation[];
  setSelectedConversation: (conversation: Conversation) => void;
  selectedConversation: Conversation | null;
}) {
  const { mutate: markConversationAsReadMutation } = useMutation({
    mutationFn: markConversationAsRead.mutation,
    onError: (err) => {
      errorToast(err);
    },
  });
  const onSelect = (conversation: Conversation) => {
    setSelectedConversation(conversation);
    markConversationAsReadMutation(conversation.id);
    conversations?.forEach((chat) => {
      if (chat.id === conversation.id) {
        chat.newMessages = 0;
      }
    });
  };

  return (
    <div
      className={tw(
        "col-span-2 flex flex-col overflow-auto rounded-2xl px-4 py-6 md:bg-brown-02",
        selectedConversation && "hidden md:flex",
      )}
    >
      <div className="overflow-auto">
        <div className="flex flex-col gap-2 md:mt-4">
          <ChatTitleList
            conversations={conversations}
            selectedConversation={selectedConversation}
            onSelect={onSelect}
          />
        </div>
      </div>
    </div>
  );
}
