import { useNavigate } from "react-router";
import { t } from "ttag";

import { CallToAction } from "@/components";
import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import {
  CurrentMedicationRequest,
  MyMedsHeader,
  PastMedicationRequests,
} from "./sections";

export const MyMedsOverview = () => {
  const navigate = useNavigate();
  return (
    <ScreenLayout>
      <MyMedsHeader />

      <CurrentMedicationRequest />

      <PastMedicationRequests />

      <CallToAction
        title={t`Having any trouble with your medication delivery?`}
        description={t`Get in touch with our support team!`}
        buttonText={t`Get in touch`}
        onClick={() => navigate(ROUTES.MESSAGES)}
      />
    </ScreenLayout>
  );
};
