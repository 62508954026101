import { shapes } from "@/ui/common/Shapes";

const Row = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex w-full flex-1 items-center justify-between gap-5">
      {children}
    </div>
  );
};

export const Background = () => {
  return (
    <div className="hidden shrink-0 sm:flex sm:flex-col sm:justify-center">
      <div className="flex flex-col gap-2 px-8 py-5 sm:bg-salmon-04">
        <Row>
          <shapes.RoundedRectangle direction="bottom" />
          <shapes.CompositeSquareBy3
            animated
            direction="bottom"
            shapeVariant="secondary"
          />
          <shapes.CompositeSquareBy2
            direction="bottom"
            shapeVariant="tertiary"
          />
          <shapes.CompositeCircleBy2 />
        </Row>

        <Row>
          <shapes.Doughnut shapeColor="secondary" />
          <shapes.PartialDoughnut direction="bottom" />
          <shapes.CompositeCircleBy2 direction="right" />
          <shapes.CompositeSquareBy2
            animated
            direction="left"
            shapeVariant="secondary"
          />
        </Row>

        <Row>
          <shapes.RoundedRectangle />
          <shapes.CompositeSquareBy2
            direction="bottom"
            shapeVariant="tertiary"
          />
          <shapes.CompositeSquareBy3
            direction="bottom"
            shapeColor="secondary"
          />
          <shapes.Doughnut />
        </Row>

        <Row>
          <shapes.CompositeSquareBy2 direction="right" />
          <shapes.PartialDoughnut2 animated />
          <shapes.CompositeSquareBy2 direction="left" shapeVariant="tertiary" />
          <shapes.CompositeSquareBy2 direction="bottom" />
        </Row>

        <Row>
          <shapes.CompositeSquareBy3
            animated
            direction="bottom"
            shapeVariant="tertiary"
          />
          <shapes.CompositeSquareBy2
            direction="bottom"
            shapeVariant="secondary"
            shapeColor="secondary"
          />
          <shapes.PartialDoughnut2 direction="right" />
          <shapes.CompositeSquareBy2
            direction="left"
            shapeVariant="secondary"
          />
        </Row>
      </div>
    </div>
  );
};
