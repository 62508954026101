import { useNavigate } from "react-router";
import { t } from "ttag";

import { useScheduleConsultationContext } from "@/contexts";
import { ROUTES } from "@/router";
import { AlertDialog, Button, VideoCameraIcon } from "@/ui";
import { formatAvailableTime, formatToFrontendDate } from "@/utils";

export const SuccessAlert = ({ show }: { show: boolean }) => {
  const {
    scheduleDate: { timeSlot },
    provider,
  } = useScheduleConsultationContext();

  const navigate = useNavigate();

  if (!timeSlot) {
    return null;
  }

  const { dateTime, timezone } = timeSlot;
  const providerName = provider ? provider.fullName : "";

  const appointmentDate = new Date(dateTime);

  return (
    <AlertDialog
      show={show}
      title={t`Consultation successfully created!`}
      renderActions={({ AlertDialogAction }) => (
        <div className="relative flex w-full flex-col gap-4 rounded-lg bg-salmon-02 p-8">
          <div className="absolute left-0 top-0 h-10 w-10 -translate-x-1.5 -translate-y-1.5 rounded-full bg-salmon-07 p-3">
            <VideoCameraIcon className="text-salmon-01" />
          </div>

          <div className="flex flex-col gap-1.5 text-brown-10">
            <h4 className="text-lg font-semibold">{t`Session with ${providerName}`}</h4>
            <p className="font-medium">
              {formatToFrontendDate(appointmentDate)}
            </p>
            <p>{`${formatAvailableTime(dateTime)} ${timezone}`}</p>
          </div>

          <AlertDialogAction asChild>
            <Button
              variant="secondary"
              className="w-fit"
              onClick={() => navigate(ROUTES.HOME)}
            >
              {t`Back to home`}
            </Button>
          </AlertDialogAction>
        </div>
      )}
    />
  );
};
