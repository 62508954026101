import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { t } from "ttag";

import { deleteWeightEntry } from "@/api";
import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import {
  Button,
  CardPrimitive,
  Dialog,
  errorToast,
  GraphIconMono,
  OverlayLoader,
  useToastStore,
} from "@/ui";
import { formatBackendDate } from "@/utils";
import type { LocationState } from "./progress-tracker.types";

export const DeleteWeightEntry = ({ show, onClose }: ModalProps) => {
  const { pushToast } = useToastStore();

  const { entryId } = useParams();
  const { weightEntry } = useLocation().state as LocationState;

  const queryClient = useQueryClient();
  const { mutate: deleteWeightEntryMutation, isPending } = useMutation({
    mutationFn: deleteWeightEntry.mutation,
    onSuccess: () => {
      onClose();
      deleteWeightEntry.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Weight entry successfully deleted!`,
      });
    },
    onError: (err) => {
      errorToast(err);
    },
  });

  if (entryId !== weightEntry?.id || !weightEntry) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal">
        <div className="flex size-full flex-col gap-6">
          <div className="flex grow flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <GraphIconMono />
              </Dialog.Icon>
              <Dialog.Title>{t`Delete Entry`}</Dialog.Title>
              <Dialog.Description>{t`Are you sure you want to delete the next entry?`}</Dialog.Description>
            </Dialog.Header>

            <CardPrimitive.Root className="bg-brown-02 text-brown-08">
              <CardPrimitive.Description className="text-base">
                {t`Weight: `}
                <strong className="text-brown-10">
                  {weightEntry.weight} lbs
                </strong>
              </CardPrimitive.Description>
              <CardPrimitive.Description className="text-base">
                {t`Date: `}
                <strong className="text-brown-10">
                  {formatBackendDate(weightEntry.date)}
                </strong>
              </CardPrimitive.Description>
              <div className="flex gap-1">
                <CardPrimitive.Description className="text-base">
                  {t`Note: `}
                </CardPrimitive.Description>
                <strong className="text-brown-10">
                  {weightEntry.note ? weightEntry.note : "-"}
                </strong>
              </div>
            </CardPrimitive.Root>
          </div>

          <Dialog.Footer>
            <Button onClick={onClose} variant="secondary" size="lg">
              {t`Cancel`}
            </Button>
            <Button
              disabled={isPending}
              size="lg"
              onClick={() => deleteWeightEntryMutation(weightEntry.id)}
            >
              {t`Delete entry`}
            </Button>
          </Dialog.Footer>
        </div>

        {isPending && <OverlayLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
