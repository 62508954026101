import { Navigate, Route } from "react-router-dom";

import { BASE_ROUTE, ROUTES } from "@/router";
import { PortalRoutesWrapper } from "@/router/components/PortalRoutesWrapper";
import type { DomainRoute } from "@/shared.types";
import {
  ConsultationFollowUpForm,
  ConsultationPurpose,
  ConsultationRefillForm,
  Consultations,
  ConsultationSchedule,
} from "./screens";

const getPartialRoute = (route: DomainRoute) => {
  return route.replace(BASE_ROUTE.CONSULTATIONS, "");
};

export const ConsultationsRouter = () => {
  return (
    <PortalRoutesWrapper>
      <Route index element={<Consultations />} />
      <Route
        path={getPartialRoute(ROUTES.CONSULTATIONS.PURPOSE)}
        element={<ConsultationPurpose />}
      />
      <Route
        path={getPartialRoute(ROUTES.CONSULTATIONS.FOLLOW_UP_FORM)}
        element={<ConsultationFollowUpForm />}
      />
      <Route
        path={getPartialRoute(ROUTES.CONSULTATIONS.MEDICATION_REFILL_REQUEST)}
        element={<ConsultationRefillForm />}
      />
      <Route
        path={getPartialRoute(ROUTES.CONSULTATIONS.SCHEDULE)}
        element={<ConsultationSchedule />}
      />

      <Route path="*" element={<Navigate to={BASE_ROUTE.CONSULTATIONS} />} />
    </PortalRoutesWrapper>
  );
};
