import { t } from "ttag";

import { usePatientQuery } from "@/hooks";
import { Header } from "@/ui";
import { NextStepsButton } from "../components";

export const HomeHeader = () => {
  const { data: patient } = usePatientQuery();

  const patientName = patient?.firstName ? `, ${patient.firstName}` : "";

  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <div className="flex items-center justify-between gap-6">
          <Header.Title>{t`Keep it up${patientName}!`}</Header.Title>

          <NextStepsButton position="header" />
        </div>
      </Header.Content>
    </Header.Root>
  );
};
