import { t } from "ttag";

import { OnboardingHeader } from "@/components";
import { EmbeddablesOnboardingScreenLayout } from "@/domains/embeddables-onboarding/components";
import { useEmbeddablesUser } from "@/domains/embeddables-onboarding/context";
import { AccountPassword } from "./sections";

export const Account = () => {
  const { email } = useEmbeddablesUser();

  return (
    <EmbeddablesOnboardingScreenLayout>
      <OnboardingHeader
        title={t`Create your password`}
        description={t`You are creating an account for the email ${email}`}
      />

      <AccountPassword />
    </EmbeddablesOnboardingScreenLayout>
  );
};
