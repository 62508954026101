import type { ComponentPropsWithoutRef, ForwardedRef } from "react";

import { forwardRef, tw } from "@/utils";
import { IconWrapper } from "../common";
import type { BaseFieldProps } from "./form.types";
import { Label } from "./Label";
import { Message } from "./Message";

type TextAreaProps = Omit<ComponentPropsWithoutRef<"textarea">, "id"> &
  BaseFieldProps;

export const TextArea = forwardRef(
  (
    {
      className,
      compact = true,
      containerClassName,
      disabled,
      error,
      id,
      label,
      left,
      message,
      right,
      readOnly,
      ...rest
    }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => (
    <div
      className={tw(
        "flex grow flex-col gap-1.5 text-brown-09",
        containerClassName,
      )}
    >
      {!!label && <Label htmlFor={id}>{label}</Label>}

      <div
        className={tw(
          "relative items-center rounded-lg",
          !!disabled && "opacity-30",
        )}
      >
        {!!left && (
          <div
            className={tw(
              "pointer-events-none absolute top-1/2 -translate-y-1/2 pl-4",
              readOnly && "pl-0",
            )}
          >
            <IconWrapper
              size="sm"
              className={tw("fill-brown-06", !!error && "fill-red-03")}
            >
              {left}
            </IconWrapper>
          </div>
        )}

        <textarea
          ref={ref}
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
          className={tw(
            "w-full rounded-lg border border-brown-05 bg-salmon-01 px-4 py-3 text-sm placeholder:text-brown-06 read-only:border-transparent read-only:bg-transparent read-only:pl-0 focus:border-brown-08 focus:outline-none focus:ring-1 focus:ring-salmon-03",
            !!left && "pl-10 read-only:pl-6",
            !!right && "pr-10",

            !!error && "border-red-07 focus:ring-red-01",
            className,
          )}
        />

        {!!right && (
          <div className="absolute right-0 top-1/2 -translate-y-1/2 pr-4">
            <IconWrapper size="sm" className="fill-secondary-beige-07">
              {right}
            </IconWrapper>
          </div>
        )}
      </div>

      {(!compact || !!message || !!error) && (
        <Message message={message} error={error} />
      )}
    </div>
  ),
);
