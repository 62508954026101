import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const Shape7 = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 178 178"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path d="M93.11 3V134.6C93.11 157.08 111.34 175.31 133.82 175.31C156.3 175.31 174.53 157.08 174.53 134.6V3H93.11Z" />
    <path d="M48.44 87.66C25.96 87.66 7.73001 105.89 7.73001 128.37V172.97H89.15V128.37C89.15 105.89 70.92 87.66 48.44 87.66Z" />
    <path d="M3 43.71C3 66.19 21.23 84.42 43.71 84.42H89.15V3H43.71C21.23 3 3 21.23 3 43.71Z" />
  </svg>
);
