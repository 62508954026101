import type { SVGProps } from "@/shared.types";

export const TestTubeFixedIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 130 123"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <circle cx="76.0649" cy="53.936" r="53.936" fill="#A3B19E" />
    <path
      d="M98.2951 84.1671L89.421 71.1973L79.1816 82.1192L89.421 92.3586L98.2951 84.1671Z"
      fill="#768371"
    />
    <path
      d="M8.19336 88.9428L42.3245 26.8242L80.5513 47.9855L47.7854 105.326L36.8635 113.517L15.0196 109.422L8.19336 88.9428Z"
      fill="#768371"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.2414 21.9148C39.6593 20.9795 37.6185 21.5039 36.6833 23.086C35.748 24.6681 36.2723 26.7089 37.8544 27.6441L38.7238 28.1581L8.88415 79.6635C2.01253 91.5245 6.09512 106.686 17.9849 113.527C29.868 120.364 45.0681 116.311 51.9358 104.457L81.7914 52.9237L82.645 53.415C84.2378 54.3319 86.2724 53.784 87.1893 52.1912C88.1062 50.5983 87.5582 48.5638 85.9654 47.6469L82.2357 45.4999L44.9317 24.0964L41.2414 21.9148ZM14.6431 82.9999L44.475 31.5076L76.0209 49.6074L69.3431 61.1336L60.8689 56.2578C59.2758 55.3412 57.2414 55.8896 56.3248 57.4827C55.4083 59.0757 55.9567 61.1101 57.5497 62.0267L66.0067 66.8925L61.7407 74.256L45.9678 65.1809C44.3748 64.2644 42.3404 64.8128 41.4238 66.4058C40.5072 67.9988 41.0556 70.0332 42.6486 70.9498L58.4043 80.0149L54.4748 86.7975L45.7579 81.7822C44.1649 80.8657 42.1305 81.4141 41.2139 83.0071C40.2974 84.6001 40.8458 86.6345 42.4388 87.5511L51.1383 92.5565L46.1769 101.12C41.1534 109.791 30.0172 112.771 21.3041 107.758C12.5976 102.748 9.62344 91.6642 14.6431 82.9999Z"
      fill="#363F33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.9568 69.6001C91.1047 66.5907 86.3797 66.5907 83.5276 69.6001C82.1629 71.04 80.4978 72.9509 79.153 74.988C77.891 76.8998 76.5404 79.4626 76.5404 82.091C76.5404 88.9048 81.8766 94.6626 88.7422 94.6626C95.6079 94.6626 100.944 88.9048 100.944 82.091C100.944 79.4626 99.5935 76.8998 98.3314 74.988C96.9867 72.9509 95.3215 71.04 93.9568 69.6001ZM88.3583 74.1784C88.4883 74.0412 88.6243 73.9986 88.7422 73.9986C88.8601 73.9986 88.9962 74.0412 89.1261 74.1784C90.3622 75.4826 91.7345 77.0754 92.777 78.6546C93.9021 80.3591 94.2885 81.5251 94.2885 82.091C94.2885 85.4877 91.6786 88.0071 88.7422 88.0071C85.8058 88.0071 83.1959 85.4877 83.1959 82.091C83.1959 81.5251 83.5823 80.3591 84.7075 78.6546C85.75 77.0754 87.1222 75.4826 88.3583 74.1784Z"
      fill="#363F33"
    />
  </svg>
);

export const TestTubeFixedIconMono = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <rect
      x="14.1189"
      y="1.48633"
      width="60"
      height="60"
      rx="30"
      transform="rotate(9.40703 14.1189 1.48633)"
      fill="#FFDFCD"
    />
    <path
      d="M60 49.5C60 47.0669 59.5 46 55.5 41C52 44 49.5 45 49.5 49C49.5 56.5 60 55 60 49.5Z"
      fill="#E3B79E"
    />
    <path
      d="M49 29L28 16C17 37 7.99997 47.9 7.99997 49.5C7.99997 51.5 5.99997 60.5 14.5 65C21.3 68.6 28 65.5 30.5 63.5L49 29Z"
      fill="#E3B79E"
    />
    <path
      fillRule="evenodd"
      d="M26.8978 12.5555C25.9618 12.0022 24.7544 12.3124 24.2011 13.2484C23.6478 14.1844 23.958 15.3917 24.894 15.9451L25.4083 16.2491L7.75488 46.7202C3.68956 53.7373 6.10486 62.7068 13.139 66.754C20.1691 70.7988 29.1617 68.4012 33.2247 61.3881L50.8875 30.9007L51.3925 31.1914C52.3349 31.7338 53.5385 31.4097 54.081 30.4673C54.6234 29.525 54.2992 28.3213 53.3569 27.7789L51.1504 26.5087L29.081 13.8462L26.8978 12.5555ZM11.1619 48.6941L28.8108 18.2308L47.4736 28.9387L43.523 35.7578L38.5096 32.8732C37.5671 32.331 36.3635 32.6554 35.8213 33.5978C35.279 34.5403 35.6035 35.7439 36.5459 36.2861L41.5492 39.1648L39.0253 43.5211L29.6939 38.1522C28.7515 37.61 27.5479 37.9344 27.0057 38.8768C26.4634 39.8193 26.7878 41.0229 27.7303 41.5651L37.0515 46.9282L34.7267 50.9408L29.5698 47.9737C28.6273 47.4315 27.4237 47.7559 26.8815 48.6983C26.3393 49.6408 26.6637 50.8444 27.6061 51.3866L32.7529 54.3479L29.8177 59.4143C26.8457 64.5441 20.2574 66.3069 15.1026 63.3411C9.95182 60.3775 8.19225 53.82 11.1619 48.6941Z"
      fill="#8C6955"
    />
    <path
      fillRule="evenodd"
      d="M58.0847 40.7666C56.3974 38.9863 53.602 38.9863 51.9147 40.7666C51.1073 41.6185 50.1222 42.749 49.3266 43.9542C48.58 45.0852 47.781 46.6014 47.781 48.1564C47.781 52.1875 50.9379 55.5939 54.9997 55.5939C59.0615 55.5939 62.2185 52.1875 62.2185 48.1564C62.2185 46.6014 61.4194 45.0852 60.6728 43.9542C59.8772 42.749 58.8921 41.6185 58.0847 40.7666ZM54.7726 43.4752C54.8495 43.394 54.93 43.3688 54.9997 43.3688C55.0695 43.3688 55.1499 43.394 55.2268 43.4752C55.9581 44.2468 56.77 45.1891 57.3867 46.1234C58.0524 47.1318 58.281 47.8216 58.281 48.1564C58.281 50.1659 56.7369 51.6564 54.9997 51.6564C53.2625 51.6564 51.7185 50.1659 51.7185 48.1564C51.7185 47.8216 51.9471 47.1318 52.6127 46.1234C53.2295 45.1891 54.0413 44.2468 54.7726 43.4752Z"
      fill="#8C6955"
    />
  </svg>
);
