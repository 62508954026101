import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { SIZE, VARIANT } from "@/shared.constants";
import { tw } from "@/utils";

// https://www.figma.com/design/wuTj0UhW8hs2D9S6ubvrSo/Fridays-Components?node-id=443-3346&t=3d3YCvCPFI4XtHPh-0
const badgeVariance = cva(
  "flex h-fit w-fit items-center justify-center border border-background bg-salmon-09 px-3 text-brown-01 cursor-default",
  {
    variants: {
      variant: {
        primary: "rounded-lg",
        secondary: "rounded-3xl",
        outline: "rounded-lg border-salmon-07 bg-transparent text-salmon-09",
        "outline-secondary":
          "rounded-3xl border-salmon-07 bg-transparent text-salmon-09",
      },
      size: {
        sm: "py-0.5 text-xs",
        md: "py-0.5 text-sm font-medium",
        lg: "py-1 text-sm font-medium",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "sm",
    },
  },
);

interface BadgeProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof badgeVariance> {
  label?: string;
  asChild?: boolean;
}

export const Badge = forwardRef<ElementRef<"div">, BadgeProps>(
  (
    {
      className,
      asChild,
      variant = VARIANT.PRIMARY,
      size = SIZE.SMALL,
      ...props
    },
    ref,
  ) => {
    const Component = asChild ? Slot : "div";
    return (
      <Component
        ref={ref}
        className={tw(badgeVariance({ variant, size, className }))}
        {...props}
      />
    );
  },
);
Badge.displayName = "Badge";
