import { t } from "ttag";

import { FullScreenAlert } from "@/components";
import { DisqualifiedActionButtons } from "@/domains/onboarding/components";
import { VALIDATION_ERROR } from "./mainInfo.constants";
import type { ValidationError } from "./mainInfo.types";

const DISQUALIFIED_MESSAGE = {
  [VALIDATION_ERROR.AGE_RANGE]: {
    title: t`It looks like you're outside the age range for our Fridays program`,
    description: t`Currently, we're focusing on individuals between 18 and 74. Keep that positive energy going, though! There are other fantastic options out there to kickstart your wellness journey.`,
  },
  [VALIDATION_ERROR.HAS_MEDICAID]: {
    title: t`We're sorry, Fridays care isn't currently available for Medicaid holders`,
    description: t`But, don't let that discourage you! There are numerous resources and support options designed to help you on your wellness journey.`,
  },
} as const;

interface DisqualifiedScreenProps {
  validationError: ValidationError;
  onGoBack: () => void;
}
export const MainInfoDisqualifiedScreen = ({
  validationError,
  onGoBack,
}: DisqualifiedScreenProps) => (
  <FullScreenAlert
    {...DISQUALIFIED_MESSAGE[validationError]}
    customAction={<DisqualifiedActionButtons onGoBack={onGoBack} />}
  />
);
