import type { Transition } from "framer-motion";
import { motion } from "framer-motion";

import { DIRECTION, SIZE, VARIANT } from "@/shared.constants";
import type { Direction, Size, Variant } from "@/shared.types";
import { tw } from "@/utils";

interface ShapeProps {
  animated?: boolean;
  className?: string;
  direction?: Direction;
  shapeColor?: Variant;
  shapeVariant?: Variant;
  size?: Size;
}

const getOppositeDirection = (direction: Direction): Direction => {
  switch (direction) {
    case DIRECTION.TOP:
      return DIRECTION.BOTTOM;
    case DIRECTION.RIGHT:
      return DIRECTION.LEFT;
    case DIRECTION.BOTTOM:
      return DIRECTION.TOP;
    case DIRECTION.LEFT:
      return DIRECTION.RIGHT;
  }
};

const getNextDirection = (direction: Direction): Direction => {
  switch (direction) {
    case DIRECTION.TOP:
      return DIRECTION.RIGHT;
    case DIRECTION.RIGHT:
      return DIRECTION.BOTTOM;
    case DIRECTION.BOTTOM:
      return DIRECTION.LEFT;
    case DIRECTION.LEFT:
      return DIRECTION.TOP;
  }
};

const getShapeColor = (shapeColor: Variant) =>
  tw(
    shapeColor === VARIANT.PRIMARY && "bg-salmon-06",
    shapeColor === VARIANT.SECONDARY && "bg-salmon-07",
    shapeColor === VARIANT.TERTIARY && "bg-nature-09",
    shapeColor === VARIANT.QUATERNARY && "bg-nature-04",
    shapeColor === VARIANT.QUINARY && "bg-salmon-09",
  );

const transition: Transition = {
  ease: "easeInOut",
  duration: 6,
  repeat: Infinity,
};

/**
 * @deprecated Use the new shapes from the `@ui/shapes` folder for V2 features.
 */
export const shapes = {
  RoundedRectangle: ({
    direction = DIRECTION.TOP,
    shapeVariant = VARIANT.PRIMARY,
    shapeColor = VARIANT.PRIMARY,
    animated = false,
  }: ShapeProps) => (
    <motion.div
      animate={
        animated
          ? {
              width:
                direction === DIRECTION.TOP || direction === DIRECTION.BOTTOM
                  ? []
                  : ["10.5rem", "7rem", "10.5rem"],
              height:
                direction === DIRECTION.TOP || direction === DIRECTION.BOTTOM
                  ? ["10.5rem", "7rem", "10.5rem"]
                  : [],
              transition,
            }
          : {}
      }
      className={tw(
        getShapeColor(shapeColor),

        direction === DIRECTION.TOP && [
          "h-42 w-20",
          shapeVariant === VARIANT.PRIMARY && "rounded-t-full",
          shapeVariant === VARIANT.SECONDARY && "rounded-tl-full",
          shapeVariant === VARIANT.TERTIARY && "rounded-tr-full",
        ],
        direction === DIRECTION.RIGHT && [
          "h-20 w-42",
          shapeVariant === VARIANT.PRIMARY && "rounded-r-full",
          shapeVariant === VARIANT.SECONDARY && "rounded-tr-full",
          shapeVariant === VARIANT.TERTIARY && "rounded-br-full",
        ],
        direction === DIRECTION.BOTTOM && [
          "h-42 w-20",
          shapeVariant === VARIANT.PRIMARY && "rounded-b-full",
          shapeVariant === VARIANT.SECONDARY && "rounded-br-full",
          shapeVariant === VARIANT.TERTIARY && "rounded-bl-full",
        ],
        direction === DIRECTION.LEFT && [
          "h-20 w-42",
          shapeVariant === VARIANT.PRIMARY && "rounded-l-full",
          shapeVariant === VARIANT.SECONDARY && "rounded-bl-full",
          shapeVariant === VARIANT.TERTIARY && "rounded-tl-full",
        ],

        animated && "self-end bg-nature-09",
      )}
    />
  ),

  RoundedSquare: ({
    direction = DIRECTION.TOP,
    shapeColor = VARIANT.PRIMARY,
  }: ShapeProps) => (
    <div
      className={tw(
        "h-full w-full",
        getShapeColor(shapeColor),

        direction === DIRECTION.TOP && "w-20 rounded-t-full",
        direction === DIRECTION.RIGHT && "h-20 rounded-r-full",
        direction === DIRECTION.BOTTOM && "w-20 rounded-b-full",
        direction === DIRECTION.LEFT && "h-20 rounded-l-full",
      )}
    />
  ),

  CompositeSquareBy2: ({
    direction = DIRECTION.TOP,
    shapeVariant = VARIANT.PRIMARY,
    shapeColor = VARIANT.PRIMARY,
    animated = false,
    className,
  }: ShapeProps) => (
    <div
      className={tw(
        "flex h-44 w-44 shrink-0 items-center justify-center gap-1 p-1",
        (direction === DIRECTION.RIGHT || direction === DIRECTION.LEFT) &&
          "flex-col",
        direction === DIRECTION.BOTTOM && "flex-row-reverse",
        direction === DIRECTION.LEFT && "flex-col-reverse",
        className,
      )}
    >
      <shapes.RoundedRectangle
        direction={direction}
        shapeColor={shapeColor}
        animated={animated}
      />
      <shapes.RoundedRectangle
        direction={
          shapeVariant === VARIANT.PRIMARY ||
          shapeVariant === VARIANT.QUATERNARY
            ? direction
            : getOppositeDirection(direction)
        }
        shapeVariant={
          shapeVariant === VARIANT.QUATERNARY ? VARIANT.SECONDARY : shapeVariant
        }
        shapeColor={shapeColor}
      />
    </div>
  ),

  CompositeSquareBy3: ({
    direction = DIRECTION.TOP,
    shapeVariant = VARIANT.PRIMARY,
    shapeColor = VARIANT.PRIMARY,
    animated = false,
    className,
  }: ShapeProps) => (
    <div
      className={tw(
        "flex h-44 w-44 shrink-0 items-center justify-center gap-1 p-1",
        (direction === DIRECTION.RIGHT || direction === DIRECTION.LEFT) &&
          "flex-col",
        direction === DIRECTION.BOTTOM && "flex-row-reverse",
        direction === DIRECTION.LEFT && "flex-col-reverse",
        className,
      )}
    >
      <shapes.RoundedRectangle direction={direction} shapeColor={shapeColor} />

      <div
        className={tw(
          "flex grow items-end justify-between gap-1",
          (direction === DIRECTION.TOP || direction === DIRECTION.BOTTOM) &&
            "h-42 flex-col",
          (direction === DIRECTION.RIGHT || direction === DIRECTION.LEFT) &&
            "w-42",
          direction === DIRECTION.RIGHT && "flex-row-reverse items-start",
          direction === DIRECTION.BOTTOM && "flex-col-reverse",
          direction === DIRECTION.TOP && "items-start",
        )}
      >
        <div
          className={tw(
            "grow",
            direction === DIRECTION.TOP && "flex items-end",
          )}
        >
          {shapeVariant === VARIANT.PRIMARY && (
            <shapes.RoundedSquare
              direction={getOppositeDirection(direction)}
              shapeColor={shapeColor}
            />
          )}
          {shapeVariant === VARIANT.SECONDARY && (
            <motion.div
              animate={
                animated
                  ? {
                      width: ["5rem", "3rem", "5rem"],
                      height: ["5rem", "3rem", "5rem"],
                      transition,
                    }
                  : {}
              }
              className={tw(
                "h-20 w-20 rounded-full",
                getShapeColor(shapeColor),
                animated && "bg-nature-09",
              )}
            />
          )}
          {shapeVariant === VARIANT.TERTIARY && (
            <motion.div
              animate={
                animated
                  ? {
                      width: ["3rem", "5rem", "3rem"],
                      height: ["3rem", "5rem", "3rem"],
                      transition,
                    }
                  : {}
              }
              className={tw(
                "h-20 w-20 rounded-full",
                direction === DIRECTION.BOTTOM && "rounded-tr-none",
                direction === DIRECTION.TOP && "rounded-bl-none",
                getShapeColor(shapeColor),
                animated && "bg-nature-09",
              )}
            />
          )}
        </div>

        <shapes.RoundedSquare
          direction={getNextDirection(direction)}
          shapeColor={shapeColor}
        />
      </div>
    </div>
  ),

  CompositeCircleBy2: ({
    direction = DIRECTION.TOP,
    shapeColor = VARIANT.PRIMARY,
    className,
  }: ShapeProps) => (
    <div
      className={tw(
        "flex h-44 w-44 gap-1",
        (direction === DIRECTION.RIGHT || direction === DIRECTION.LEFT) &&
          "rotate-90",
        className,
      )}
    >
      <div className={tw("w-1/2 rounded-l-full", getShapeColor(shapeColor))} />
      <div className={tw("w-1/2 rounded-r-full", getShapeColor(shapeColor))} />
    </div>
  ),

  Circle: ({ shapeColor = VARIANT.PRIMARY, className }: ShapeProps) => (
    <div className={tw("flex h-44 w-44", className)}>
      <div className={tw("w-full rounded-b-full", getShapeColor(shapeColor))} />
    </div>
  ),

  Doughnut: ({ shapeColor = VARIANT.PRIMARY, className }: ShapeProps) => (
    <div
      className={tw(
        "relative flex h-44 w-44 rounded-full",
        getShapeColor(shapeColor),

        shapeColor === VARIANT.QUATERNARY && "bg-nature-09",
        className,
      )}
    >
      <div
        className={tw(
          "absolute right-1/2 top-1/2 h-16 w-16 -translate-y-1/2 translate-x-1/2 rounded-full",
          shapeColor === VARIANT.PRIMARY && "bg-salmon-04",
          shapeColor === VARIANT.SECONDARY && "bg-salmon-04",
          shapeColor === VARIANT.TERTIARY && "bg-nature-10",
          shapeColor === VARIANT.QUATERNARY && "bg-nature-08",
        )}
      />
    </div>
  ),

  PartialDoughnut: ({
    direction = DIRECTION.TOP,
    shapeColor = VARIANT.PRIMARY,
    size = SIZE.LARGE,
    className,
  }: ShapeProps) => {
    const color = tw(
      shapeColor === VARIANT.PRIMARY && "fill-salmon-06",
      shapeColor === VARIANT.SECONDARY && "fill-salmon-07",
      shapeColor === VARIANT.TERTIARY && "fill-nature-09",
      shapeColor === VARIANT.QUATERNARY && "fill-brown-04",
    );
    return (
      <div
        className={tw(
          "flex h-44 w-44 items-center justify-center",
          direction === DIRECTION.TOP && "rotate-180",
          direction === DIRECTION.RIGHT && "-rotate-90",
          direction === DIRECTION.LEFT && "rotate-90",

          size === SIZE.MEDIUM && "h-36 w-36",
          className,
        )}
      >
        {size === SIZE.MEDIUM ? (
          <svg
            width="152"
            height="152"
            viewBox="0 0 152 152"
            xmlns="http://www.w3.org/2000/svg"
            className={tw("rotate-180", color)}
          >
            <path d="M121.53 46.4335C104.96 46.4335 91.53 59.8635 91.53 76.4335C91.53 85.1235 84.46 92.1935 75.77 92.1935C67.08 92.1935 60.01 85.1235 60.01 76.4335C60.01 67.7435 67.08 60.6735 75.77 60.6735C92.34 60.6735 105.77 47.2435 105.77 30.6735C105.77 14.1035 92.34 0.673464 75.77 0.673464C33.99 0.663464 0 34.6535 0 76.4335C0 118.213 33.99 152.193 75.76 152.193C117.53 152.193 151.52 118.203 151.52 76.4335C151.52 59.8635 138.09 46.4335 121.52 46.4335H121.53Z" />
          </svg>
        ) : (
          <svg
            width="175"
            height="175"
            viewBox="0 0 175 175"
            xmlns="http://www.w3.org/2000/svg"
            className={color}
          >
            <path d="M87.28 174.55C70.71 174.55 57.28 161.12 57.28 144.55C57.28 127.98 70.71 114.55 87.28 114.55C102.32 114.55 114.56 102.31 114.56 87.27C114.56 72.23 102.32 59.99 87.28 59.99C72.24 59.99 60 72.23 60 87.27C60 103.84 46.57 117.27 30 117.27C13.43 117.27 0 103.84 0 87.28C0 39.15 39.15 0 87.28 0C135.41 0 174.56 39.15 174.56 87.28C174.56 135.41 135.41 174.56 87.28 174.56V174.55Z" />
          </svg>
        )}
      </div>
    );
  },

  PartialDoughnut2: ({
    direction = DIRECTION.TOP,
    shapeColor = VARIANT.PRIMARY,
    animated = false,
    className,
  }: ShapeProps) => (
    <div
      className={tw(
        "relative flex h-44 w-44 shrink-0",
        direction === DIRECTION.TOP && "items-end justify-start",
        direction === DIRECTION.LEFT && "items-end justify-end",
        direction === DIRECTION.BOTTOM && "items-start justify-end",
        className,
      )}
    >
      <div
        className={tw(
          "absolute flex h-14 w-14 items-end",
          direction === DIRECTION.TOP && "right-1 top-1",
          direction === DIRECTION.RIGHT && "bottom-1 right-1 items-start",
          direction === DIRECTION.LEFT && "left-1 top-1",
          direction === DIRECTION.BOTTOM &&
            "bottom-1 left-1 items-start justify-end",
        )}
      >
        <motion.div
          animate={
            animated
              ? {
                  width: ["100%", "60%", "100%"],
                  height: ["100%", "60%", "100%"],
                  transition,
                }
              : {}
          }
          className={tw(
            "h-full w-full rounded-full",
            getShapeColor(shapeColor),

            shapeColor === VARIANT.QUATERNARY && "bg-nature-05",
            animated && "bg-nature-09",
          )}
        />
      </div>

      <shapes.PartialDoughnut
        size="md"
        direction={direction}
        shapeColor={shapeColor}
      />
    </div>
  ),
};
