import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Skeleton } from "@/ui";

const cardSkeletonVariant = tv({
  slots: {
    card: "rounded-2xl bg-gray-200 p-4",
    skeletonPrimary: "h-5 rounded-full bg-gray-400",
    skeletonSecondary: "h-5 rounded-full bg-gray-500",
  },
  variants: {
    color: {
      salmon: {
        card: "bg-salmon-02",
        skeletonPrimary: "bg-salmon-04",
        skeletonSecondary: "bg-salmon-05",
      },
      nature: {
        card: "bg-nature-03",
        skeletonPrimary: "bg-nature-04",
        skeletonSecondary: "bg-nature-05",
      },
    },
  },
});
type CardSkeletonVariant = VariantProps<typeof cardSkeletonVariant>;

interface CardSkeletonProps extends CardSkeletonVariant {
  className?: string;
}

export const CardSkeleton = ({
  className,
  color = "salmon",
}: CardSkeletonProps) => {
  const { card, skeletonPrimary, skeletonSecondary } = cardSkeletonVariant({
    color,
  });

  return (
    <div className={card({ className })}>
      <div className="flex gap-7">
        <div className="flex flex-1 flex-col gap-3">
          <Skeleton className={skeletonPrimary()} />
          <Skeleton className={skeletonPrimary()} />
          <Skeleton className={skeletonPrimary()} />
        </div>

        <div className="flex w-1/4 flex-col justify-between">
          <Skeleton className={skeletonSecondary()} />
          <Skeleton
            className={skeletonPrimary({ className: "w-2/3 self-end" })}
          />
        </div>
      </div>
    </div>
  );
};
