import type { SVGProps } from "@/shared.types";

export const GraphPenIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 85 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <rect
      x="14.292"
      y="8.24646"
      width="63.9381"
      height="63.9381"
      rx="31.969"
      fill="#C69C84"
    />
    <rect
      x="-0.538086"
      y="31.0413"
      width="48.1416"
      height="44.3805"
      rx="11.2832"
      transform="rotate(-11.4008 -0.538086 31.0413)"
      fill="#FFD2B8"
    />

    <path
      d="M38.8949 42.1792C39.3457 41.3456 39.0354 40.3043 38.2018 39.8535C37.3682 39.4027 36.3269 39.713 35.8761 40.5466L32.8212 46.1954C32.1908 47.3611 31.8024 48.0706 31.4583 48.5353C31.2987 48.7509 31.1971 48.8444 31.1471 48.8825C31.1369 48.8902 31.1295 48.8951 31.1248 48.898C31.1194 48.8971 31.1107 48.8955 31.0983 48.8923C31.0374 48.8766 30.9075 48.8297 30.6769 48.6929C30.1796 48.3978 29.5466 47.8943 28.5137 47.0641L27.6992 46.4093C26.7842 45.6737 25.9593 45.0105 25.226 44.5754C24.4282 44.102 23.4685 43.7306 22.3483 43.9565C21.228 44.1824 20.4873 44.8967 19.9352 45.6423C19.4278 46.3275 18.9244 47.2586 18.366 48.2914L15.2568 54.0406C14.806 54.8742 15.1163 55.9155 15.9499 56.3663C16.7835 56.8171 17.8248 56.5068 18.2756 55.6732L21.3305 50.0244C21.9609 48.8587 22.3493 48.1492 22.6934 47.6845C22.853 47.469 22.9546 47.3754 23.0047 47.3374C23.0148 47.3296 23.0222 47.3247 23.0269 47.3218C23.0323 47.3227 23.041 47.3244 23.0534 47.3275C23.1143 47.3432 23.2442 47.3901 23.4748 47.5269C23.9722 47.822 24.6052 48.3255 25.638 49.1558L26.4525 49.8105C27.3675 50.5462 28.1924 51.2094 28.9257 51.6445C29.7235 52.1178 30.6832 52.4892 31.8035 52.2633C32.9237 52.0374 33.6644 51.3232 34.2165 50.5776C34.7239 49.8923 35.2273 48.9613 35.7856 47.9285L38.8949 42.1792Z"
      fill="#523A2D"
    />
    <path
      fillRule="evenodd"
      d="M22.0853 24.0254C16.9079 25.0694 12.8506 25.8875 9.77067 26.9515C6.61821 28.0406 4.24465 29.4653 2.64243 31.8769C1.04022 34.2885 0.646703 37.0287 0.864463 40.3568C1.07721 43.6084 1.8954 47.6658 2.93944 52.8431L2.99134 53.1005C4.03533 58.2778 4.85347 62.3352 5.91746 65.4151C7.00652 68.5676 8.43122 70.9411 10.8428 72.5433C13.2544 74.1456 15.9946 74.5391 19.3228 74.3213C22.5743 74.1086 26.6317 73.2904 31.8091 72.2463L32.0664 72.1944C37.2438 71.1504 41.3011 70.3323 44.3811 69.2683C47.5335 68.1793 49.9071 66.7545 51.5093 64.3429C53.1115 61.9313 53.505 59.1911 53.2872 55.863C53.0745 52.6114 52.2563 48.5541 51.2123 43.3767L51.1604 43.1194C50.1164 37.942 49.2982 33.8846 48.2342 30.8047C47.1452 27.6523 45.7205 25.2787 43.3089 23.6765C40.8973 22.0743 38.1571 21.6808 34.8289 21.8985C31.5774 22.1113 27.52 22.9295 22.3427 23.9735L22.0853 24.0254ZM5.50102 33.7761C6.52103 32.2408 8.10178 31.159 10.8913 30.1954C13.7279 29.2154 17.5584 28.4393 22.8924 27.3637C28.2263 26.2881 32.0583 25.5191 35.053 25.3232C37.998 25.1305 39.8744 25.5151 41.4097 26.5351C42.945 27.5551 44.0267 29.1358 44.9904 31.9254C45.9703 34.7619 46.7465 38.5925 47.8221 43.9264C48.8977 49.2604 49.6667 53.0924 49.8626 56.0871C50.0553 59.032 49.6707 60.9085 48.6507 62.4438C47.6307 63.979 46.0499 65.0608 43.2604 66.0245C40.4238 67.0044 36.5933 67.7805 31.2593 68.8561C25.9254 69.9317 22.0934 70.7007 19.0987 70.8967C16.1537 71.0893 14.2773 70.7048 12.742 69.6847C11.2067 68.6647 10.125 67.084 9.16132 64.2945C8.18138 61.4579 7.40524 57.6274 6.32964 52.2934C5.25405 46.9594 4.48505 43.1275 4.28911 40.1328C4.09642 37.1878 4.48101 35.3113 5.50102 33.7761Z"
      fill="#523A2D"
    />

    <path
      d="M58.5785 30.684L71.2485 5.26753C72.7589 2.23775 76.4703 1.04961 79.4594 2.63897L79.6411 2.73559C82.8094 4.42028 84.1129 8.28447 82.6123 11.5441L71.0487 36.6626L61.4038 43.246L59.8401 42.2014L58.5785 30.684Z"
      fill="#E3B79E"
    />

    <path
      fillRule="evenodd"
      d="M69.4489 5.33485C71.3761 1.31512 76.1971 -0.381217 80.2168 1.54599C84.2365 3.47319 85.9329 8.29414 84.0057 12.3139L74.0089 33.1651C73.4378 34.3564 73.1016 35.0577 72.6829 35.7109C72.1893 36.4807 71.6134 37.1946 70.9653 37.8397C70.4154 38.387 69.801 38.8639 68.7573 39.674L63.9759 43.3855L62.8247 44.2792C61.8909 45.0041 60.6277 45.1391 59.5617 44.6281C58.4958 44.117 57.8101 43.0476 57.7905 41.8656L57.6659 34.3564C57.6439 33.0354 57.631 32.2578 57.7134 31.4863C57.8105 30.577 58.0064 29.681 58.2976 28.8141C58.5447 28.0786 58.8809 27.3773 59.4522 26.186L69.4489 5.33485ZM63.0216 40.853L67.1002 37.687C68.2358 36.8055 68.7175 36.4288 69.1412 36.0071C69.6562 35.4944 70.1138 34.9272 70.506 34.3154C70.8286 33.8121 71.095 33.2617 71.7165 31.9655L79.6941 15.3258C78.2526 15.3247 76.3055 15.0676 74.2146 14.0652C72.1238 13.0628 70.7041 11.7057 69.8006 10.5825L61.8229 27.2222C61.2015 28.5184 60.9391 29.0708 60.7487 29.6375C60.5174 30.3263 60.3617 31.0383 60.2845 31.7609C60.221 32.3553 60.2289 32.9668 60.2528 34.4041L60.3384 39.5666L63.0216 40.853ZM71.0829 7.90782C71.2386 8.17275 71.4687 8.52642 71.7846 8.92334C72.4959 9.81694 73.6333 10.919 75.3325 11.7336C77.0317 12.5483 78.6031 12.745 79.7452 12.7399C80.2525 12.7377 80.6724 12.6956 80.9764 12.6511L81.6741 11.196C82.9839 8.46399 81.831 5.18742 79.0989 3.8776C76.3669 2.56777 73.0904 3.72069 71.7805 6.45271L71.0829 7.90782Z"
      fill="#523A2D"
    />
  </svg>
);
