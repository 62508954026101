import { useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { Link } from "react-router-dom";
import { jt, t } from "ttag";

import { FullScreenLoader } from "@/components";
import {
  useChangeSubscriptionPlan,
  useMonthlyPeriodPlan,
} from "@/domains/billing/hooks";
import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  Label,
  LightbulbFixedIconMono,
} from "@/ui";
import { PlanChangeComparison } from "./components";

interface SubscriptionUpdateModalProps extends ModalProps {
  onContinue: () => void;
}

export const SubscriptionUpdateModal = ({
  show,
  onClose,
  onContinue,
}: SubscriptionUpdateModalProps) => {
  const [hasConsent, setHasConsent] = useState<CheckedState>(false);

  const changeSubscriptionPlanMutation = useChangeSubscriptionPlan();

  const { data: monthlyPeriodPlan, isLoading } = useMonthlyPeriodPlan();

  const handleContinue = () => {
    if (monthlyPeriodPlan)
      changeSubscriptionPlanMutation.mutate(
        monthlyPeriodPlan.subscriptionPriceId,
        {
          onSuccess: () => {
            onClose();
            onContinue();
          },
        },
      );
  };

  const linkToMessages = (
    <Link key={ROUTES.MESSAGES} to={ROUTES.MESSAGES} className="underline">
      {t`send us a message`}
    </Link>
  );

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal">
        <div className="flex flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <LightbulbFixedIconMono />
            </Dialog.Icon>

            <Dialog.Title>{t`Subscription Update Needed`}</Dialog.Title>

            <Dialog.Description>
              {t`To stay aligned with our policies, we'll need to switch your subscription from 3 months to 1 month. No worries—this won't affect your access to anything; it's just a quick update to keep everything on track!`}
            </Dialog.Description>
          </Dialog.Header>

          <Alert>
            {jt`You'll receive a partial refund to the card on file to cover the difference in price with your subscription. If you need additional help, please ${linkToMessages} in the portal.`}
          </Alert>

          <PlanChangeComparison />

          <div className="flex gap-2.5 pt-2">
            <Checkbox
              id="subscription-update-consent"
              checked={hasConsent}
              onCheckedChange={setHasConsent}
              containerClassName="grow-0"
              className="mt-0.5"
            />
            <Label htmlFor="subscription-update-consent">
              {t`I agree to the new charge and understand the changes`}
            </Label>
          </div>

          <Dialog.Footer variant="sticky">
            <Button
              disabled={!hasConsent || isLoading}
              onClick={handleContinue}
              size="lg"
              className="w-full md:w-fit"
            >
              {t`Approve change`}
            </Button>
          </Dialog.Footer>
        </div>

        {changeSubscriptionPlanMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
