import { createContext, useContext } from "react";

import type { Period, WeightEntry } from "@/shared.types";

interface WeightEntryData extends WeightEntry {
  x: number;
  y: number;
}

interface WeightEntriesContextValue {
  data: WeightEntryData[];
  view: Period;
}

export const WeightEntriesContext =
  createContext<WeightEntriesContextValue | null>(null);

export const useWeightEntries = () => {
  const context = useContext(WeightEntriesContext);

  if (!context) {
    throw new Error(
      "useWeightEntries must be used within a <WeightEntriesContext.Provider />",
    );
  }

  return context;
};
