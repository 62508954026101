import type {
  DetailedHTMLProps,
  HTMLAttributes,
  ImgHTMLAttributes,
} from "react";
import { forwardRef } from "react";

import { tw } from "@/utils";
import { Skeleton } from "./Skeleton";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex flex-col gap-1", className)}
      {...props}
    />
  ),
);
Root.displayName = "UploadedFile.Root";

const Container = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex h-20 gap-4 rounded-2xl border border-salmon-09 bg-salmon-02 px-4 py-3",
        className,
      )}
      {...props}
    />
  ),
);
Container.displayName = "UploadedFile.Container";

const Preview = forwardRef<
  HTMLImageElement,
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
>(({ className, alt, ...props }, ref) => (
  <img
    alt={alt}
    ref={ref}
    className={tw("aspect-square h-full rounded-md object-cover", className)}
    {...props}
  />
));
Preview.displayName = "UploadedFile.Preview";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex h-full grow flex-col items-start justify-center gap-1",
        className,
      )}
      {...props}
    />
  ),
);
Content.displayName = "Header.Content";

const Title = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h6
    ref={ref}
    className={tw(
      "max-w-52 truncate text-sm text-salmon-09 md:max-w-80",
      className,
    )}
    {...props}
  >
    {children}
  </h6>
));
Title.displayName = "UploadedFile.Title";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={tw("text-xs text-brown-06", className)} {...props} />
));
Description.displayName = "UploadedFile.Description";

const Actions = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("self-center", className)} {...props} />
  ),
);
Actions.displayName = "UploadedFile.Actions";

const UploadedFileSkeleton = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={tw(
      "flex h-20 items-center gap-4 rounded-2xl border border-salmon-03 bg-salmon-02 px-4 py-3",
      className,
    )}
    {...props}
  >
    <div className="flex grow gap-3">
      <Skeleton className="size-6" />
      <Skeleton className="h-6 w-full max-w-40" />
    </div>

    <div className="flex basis-1/6 gap-3 justify-self-end">
      <Skeleton className="size-6 shrink-0" />
      <Skeleton className="h-6 w-full max-w-24" />
    </div>

    <Skeleton className="size-6" />
  </div>
));
UploadedFileSkeleton.displayName = "UploadedFile.Skeleton";

export {
  Root,
  Container,
  Preview,
  Content,
  Title,
  Description,
  Actions,
  UploadedFileSkeleton as Skeleton,
};
