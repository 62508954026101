const BASE_ROUTE = "/embeddables-onboarding";

export const EMBEDDABLES_ONBOARDING_ROUTE = {
  BASE: BASE_ROUTE,
  ACCOUNT: `${BASE_ROUTE}/account`,
  MAIN_INFO: `${BASE_ROUTE}/main-info`,
  MAIN_INFO_MEASUREMENTS: `${BASE_ROUTE}/main-info/measurements`,
  MAIN_INFO_BMI_SURVEY: `${BASE_ROUTE}/main-info/bmi-survey`,
  CHOOSE_JOURNEY: `${BASE_ROUTE}/choose-journey`,
  CHOOSE_JOURNEY_SCHEDULE: `${BASE_ROUTE}/choose-journey/schedule`,
  INTAKE: `${BASE_ROUTE}/intake-form`,
  INTAKE_REFILL_REQUEST: `${BASE_ROUTE}/intake-form/refill-request`,
  INTAKE_QUESTIONS: `${BASE_ROUTE}/intake-form/questions`,
  INTAKE_ADDITIONAL: `${BASE_ROUTE}/intake-form/additional-files`,
  SUCCESS: `${BASE_ROUTE}/start-your-journey`,
} as const;

export const REQUIRE_JOURNEY_INFO_ROUTES = [
  EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE,
  EMBEDDABLES_ONBOARDING_ROUTE.INTAKE,
  EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_REFILL_REQUEST,
  EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_QUESTIONS,
  EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_ADDITIONAL,
  EMBEDDABLES_ONBOARDING_ROUTE.SUCCESS,
] as const;

export const REQUIRE_MEASUREMENTS_ROUTES = [
  EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_BMI_SURVEY,
  EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY,
  ...REQUIRE_JOURNEY_INFO_ROUTES,
] as const;

export const REQUIRE_MAIN_INFO_ROUTES = [
  EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS,
  ...REQUIRE_MEASUREMENTS_ROUTES,
] as const;
