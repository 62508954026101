import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import { SKELETON_DATA } from "../mockup-data";

export const ChartSkeleton = () => {
  return (
    <ResponsiveContainer
      width="100%"
      height={400}
      className="pointer-events-none animate-pulse"
    >
      <ScatterChart
        data={SKELETON_DATA}
        height={300}
        margin={{
          top: 30,
          right: 30,
          bottom: 16,
          left: -30,
        }}
        className="opacity-50"
      >
        <XAxis
          scale="linear"
          dataKey="x"
          padding={{ left: 20 }}
          tickFormatter={() => ""}
        />

        <YAxis
          tickCount={6}
          dataKey="y"
          tickMargin={25}
          padding={{ top: 20 }}
        />
        <ZAxis type="number" range={[100]} />

        <Scatter
          fill="#8C6955"
          line={{
            strokeDasharray: "5 4",
          }}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
