import { Link } from "react-router-dom";
import { t } from "ttag";

import { useBoolean } from "@/hooks";
import { Button, Popover, VideoCameraIcon } from "@/ui";
import { tw } from "@/utils";

interface JoinNowButtonProps {
  canJoin: boolean;
  videoUrl?: string;
}

export const JoinNowButton = ({ canJoin, videoUrl }: JoinNowButtonProps) => {
  const {
    value: showTooltip,
    setFalse: closeTooltip,
    setTrue: openTooltip,
    setValue: toggleTooltip,
  } = useBoolean(false);

  const TriggerComponent = canJoin ? Button : Popover.Trigger;
  const Component = canJoin ? Link : Button;
  const handleMouseEnter = canJoin ? undefined : openTooltip;
  const handleMouseLeave = canJoin ? undefined : closeTooltip;

  return (
    <Popover.Root open={showTooltip} onOpenChange={toggleTooltip}>
      <TriggerComponent
        asChild
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={tw(
          "grow px-4 lg:max-w-52",
          !canJoin && "cursor-help bg-brown-04 hover:bg-brown-05/70",
        )}
        size="lg"
      >
        <Component to={videoUrl ?? ""} target="_blank" rel="noreferrer">
          <VideoCameraIcon className="size-5" />
          {t`Join now`}
        </Component>
      </TriggerComponent>

      <Popover.Content>
        {t`Available 10 minutes before your session.`}
      </Popover.Content>
    </Popover.Root>
  );
};
