import type { ProviderFolder, UploadedFile } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "coaching-hub";
const API_PATH = "/documents/coaching-hub";

interface UploadedFileWithUrl extends UploadedFile {
  url: string;
}

interface GetCoachingHubDocumentsParams {
  providerFolder: ProviderFolder;
  limit?: number;
}

export const getProviderDocumentsQuery = (
  params: GetCoachingHubDocumentsParams,
) => ({
  queryKey: [DOMAIN, "getProviderDocumentsQuery", params],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<UploadedFile[]>>(
      API_PATH,
      // TODO: BE - This should be called providerFolder instead of coachingType
      // because not all providerFolders are coachingTypes
      { params: { coachingType: params.providerFolder, limit: params.limit } },
    );

    return data.data;
  },
});

interface GetDocumentFromProviderParams {
  providerFolder: ProviderFolder;
  documentId?: string | null;
}

export const getDocumentFromProviderQuery = ({
  providerFolder: coachingType,
  documentId,
}: GetDocumentFromProviderParams) => ({
  enabled: !!documentId,
  queryKey: [DOMAIN, "getDocumentFromProviderQuery", documentId],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<UploadedFileWithUrl>>(
      `/documents/from-provider/${documentId}`,
      { params: { coachingType } },
    );

    return data.data;
  },
});
