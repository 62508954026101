import { useQuery } from "@tanstack/react-query";

import { billingQueriesStore } from "../api";
import { useUserSubscription } from "./useUserSubscription";

export const useMonthlyPeriodPlan = () => {
  const { data: userSubscription } = useUserSubscription();

  return useQuery(
    billingQueriesStore.stripe.planForMonthlyPeriod(
      userSubscription?.currentPeriodPlan?.subscriptionPriceId,
    ),
  );
};
