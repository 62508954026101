import { t } from "ttag";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { Button, icons, IconWrapper } from "@/ui";
import { tw } from "@/utils";

interface OnboardingActionButtonsProps {
  onCancel: () => void;
  onContinue: () => void;

  variant?: Variant;
  continueLabel?: string;
  continueDisabled?: boolean;
  cancelLabel?: string;
}
export const OnboardingActionButtons = ({
  onCancel,
  onContinue,
  variant = VARIANT.PRIMARY,
  continueLabel,
  continueDisabled,
  cancelLabel,
}: OnboardingActionButtonsProps) => (
  <div
    className={tw(
      "flex w-full flex-col-reverse items-center gap-4 sm:flex-row sm:justify-center sm:gap-4",
      variant === VARIANT.PRIMARY && "sm:justify-between",
    )}
  >
    <Button
      onClick={onCancel}
      variant={VARIANT.SECONDARY}
      size="lg"
      className={tw(
        "w-full sm:w-fit",
        variant === VARIANT.SECONDARY &&
          "border-salmon-01 text-salmon-01 hover:border-salmon-03 hover:text-salmon-03",
      )}
    >
      {variant === VARIANT.PRIMARY && (
        <IconWrapper size="sm" className="hidden sm:block">
          <icons.ChevronLeft />
        </IconWrapper>
      )}
      {cancelLabel ?? t`Back`}
    </Button>

    <Button
      disabled={continueDisabled}
      onClick={onContinue}
      size="lg"
      className={tw(
        "w-full sm:w-fit",
        variant === VARIANT.SECONDARY &&
          "bg-salmon-02 text-salmon-10 hover:bg-salmon-03",
      )}
    >
      {continueLabel ?? t`Continue`}
      {variant === VARIANT.PRIMARY && (
        <IconWrapper size="sm" className="hidden sm:block">
          <icons.ChevronRight />
        </IconWrapper>
      )}
    </Button>
  </div>
);
