import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPaymentMethodQuery } from "@/domains/billing/api";
import { useBoolean } from "@/hooks";
import { EditPaymentMethodModal } from "@/modals";
import {
  Button,
  CardPrimitive as Card,
  CardSkeleton,
  History2Icon,
  IconWrapper,
  PenIcon,
  PlusIcon,
} from "@/ui";
import { EmptyPaymentMethod, PaymentMethodCard } from "./components";

export const PaymentMethod = () => {
  const { data: paymentMethod, isLoading } = useQuery(getPaymentMethodQuery());

  const {
    value: showEditPaymentModal,
    setTrue: openEditPaymentModal,
    setFalse: closeEditPaymentModal,
  } = useBoolean(false);

  if (isLoading) {
    return <CardSkeleton className="flex-1" />;
  }

  return (
    <Card.Root className="grid flex-1 grid-cols-[1fr_auto] grid-rows-[auto_1fr] bg-brown-03">
      <Card.Header className="flex-row items-center gap-2">
        <IconWrapper size="sm">
          <History2Icon />
        </IconWrapper>
        <Card.Title>{t`Payment method`}</Card.Title>
      </Card.Header>

      <Button
        size="lg"
        onClick={openEditPaymentModal}
        className="w-fit justify-self-end px-4 xl:px-6"
      >
        <IconWrapper size="sm">
          {paymentMethod ? <PenIcon /> : <PlusIcon />}
        </IconWrapper>
        <span className="hidden sm:block">
          {paymentMethod ? t`Edit payment method` : t`Add payment method`}
        </span>
      </Button>

      <div className="col-span-full flex">
        {paymentMethod ? (
          <PaymentMethodCard paymentMethod={paymentMethod} />
        ) : (
          <EmptyPaymentMethod />
        )}
      </div>

      <EditPaymentMethodModal
        show={showEditPaymentModal}
        onClose={closeEditPaymentModal}
      />
    </Card.Root>
  );
};
