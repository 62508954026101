import { differenceInYears, parse } from "date-fns";

import type { DateOfBirthValues } from "@/shared.types";
import { formatDOB } from "./dates";

export const calculateHeight = (feet: number, inches: number) => {
  return feet * 0.3048 + inches * 0.0254;
};

interface CalculateBMIParams {
  heightFeet?: number;
  heightInches?: number;
  weight?: number;
}

export const calculateBMI = ({
  heightFeet,
  heightInches,
  weight: weightPounds,
}: CalculateBMIParams = {}) => {
  if (!(heightFeet && heightInches && weightPounds)) {
    return 0;
  }

  const heightMeters = calculateHeight(heightFeet, heightInches);
  const weightKg = weightPounds * 0.45359237;
  const bmi = heightMeters && weightKg / Math.pow(heightMeters, 2);

  return isNaN(bmi) ? 0 : parseFloat(bmi.toFixed(1));
};

export const calculateAge = (date: string) => {
  const dob = parse(date, "yyyy-MM-dd", new Date());
  const age = differenceInYears(new Date(), dob);

  return age;
};

export const calculateAgeByDOB = (dob: DateOfBirthValues) => {
  const dobIso = formatDOB(dob);
  const dobDate = parse(dobIso, "yyyy-MM-dd", new Date());
  const age = differenceInYears(new Date(), dobDate);

  return age;
};
