import { DIRECTION, VARIANT } from "@/shared.constants";
import { shapes } from "@/ui";

export const IntakeFormBackground = () => (
  <div className="fixed h-desktop w-desktop bg-nature-08">
    <div className="relative h-full w-full">
      <shapes.CompositeSquareBy3
        direction={DIRECTION.TOP}
        shapeColor={VARIANT.TERTIARY}
        className="absolute left-48 top-3/4 -mt-10 scale-[6.7] opacity-40"
      />
      <shapes.Doughnut
        shapeColor={VARIANT.QUATERNARY}
        className="absolute right-0 top-64 scale-[5.78] opacity-40"
      />
    </div>
  </div>
);
