import { useQuery } from "@tanstack/react-query";

import { getFormQuery } from "@/api";

export const useFieldsQuery = (params: Parameters<typeof getFormQuery>) => {
  return useQuery({
    ...getFormQuery(...params),
    select: (data) => data.fields,
    staleTime: Infinity,
  });
};
