import { ScreenLayout } from "@/layouts";
import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import {
  DocumentCenterDoctorNotes,
  DocumentCenterHeader,
  DocumentCenterLabs,
  DocumentCenterMedicalRecords,
  DocumentCenterPrescriptions,
  FridaysResources,
} from "./components";

export const DocumentCenterOverview = () => {
  const portalUser = usePortalUserStore((state) => state.portalUser);
  const isActiveUser = Boolean(
    portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );

  return (
    <ScreenLayout className="pb-0 md:pb-0">
      <DocumentCenterHeader />

      {isActiveUser && <FridaysResources />}

      <section className="grid shrink-0 gap-4 pb-6 md:pb-12 lg:grid-cols-2">
        <DocumentCenterLabs />

        <DocumentCenterPrescriptions />

        <DocumentCenterDoctorNotes />

        <DocumentCenterMedicalRecords />
      </section>
    </ScreenLayout>
  );
};
