import { useWindowSize } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { EXTERNAL_LINK, ROUTES } from "@/router";
import { DropdownMenu, Label, LinkIcon, QuestionCircleIcon } from "@/ui";

export const NeedHelpMenu = ({
  onCloseSidebar,
}: {
  onCloseSidebar?: () => void;
}) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = Boolean(width && width < 530);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger className="flex items-center gap-1 py-2.5 underline-offset-2 hover:underline focus:underline data-[state=open]:underline">
        <QuestionCircleIcon className="size-4" />
        {t`Need help?`}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align={isMobile ? "start" : "end"}
        side={isMobile ? "top" : "right"}
        sideOffset={isMobile ? 0 : 12}
        alignOffset={isMobile ? -12 : 0}
      >
        <DropdownMenu.Item
          asChild
          className="flex items-center justify-between gap-4"
        >
          <a href={EXTERNAL_LINK.FAQ} rel="noreferrer noopener" target="_blank">
            {t`Check our FAQs`}.
            <LinkIcon className="size-4 text-nature-08" />
          </a>
        </DropdownMenu.Item>

        <DropdownMenu.Separator />

        <DropdownMenu.Item
          onClick={() => {
            navigate(ROUTES.MESSAGES);
            onCloseSidebar?.();
          }}
          className="flex items-center justify-between gap-4"
        >
          {t`Send us a message`}

          <Label
            size="xs"
            className="text-nature-08"
          >{t`Go to Messages`}</Label>
        </DropdownMenu.Item>

        <DropdownMenu.Separator />

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between gap-7 p-2 text-sm">
            <p>
              {t`English `}
              <span className="text-brown-08">{t`(7 days 7am-7pm CST)`}</span>
            </p>
            <a href="tel:+14842995495">(484) 299 5495</a>
          </div>
          <div className="flex items-center justify-between gap-7 p-2 text-sm text-brown-10">
            <p>
              {t`Español `}
              <span className="text-brown-08">{t`(M-F 8am-2pm EST)`}</span>
            </p>
            <a href="tel:+14847159081">(484) 715 9081</a>
          </div>
        </div>

        <DropdownMenu.Arrow className="h-3 w-6 -translate-y-0.5 fill-brown-01" />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
