import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  HealthieFormSection,
  RefillRequirementsAlert,
  SymptomsForm,
} from "@/domains/consultations/components";
import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import { HEALTHIE_FORM_TYPE } from "@/shared.constants";
import { FollowUpFormHeader, FollowUpInjectionsForm } from "./sections";
import type { FollowUpFormStep } from "./utils";
import { FORM_UP_FORM_STEPS, getFollowUpFormHeaderDescription } from "./utils";

export const ConsultationFollowUpForm = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState<FollowUpFormStep>(
    FORM_UP_FORM_STEPS.SYMPTOMS_FORM,
  );

  return (
    <ScreenLayout variant="secondary" className="gap-0">
      <FollowUpFormHeader
        description={getFollowUpFormHeaderDescription(step)}
      />

      {step === FORM_UP_FORM_STEPS.SYMPTOMS_FORM && (
        <SymptomsForm
          onSubmit={() => setStep(FORM_UP_FORM_STEPS.INJECTIONS_FORM)}
        />
      )}

      {step === FORM_UP_FORM_STEPS.INJECTIONS_FORM && (
        <FollowUpInjectionsForm
          onCancel={() => setStep(FORM_UP_FORM_STEPS.SYMPTOMS_FORM)}
          onSubmit={() => setStep(FORM_UP_FORM_STEPS.HEALTHIE_FORM)}
        />
      )}

      {step === FORM_UP_FORM_STEPS.HEALTHIE_FORM && (
        <div className="flex max-w-2xl flex-col gap-6">
          <RefillRequirementsAlert />

          <HealthieFormSection
            formType={HEALTHIE_FORM_TYPE.FOLLOW_UP_VIDEO_CONSULTATION}
            onCancel={() => navigate(ROUTES.CONSULTATIONS.PURPOSE)}
            onSubmit={() => navigate(ROUTES.CONSULTATIONS.SCHEDULE)}
          />
        </div>
      )}
    </ScreenLayout>
  );
};
