import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { t } from "ttag";

import { getDocumentFromProviderQuery, getDocumentQuery } from "@/api";
import { HEALTHIE_FILE_STALE_TIME, IMAGE_FILE_TYPES } from "@/shared.constants";
import type { Folder, ModalProps } from "@/shared.types";
import { Dialog, Skeleton } from "@/ui";
import { isProviderFile } from "@/utils";
import { ErrorState, LoadingState } from "./components";

export const PreviewDocument = ({ show, onClose }: ModalProps) => {
  const { documentId, folder } = useParams<{
    documentId: string;
    folder: Folder;
  }>();

  const { data, isLoading, isRefetching, refetch, isError } = useQuery({
    ...(folder && isProviderFile(folder)
      ? getDocumentFromProviderQuery({ documentId, providerFolder: folder })
      : getDocumentQuery(documentId)),
    staleTime: HEALTHIE_FILE_STALE_TIME,
    refetchOnWindowFocus: false,
  });

  const documentType =
    data?.url && new URL(data?.url).searchParams.get("response-content-type");

  const isImage = Boolean(
    documentType &&
      (IMAGE_FILE_TYPES.PNG === documentType ||
        IMAGE_FILE_TYPES.JPEG === documentType),
  );

  const renderDocument = () => {
    return (
      data &&
      (isImage ? (
        <div className="flex h-0 w-full grow items-center justify-center rounded-lg bg-salmon-03">
          <img
            src={data.url}
            alt={data.name}
            className="h-full max-h-full w-full max-w-full object-contain"
          />
        </div>
      ) : (
        <div className="grow rounded-lg bg-salmon-03">
          {documentType && (
            <iframe
              title="test"
              src={data.url}
              itemType={documentType}
              className="size-full"
            />
          )}
        </div>
      ))
    );
  };

  if (!documentId || !folder) {
    return null;
  }

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content>
        <div className="flex h-full flex-col gap-4">
          <VisuallyHidden asChild>
            <Dialog.Description>
              {t`This modal provides a preview of the selected document, allowing you to view its content without downloading`}
            </Dialog.Description>
          </VisuallyHidden>

          {isLoading && (
            <Skeleton className="h-10 w-56 self-center py-1 md:self-start" />
          )}

          {isError && <ErrorState folder={folder} onTryAgain={refetch} />}

          {data ? (
            <Dialog.Title className="shrink-0 px-3 md:px-0">
              {data.name}
            </Dialog.Title>
          ) : (
            <VisuallyHidden asChild>
              <Dialog.Title>{t`Preview Document`}</Dialog.Title>
            </VisuallyHidden>
          )}

          {(isRefetching || isLoading) && <LoadingState />}

          {data && !isRefetching && (
            <div className="flex grow flex-col gap-2">
              {renderDocument()}
              <p className="w-fit text-sm text-salmon-10 md:mt-0 md:self-end md:text-right md:text-base">
                {t`Can't view the file correctly? Open it `}
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={data.url}
                  onClick={async (e) => {
                    e.preventDefault();
                    const updatedUrl = await refetch();
                    if (updatedUrl.data?.url) {
                      window.location.replace(updatedUrl.data.url);
                    }
                  }}
                  className="text-nature-07 underline underline-offset-2"
                >
                  {t`here`}.
                </a>
              </p>
            </div>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
