export const DISCOUNT_TYPE = {
  PERCENTAGE: "percentage",
  FIXED_AMOUNT: "fixed_amount",
} as const;

export const DISCOUNT_DURATION = {
  ONCE: "once",
  REPEATING: "repeating",
  FOREVER: "forever",
} as const;

export const DURATION_IN_MONTHS = {
  MONTHLY: 1,
  QUARTERLY: 3,
} as const;

export const PLAN_DURATION = {
  MONTHLY: {
    TEXT_VALUE: "monthly",
    VALUE: DURATION_IN_MONTHS.MONTHLY,
  },
  QUARTERLY: {
    TEXT_VALUE: "quarterly",
    VALUE: DURATION_IN_MONTHS.QUARTERLY,
  },
} as const;
