import { t } from "ttag";

import type { ObjectValue } from "@/shared.types";

export const FORM_UP_FORM_STEPS = {
  SYMPTOMS_FORM: "symptoms",
  INJECTIONS_FORM: "injections",
  HEALTHIE_FORM: "healthie",
} as const;

export type FollowUpFormStep = ObjectValue<typeof FORM_UP_FORM_STEPS>;

export const getFollowUpFormHeaderDescription = (step: FollowUpFormStep) =>
  ({
    [FORM_UP_FORM_STEPS.SYMPTOMS_FORM]: t`These questions are required before further medication can be prescribed. Keep in mind that if you select any of the symptoms listed on this screen, you won't be able to continue with the Fridays program.`,
    [FORM_UP_FORM_STEPS.INJECTIONS_FORM]: t`This question is required before further medication can be prescribed.`,
    [FORM_UP_FORM_STEPS.HEALTHIE_FORM]: t`Please take a moment to provide your responses to these follow-up questions. Your input is essential in guiding us to offer optimal care tailored to your progress.`,
  })[step];
