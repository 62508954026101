import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { DocumentMedicineIcon, DocumentsIcon, Header } from "@/ui";

export const DoctorNotesHeader = () => {
  const navigate = useNavigate();
  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: DocumentsIcon,
              label: t`Document center`,
            },
            {
              href: ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES,
              icon: DocumentMedicineIcon,
              label: t`Doctor's notes`,
            },
          ]}
        />

        <div className="flex items-center gap-2">
          <Header.GoBackButton
            onClick={() => navigate(ROUTES.DOCUMENT_CENTER.OVERVIEW)}
            className="md:hidden"
          />

          <Header.Title>{t`Doctor's Notes`}</Header.Title>
        </div>

        <Header.Description>{t`Your doctor's notes and recommendations`}</Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
