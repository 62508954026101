import { t } from "ttag";

import type { Patient } from "@/shared.types";
import { Home2Icon, LetterIcon, SmartphoneIcon } from "@/ui";
import { ProfileCard } from "../components";

interface ContactProps {
  patient: Patient;
}
export const Contact = ({ patient }: ContactProps) => (
  <ProfileCard.Root>
    <ProfileCard.Header>
      <ProfileCard.Title>{t`Contact`}</ProfileCard.Title>
    </ProfileCard.Header>

    <div className="grid gap-4 lg:grid-cols-2">
      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <LetterIcon className="size-4" />
          {t`Email address`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>{patient.email}</ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <SmartphoneIcon className="size-4" />
          {t`Phone number`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>{patient.phoneNumber}</ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <Home2Icon className="size-4" />
          {t`House and street #`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>
          {patient.location?.line1 ?? "-"}
        </ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <Home2Icon className="size-4" />
          {t`Apartment number`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>
          {patient.location?.line2 ?? "-"}
        </ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <Home2Icon className="size-4" />
          {t`Zip code`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>
          {patient.location?.zip ?? "-"}
        </ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <Home2Icon className="size-4" />
          {t`City`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>
          {patient.location?.city ?? "-"}
        </ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <Home2Icon className="size-4" />
          {t`State`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>
          {patient.location?.state ?? "-"}
        </ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item>
        <ProfileCard.ItemLabel>
          <Home2Icon className="size-4" />
          {t`Country`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>
          {patient.location?.country ?? "-"}
        </ProfileCard.ItemValue>
      </ProfileCard.Item>
    </div>
  </ProfileCard.Root>
);
