import type { ComponentPropsWithoutRef } from "react";
import { motion } from "framer-motion";

export const FadeOut = (props: ComponentPropsWithoutRef<typeof motion.div>) => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x: "-10%" }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      {...props}
    />
  );
};
