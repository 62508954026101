import type { ServiceResponse } from "@/api";
import { publicAPI } from "@/api";
import type { EmbeddablesUser } from "../models";

export const getUserValidation = async (userCode: string) => {
  const { data } = await publicAPI.get<ServiceResponse<EmbeddablesUser>>(
    `/embeddables-user/${userCode}`,
  );

  return data.data;
};
