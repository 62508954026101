import type { ComponentPropsWithoutRef, ReactNode } from "react";

import { tw } from "@/utils";

interface CardImageItemProps
  extends Omit<ComponentPropsWithoutRef<"img">, "alt"> {
  alt: string;
  emptyMessage: ReactNode;
}
export const CardImageItem = ({
  src,
  alt,
  emptyMessage,
  ...rest
}: CardImageItemProps) => (
  <div
    className={tw(
      "flex h-42 w-full items-center justify-center overflow-hidden rounded-2xl border border-salmon-07 lg:h-72",
      !src && "border-dashed",
    )}
  >
    {src ? (
      <img
        src={src}
        alt={alt}
        className="max-h-full w-full max-w-full object-contain"
        {...rest}
      />
    ) : (
      emptyMessage
    )}
  </div>
);
