import {
  CheckCircleIcon,
  CloseCircleIcon,
  InfoCircleIcon,
  WarningCircleIcon,
} from "@/ui/icons";

const toastIcons = {
  info: <InfoCircleIcon className="size-6 text-blue-400" />,
  success: <CheckCircleIcon className="size-6 text-green-400" />,
  warning: <WarningCircleIcon className="size-6 text-yellow-400" />,
  error: <CloseCircleIcon className="size-6 text-red-400" />,
};

export default toastIcons;
