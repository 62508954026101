import type { SVGProps } from "@/shared.types";

export const TestTubeIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M9.29454 1.35437C8.93797 1.14358 8.47804 1.26176 8.26725 1.61833C8.05646 1.9749 8.17464 2.43483 8.5312 2.64562L8.72713 2.76144L2.00202 14.3695C0.453324 17.0427 1.37344 20.4596 4.0531 22.0014C6.73126 23.5423 10.157 22.6289 11.7048 19.9573L18.4335 8.343L18.6259 8.45374C18.9849 8.66039 19.4434 8.53689 19.65 8.17791C19.8567 7.81892 19.7332 7.36039 19.3742 7.15374L18.5336 6.66987L10.1262 1.84604L9.29454 1.35437ZM3.29993 15.1214L10.0233 3.51636L17.133 7.59558L15.628 10.1933L13.7181 9.09443C13.3591 8.88786 12.9005 9.01145 12.694 9.37048C12.4874 9.72951 12.611 10.188 12.97 10.3946L14.876 11.4912L13.9146 13.1508L10.3598 11.1055C10.0007 10.8989 9.54222 11.0225 9.33565 11.3815C9.12908 11.7406 9.25267 12.1991 9.6117 12.4056L13.1626 14.4487L12.277 15.9773L10.3125 14.847C9.95343 14.6404 9.49492 14.764 9.28835 15.1231C9.08178 15.4821 9.20537 15.9406 9.5644 16.1472L11.5251 17.2752L10.4069 19.2053C9.2747 21.1595 6.76488 21.8311 4.80116 20.7012C2.83894 19.5723 2.16863 17.0741 3.29993 15.1214Z"
    />
    <path
      fillRule="evenodd"
      d="M21.1753 12.1014C20.5325 11.4232 19.4676 11.4232 18.8248 12.1014C18.5172 12.426 18.142 12.8566 17.8389 13.3157C17.5544 13.7466 17.25 14.3242 17.25 14.9166C17.25 16.4522 18.4527 17.7499 20 17.7499C21.5474 17.7499 22.75 16.4522 22.75 14.9166C22.75 14.3242 22.4457 13.7466 22.1612 13.3157C21.8581 12.8566 21.4829 12.426 21.1753 12.1014ZM19.9135 13.1333C19.9428 13.1024 19.9735 13.0928 20 13.0928C20.0266 13.0928 20.0573 13.1024 20.0866 13.1333C20.3652 13.4272 20.6744 13.7862 20.9094 14.1421C21.163 14.5263 21.25 14.7891 21.25 14.9166C21.25 15.6821 20.6618 16.2499 20 16.2499C19.3383 16.2499 18.75 15.6821 18.75 14.9166C18.75 14.7891 18.8371 14.5263 19.0907 14.1421C19.3257 13.7862 19.6349 13.4272 19.9135 13.1333Z"
    />
  </svg>
);
