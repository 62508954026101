import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import {
  useChangeSubscriptionPlan,
  useCoachingPlans,
} from "@/domains/billing/hooks";
import { ErrorModal } from "@/modals";
import { ROUTES } from "@/router";
import { PORTAL_USER_TYPE } from "@/shared.constants";
import type { ModalProps } from "@/shared.types";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  Form,
  LightbulbFixedIconMono,
  RadioGroupCards,
} from "@/ui";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "../../router";
import { CoachingSubscriptionOptions } from "./components";
import { useChangeSubscriptionForm } from "./hooks";

export const ChangeToCoachingModal = ({ show, onClose }: ModalProps) => {
  const { data, isSuccess, isLoading, isError } = useCoachingPlans();

  const form = useChangeSubscriptionForm();

  const navigate = useNavigate();

  const changeSubscriptionPlanMutation = useChangeSubscriptionPlan();

  const onSubmit = form.handleSubmit((values) => {
    const { subscriptionPriceId, hasConsent } = values;

    if (hasConsent) {
      changeSubscriptionPlanMutation.mutate(subscriptionPriceId, {
        onSuccess: () =>
          navigate(EMBEDDABLES_ONBOARDING_ROUTE.SUCCESS, {
            state: PORTAL_USER_TYPE.COACHING,
          }),
      });
    }
  });

  if (isError) {
    return <ErrorModal show={show} onClose={onClose} />;
  }

  return (
    <Form.Provider {...form}>
      <Dialog.Root open={show} onOpenChange={onClose}>
        <Dialog.Content size="modal">
          <form onSubmit={onSubmit} className="flex h-full flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <LightbulbFixedIconMono />
              </Dialog.Icon>

              <Dialog.Title>{t`Change to Fridays Coaching`}</Dialog.Title>

              <Dialog.Description>
                {t`Work with expert coaches to develop healthy habits and build a sustainable foundation for long-term success.`}
              </Dialog.Description>
            </Dialog.Header>

            <Alert>
              {t`You'll receive a partial refund to the card on file to cover the difference in price with your subscription.`}
            </Alert>

            {isLoading && <RadioGroupCards.Skeleton />}

            {isSuccess && (
              <Form.Field
                control={form.control}
                defaultValue=""
                name="subscriptionPriceId"
                render={({ field: { onChange, ...field } }) => (
                  <Form.Item>
                    <Form.Control>
                      <CoachingSubscriptionOptions
                        onValueChange={onChange}
                        subscriptionPlans={data}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />
            )}

            <Alert type="warning" className="-mt-2">
              {t`Any changes to your subscription plan will take effect starting from your next billing period. This means that your current plan will remain active until the end of the current billing cycle. No commitment - cancel anytime!`}
            </Alert>

            {isSuccess && (
              <Form.Field
                control={form.control}
                name="hasConsent"
                defaultValue={false}
                disabled={isLoading}
                render={({ field: { value, onChange, ...rest } }) => (
                  <Form.Item>
                    <div className="flex gap-2.5">
                      <Form.Control>
                        <Checkbox
                          checked={value}
                          onCheckedChange={onChange}
                          containerClassName="grow-0"
                          className="mt-0.5"
                          {...rest}
                        />
                      </Form.Control>
                      <Form.Label>
                        {t`I agree to the new charge and understand the changes`}
                      </Form.Label>
                    </div>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />
            )}

            <Dialog.Footer variant="sticky">
              {isError ? (
                <Button
                  onClick={() => navigate(ROUTES.MESSAGES)}
                  size="lg"
                  className="justify-self-end"
                >
                  {t`Contact support`}
                </Button>
              ) : (
                <Button type="submit" size="lg" className="w-full md:max-w-56">
                  {t`Continue`}
                </Button>
              )}
            </Dialog.Footer>
          </form>

          {changeSubscriptionPlanMutation.isPending && <FullScreenLoader />}
        </Dialog.Content>
      </Dialog.Root>
    </Form.Provider>
  );
};
