import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import {
  IMAGE_FILE_TYPES,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
} from "@/shared.constants";
import type { OnboardingImageUploadsValues } from "@/shared.types";
import { CardPrimitive as Card, Dropzone, Form } from "@/ui";
import { SectionHeader } from "../components";

export const ACCEPT_IMAGE_FILE = {
  [IMAGE_FILE_TYPES.PNG]: [],
  [IMAGE_FILE_TYPES.JPEG]: [],
};

export const InsuranceCardUpload = () => {
  const { control } = useFormContext<OnboardingImageUploadsValues>();

  return (
    <section className="flex flex-col gap-6">
      <SectionHeader
        title={t`Would you like to upload your insurance information?`}
        description={t`At your initial consultation, your provider may need details about your insurance coverage. Providing this information can help our team process your prior authorization request efficiently.`}
      />

      <Card.Root className="bg-brown-03">
        <Card.Title>{t`Insurance card`}</Card.Title>
        <Card.Content className="grid gap-x-4 gap-y-2 md:grid-cols-2">
          <Form.Field
            control={control}
            name="insuranceCardFront"
            render={({ field: { onChange, ...rest } }) => (
              <>
                <Form.Item>
                  <Form.Control>
                    <Dropzone
                      {...rest}
                      accept={ACCEPT_IMAGE_FILE}
                      onDrop={(acceptedFiles) => onChange(acceptedFiles[0])}
                      label={t`Drop your front card photo here`}
                      placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
                    />
                  </Form.Control>
                </Form.Item>
                <Form.Message className="row-start-2 -mt-1" />
              </>
            )}
          />
          <Form.Field
            control={control}
            name="insuranceCardBack"
            render={({ field: { onChange, ...rest } }) => (
              <>
                <Form.Item>
                  <Form.Control>
                    <Dropzone
                      {...rest}
                      accept={ACCEPT_IMAGE_FILE}
                      onDrop={(acceptedFiles) => onChange(acceptedFiles[0])}
                      label={t`Drop your back card photo here`}
                      placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
                    />
                  </Form.Control>
                </Form.Item>
                <Form.Message className="row-start-4 -mt-1 md:col-start-2 md:row-start-2" />
              </>
            )}
          />
        </Card.Content>
      </Card.Root>
    </section>
  );
};
