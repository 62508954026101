import { t } from "ttag";

import type { Patient } from "@/shared.types";
import { Avatar, CalendarMinimalisticIcon, GenderIcon, UserIcon } from "@/ui";
import { formatBackendDate } from "@/utils";
import { ProfileCard } from "../components";

interface GeneralInformationProps {
  patient: Patient;
}

export const GeneralInformation = ({ patient }: GeneralInformationProps) => (
  <ProfileCard.Root className="bg-brown-04">
    <ProfileCard.Header>
      <ProfileCard.Title>{t`General information`}</ProfileCard.Title>
      <Avatar imgURL={patient.picture} name={patient.firstName} />
    </ProfileCard.Header>

    <div className="flex flex-col gap-4">
      <ProfileCard.Item className="bg-brown-03">
        <ProfileCard.ItemLabel>
          <UserIcon className="size-4" />
          {t`First name`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>{patient.firstName}</ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item className="bg-brown-03">
        <ProfileCard.ItemLabel>
          <UserIcon className="size-4" />
          {t`Last name`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>{patient.lastName}</ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item className="bg-brown-03">
        <ProfileCard.ItemLabel>
          <GenderIcon className="size-4" />
          {t`Sex assigned at birth`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>{patient.gender}</ProfileCard.ItemValue>
      </ProfileCard.Item>

      <ProfileCard.Item className="bg-brown-03">
        <ProfileCard.ItemLabel>
          <CalendarMinimalisticIcon className="size-4" />
          {t`Date of birth`}
        </ProfileCard.ItemLabel>
        <ProfileCard.ItemValue>
          {formatBackendDate(patient.dateOfBirth)}
        </ProfileCard.ItemValue>
      </ProfileCard.Item>
    </div>
  </ProfileCard.Root>
);
