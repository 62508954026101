import { CardPrimitive as Card, Skeleton } from "@/ui";
import { SessionListSlot } from "./SessionListSlot";

export const SessionListSkeleton = () => {
  return (
    <SessionListSlot>
      {Array.from({ length: 3 }, (_, index) => `${index}`).map((i) => (
        <Card.Root
          key={i}
          className="flex-row items-center rounded-none border-b border-brown-04 bg-brown-01 last:border-b-0 lg:rounded-2xl lg:border-b-0"
        >
          <Skeleton className="size-16 rounded-md" />

          <Card.Content className="flex flex-col gap-4">
            <Skeleton className="h-6 w-44 py-1" />
            <Skeleton className="h-6 w-20 py-1" />
          </Card.Content>
        </Card.Root>
      ))}
    </SessionListSlot>
  );
};
