import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";
import { t } from "ttag";

import { MODAL_ROUTES, useNavigateModal } from "@/router";
import { usePortalUserStore } from "@/stores";
import { Button, LightbulbMinimalisticIcon } from "@/ui";
import { isInactiveSubscription } from "@/utils";

const buttonVariance = tv({
  slots: {
    container: "",
    button: "",
  },
  variants: {
    position: {
      header: {
        container: "hidden md:flex",
      },
      body: {
        container: "sticky bottom-0 -m-6 mt-0 bg-salmon-01 p-6 py-5 md:hidden",
        button: "w-full",
      },
    },
  },
});
type ButtonVariance = VariantProps<typeof buttonVariance>;

export const NextStepsButton = ({ position = "body" }: ButtonVariance) => {
  const { container, button } = buttonVariance({ position });
  const navigateModal = useNavigateModal();
  const portalUser = usePortalUserStore((state) => state.portalUser);

  if (
    !portalUser ||
    isInactiveSubscription(portalUser.subscriptionStatus) ||
    !portalUser.intakeFormCompleted
  ) {
    return null;
  }

  return (
    <div className={container()}>
      <Button
        onClick={() => navigateModal(MODAL_ROUTES.NEXT_STEPS)}
        size="lg"
        className={button()}
      >
        <LightbulbMinimalisticIcon className="size-4" />
        {t`Next steps`}
      </Button>
    </div>
  );
};
