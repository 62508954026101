import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getDocumentsQuery, getLabRequisitionFormsQuery } from "@/api";
import { UploadedDocument } from "@/components";
import { ScreenLayout } from "@/layouts";
import { MODAL_ROUTES, useNavigateModal } from "@/router";
import { DOCUMENT_FOLDER } from "@/shared.constants";
import {
  Button,
  EmptyState,
  IconWrapper,
  TestTubeIcon,
  UploadedFilesSkeleton,
} from "@/ui";
import { LabsDetailsHeader } from "./components";

const FOLDER = DOCUMENT_FOLDER.LABS;

export const LabsDetails = () => {
  const { data: labResults, isLoading: isLoadingLabResults } = useQuery(
    getDocumentsQuery(FOLDER),
  );
  const { data: labOrders, isLoading: isLoadingLabOrders } = useQuery(
    getLabRequisitionFormsQuery(),
  );
  const navigateModal = useNavigateModal();

  return (
    <ScreenLayout>
      <LabsDetailsHeader />

      <div className="flex grow flex-col gap-7">
        <div className="flex flex-col gap-2.5">
          <label
            htmlFor="lab-orders"
            className="text-lg font-bold text-salmon-09"
          >
            {t`Your lab orders`}
          </label>

          {isLoadingLabOrders && <UploadedFilesSkeleton filesCount={2} />}

          {!!labOrders?.length && (
            <div id="lab-orders" className="flex flex-col gap-4">
              {labOrders.map((file) => (
                <UploadedDocument
                  key={file.id}
                  folder={FOLDER}
                  file={file}
                  readOnly
                />
              ))}
            </div>
          )}

          {!labOrders?.length && !isLoadingLabOrders && (
            <EmptyState.Root>
              <EmptyState.Description>
                {t`No documents found in this folder.`}
              </EmptyState.Description>
            </EmptyState.Root>
          )}
        </div>

        <div className="flex flex-col gap-2.5">
          <label
            htmlFor="lab-results"
            className="text-lg font-bold text-salmon-09"
          >
            {t`Lab results`}
          </label>

          {isLoadingLabResults && <UploadedFilesSkeleton filesCount={3} />}

          {!!labResults?.length && (
            <div id="lab-results" className="flex flex-col gap-4">
              {labResults.map((file) => (
                <UploadedDocument key={file.id} folder={FOLDER} file={file} />
              ))}
            </div>
          )}

          {!labResults?.length && !isLoadingLabResults && (
            <EmptyState.Root>
              <EmptyState.Description>
                {t`No documents found in this folder.`}
                <br />
                {t`You can upload new documents using the button above.`}
              </EmptyState.Description>
            </EmptyState.Root>
          )}
        </div>
      </div>

      <div className="sticky bottom-0 -m-6 bg-salmon-01 p-6 py-5 md:hidden">
        <Button
          size="lg"
          onClick={() => navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.LABS)}
          className="w-full"
        >
          <IconWrapper size="sm">
            <TestTubeIcon />
          </IconWrapper>
          {t`Add lab result`}
        </Button>
      </div>
    </ScreenLayout>
  );
};
