import { useQuery } from "@tanstack/react-query";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";
import { t } from "ttag";

import { getProviderDocumentsQuery } from "@/api";
import { COACHING_SESSION_ICON } from "@/components";
import { MODAL_ROUTES, useNavigateModal } from "@/router";
import { COACHING_SESSION_TYPE } from "@/shared.constants";
import type { CoachingSessionType } from "@/shared.types";
import { Badge, CardPrimitive as Card, EmptyState, IconWrapper } from "@/ui";
import { formatBackendDate } from "@/utils";
import { SessionListSkeleton } from "./SessionListSkeleton";
import { SessionListSlot } from "./SessionListSlot";

const sessionCardVariance = tv({
  base: "flex size-16 shrink-0 items-center justify-center rounded-md",
  variants: {
    variant: {
      [COACHING_SESSION_TYPE.NUTRITIONAL]: "bg-nature-03 text-nature-09",
      [COACHING_SESSION_TYPE.MINDSET]: "bg-brown-04 text-brown-09",
      [COACHING_SESSION_TYPE.SPANISH]: "bg-salmon-04 text-salmon-10",
      [COACHING_SESSION_TYPE.MOVEMENT]: "bg-nature-06 text-nature-01",
    },
  },
  defaultVariants: {
    variant: COACHING_SESSION_TYPE.NUTRITIONAL,
  },
});

type SessionCardVariant = VariantProps<typeof sessionCardVariance>;

interface SessionCardProps extends SessionCardVariant {
  sessionType: CoachingSessionType;
}

export const SessionList = ({
  sessionType,
  variant = COACHING_SESSION_TYPE.NUTRITIONAL,
}: SessionCardProps) => {
  const navigateModal = useNavigateModal();
  const {
    data: sessions,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    getProviderDocumentsQuery({
      providerFolder: sessionType,
    }),
  );

  if (isLoading) {
    return <SessionListSkeleton />;
  }

  if (isError || (isSuccess && sessions.length === 0)) {
    return (
      <EmptyState.Root>
        <EmptyState.Icon />
        <EmptyState.Description>
          {t`There are no past sessions yet`}
        </EmptyState.Description>
      </EmptyState.Root>
    );
  }

  return (
    <SessionListSlot>
      {sessions?.map((session) => (
        <Card.Root
          key={session.id}
          onClick={() =>
            navigateModal(
              `${MODAL_ROUTES.DOCUMENT_CENTER.PREVIEW_DOCUMENT}/${sessionType}/${session.id}`,
            )
          }
          className="cursor-pointer flex-row items-center rounded-none border-b border-brown-04 bg-brown-01 last:border-b-0 lg:rounded-2xl lg:border-b-0"
        >
          <div className={sessionCardVariance({ variant })}>
            <IconWrapper size="xl">
              {COACHING_SESSION_ICON[variant]}
            </IconWrapper>
          </div>

          <Card.Content className="flex h-full flex-col justify-between">
            <Card.Title className="line-clamp-1 text-base">
              {session.name}
            </Card.Title>
            <Badge variant="outline" size="md">
              {formatBackendDate(session.createdAt)}
            </Badge>
          </Card.Content>
        </Card.Root>
      ))}
    </SessionListSlot>
  );
};
