import type { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { EmbeddablesFormContextProvider } from "@/domains/embeddables-onboarding/context";
import { ROUTES } from "@/router";
import { Loading } from "@/ui";
import { usePortalUserQuery } from "./hooks";

export const RequireEmbeddablesHealthieAccount: FC<PropsWithChildren> = () => {
  const { data: portalUser, isLoading, isError } = usePortalUserQuery();

  if (isLoading) {
    return <Loading />;
  }

  const isEmbeddablesHealthieAccount =
    portalUser?.isFromEmbeddables && portalUser.patientId;

  if (!isEmbeddablesHealthieAccount || isError) {
    return <Navigate replace to={ROUTES.BASE} />;
  }

  return <EmbeddablesFormContextProvider />;
};
