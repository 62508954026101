import { useMutation, useQueryClient } from "@tanstack/react-query";

import { scheduleAppointment } from "@/api";

export const useScheduleAppointmentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: scheduleAppointment.mutation,
    onSuccess: () => {
      scheduleAppointment.invalidates(queryClient);
    },
  });
};
