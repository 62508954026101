import { useQuery } from "@tanstack/react-query";

import { getAvailableDaysQuery } from "@/api";
import { useScheduleConsultationContext } from "@/contexts";
import { PROVIDER_PREFERENCE } from "@/shared.constants";
import type { OnboardingUser } from "@/shared.types";

export const useAvailableDaysQuery = (userId?: OnboardingUser["id"]) => {
  const {
    language,
    scheduleDate: { dateFromMonth },
    provider,
    providerPreference,
  } = useScheduleConsultationContext();

  const enabled = Boolean(
    provider ?? providerPreference === PROVIDER_PREFERENCE.EARLIEST,
  );

  return useQuery({
    ...getAvailableDaysQuery({
      dateFromMonth,
      providerId: provider?.id,
      userId,
      language,
    }),
    enabled,
  });
};
