import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { AVAILABLE_LANGUAGE } from "@/shared.constants";
import { useAppSettingsStore } from "@/stores";

export const useSetDefaultLanguage = () => {
  const [searchParams] = useSearchParams();
  const setLanguage = useAppSettingsStore((state) => state.setLanguage);

  const language = searchParams.get("lang");

  useEffect(() => {
    if (language === AVAILABLE_LANGUAGE.ES) {
      setLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
