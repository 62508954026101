import { t } from "ttag";

import { FullScreenAlert } from "@/components";

export const ErrorBoundaryFallback = () => {
  return (
    <FullScreenAlert
      title={t`Oops! Looks like something unexpected happened`}
      description={t`Our team has been notified and is working hard to fix it. Please try again later, or contact support if the issue persists`}
    />
  );
};
