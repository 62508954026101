import { Navigate, Route, Routes } from "react-router-dom";

import { BillingRouter } from "@/domains";
import { CoachingHubOverview, Messages, PastSessions } from "@/screens";
import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import { BASE_ROUTE, ROUTES } from "../constants";
import { PortalRoutesWrapper } from "./PortalRoutesWrapper";

export const CoachingUserRouter = () => {
  const activeSubscription = usePortalUserStore(
    ({ portalUser }) =>
      portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );

  return (
    <Routes>
      <Route element={<PortalRoutesWrapper />}>
        {activeSubscription && (
          <>
            <Route
              path={ROUTES.COACHING_HUB.OVERVIEW}
              element={<CoachingHubOverview />}
            />
            <Route
              path={`${ROUTES.COACHING_HUB.PAST_SESSIONS}/:sessionType`}
              element={<PastSessions />}
            />
          </>
        )}

        <Route path={ROUTES.MESSAGES} element={<Messages />} />
      </Route>

      <Route path={`${BASE_ROUTE.BILLING}/*`} element={<BillingRouter />} />

      <Route path="*" element={<Navigate replace to={ROUTES.BASE} />} />
    </Routes>
  );
};
