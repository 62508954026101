import type { SVGProps } from "@/shared.types";

export const GraphIconMono = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <rect
      x="14.1189"
      y="1.48633"
      width="60"
      height="60"
      rx="30"
      transform="rotate(9.40703 14.1189 1.48633)"
      fill="#FFDFCD"
    />
    <rect
      x="5.63135"
      y="21.9722"
      width="46.974"
      height="46.974"
      rx="13.4211"
      transform="rotate(-8.99069 5.63135 21.9722)"
      fill="#E3B79E"
    />

    <path
      d="M44.9202 35.9088C45.4202 35.0705 45.146 33.9855 44.3076 33.4854C43.4692 32.9853 42.3842 33.2596 41.8842 34.098L38.4956 39.779C37.7964 40.9512 37.366 41.6646 36.9917 42.128C36.8181 42.3428 36.7095 42.4347 36.6564 42.4717C36.6456 42.4792 36.6378 42.4839 36.6328 42.4867C36.6272 42.4856 36.6184 42.4835 36.6058 42.4797C36.5438 42.461 36.4121 42.4071 36.1807 42.2563C35.6817 41.9311 35.0521 41.3854 34.0251 40.4863L33.2152 39.7772C32.3055 38.9805 31.4853 38.2622 30.7495 37.7827C29.9489 37.261 28.9774 36.8372 27.8147 37.0212C26.652 37.2051 25.8588 37.9081 25.2584 38.6515C24.7065 39.3348 24.1482 40.2711 23.5288 41.3098L20.08 47.0918C19.5799 47.9302 19.8542 49.0152 20.6925 49.5152C21.5309 50.0153 22.6159 49.741 23.116 48.9027L26.5045 43.2217C27.2038 42.0494 27.6342 41.3361 28.0085 40.8727C28.182 40.6578 28.2906 40.5659 28.3438 40.5289C28.3546 40.5214 28.3624 40.5167 28.3673 40.5139C28.3729 40.515 28.3818 40.5171 28.3944 40.5209C28.4564 40.5397 28.588 40.5936 28.8194 40.7444C29.3185 41.0696 29.9481 41.6152 30.9751 42.5144L31.7849 43.2234C32.6947 44.0202 33.5148 44.7384 34.2507 45.2179C35.0513 45.7396 36.0228 46.1634 37.1854 45.9795C38.3481 45.7955 39.1413 45.0925 39.7418 44.3491C40.2936 43.6659 40.852 42.7295 41.4714 41.6909L44.9202 35.9088Z"
      fill="#8C6955"
    />
    <path
      fillRule="evenodd"
      d="M28.4074 16.4983C23.0341 17.3484 18.8231 18.0147 15.6074 18.9762C12.316 19.9604 9.81163 21.3238 8.05829 23.7363C6.30496 26.1487 5.7813 28.9516 5.86124 32.3861C5.93934 35.7416 6.60561 39.9525 7.4558 45.3259L7.49806 45.593C8.34819 50.9663 9.01442 55.1773 9.97599 58.393C10.9602 61.6844 12.3236 64.1888 14.736 65.9421C17.1484 67.6954 19.9514 68.2191 23.3859 68.1392C26.7414 68.0611 30.9523 67.3948 36.3256 66.5446L36.5927 66.5023C41.9661 65.6522 46.177 64.986 49.3927 64.0244C52.6841 63.0402 55.1885 61.6768 56.9419 59.2644C58.6952 56.852 59.2189 54.049 59.1389 50.6145C59.0608 47.259 58.3945 43.0481 57.5444 37.6748L57.5021 37.4077C56.652 32.0343 55.9857 27.8234 55.0242 24.6077C54.04 21.3163 52.6766 18.8119 50.2641 17.0585C47.8517 15.3052 45.0488 14.7815 41.6143 14.8615C38.2588 14.9396 34.0479 15.6059 28.6745 16.456L28.4074 16.4983ZM10.9179 25.8146C12.0341 24.2788 13.7077 23.234 16.6202 22.3631C19.5818 21.4775 23.5575 20.8446 29.0934 19.9688C34.6293 19.0929 38.6061 18.4675 41.6965 18.3956C44.7356 18.3248 46.65 18.8019 48.1858 19.9181C49.7216 21.0343 50.7664 22.7079 51.6373 25.6204C52.5229 28.5821 53.1557 32.5578 54.0316 38.0936C54.9075 43.6295 55.5329 47.6064 55.6048 50.6968C55.6756 53.7358 55.1985 55.6503 54.0823 57.1861C52.9661 58.7219 51.2924 59.7666 48.38 60.6376C45.4183 61.5232 41.4426 62.156 35.9068 63.0319C30.3709 63.9077 26.394 64.5331 23.3036 64.6051C20.2646 64.6758 18.3501 64.1987 16.8143 63.0825C15.2785 61.9663 14.2337 60.2927 13.3628 57.3802C12.4772 54.4186 11.8444 50.4429 10.9685 44.907C10.0927 39.3711 9.46725 35.3942 9.39532 32.3039C9.32458 29.2648 9.80165 27.3504 10.9179 25.8146Z"
      fill="#8C6955"
    />
  </svg>
);
