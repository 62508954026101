import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { AlertDialog, Button, illustratedIcons } from "@/ui";

interface MedRequestExistsAlertProps {
  show: boolean;
}

export const MedRequestExistsAlert = ({ show }: MedRequestExistsAlertProps) => {
  return (
    <AlertDialog
      show={show}
      icon={<illustratedIcons.Form />}
      title={t`You have a medication request in progress`}
      description={t`It looks like you already have a medication request in progress. Please wait until the process is complete to book a video consultation or to order more medication. We're here to help if you need any assistance!`}
      renderActions={() => (
        <Button asChild className="mx-auto w-full sm:w-fit">
          <Link to={ROUTES.MY_MEDS}>{t`Review medication orders`}</Link>
        </Button>
      )}
    />
  );
};
