import { t } from "ttag";

export const SUBSCRIPTION_CANCELLATION_EMAIL = "cancellation@joinfridays.com";
export const SUPPORT_EMAIL = "support@joinfridays.com";
export const DEFAULT_DEBOUNCE_TIME = 300;
export const MAX_NOTE_CHARACTERS = 110;

export const MIN_BMI_VALUE = 22;

export const SENIOR_AGE_THRESHOLD = 65;

export const PORTAL_USER_TYPE = {
  PATIENT: "healthie-patient-user",
  COACHING: "coaching-user",
} as const;

export const AVAILABLE_LANGUAGE = {
  EN: "en",
  ES: "es",
} as const;

export const DIRECTION = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
} as const;

export const VARIANT = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  QUATERNARY: "quaternary",
  QUINARY: "quinary",
} as const;

export const SIZE = {
  X_SMALL: "xs",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
  X_LARGE: "xl",
} as const;

export const ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
} as const;

export const PERIOD = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
} as const;

export const FIELD_TYPE = {
  CHECKBOX: "checkbox",
  DROPDOWN: "dropdown",
  HORIZONTAL_RADIO: "horizontal_radio",
  RADIO: "radio",
  READ_ONLY: "read_only",
  READ_ONLY_LINK: "read_only_link",
  TEXT: "text",
  TEXTAREA: "textarea",
  FILE: "document_upload",
  HIDDEN: "hidden",
} as const;

export const FILTER_TYPE = {
  IS_LESS_THAN: "is_less_than",
  IS_LEES_THAN_OR_EQUAL_TO: "is_less_than_or_equal_to",
  IS_GREATER_THAN: "is_greater_than",
  IS_GREATER_THAN_OR_EQUAL_TO: "is_greater_than_or_equal_to",
  IS_EQUAL_TO: "is_equal_to",
  IS_NOT_EQUAL_TO: "is_not_equal_to",
  INCLUDES: "includes",
  CONTAINS: "contains",
} as const;

export const NOW = new Date();

export const PAYMENT_STATUS = {
  FAILED: "failed",
  SUCCESS: "completed",
  PROCESSING: "processing",
} as const;

export const FORM_STEPS = {
  SYMPTOMS: "symptoms",
  INJECTIONS: "injections",
  FOLLOW_UP: "followUp",
  REFILL: "refill",
} as const;

export const MEDICATION_REQUEST_STATUS = {
  PAID: "paid",
  PENDING: "pending",
  APPROVED: "approved",
  SHIPPED: "shipped",
  ON_ITS_WAY: "on its way",
  CANCELLED: "cancelled",
  DENIED: "denied",
  REFUND_REQUESTED: "refund request",
  PROCESS_COMPLETE: "process complete",
  BRANDED_MEDICATION: "no more data",
} as const;

export const INACTIVE_STATUS = {
  ONBOARDING: "onboarding",
  CANCELLED: "cancelled",
  PAYMENT_FAILED: "payment_failed",
} as const;

export const SUBSCRIPTION_STATUS = {
  ...INACTIVE_STATUS,
  ACTIVE: "active",
  PAID_IN_HEALTHIE: "paid_in_healthie",
  EMBEDDABLES: "embeddables",
} as const;

export const CONSULTATION_PURPOSE = {
  REFILL: "refill",
  CONSULTATION: "consultation",
} as const;

// The BE is supposedly receiving up to 5 MB
// but it's not working properly, so we're going to reduce it to 2 MB.
export const MAX_UPLOAD_SIZE = 1024 * 1024 * 2; // 2MB
export const MAX_UPLOAD_SIZE_PLACEHOLDER = t`Maximum size: 2MB`;
export const HEALTHIE_FILE_STALE_TIME = 9000;

export const IMAGE_FILE_TYPES = {
  PNG: "image/png" as Blob["type"],
  JPEG: "image/jpeg" as Blob["type"],
  PDF: "application/pdf" as Blob["type"],
} as const;

export const ACCEPT_IMAGE_FILE = {
  [IMAGE_FILE_TYPES.PNG]: [],
  [IMAGE_FILE_TYPES.JPEG]: [],
  [IMAGE_FILE_TYPES.PDF]: [],
};

export const ACCEPTED_IMAGE_TYPES: Blob["type"][] =
  Object.keys(ACCEPT_IMAGE_FILE);

export const COACHING_SESSION_TYPE = {
  NUTRITIONAL: "nutritional",
  MINDSET: "mindset",
  MOVEMENT: "movement",
  SPANISH: "spanish",
} as const;

export const PROVIDER_FOLDER = {
  ...COACHING_SESSION_TYPE,
  FRIDAYS_RESOURCES: "fridays-resources",
} as const;

export const READ_ONLY_DOCUMENT_FOLDER = {
  ...PROVIDER_FOLDER,
  DOCTOR_NOTES: "doctor-notes",
} as const;

export const DOCUMENT_FOLDER = {
  LABS: "labs",
  PRESCRIPTIONS: "prescriptions",
  OTHER: "other",
} as const;

export const ATTACHMENT_TYPES = {
  IMAGE: "image",
  FILE: "application",
  PLACEHOLDER: "placeholder",
} as const;

export const PROVIDER_PREFERENCE = {
  CUSTOM: "custom",
  EARLIEST: "earliest",
} as const;

export const HEALTHIE_FORM_TYPE = {
  ONBOARDING_VIDEO_CONSULTATION: "onboarding-video-consultation",
  ONBOARDING_REFILL: "onboarding-refill",
  FOLLOW_UP_VIDEO_CONSULTATION: "follow-up-video-consultation",
  FOLLOW_UP_REFILL: "follow-up-refill",
  EMBEDDABLES_ONBOARDING_VIDEO_CONSULTATION:
    "embeddables-onboarding-video-consultation",
  EMBEDDABLES_ONBOARDING_REFILL: "embeddables-onboarding-refill",
} as const;

export const JOURNEY = {
  VIDEO_CONSULTATION: "sync",
  MEDICATION_REQUEST: "async",
} as const;
