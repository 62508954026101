import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { DocumentsIcon, Header, LogoIcon } from "@/ui";

export const FridaysResourcesHeader = () => {
  const navigate = useNavigate();
  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: DocumentsIcon,
              label: t`Document center`,
            },
            {
              href: ROUTES.DOCUMENT_CENTER.FRIDAYS_RESOURCES,
              icon: LogoIcon,
              label: t`Fridays' Resources`,
            },
          ]}
        />

        <div className="flex items-center gap-2">
          <Header.GoBackButton
            onClick={() => navigate(ROUTES.DOCUMENT_CENTER.OVERVIEW)}
            className="md:hidden"
          />

          <Header.Title>{t`Fridays' Resources`}</Header.Title>
        </div>

        <Header.Description>
          {t`Stay informed with the latest news and updates from Fridays.`}
          <br />
          {t`Access valuable resources, articles, and company announcements here.`}
        </Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
