import { ScreenLayout } from "@/layouts";
import { SetUpFridaysPasswordModal, SetUpPaymentMethodModal } from "@/modals";
import { HomeHeader } from "./sections";
import { HomeContent } from "./sections/Content";

export const Home = () => {
  return (
    <>
      <ScreenLayout variant="secondary" className="gap-1">
        <HomeHeader />

        <HomeContent />
      </ScreenLayout>

      <SetUpPaymentMethodModal />

      <SetUpFridaysPasswordModal />
    </>
  );
};
