import type {
  AnchorHTMLAttributes,
  ComponentProps,
  ElementRef,
  HTMLAttributes,
} from "react";
import { forwardRef } from "react";

import { tw } from "@/utils";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "relative flex flex-col gap-2 rounded-lg border border-salmon-07 bg-salmon-01 px-3 pb-4 pt-6 text-salmon-09",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "RequestCard.Root";

const Title = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={tw("font-bold leading-5 text-salmon-10", className)}
    {...props}
  >
    {children}
  </h3>
));
Title.displayName = "RequestCard.Title";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={tw("leading-5", className)} {...props} />
));
Description.displayName = "RequestCard.Description";

const Details = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex flex-col gap-1.5 rounded-xl border border-brown-07 p-2 md:w-fit md:flex-row md:px-4",
        className,
      )}
      {...props}
    />
  ),
);
Details.displayName = "RequestCard.Details";

const Label = forwardRef<ElementRef<"label">, ComponentProps<"label">>(
  ({ className, htmlFor, ...props }, ref) => (
    <label
      htmlFor={htmlFor}
      ref={ref}
      className={tw("text-sm text-salmon-08", className)}
      {...props}
    />
  ),
);
Label.displayName = "RequestCard.Label";

const Link = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>(({ className, children, ...props }, ref) => (
  <a
    ref={ref}
    target="_blank"
    rel="noreferrer"
    className={tw(
      "inline-flex items-center gap-1 text-sm text-nature-08 underline underline-offset-2",
      className,
    )}
    {...props}
  >
    {children}
  </a>
));
Link.displayName = "RequestCard.Link";

const Footer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("flex items-center", className)} {...props} />
  ),
);
Footer.displayName = "RequestCard.Footer";

const Status = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("flex items-center", className)} {...props} />
  ),
);
Status.displayName = "RequestCard.Status";

export { Root, Footer, Title, Description, Details, Label, Status, Link };
