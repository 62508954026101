import { useNavigate } from "react-router-dom";
import { jt, t } from "ttag";

import { EXTERNAL_LINK, ROUTES } from "@/router";
import { Button, CardSkeleton, DollarMinimalisticIcon } from "@/ui";
import { CoachingCard } from ".";
import { useSessionsOverview } from "../hooks";

export const NutritionCounseling = () => {
  const { canBookCounselingQuery } = useSessionsOverview();
  const navigate = useNavigate();

  if (canBookCounselingQuery.isLoading) {
    return <CardSkeleton color="nature" />;
  }

  if (canBookCounselingQuery.isError) {
    return null;
  }

  const canBook =
    canBookCounselingQuery.isSuccess && canBookCounselingQuery.data;

  const counselingLink = (
    <a
      key={EXTERNAL_LINK.COUNSELING_WAIVER}
      href={EXTERNAL_LINK.COUNSELING_WAIVER}
      target="_blank"
      rel="noreferrer"
      className="underline"
    >
      {t`counseling waiver`}
    </a>
  );

  return (
    <CoachingCard.Root>
      <CoachingCard.Content>
        <div className="flex flex-col gap-1">
          <CoachingCard.Tagline>
            {canBook ? t`Save your spot` : "Subscription update needed"}
          </CoachingCard.Tagline>
          <CoachingCard.Title>
            {canBook
              ? t`Book Your 1:1 nutritional counseling session today!`
              : t`Access to 1:1 nutritional counseling sessions`}
          </CoachingCard.Title>
        </div>

        <CoachingCard.Description>
          {canBook
            ? jt`By clicking "Book now," you agree that you have read and understand the nutritional ${
                counselingLink
              }`
            : t`This feature is only available for new subscriptions. Upgrade and get personalized tips and advice.`}
        </CoachingCard.Description>
      </CoachingCard.Content>

      {canBook ? (
        <Button asChild size="lg">
          <a
            href={EXTERNAL_LINK.BOOK_NUTRITIONAL_COUNSELING}
            target="_blank"
            rel="noreferrer"
          >
            {t`Book now`}
          </a>
        </Button>
      ) : (
        <Button
          size="lg"
          onClick={() => navigate(ROUTES.BILLING.CHANGE_SUBSCRIPTION)}
        >
          <DollarMinimalisticIcon className="size-4" />
          {t`Change subscription`}
        </Button>
      )}
    </CoachingCard.Root>
  );
};
