import { t } from "ttag";

import { SIZE } from "@/shared.constants";
import { icons, IconWrapper } from "@/ui";

export const PasswordTips = () => {
  return (
    <div className="flex gap-2 rounded-2xl bg-brown-03 p-4">
      <IconWrapper size={SIZE.SMALL} className="mt-0.5 text-salmon-08">
        <icons.ShieldCheck />
      </IconWrapper>
      <div className="text-sm">
        <div className="font-bold text-salmon-10">{t`Password Tips:`}</div>
        <ul className="list-inside list-disc pl-2 text-salmon-08">
          <li>{t`Include at least 8 characters.`}</li>
          <li>{t`Incorporate numbers (0-9).`}</li>
          <li>{t`Utilize special characters (e.g., !, @, #, $, %, etc).`}</li>
          <li>
            {t`Avoid common phrases like "password," "123456," or "qwerty."`}
          </li>
          <li>{t`Include a mix of uppercase and lowercase letters.`}</li>
        </ul>
      </div>
    </div>
  );
};
