import type { ComponentProps, PropsWithChildren } from "react";
import { t } from "ttag";

import { useScheduleConsultationContext } from "@/contexts";
import type { TimeSlot } from "@/shared.types";
import {
  CalendarFixedIconMono,
  EmptyState,
  HeartsCircleFixedIconMono,
  ScrollArea,
  Skeleton as SkeletonPrimitive,
} from "@/ui";
import { formatAvailableTime, tw } from "@/utils";

const Content = ({ children }: PropsWithChildren) => {
  return (
    <ScrollArea>
      <div className="flex max-h-44 flex-wrap gap-2">{children}</div>
    </ScrollArea>
  );
};

const Disabled = ({
  className,
  ...props
}: ComponentProps<typeof EmptyState.Root>) => {
  return (
    <EmptyState.Root className={tw("border-none shadow", className)} {...props}>
      <CalendarFixedIconMono className="w-28" />
      <EmptyState.Description>
        {t`Choose a date to view available times`}
      </EmptyState.Description>
    </EmptyState.Root>
  );
};

const Error = ({
  className,
  ...props
}: ComponentProps<typeof EmptyState.Root>) => {
  return (
    <EmptyState.Root className={tw("border-none shadow", className)} {...props}>
      <HeartsCircleFixedIconMono className="size-14" />

      <EmptyState.Title>{t`Oops seems like something went wrong`}</EmptyState.Title>

      <EmptyState.Description className="text-base">
        {t`Our team is already working on fixing it.`}
        <br />
        <strong> {t`Try to select another date.`}</strong>
        <br />
        {t`Thank you for your patience!`}
      </EmptyState.Description>
    </EmptyState.Root>
  );
};

const Skeleton = () => {
  return (
    <div className="flex min-h-44 grow flex-col">
      <div className="flex flex-wrap gap-2.5">
        {Array.from({ length: 5 }, (_, index) => `${index}`).map((i) => (
          <SkeletonPrimitive
            key={`time-slot-skeleton-${i}`}
            className="h-9 w-24 rounded-l-full rounded-r-full"
          />
        ))}
      </div>
    </div>
  );
};

interface TimeSlotItemProps extends ComponentProps<"button"> {
  timeSlot: TimeSlot;
}

const Item = ({ timeSlot, className, ...props }: TimeSlotItemProps) => {
  const {
    scheduleDate,
    actions: { setScheduleDate },
  } = useScheduleConsultationContext();

  return (
    <button
      {...props}
      type="button"
      className={tw(
        "h-fit w-32 rounded-l-full rounded-r-full bg-brown-02 py-2 text-center text-sm font-medium text-brown-09 duration-150 hover:bg-salmon-09 hover:text-salmon-02",
        className,
        timeSlot.dateTime === scheduleDate.timeSlot?.dateTime &&
          "bg-salmon-10 text-salmon-02",
      )}
      onClick={() => setScheduleDate((prev) => ({ ...prev, timeSlot }))}
    >
      {`${formatAvailableTime(timeSlot.dateTime)} ${timeSlot.timezone}`}
    </button>
  );
};

export { Content, Disabled, Error, Skeleton, Item };
