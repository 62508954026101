import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { createContext, forwardRef, useContext, useId } from "react";
import { Slot } from "@radix-ui/react-slot";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { tw } from "@/utils";
import { Label as LabelPrimitive } from "./Label";

const Provider = FormProvider;

interface FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
}

const FormFieldContext = createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);

const Field = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: ControllerProps<TFieldValues, TName>,
) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

export const useFormField = () => {
  const fieldContext = useContext(FormFieldContext);
  const itemContext = useContext(FormItemContext);
  const { getFieldState, formState, control } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    control,
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

interface FormItemContextValue {
  id: string;
}

const FormItemContext = createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

const Item = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const id = useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div
          ref={ref}
          className={tw("flex flex-col gap-1.5", className)}
          {...props}
        />
      </FormItemContext.Provider>
    );
  },
);
Item.displayName = "Form.Item";

const Label = forwardRef<
  ElementRef<typeof LabelPrimitive>,
  ComponentPropsWithoutRef<typeof LabelPrimitive>
>((props, ref) => {
  const { formItemId } = useFormField();

  return <LabelPrimitive ref={ref} htmlFor={formItemId} {...props} />;
});
Label.displayName = "Form.Label";

const Control = forwardRef<
  ElementRef<typeof Slot>,
  ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      className={tw(error && "border-red-07 text-red-07")}
      {...props}
    />
  );
});
Control.displayName = "Form.Control";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return (
      <p
        ref={ref}
        id={formDescriptionId}
        className={tw("text-sm text-brown-07", className)}
        {...props}
      />
    );
  },
);
Description.displayName = "Form.Description";

const Message = forwardRef<
  ElementRef<"p">,
  ComponentPropsWithoutRef<"p"> & { compact?: boolean }
>(({ className, children, compact = true, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error?.message ? String(error.message) : children;

  if (!body && compact) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={tw(
        "-mt-0.5 min-h-5 text-left text-sm font-medium text-red-07",
        className,
      )}
      {...props}
    >
      {body}
    </p>
  );
});
Message.displayName = "Form.Message";

const CharCounter = forwardRef<
  ElementRef<"p">,
  ComponentPropsWithoutRef<"p"> & { max: number }
>(({ className, max, ...props }, ref) => {
  const { formDescriptionId, control, name, error } = useFormField();
  const fieldValue = useWatch({
    control,
    defaultValue: "",
    name,
  }) as string;

  return (
    <p
      ref={ref}
      id={`${formDescriptionId}-char-counter`}
      className={tw("text-sm text-brown-07", error && "text-red-07", className)}
      {...props}
    >
      {`${fieldValue.length}/${max}`}
    </p>
  );
});
CharCounter.displayName = "Form.CharCounter";

export default {
  Provider,
  Item,
  Label,
  Control,
  Description,
  Message,
  Field,
  CharCounter,
};
