import { t } from "ttag";

import { AppointmentSum, TimeSlots, TimeSlotsEmptyState } from "@/components";
import { useScheduleConsultationContext } from "@/contexts";
import { useTimeSlotsQuery } from "@/hooks";
import { PROVIDER_PREFERENCE } from "@/shared.constants";
import { useOnboardingStore } from "@/stores";
import { CardPrimitive as Card } from "@/ui";
import { formatBackendDateToVerbose, tw } from "@/utils";

export const AvailableTimes = () => {
  const {
    language,
    scheduleDate: { appointmentDate },
    providerPreference,
    provider,
  } = useScheduleConsultationContext();

  const userId = useOnboardingStore((state) => state.user?.id);

  const disabled =
    (providerPreference === PROVIDER_PREFERENCE.CUSTOM && !provider) ||
    !appointmentDate;

  const { isError, isLoading, isSuccess, data } = useTimeSlotsQuery({
    disabled,
    userId,
  });

  const verboseSelectedDate =
    appointmentDate && formatBackendDateToVerbose(appointmentDate);
  const availability = verboseSelectedDate ? t` on ${verboseSelectedDate}` : "";

  return (
    <Card.Root
      className={tw(
        "basis-1/2 bg-transparent p-0",
        disabled && "cursor-not-allowed opacity-50",
      )}
    >
      <Card.Title>{t`2. Available times${availability}`}</Card.Title>
      <Card.Content
        className={tw(
          "flex max-h-112 flex-1 flex-col gap-4",
          disabled && "pointer-events-none",
        )}
      >
        {disabled && <TimeSlots.Disabled className="shadow-none" />}

        {isError && <TimeSlots.Error />}

        {isLoading && <TimeSlots.Skeleton />}

        {isSuccess &&
          (!data.length ? (
            <TimeSlotsEmptyState language={language} />
          ) : (
            <TimeSlots.Content>
              {data.map((timeSlot) => (
                <TimeSlots.Item
                  key={timeSlot.dateTime}
                  timeSlot={timeSlot}
                  className="bg-brown-01"
                />
              ))}
            </TimeSlots.Content>
          ))}

        <AppointmentSum />
      </Card.Content>
    </Card.Root>
  );
};
