import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { tw } from "@/utils";
import { Label as LabelPrimitive } from "../form";

const Root = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex flex-col gap-2 rounded-lg border border-brown-05 p-4 text-brown-10",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "DetailsCard.Root";

const Item = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex flex-col gap-0 sm:flex-row sm:gap-2", className)}
      {...props}
    />
  ),
);
Item.displayName = "DetailsCard.Item";

const Separator = forwardRef<
  ElementRef<typeof SeparatorPrimitive.Root>,
  ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = "vertical", decorative = true, ...props },
    ref,
  ) => (
    <SeparatorPrimitive.Root
      asChild
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={tw(
        "shrink-0 bg-brown-05",
        orientation === "horizontal"
          ? "h-[1px] w-full"
          : "mx-1.5 my-auto h-3.5 w-0.5",
        className,
      )}
      {...props}
    >
      <span />
    </SeparatorPrimitive.Root>
  ),
);
Separator.displayName = "DetailsCard.Separator";

const Label = forwardRef<
  ElementRef<typeof LabelPrimitive>,
  ComponentPropsWithoutRef<typeof LabelPrimitive>
>(({ className, ...props }, ref) => (
  <LabelPrimitive
    ref={ref}
    className={tw("text-sm font-medium text-brown-07", className)}
    {...props}
  />
));
Label.displayName = "DetailsCard.Label";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p
      ref={ref}
      className={tw(
        "flex h-fit flex-wrap pl-5 text-sm font-medium sm:pl-0",
        className,
      )}
      {...props}
    />
  ),
);
Description.displayName = "DetailsCard.Description";

export { Root, Description, Label, Item, Separator };
