import { t } from "ttag";

import {
  useMonthlyPeriodPlan,
  useUserSubscription,
} from "@/domains/billing/hooks";
import { getPeriodDurationText } from "@/domains/billing/utils";
import {
  CardPrimitive as Card,
  JarOfPillsIcon,
  Label,
  PillIcon,
  Skeleton,
} from "@/ui";

export const PlanChangeComparison = () => {
  const { data: patientSubscription } = useUserSubscription();
  const { data: newPlan } = useMonthlyPeriodPlan();

  const currentPeriodPlan = patientSubscription?.currentPeriodPlan;

  if (!currentPeriodPlan || !newPlan) {
    return null;
  }

  return (
    <Card.Root className="border border-brown-05 bg-transparent">
      <div className="flex gap-1">
        <Label
          htmlFor="current-plan"
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <JarOfPillsIcon className="size-4" />
          {t`Current Plan:`}
        </Label>

        {currentPeriodPlan ? (
          <Card.Description
            id="current-plan"
            className="flex items-center gap-1.5 font-medium"
          >
            {currentPeriodPlan.name}{" "}
            <span className="text-xs text-brown-05">|</span>
            <span>
              ${currentPeriodPlan.price}/
              {getPeriodDurationText(currentPeriodPlan.durationInMonths)}
            </span>
          </Card.Description>
        ) : (
          <Skeleton className="h-3 w-28 self-center" />
        )}
      </div>

      <div className="flex gap-1">
        <Label
          htmlFor="new-period-plan"
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <PillIcon className="size-4" />
          {t`New Plan:`}
        </Label>

        {newPlan ? (
          <Card.Description
            id="new-period-plan"
            className="flex items-center gap-1.5 font-medium"
          >
            {newPlan?.name} <span className="text-xs text-brown-05">|</span>
            <span>
              ${newPlan.price}/{getPeriodDurationText(newPlan.durationInMonths)}
            </span>
          </Card.Description>
        ) : (
          <Skeleton className="h-3 w-28 self-center" />
        )}
      </div>
    </Card.Root>
  );
};
