import type { PropsWithChildren } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { FullScreenLoader, Loader } from "@/components";
import type { HealthieFormType, Patient } from "@/shared.types";
import { Form } from "@/ui";
import { handleAxiosFieldErrors, tw } from "@/utils";
import { HealthieFormField } from "./components";
import { useFieldsQuery, useFormId, useSubmitMutation } from "./hooks";

interface HealthieFormProps extends PropsWithChildren {
  onSubmit?: () => void;
  formType: HealthieFormType;
  patientId: Patient["id"];
  className?: string;
}

export const HealthieForm = ({
  onSubmit,
  formType,
  patientId,
  children,
  className,
}: HealthieFormProps) => {
  const { formId } = useFormId(formType);

  const {
    data: fields,
    isLoading,
    isSuccess,
  } = useFieldsQuery([formId, patientId]);

  const form = useForm({
    values: fields?.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value ?? undefined,
      }),
      {},
    ),
  });

  const submitMutation = useSubmitMutation();

  const handleSubmit: SubmitHandler<Record<string, string>> = (values) =>
    submitMutation.mutate(
      {
        formId,
        patientId,
        answers: values,
      },
      {
        onSuccess: onSubmit,
        onError: (err) => handleAxiosFieldErrors(err, form.setError),
      },
    );

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <Form.Provider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className={tw("flex max-w-3xl flex-col gap-8", className)}
        >
          {fields.map((field) => (
            <HealthieFormField key={field.id} healthieField={field} />
          ))}

          {children}
        </form>

        {submitMutation.isPending && <FullScreenLoader />}
      </Form.Provider>
    );
  }

  return null;
};
