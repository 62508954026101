import { useQuery } from "@tanstack/react-query";

import { billingQueriesStore } from "../api";
import { DURATION_IN_MONTHS } from "../models";
import type { PlansParams } from "../models";

export const usePatientPlans = ({
  durationInMonths = DURATION_IN_MONTHS.MONTHLY,
  couponCode,
}: Partial<PlansParams> = {}) => {
  return useQuery({
    ...billingQueriesStore.stripe.plans._ctx.portal._ctx.patient({
      durationInMonths,
      couponCode,
    }),
    staleTime: Infinity,
  });
};
