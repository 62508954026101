import { useQuery } from "@tanstack/react-query";

import { getAvailableTimesQuery } from "@/api";
import { useScheduleConsultationContext } from "@/contexts";
import type { OnboardingUser } from "@/shared.types";

interface useTimeSlotsParams {
  disabled?: boolean;
  userId?: OnboardingUser["id"];
}

export const useTimeSlotsQuery = ({ disabled, userId }: useTimeSlotsParams) => {
  const {
    language,
    scheduleDate: { appointmentDate: date },
    provider,
  } = useScheduleConsultationContext();

  return useQuery({
    ...getAvailableTimesQuery({
      date,
      providerId: provider?.id,
      language,
      userId,
    }),
    enabled: !disabled,
  });
};
