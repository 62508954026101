import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { tw } from "@/utils";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex flex-col gap-4 rounded-2xl border border-salmon-05 bg-white p-4 text-brown-10",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "DocumentCard.Root";

const Banner = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "relative z-10 flex h-32 items-center justify-center overflow-hidden rounded-lg bg-nature-03",
        className,
      )}
      {...props}
    />
  ),
);
Banner.displayName = "DocumentCard.Banner";

const IconWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("w-32 p-1", className)} {...props} />
  ),
);
IconWrapper.displayName = "DocumentCard.IconWrapper";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex grow flex-col items-center gap-2 text-center",
        className,
      )}
      {...props}
    />
  ),
);
Content.displayName = "DocumentCard.Content";

const Title = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3 ref={ref} className={tw("text-xl font-medium", className)} {...props}>
    {children}
  </h3>
));
Title.displayName = "DocumentCard.Title";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={tw("font-medium", className)} {...props} />
));
Description.displayName = "DocumentCard.Description";

const Actions = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex w-full flex-col-reverse items-center gap-2 lg:flex-row [&>*]:w-full [&>*]:flex-1",
        className,
      )}
      {...props}
    />
  ),
);
Actions.displayName = "DocumentCard.Actions";

export { Root, Banner, Content, Title, Description, Actions, IconWrapper };
