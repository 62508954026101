import { FIELD_TYPE } from "@/shared.constants";
import type { FieldType } from "@/shared.types";

const READ_ONLY_TYPES = [
  FIELD_TYPE.READ_ONLY,
  FIELD_TYPE.READ_ONLY_LINK,
] as const;

const HIDDEN_FIELD_LABELS = ["async_visit", "sync_visit"] as const;

export type ReadOnlyType = (typeof READ_ONLY_TYPES)[number];

type HiddenFieldLabel = (typeof HIDDEN_FIELD_LABELS)[number];

export const isReadOnlyType = (x: FieldType): x is ReadOnlyType => {
  return READ_ONLY_TYPES.includes(x as ReadOnlyType);
};

export const isHiddenFieldByLabel = (x: string): x is HiddenFieldLabel => {
  return HIDDEN_FIELD_LABELS.includes(x as HiddenFieldLabel);
};

export const isInvalidFieldType = (x: string): x is FieldType => {
  return !Object.values(FIELD_TYPE).includes(x as FieldType);
};
