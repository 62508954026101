import { useQuery } from "@tanstack/react-query";

import { getProvidersQuery } from "@/api";
import { useScheduleConsultationContext } from "@/contexts";

export const useProvidersQuery = () => {
  const { state } = useScheduleConsultationContext();

  return useQuery({
    ...getProvidersQuery({ state }),
    enabled: Boolean(state),
  });
};
