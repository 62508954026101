import { t } from "ttag";

import type { Consultation } from "@/shared.types";
import { Badge, icons } from "@/ui";
import { formatBackendDate, formatConsultationTime } from "@/utils";

export const PastConsultationCard = ({
  consultation,
  setDocumentId,
}: {
  consultation: Consultation;
  setDocumentId: (id: string) => void;
}) => {
  return (
    <div className="flex rounded-xl bg-salmon-02 p-4">
      <div className="flex flex-1 flex-col gap-2">
        <div className="flex items-baseline gap-2">
          <span className="font-light">{t`Provider`}</span>
          <span className="font-semibold">
            {consultation.provider.fullName}
          </span>
        </div>
        <div className="flex items-baseline gap-2">
          <span className="font-light">{t`Date`}</span>
          <span className="font-semibold">
            {formatBackendDate(consultation.startTime)}
          </span>
        </div>
        <div className="flex items-baseline gap-2">
          <span className="font-light">{t`Time`}</span>
          <span className="font-semibold">{`${formatConsultationTime(
            consultation.startTime,
          )} - ${formatConsultationTime(consultation.endTime)} ${
            consultation.timezone
          }`}</span>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-1 flex-col">
          {consultation.pmStatus && (
            <Badge size="md" variant="secondary">
              {consultation.pmStatus}
            </Badge>
          )}
        </div>
        {consultation.documentId && (
          <button
            className="flex items-center justify-end gap-0.5"
            onClick={() => setDocumentId(consultation.documentId!)}
          >
            <icons.Link className="h-4 w-4" />
            <span className="text-sm">{t`Note`}</span>
          </button>
        )}
      </div>
    </div>
  );
};
