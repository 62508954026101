import { Navigate } from "react-router-dom";

import { PORTAL_USER_TYPE } from "@/shared.constants";
import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import { ROUTES } from "../constants";

export const BaseRouter = () => {
  const portalUserType = usePortalUserStore(
    ({ portalUser }) => portalUser?.type,
  );
  const activeSubscription = usePortalUserStore(
    ({ portalUser }) =>
      portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );

  if (!portalUserType) {
    return <Navigate replace to={ROUTES.LOGIN} />;
  }
  if (portalUserType === PORTAL_USER_TYPE.PATIENT) {
    return <Navigate replace to={ROUTES.HOME} />;
  }
  if (portalUserType === PORTAL_USER_TYPE.COACHING) {
    if (activeSubscription) {
      return <Navigate replace to={ROUTES.COACHING_HUB.OVERVIEW} />;
    }
    return <Navigate replace to={ROUTES.BILLING.OVERVIEW} />;
  }

  return <Navigate replace to={ROUTES.HOME} />;
};
