import { jt, t } from "ttag";

import { OnboardingHeader } from "@/components";
import { ScheduleContextProvider } from "@/contexts";
import { EmbeddablesOnboardingScreenLayout } from "@/domains/embeddables-onboarding/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { useBoolean } from "@/hooks";
import { AVAILABLE_LANGUAGE } from "@/shared.constants";
import { usePortalUserStore } from "@/stores";
import { Alert, ShieldCheckIcon } from "@/ui";
import {
  AvailableDays,
  AvailableTimes,
  LanguagePreference,
  Submit,
} from "./sections";

export const ScheduleVideoConsultation = () => {
  const preferredLanguage = usePortalUserStore(
    (state) => state.portalUser?.preferredLanguage ?? AVAILABLE_LANGUAGE.EN,
  );
  const {
    patient: { location },
  } = useEmbeddablesForms();

  const { value: showLanguageSelector, setTrue: openLanguageSelector } =
    useBoolean(false);

  const selectorOpener = (
    <button
      key="language-selector-opener"
      type="button"
      onClick={openLanguageSelector}
      className="underline"
    >
      {t`here`}
    </button>
  );

  return (
    <EmbeddablesOnboardingScreenLayout>
      <OnboardingHeader
        title={t`Lets meet!`}
        description={t`Choose the perfect time and date`}
      />

      {!showLanguageSelector && (
        <Alert customIcon={<ShieldCheckIcon />}>
          {jt`These are the available consultations based on your language preference. If you'd like, you can change your preferred language for the consultation by clicking ${selectorOpener}.`}
        </Alert>
      )}

      <ScheduleContextProvider
        defaultLanguage={preferredLanguage}
        state={location?.state}
      >
        {showLanguageSelector && <LanguagePreference />}

        <div className="flex grow flex-col gap-8 lg:flex-row">
          <AvailableDays />

          <AvailableTimes />
        </div>

        <Submit />
      </ScheduleContextProvider>
    </EmbeddablesOnboardingScreenLayout>
  );
};
