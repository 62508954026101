import type { ComponentPropsWithoutRef, PropsWithChildren } from "react";

import { tw } from "@/utils";

export const SessionListSlot = ({
  className,
  children,
  ...rest
}: PropsWithChildren<ComponentPropsWithoutRef<"section">>) => {
  return (
    <section
      className={tw(
        "grid grid-cols-1 rounded-2xl border border-brown-04 bg-brown-01 px-4 py-2 lg:grid-cols-2 lg:gap-3 lg:border-none lg:bg-transparent lg:p-0",
        className,
      )}
      {...rest}
    >
      {children}
    </section>
  );
};
