import type { FC, PropsWithChildren } from "react";
import { Navigate, useParams } from "react-router-dom";

import { LoadingScreen } from "@/components";
import { EmbeddablesUserContext } from "@/domains/embeddables-onboarding/context";
import { ROUTES } from "@/router";
import { useUserValidation } from "./hooks";

export const RequireValidUserCode: FC<PropsWithChildren> = ({ children }) => {
  const { userCode } = useParams();

  const { data, isError, isSuccess, isLoading } = useUserValidation(userCode);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!userCode || isError || (isSuccess && !data.isValid)) {
    return <Navigate replace to={ROUTES.LOGIN} />;
  }

  return (
    isSuccess && (
      <EmbeddablesUserContext.Provider value={data}>
        {children}
      </EmbeddablesUserContext.Provider>
    )
  );
};
