import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isAfter } from "date-fns";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  getCurrentMedicationRequestQuery,
  getUpcomingConsultationsQuery,
} from "@/api";
import { usePatientQuery } from "@/hooks";
import { ROUTES } from "@/router";
import { CONSULTATION_PURPOSE, NOW, VARIANT } from "@/shared.constants";
import { useConsultationStore } from "@/stores";
import { Alert, Button, Card, icons, ScreenHeader } from "@/ui";
import { PurposeOptionsSkeleton } from "./components";
import { ConsultationExistsAlert, MedRequestExistsAlert } from "./modals";
import { PurposeOptions } from "./sections";

export const ConsultationPurpose = () => {
  const navigate = useNavigate();

  const purpose = useConsultationStore((state) => state.purpose);
  const setPurpose = useConsultationStore((state) => state.setPurpose);

  const [showMedRequestExistsAlert, setShowMedRequestExistsAlert] =
    useState(false);
  const [showConsultationExistsAlert, setShowConsultationExistsAlert] =
    useState(false);

  const {
    data: hasUpcomingConsultations,
    isLoading: isLoadingHasUpcomingConsultations,
  } = useQuery({
    ...getUpcomingConsultationsQuery(),
    select: (data) => !!data.length,
  });
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();

  const {
    data: currentMedicationRequest,
    isLoading: isLoadingCurrentMedicationRequest,
  } = useQuery(getCurrentMedicationRequestQuery());

  const isLoading =
    isLoadingHasUpcomingConsultations ||
    isPatientLoading ||
    isLoadingCurrentMedicationRequest;

  const hasUpcomingThirdInjection = Boolean(
    patient?.thirdInjectionDate &&
      isAfter(new Date(patient.thirdInjectionDate), NOW),
  );

  const isRefillDisabled =
    isLoading || Boolean(currentMedicationRequest) || hasUpcomingThirdInjection;

  useEffect(() => {
    setPurpose(
      isRefillDisabled
        ? CONSULTATION_PURPOSE.CONSULTATION
        : CONSULTATION_PURPOSE.REFILL,
    );
  }, [isRefillDisabled, setPurpose]);

  useEffect(() => {
    if (!isLoading) {
      if (currentMedicationRequest?.dateOfRequest) {
        setShowMedRequestExistsAlert(true);
      } else if (hasUpcomingConsultations) {
        setShowConsultationExistsAlert(true);
        return;
      }
    }
  }, [isLoading, currentMedicationRequest, hasUpcomingConsultations]);

  const handleNext = () => {
    if (purpose === CONSULTATION_PURPOSE.REFILL) {
      navigate(ROUTES.CONSULTATIONS.MEDICATION_REFILL_REQUEST);
    } else {
      navigate(ROUTES.CONSULTATIONS.FOLLOW_UP_FORM);
    }
  };

  return (
    <main className="flex w-full flex-col gap-6 px-4 pt-4 md:px-8 md:pt-12 lg:px-16">
      <MedRequestExistsAlert show={showMedRequestExistsAlert} />
      <ConsultationExistsAlert show={showConsultationExistsAlert} />
      <ScreenHeader
        variant="secondary"
        title={t`What's the purpose of your consultation?`}
        description={
          <p className="mt-4 text-base font-medium leading-5 md:mt-0">
            {t`Please choose the option that best matches your reason for reaching out today.`}
            <br />
            {t`Understanding your needs helps us provide the best support possible.`}
          </p>
        }
        paths={[
          {
            href: ROUTES.CONSULTATIONS.OVERVIEW,
            icon: icons.Calendar,
            label: t`Consultations`,
          },
          {
            href: ROUTES.CONSULTATIONS.PURPOSE,
            icon: icons.HandShake,
            label: t`Consultation Purpose`,
          },
        ]}
      />

      <div className="flex flex-col gap-4 pb-8">
        <Card
          title={t`Select the option that best applies to you`}
          colorVariant={VARIANT.TERTIARY}
        >
          {isLoading ? (
            <PurposeOptionsSkeleton />
          ) : (
            <PurposeOptions isRefillDisabled={isRefillDisabled} />
          )}
        </Card>

        {purpose === CONSULTATION_PURPOSE.REFILL && (
          <Alert>
            {t`You will be able to review your medication request by visiting the “Your Meds” section`}
          </Alert>
        )}

        <div className="flex flex-col-reverse items-stretch justify-end gap-4 md:flex-row md:items-center">
          <Button
            size="lg"
            onClick={() => navigate(ROUTES.CONSULTATIONS.OVERVIEW)}
            variant="secondary"
          >
            {t`Cancel`}
          </Button>
          <Button
            size="lg"
            disabled={isLoading || !purpose}
            onClick={handleNext}
          >
            {t`Next`}
          </Button>
        </div>
      </div>
    </main>
  );
};
