import * as Tooltip from "@radix-ui/react-tooltip";

export const BasicTooltip = ({
  triggerContent,
  content,
}: {
  triggerContent: JSX.Element;
  content: string;
}) => {
  return (
    <Tooltip.Provider delayDuration={150}>
      <Tooltip.Root>
        <Tooltip.Trigger className="h-fit rounded-l-full rounded-r-full bg-salmon-08 text-sm text-salmon-10 duration-150 data-[state=delayed-open]:bg-salmon-10 data-[state=instant-open]:bg-salmon-10 data-[state=delayed-open]:text-salmon-01 data-[state=instant-open]:text-salmon-01">
          {triggerContent}
        </Tooltip.Trigger>
        <Tooltip.Content className="rounded-2xl border border-salmon-05 bg-salmon-10 p-4 text-xs text-brown-01 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=top]:slide-in-from-bottom-2">
          {content}
          <Tooltip.Arrow
            className="z-10 -translate-y-0.5 fill-salmon-10"
            width={23}
            height={12}
          />
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
