import type { QueryClient } from "@tanstack/react-query";

import type { ISODate, Period, WeightEntry } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "progress-tracker";

interface GetWeightEntriesParams {
  periodType: Period;
  date: ISODate;
}

export const getWeightEntriesQuery = (params: GetWeightEntriesParams) => ({
  queryKey: [DOMAIN, "getWeightEntriesQuery", params],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<WeightEntry[]>>(
      `/weight-entries`,
      { params },
    );

    return data.data;
  },
});

export const getWeightEntryQuery = (id?: string | number) => ({
  enabled: id !== undefined,
  queryKey: [DOMAIN, "getWeightEntryQuery", id],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<WeightEntry>>(
      `/weight-entries/${id}`,
    );

    return data.data;
  },
});

export const createWeightEntry = {
  mutation: async (params: Omit<WeightEntry, "id">) => {
    const { data } = await privateAPI.post<ServiceResponse<WeightEntry>>(
      `/weight-entries`,
      params,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const editWeightEntry = {
  mutation: async (params: WeightEntry) => {
    const { id, ...rest } = params;
    const { data } = await privateAPI.put<ServiceResponse<WeightEntry>>(
      `/weight-entries/${id}`,
      rest,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const deleteWeightEntry = {
  mutation: async (id: string) => {
    const { data } = await privateAPI.delete<ServiceResponse<WeightEntry>>(
      `/weight-entries/${id}`,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};
