import { t } from "ttag";

import { SIZE } from "@/shared.constants";
import { Button, illustratedIcons, Modal } from "@/ui";

export const RedirectToSync = ({
  show,
  onClose,
  onConfirm,
}: {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      className="flex max-w-[40rem] flex-col items-start gap-5 p-10"
    >
      <div className="flex flex-col items-center gap-4 text-center">
        <illustratedIcons.Video />
        <h3 className="font-serif text-3xl text-salmon-10">{t`Redirecting to video consultation`}</h3>
        <p className="text-brown-09">
          {t`Based on your form responses, we're guiding you to schedule a video consultation. Get ready to connect with your provider and discuss your needs!`}
        </p>
        <Button
          variant="primary"
          size={SIZE.SMALL}
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {t`Continue`}
        </Button>
      </div>
    </Modal>
  );
};
