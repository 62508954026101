import { isAfter } from "date-fns";
import { t } from "ttag";

import { MedicationRequestCard } from "@/components";
import { usePatientQuery } from "@/hooks";
import { Alert, CardPrimitive as Card, CardSkeleton, PillIcon } from "@/ui";
import { formatBackendDateToVerbose } from "@/utils";

export const CurrentMedicationRequest = () => {
  const { data: patient, isLoading } = usePatientQuery();

  const hasUpcomingThirdInjection =
    patient?.thirdInjectionDate &&
    isAfter(new Date(patient.thirdInjectionDate), new Date());

  if (hasUpcomingThirdInjection) {
    const thirdInjectionDate = formatBackendDateToVerbose(
      patient.thirdInjectionDate,
    );

    return (
      <Alert>
        {t`You will be able to order medication after your third injection date
  on ${thirdInjectionDate}`}
      </Alert>
    );
  }

  return (
    <Card.Root>
      <Card.Header>
        <Card.Title>
          <PillIcon className="size-4" />
          {t`Current medication requests`}
        </Card.Title>
        <Card.Description>
          {t`You wont be able to request medication until your current request has been delivered.`}
        </Card.Description>
      </Card.Header>

      <Card.Content>
        {isLoading ? (
          <CardSkeleton className="bg-salmon-01 px-2" />
        ) : (
          <MedicationRequestCard className="border-transparent" />
        )}
      </Card.Content>
    </Card.Root>
  );
};
