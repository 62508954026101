import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader, PasswordTips } from "@/components";
import { ROUTES } from "@/router";
import { getConfirmPasswordSchema } from "@/shared.schemas";
import type { PasswordFormValues } from "@/shared.types";
import { AltArrowRightIcon, Button, Form, PasswordInput } from "@/ui";
import { useSetEmbeddablesPassword } from "../hooks";

export const AccountPassword = () => {
  const { userCode } = useParams();

  const form = useForm<PasswordFormValues>({
    resolver: zodResolver(getConfirmPasswordSchema()),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const setPasswordMutation = useSetEmbeddablesPassword();

  if (!userCode) {
    return <Navigate replace to={ROUTES.LOGIN} />;
  }

  const onSubmit: SubmitHandler<PasswordFormValues> = (values) => {
    setPasswordMutation.mutate({
      ...values,
      token: userCode,
    });
  };

  return (
    <Form.Provider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex grow flex-col gap-8"
      >
        <div className="grid gap-x-8 gap-y-1.5 sm:grid-cols-2">
          <Form.Field
            control={form.control}
            name="password"
            render={({ field }) => (
              <Form.Item>
                <Form.Label>{t`Password *`}</Form.Label>
                <Form.Control>
                  <PasswordInput
                    {...field}
                    placeholder={t`Password`}
                    autoComplete="new-password"
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />

          <Form.Field
            control={form.control}
            name="passwordConfirmation"
            render={({ field }) => (
              <Form.Item>
                <Form.Label>{t`Confirm password *`}</Form.Label>
                <Form.Control>
                  <PasswordInput
                    {...field}
                    placeholder={t`Confirm password`}
                    autoComplete="new-password"
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />

          <div className="col-span-full">
            <PasswordTips />
          </div>
        </div>

        <div className="mt-auto flex justify-end">
          <Button
            disabled={setPasswordMutation.isPending}
            type="submit"
            size="lg"
            className="w-full sm:w-fit"
          >
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </form>

      {setPasswordMutation.isPending && <FullScreenLoader />}
    </Form.Provider>
  );
};
