import { isSameMonth } from "date-fns";

import { useScheduleConsultationContext } from "@/contexts";
import { useAvailableDaysQuery } from "@/hooks";
import type { ISODate, OnboardingUser } from "@/shared.types";
import { Calendar } from "@/ui";
import { parseBackendDate } from "@/utils";

interface AvailableDaysCalendarProps {
  userId?: OnboardingUser["id"];
}

export const AvailableDaysCalendar = ({
  userId,
}: AvailableDaysCalendarProps) => {
  const {
    scheduleDate: { appointmentDate },
    actions: { setScheduleDate },
  } = useScheduleConsultationContext();

  const { data, isFetching } = useAvailableDaysQuery(userId);

  const isLoading = isFetching && !data?.length;

  const handleOffsetDateChange = (offsetDate: ISODate) => {
    setScheduleDate((prev) => {
      const appointmentDate = prev.appointmentDate;

      const parsedOffsetDate = parseBackendDate(offsetDate);
      const parsedAppointmentDate = appointmentDate
        ? parseBackendDate(appointmentDate)
        : null;

      const isSameMonthAndYear =
        parsedAppointmentDate &&
        isSameMonth(parsedOffsetDate, parsedAppointmentDate);

      const updatedScheduleDate = {
        appointmentDate: isSameMonthAndYear ? appointmentDate : null,
        timeSlot: null,
        dateFromMonth: offsetDate,
      };

      return updatedScheduleDate;
    });
  };

  const handleOnAppointmentChange = (date: ISODate | null) =>
    setScheduleDate((prev) => ({
      ...prev,
      appointmentDate: date,
      timeSlot: null,
    }));

  return (
    <Calendar
      value={appointmentDate}
      onChange={handleOnAppointmentChange}
      onOffsetDateChange={handleOffsetDateChange}
      availableDays={data ?? []}
      isLoading={isLoading}
    />
  );
};
