import { Navigate, Route } from "react-router-dom";

import { BASE_ROUTE } from "@/router";
import { PortalRoutesWrapper } from "@/router/components/PortalRoutesWrapper";
import { MyMedsOverview } from "./screens";

export const MyMedsRouter = () => {
  return (
    <PortalRoutesWrapper>
      <Route index element={<MyMedsOverview />} />

      <Route path="*" element={<Navigate to={BASE_ROUTE.MY_MEDS} />} />
    </PortalRoutesWrapper>
  );
};
