import { t } from "ttag";

import { FIELD_TYPE } from "@/shared.constants";

export const getSymptomQuestions = () =>
  [
    {
      id: "q1",
      type: FIELD_TYPE.RADIO,
      question: t`Have you experienced any of the following since starting medication?`,
      answers: [
        {
          value: "chestPain",
          label: t`Chest pain, heart palpitations, or racing heart`,
        },
        {
          value: "seizures",
          label: t`Seizures`,
        },
        {
          value: "suicidalThoughts",
          label: t`Concerning and/or serious mood changes`,
          clarification: t`(or suicidal thoughts)`,
        },
        {
          value: "none",
          label: t`None of the above.`,
          unique: true,
        },
      ],
    },
    {
      id: "q2",
      type: FIELD_TYPE.CHECKBOX,
      question: t`Is there any specific medical circumstance affecting your health currently or in the past?`,
      answers: [
        {
          value: "possiblyPregnant",
          label: t`Current or possibly pregnant, or actively trying to become pregnant.`,
        },
        {
          value: "breastmilk",
          label: t`Breastfeeding or bottle feeding with breastmilk?`,
        },

        {
          value: "kidneyDisease",
          label: t`End-stage kidney disease?`,
          clarification: t`(on or about to be in dialysis)`,
        },
        {
          value: "liverDisease",
          label: t`End-stage liver disease?`,
          clarification: t`(cirrhosis)`,
        },
        {
          value: "eatingDisorder",
          label: t`Current or prior eating disorder?`,
          clarification: t`(anorexia or bulimia)`,
        },
        {
          value: "suicidalThoughts",
          label: t`Current suicidal thoughts and/or prior suicidal attempt?`,
        },
        {
          value: "cancer",
          label: t`Cancer?`,
          clarification: t`(active diagnosis or treatment)`,
        },
        {
          value: "organTransplant",
          label: t`History of organ transplant or anti rejection medication?`,
        },
        {
          value: "gastroparesis",
          label: t`Severe gastrointestinal condition?`,
          clarification: t`(gastroparesis, inflammatory bowel disease)`,
        },
        {
          value: "none",
          label: t`None of the above.`,
          unique: true,
        },
      ],
    },
  ] as const;

export type SymptomAnswerValue = ReturnType<
  typeof getSymptomQuestions
>[number]["answers"][number]["value"];
