import type {
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
} from "react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";

import { tw } from "@/utils";
import { Button } from "./Button";
import type { DialogProps } from "./common.types";
import { IconWrapper } from "./Icons";

interface AlertDialogProps extends Omit<DialogProps, "onClose"> {
  children?: ReactNode;
  onClose?: () => void;
  renderActions?: (props: {
    AlertDialogAction: ForwardRefExoticComponent<
      AlertDialogPrimitive.AlertDialogActionProps &
        RefAttributes<HTMLButtonElement>
    >;
    AlertDialogCancel: ForwardRefExoticComponent<
      AlertDialogPrimitive.AlertDialogCancelProps &
        RefAttributes<HTMLButtonElement>
    >;
  }) => JSX.Element;
}

export const AlertDialog = ({
  show,
  title,
  className,
  description,
  icon,
  children,
  renderActions,
  onClose,
}: AlertDialogProps) => (
  <AlertDialogPrimitive.Root open={show} onOpenChange={onClose}>
    <AlertDialogPrimitive.Portal>
      <AlertDialogPrimitive.Overlay className="fixed inset-0 z-50 bg-black/60 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <AlertDialogPrimitive.Content
        className={tw(
          "fixed left-1/2 top-1/2 z-50 flex max-h-[calc(100dvh-2rem)] w-[calc(100vw-2rem)] min-w-min max-w-modal -translate-x-1/2 -translate-y-1/2 flex-col gap-6 rounded-2xl bg-salmon-01 shadow-lg duration-200 focus:outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-2xl",
          "overflow-y-auto px-6 py-8 sm:px-8 sm:py-10",
          className,
        )}
      >
        <div className="flex flex-col items-center gap-2 text-center">
          {icon && (
            <IconWrapper className="size-16 sm:size-20">{icon}</IconWrapper>
          )}

          <AlertDialogPrimitive.Title className="font-serif text-2xl text-salmon-10 sm:text-3xl sm:leading-10">
            {title}
          </AlertDialogPrimitive.Title>

          <AlertDialogPrimitive.Description
            asChild={typeof description !== "string"}
            className="leading-4.5 text-brown-09 sm:font-medium"
          >
            {description}
          </AlertDialogPrimitive.Description>
        </div>

        {children}

        {renderActions ? (
          renderActions({
            AlertDialogAction: AlertDialogPrimitive.Action,
            AlertDialogCancel: AlertDialogPrimitive.Cancel,
          })
        ) : (
          <div className="flex items-center gap-4">
            <AlertDialogPrimitive.Cancel asChild>
              <Button variant="secondary">Cancel</Button>
            </AlertDialogPrimitive.Cancel>
            <AlertDialogPrimitive.Action asChild>
              <Button>Continue</Button>
            </AlertDialogPrimitive.Action>
          </div>
        )}
      </AlertDialogPrimitive.Content>
    </AlertDialogPrimitive.Portal>
  </AlertDialogPrimitive.Root>
);
