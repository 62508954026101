import type { QueryClient } from "@tanstack/react-query";

import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type { Plan, UserSubscription } from "../models";
import { billingQueriesStore } from "./queries";

export const getUserSubscription = async () => {
  const { data } =
    await privateAPI.get<ServiceResponse<UserSubscription>>("/patients/plans");

  return data.data;
};

export const acceptRequestedPlanChange = async (accepted: boolean) => {
  const { data } = await privateAPI.put<ServiceResponse<null>>(
    "/patients/plans/requested-plan-change",
    { accepted },
  );

  return data.data;
};

// TODO: change to async func
export const changeNextPeriodSubscription = {
  mutation: async (subscriptionPriceId: Plan["subscriptionPriceId"]) => {
    const { data } = await privateAPI.put<ServiceResponse<null>>(
      "/patients/plans",
      { subscriptionPriceId },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({
      queryKey: billingQueriesStore.stripe.userSubscription._def,
    });
  },
};
