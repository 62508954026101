import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getProviderDocumentsQuery } from "@/api";
import { UploadedDocument } from "@/components";
import { ScreenLayout } from "@/layouts";
import { PROVIDER_FOLDER } from "@/shared.constants";
import { EmptyState, UploadedFilesSkeleton } from "@/ui";
import { FridaysResourcesHeader } from "./components";

const FOLDER = PROVIDER_FOLDER.FRIDAYS_RESOURCES;

export const FridaysResources = () => {
  const { data, isLoading } = useQuery(
    getProviderDocumentsQuery({ providerFolder: FOLDER }),
  );

  return (
    <ScreenLayout>
      <FridaysResourcesHeader />

      {isLoading && <UploadedFilesSkeleton />}

      {!!data?.length && (
        <div className="flex flex-col gap-4">
          {data.map((file) => (
            <UploadedDocument key={file.id} folder={FOLDER} file={file} />
          ))}
        </div>
      )}

      {!data?.length && !isLoading && (
        <EmptyState.Root>
          <EmptyState.Icon />
          <EmptyState.Description>
            {t`No documents found in this folder.`}
          </EmptyState.Description>
        </EmptyState.Root>
      )}
    </ScreenLayout>
  );
};
