import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { Skeleton } from "@/ui";
import { tw } from "@/utils";

export const SubscriptionItemSkeleton = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={tw(
      "flex w-subscription-card flex-col overflow-hidden rounded-lg border border-transparent bg-white text-brown-08",
      className,
    )}
    {...props}
  >
    <Skeleton className="size-full h-60" />

    <div className="flex flex-col items-start gap-2 p-6">
      <Skeleton className="-mb-1 h-5 py-0.5 font-bold text-nature-09 md:h-4" />

      <div className="flex w-full justify-between gap-4">
        <Skeleton className="my-1 h-6 w-full max-w-48" />

        <Skeleton className="my-1 size-6" />
      </div>

      <Skeleton className="h-5 py-0.5 md:h-4" />

      <div className="flex items-end gap-2">
        <Skeleton className="my-1 h-10 w-16" /> /
        <Skeleton className="mb-1 h-4 w-14" />
      </div>

      <Skeleton className="h-4 -translate-y-2.5 py-0.5" />

      <div className="flex w-full grow flex-col gap-2">
        <Skeleton className="h-4 w-1/3 py-0.5" />
        <ul className="flex list-inside list-disc flex-col pl-1.5 text-left">
          {Array.from({ length: 5 }, (_, index) => `${index}`).map((i) => (
            <li key={`${i}-skeleton-li`}>
              <Skeleton className="t-0.5 inline-block h-4 w-1/2 translate-y-1" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
));
SubscriptionItemSkeleton.displayName = "SubscriptionRadioGroup.ItemSkeleton";
