import { useState } from "react";

import {
  RefillRequirementsAlert,
  SymptomsForm,
} from "@/domains/consultations/components";
import { ScreenLayout } from "@/layouts";
import {
  FilterForm,
  RefillFormHeader,
  RefillHealthieForm,
  RefillInjectionsForm,
} from "./sections";
import type { RefillFormStep } from "./utils";
import { getRefillFormHeaderDescription, REFILL_FORM_STEP } from "./utils";

export const ConsultationRefillForm = () => {
  const [step, setStep] = useState<RefillFormStep>(
    REFILL_FORM_STEP.SYMPTOMS_FORM,
  );

  return (
    <ScreenLayout variant="secondary" className="gap-0">
      <RefillFormHeader description={getRefillFormHeaderDescription(step)} />

      {step === REFILL_FORM_STEP.SYMPTOMS_FORM && (
        <SymptomsForm
          onSubmit={() => setStep(REFILL_FORM_STEP.INJECTIONS_FORM)}
        />
      )}

      {step === REFILL_FORM_STEP.INJECTIONS_FORM && (
        <RefillInjectionsForm
          onCancel={() => setStep(REFILL_FORM_STEP.SYMPTOMS_FORM)}
          onSubmit={() => setStep(REFILL_FORM_STEP.FILTER_FORM)}
        />
      )}

      {step === REFILL_FORM_STEP.FILTER_FORM && (
        <FilterForm
          onContinue={() => setStep(REFILL_FORM_STEP.HEALTHIE_FORM)}
        />
      )}

      {step === REFILL_FORM_STEP.HEALTHIE_FORM && (
        <div className="flex max-w-2xl flex-col gap-6">
          <RefillRequirementsAlert />

          <RefillHealthieForm
            onCancel={() => setStep(REFILL_FORM_STEP.FILTER_FORM)}
          />
        </div>
      )}
    </ScreenLayout>
  );
};
