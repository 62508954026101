import { Navigate, useParams } from "react-router-dom";

import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import { PROVIDER_FOLDER } from "@/shared.constants";
import type { CoachingSessionType } from "@/shared.types";
import { isEnumValue } from "@/utils";
import { PastSessionsHeader, SessionList } from "./components";

export const PastSessions = () => {
  const { sessionType } = useParams<{
    sessionType: CoachingSessionType;
  }>();

  if (!(sessionType && isEnumValue(sessionType, PROVIDER_FOLDER))) {
    return <Navigate to={ROUTES.COACHING_HUB.OVERVIEW} replace />;
  }

  return (
    <ScreenLayout variant="secondary" className="gap-3">
      <PastSessionsHeader />

      <SessionList sessionType={sessionType} />
    </ScreenLayout>
  );
};
