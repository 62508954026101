import { useScheduleConsultationContext } from "@/contexts";
import { getLanguageOptions } from "@/shared.options";
import type { AvailableLanguage } from "@/shared.types";
import { RadioGroup } from "@/ui";

export const ConsultationLanguagePreference = () => {
  const {
    language,
    actions: { setLanguage, setScheduleDate },
  } = useScheduleConsultationContext();

  return (
    <RadioGroup
      defaultValue={language}
      onChange={(lang) => {
        setLanguage(lang as AvailableLanguage);
        setScheduleDate((prev) => ({
          ...prev,
          appointmentDate: null,
          timeSlot: null,
        }));
      }}
      options={getLanguageOptions()}
      className="pl-6"
      orientation="horizontal"
    />
  );
};
