import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { getPortalUserQuery } from "@/api";
import { useAuthStore, usePortalUserStore } from "@/stores";

export const useSetPortalUser = () => {
  const setPortalUser = usePortalUserStore((state) => state.setPortalUser);
  const isLoggedIn = useAuthStore((state) => Boolean(state.token));

  const portalUserQuery = useQuery({
    ...getPortalUserQuery(),
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (portalUserQuery.data) {
      setPortalUser(portalUserQuery.data);
    }
  }, [portalUserQuery, setPortalUser]);

  return portalUserQuery;
};
