import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { tw } from "@/utils";
import { CheckboxIndicator } from "./CheckboxIndicator";
import type { BaseFieldProps } from "./form.types";
import { Label } from "./Label";
import { Message } from "./Message";

type CheckboxProps = ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
  Omit<BaseFieldProps, "id"> & { variant?: Variant; id?: string };

export const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    {
      className,
      compact = true,
      containerClassName,
      disabled,
      error,
      id,
      label,
      message,
      variant,
      ...rest
    }: CheckboxProps,
    ref,
  ) => {
    return (
      <div
        className={tw(
          "group flex grow flex-col gap-1.5 text-brown-09",
          containerClassName,
        )}
      >
        <div className="flex items-start gap-2.5">
          <CheckboxPrimitive.Root
            id={id}
            disabled={disabled}
            ref={ref}
            className={tw(
              "mt-0.5 rounded-[0.25rem] focus:outline-none focus:ring-1 focus:ring-nature-08 disabled:cursor-not-allowed disabled:opacity-50",
              variant === VARIANT.SECONDARY && "bg-transparent",
              className,
            )}
            {...rest}
          >
            <CheckboxIndicator variant={variant} size="sm" />
          </CheckboxPrimitive.Root>

          {!!label && (
            <Label htmlFor={id} size="sm" className="min-h-7 font-normal">
              {label}
            </Label>
          )}
        </div>

        {(!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";
