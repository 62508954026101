import type { ComponentPropsWithoutRef, ReactNode } from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { SIZE, VARIANT } from "@/shared.constants";
import type { Size, Variant } from "@/shared.types";
import { tw } from "@/utils";
import { icons, IconWrapper } from "./Icons";

export interface AvatarProps extends ComponentPropsWithoutRef<"span"> {
  size?: Size;
  defaultIcon?: ReactNode;
  imgURL?: string;
  name?: string;
  className?: string;
  variant?: Variant;
  onlyInitial?: boolean;
}

export const Avatar = ({
  className,
  defaultIcon,
  imgURL,
  name,
  size = SIZE.MEDIUM,
  variant = VARIANT.PRIMARY,
  onlyInitial = true,
  ...props
}: AvatarProps) => {
  return (
    <AvatarPrimitive.Root
      className={tw(
        "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
        size === SIZE.X_SMALL && "h-4 w-4",
        size === SIZE.SMALL && "h-8 w-8",
        size === SIZE.MEDIUM && "h-10 w-10",
        size === SIZE.LARGE && "h-12 w-12",
        className,
      )}
      {...props}
    >
      <AvatarPrimitive.Image
        className="aspect-square h-full w-full"
        src={imgURL}
      />
      <AvatarPrimitive.Fallback
        className={tw(
          "flex h-full w-full items-center justify-center rounded-full font-serif",
          variant === VARIANT.PRIMARY &&
            "bg-salmon-08 fill-salmon-01 text-salmon-01",
          variant === VARIANT.SECONDARY &&
            "bg-nature-02 fill-nature-09 text-nature-09",
          variant === VARIANT.TERTIARY &&
            "bg-nature-08 fill-salmon-01 text-salmon-01",
          size === SIZE.X_SMALL && "text-xs",
          size === SIZE.SMALL && "text-2xl",
          size === SIZE.MEDIUM && "text-3xl",
          size === SIZE.LARGE && "text-4xl",
        )}
      >
        {(onlyInitial ? name?.[0] : name) ?? defaultIcon ?? (
          <IconWrapper className="p-0.5">
            <icons.User />
          </IconWrapper>
        )}
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  );
};
