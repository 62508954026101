import { useQuery } from "@tanstack/react-query";

import { getPatientQuery } from "@/api";
import type { Patient } from "@/shared.types";
import { usePortalUserStore } from "@/stores";

export const usePatientQuery = <TSelect = Patient>(
  select?: (data: Patient) => TSelect,
) => {
  const hasPatientId = usePortalUserStore((state) =>
    Boolean(state.portalUser?.patientId),
  );

  return useQuery({ ...getPatientQuery(), enabled: hasPatientId, select });
};
