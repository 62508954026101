import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { jt, t } from "ttag";

import { Checkbox } from "@/ui";

const OPEN_LOOP_LINK = {
  PRIVACY_POLICY: "https://openloophealth.com/privacy-policy",
  TERMS_OF_USE: "https://openloophealth.com/terms-of-use",
  TELEHEALTH_CONSENT: "https://openloophealth.com/telehealth-consent",
} as const;

export const OpenLoopConsentCheckbox = forwardRef<
  ElementRef<typeof Checkbox>,
  ComponentPropsWithoutRef<typeof Checkbox>
>((props, ref) => {
  const privacyLink = (
    <Link
      key="privacyLink"
      to={OPEN_LOOP_LINK.PRIVACY_POLICY}
      className="font-bold underline"
    >
      {t`Privacy Policy`}
    </Link>
  );
  const termsAndConditionsLink = (
    <Link
      key="termsAndConditionsLink"
      to={OPEN_LOOP_LINK.TERMS_OF_USE}
      className="font-bold underline"
    >
      {t`Terms & Conditions`}
    </Link>
  );
  const telehealthConsentLink = (
    <Link
      key="telehealthConsentLink"
      to={OPEN_LOOP_LINK.TELEHEALTH_CONSENT}
      className="font-bold underline"
    >
      {t`Telehealth Consent`}
    </Link>
  );

  return (
    <Checkbox
      ref={ref}
      id="open-loop-consent"
      label={jt`I consent to OpenLoop Health's ${privacyLink}, ${termsAndConditionsLink}, and ${telehealthConsentLink}`}
      {...props}
    />
  );
});
OpenLoopConsentCheckbox.displayName = "OpenLoopConsentCheckbox";
