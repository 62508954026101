import type { Control } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { t } from "ttag";

import type { Measurements } from "@/domains/embeddables-onboarding/models";
import { Alert } from "@/ui";
import { calculateBMI } from "@/utils";

interface BMIScoreAlert {
  control: Control<Measurements>;
}

export const BMIScoreAlert = ({ control }: BMIScoreAlert) => {
  const measurements = useWatch({ control });

  const bmiValue = calculateBMI(measurements);

  return <Alert>{t`Your BMI Score is ${bmiValue}`}</Alert>;
};
