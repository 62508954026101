import type { AxiosError } from "axios";

import { authStore } from "@/stores";
import { privateAPI } from "../axios";
import { memoizedRefreshToken } from "./refreshToken";

export const errorResponse = async (error: AxiosError) => {
  const config = error?.config;

  if (error?.response?.status === 401) {
    const refreshWasSuccessful = await memoizedRefreshToken();
    if (refreshWasSuccessful) {
      const refreshedToken = authStore.getToken();

      return privateAPI({
        ...config,
        headers: { Authorization: `Bearer ${refreshedToken}` },
      });
    } else {
      authStore.setToken(null);
      window.location.href = "/login";
    }
  }
  return Promise.reject(error);
};
