import type { SVGProps } from "@/shared.types";

export const StethoscopeFixedIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 131 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <circle cx="76.5649" cy="53.936" r="53.936" fill="#BEAC9C" />
    <circle cx="80.5" cy="84" r="12" fill="#8B7B6C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 21.3125C52.7604 21.3125 54.1875 22.7396 54.1875 24.5V25.5686C54.6603 25.5757 55.0633 25.5912 55.4396 25.6244C63.1327 26.3047 69.231 32.403 69.9113 40.0961C69.9735 40.7993 69.9734 41.5959 69.9733 42.7659L69.9732 47.9643C69.9732 64.4087 57.461 77.9307 41.4375 79.5294V88.2503C41.4375 98.2259 49.5244 106.313 59.5 106.313H63.25C68.5632 106.313 73.2051 103.416 75.6743 99.1122C69.3609 96.9926 64.8125 91.0275 64.8125 84C64.8125 75.198 71.948 68.0625 80.75 68.0625C89.5521 68.0625 96.6875 75.198 96.6875 84C96.6875 92.2471 90.4235 99.0311 82.3937 99.8538C79.3016 107.383 71.8988 112.688 63.25 112.688H59.5C46.0036 112.688 35.0625 101.747 35.0625 88.2503V79.5352C18.3672 77.932 5.31252 63.8656 5.31252 46.75L5.31251 42.7661C5.31238 41.596 5.31229 40.7993 5.37447 40.0961C6.05477 32.403 12.153 26.3047 19.8462 25.6244C20.5078 25.5659 21.2522 25.5626 22.3125 25.5625V24.5C22.3125 22.7396 23.7396 21.3125 25.5 21.3125C27.2604 21.3125 28.6875 22.7396 28.6875 24.5V33C28.6875 34.7604 27.2604 36.1875 25.5 36.1875C23.7396 36.1875 22.3125 34.7604 22.3125 33V31.9376C21.193 31.9384 20.7567 31.9438 20.4077 31.9747C15.7918 32.3828 12.1329 36.0418 11.7247 40.6577C11.6906 41.0427 11.6875 41.5341 11.6875 42.9318V46.75C11.6875 61.4201 23.58 73.3125 38.25 73.3125C52.2495 73.3125 63.5982 61.9637 63.5982 47.9643V42.9318C63.5982 41.5341 63.5951 41.0427 63.5611 40.6577C63.1529 36.0418 59.4939 32.3828 54.8781 31.9747C54.6982 31.9588 54.4952 31.9496 54.1875 31.9444V33C54.1875 34.7604 52.7604 36.1875 51 36.1875C49.2396 36.1875 47.8125 34.7604 47.8125 33V24.5C47.8125 22.7396 49.2396 21.3125 51 21.3125ZM71.1875 84C71.1875 78.7188 75.4688 74.4375 80.75 74.4375C86.0312 74.4375 90.3125 78.7188 90.3125 84C90.3125 89.2812 86.0312 93.5625 80.75 93.5625C75.4688 93.5625 71.1875 89.2812 71.1875 84Z"
      fill="#3F352C"
    />
  </svg>
);

export const StethoscopeFixedIconMono = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <rect
      x="14.6191"
      y="1.98633"
      width="60"
      height="60"
      rx="30"
      transform="rotate(9.40703 14.6191 1.98633)"
      fill="#FFDFCD"
    />
    <circle cx="47" cy="53.5" r="6.5" fill="#E3B79E" />
    <path
      fillRule="evenodd"
      d="M29.0007 16.6777C30.0535 16.6777 30.9069 17.5312 30.9069 18.584V19.223C31.1896 19.2273 31.4307 19.2365 31.6557 19.2564C36.2565 19.6633 39.9035 23.3103 40.3103 27.9111C40.3475 28.3316 40.3475 28.808 40.3474 29.5077L40.3474 32.6165C40.3474 42.4509 32.8646 50.5376 23.2819 51.4937V56.7091C23.2819 62.675 28.1182 67.5112 34.084 67.5112H36.3266C39.5041 67.5112 42.2802 65.7788 43.7569 63.205C39.9812 61.9374 37.2611 58.37 37.2611 54.1673C37.2611 48.9034 41.5284 44.6361 46.7923 44.6361C52.0563 44.6361 56.3236 48.9034 56.3236 54.1673C56.3236 59.0994 52.5774 63.1565 47.7753 63.6485C45.9261 68.1511 41.4989 71.3237 36.3266 71.3237H34.084C26.0126 71.3237 19.4694 64.7805 19.4694 56.7091V51.4972C9.48498 50.5384 1.67775 42.1261 1.67775 31.8904L1.67774 29.5079C1.67766 28.8081 1.67761 28.3316 1.7148 27.9111C2.12164 23.3103 5.76865 19.6633 10.3694 19.2564C10.7651 19.2215 11.2103 19.2194 11.8444 19.2194V18.584C11.8444 17.5312 12.6979 16.6777 13.7507 16.6777C14.8035 16.6777 15.6569 17.5312 15.6569 18.584V23.6673C15.6569 24.7201 14.8035 25.5736 13.7507 25.5736C12.6979 25.5736 11.8444 24.7201 11.8444 23.6673V23.032C11.1749 23.0324 10.914 23.0357 10.7053 23.0541C7.94479 23.2982 5.75658 25.4864 5.51248 28.2469C5.49212 28.4772 5.49025 28.771 5.49025 29.6069V31.8904C5.49025 40.6636 12.6024 47.7758 21.3757 47.7758C29.7479 47.7758 36.5349 40.9887 36.5349 32.6165V29.6069C36.5349 28.771 36.533 28.4772 36.5127 28.2469C36.2686 25.4864 34.0804 23.2982 31.3199 23.0541C31.2123 23.0446 31.0909 23.0391 30.9069 23.036V23.6673C30.9069 24.7201 30.0535 25.5736 29.0007 25.5736C27.9479 25.5736 27.0944 24.7201 27.0944 23.6673V18.584C27.0944 17.5312 27.9479 16.6777 29.0007 16.6777ZM41.0736 54.1673C41.0736 51.0089 43.6339 48.4486 46.7923 48.4486C49.9507 48.4486 52.5111 51.0089 52.5111 54.1673C52.5111 57.3257 49.9507 59.8861 46.7923 59.8861C43.6339 59.8861 41.0736 57.3257 41.0736 54.1673Z"
      fill="#8C6955"
    />
  </svg>
);
