import { Fragment } from "react";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { tw } from "@/utils";
import { icons, IconWrapper } from "./Icons";

interface Step {
  value: string;
  label: string;
}

interface StepProgressBarProps {
  steps: readonly Step[];
  currentStep: Step["value"];
  variant?: Variant;
}

export const StepProgressBar = ({
  steps,
  currentStep,
  variant = VARIANT.PRIMARY,
}: StepProgressBarProps) => {
  const currentStepIndex = steps.findIndex((step) =>
    currentStep.includes(step.value),
  );

  return (
    <ol className="flex w-fit">
      {steps.map((step, index) => (
        <Fragment key={step.value}>
          <li className="flex max-w-24 flex-col items-center gap-1">
            <div
              id={`step-${index + 1}`}
              className={tw(
                "flex h-8 w-8 items-center justify-center rounded-full bg-brown-03 text-sm font-medium text-brown-08 sm:h-10 sm:w-10",
                variant === VARIANT.SECONDARY && "bg-nature-09 text-nature-06",
                currentStep.includes(step.value) && [
                  "bg-salmon-09 text-salmon-01",
                  variant === VARIANT.SECONDARY &&
                    "bg-nature-04 text-nature-09",
                ],
                currentStepIndex > index && [
                  variant === VARIANT.PRIMARY && "bg-salmon-07",
                ],
              )}
            >
              {currentStepIndex <= index ? (
                index + 1
              ) : (
                <IconWrapper size="sm">
                  <icons.CheckCircle
                    className={tw(
                      "stroke-salmon-02",
                      variant === VARIANT.SECONDARY && "stroke-nature-01",
                    )}
                  />
                </IconWrapper>
              )}
            </div>
            <label
              htmlFor={`step-${index + 1}`}
              className={tw(
                "max-w-20 text-center text-xs font-medium text-brown-05 sm:max-w-32",
                variant === VARIANT.SECONDARY && "text-nature-04",
                currentStep.includes(step.value) && [
                  "text-salmon-10",
                  variant === VARIANT.SECONDARY && "text-nature-01",
                ],
                currentStepIndex > index && [
                  variant === VARIANT.PRIMARY && "text-salmon-07",
                ],
              )}
            >
              {step.label}
            </label>
          </li>

          {index + 1 !== steps.length && (
            <li
              role="presentation"
              aria-hidden="true"
              className="w-5 pt-4.5 sm:w-16"
            >
              <icons.StepProgressBarSeparator
                className={tw(
                  "hidden stroke-brown-04 sm:block",
                  variant === VARIANT.SECONDARY && "stroke-nature-05",
                )}
              />
              <icons.StepProgressBarSeparatorSmall
                className={tw(
                  "stroke-brown-04 sm:hidden",
                  variant === VARIANT.SECONDARY && "stroke-nature-05",
                )}
              />
            </li>
          )}
        </Fragment>
      ))}
    </ol>
  );
};
