import { Link } from "react-router-dom";
import { t } from "ttag";

import { useSubscriptionStatus } from "@/hooks";
import { MODAL_ROUTES, ROUTES, useNavigateModal } from "@/router";
import { Button, FolderCheckFixedIcon, Shape7, Shape10 } from "@/ui";
import { DocumentCard } from ".";

export const DocumentCenterMedicalRecords = () => {
  const navigateModal = useNavigateModal();

  const { isActiveUser } = useSubscriptionStatus();

  return (
    <DocumentCard.Root>
      <DocumentCard.Banner>
        <DocumentCard.IconWrapper>
          <FolderCheckFixedIcon />
        </DocumentCard.IconWrapper>

        <div className="absolute -z-10 flex h-full w-full items-start justify-center gap-7 text-nature-04">
          <Shape7 className="w-64 translate-y-11 -scale-x-100 opacity-30" />
          <Shape10 className="w-64 -translate-y-4 self-end opacity-30" />
        </div>
      </DocumentCard.Banner>

      <DocumentCard.Content>
        <DocumentCard.Title>{t`Other Documents`}</DocumentCard.Title>
        <DocumentCard.Description>{t`Your dietitian notes, medical records and more.`}</DocumentCard.Description>
      </DocumentCard.Content>

      <DocumentCard.Actions>
        <Button asChild variant="secondary" size="lg">
          <Link
            to={ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS}
          >{t`View details`}</Link>
        </Button>

        {isActiveUser && (
          <Button
            onClick={() =>
              navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS)
            }
            size="lg"
          >
            {t`Upload medical records`}
          </Button>
        )}
      </DocumentCard.Actions>
    </DocumentCard.Root>
  );
};
