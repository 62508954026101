import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import type { Question } from "@/shared.types";
import { calculateBMI } from "@/utils";
import { useBMIInitialQuestion } from "./useBMIInitialQuestion";
import { useBMIQuestion } from "./useBMIQuestion";

export const useQuestionFetcher = (nextQuestionId?: Question["id"]) => {
  const { measurementsFormValues } = useEmbeddablesForms();
  const bmi = calculateBMI(measurementsFormValues);

  const { data: initialQuestion, isLoading: isLoadingInitialQuestion } =
    useBMIInitialQuestion(bmi);
  const { data: nextQuestion, isLoading: isLoadingNextQuestion } =
    useBMIQuestion(nextQuestionId);

  const isLoadingQuestion = isLoadingInitialQuestion || isLoadingNextQuestion;

  const question = nextQuestion ?? initialQuestion;

  return {
    question,
    isLoadingQuestion,
    bmi,
  };
};
