import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { DIRECTION, VARIANT } from "@/shared.constants";
import { Button, illustratedIcons, shapes } from "@/ui";
import { tw } from "@/utils";

interface FullScreenAlertProps {
  title: string;
  description: string;
  customIcon?: JSX.Element;
  customShapes?: JSX.Element;
  customAction?: JSX.Element;
  className?: string;
  subtext?: string;
}

export const FullScreenAlert = ({
  title,
  description,
  customIcon,
  customShapes,
  customAction,
  className,
  subtext,
}: FullScreenAlertProps) => (
  <div className="fixed left-0 top-0 z-50">
    <div
      className={tw(
        "flex h-dvh w-screen items-center justify-center overflow-hidden bg-salmon-02",
        className,
      )}
    >
      <div className="flex h-full w-desktop overflow-y-auto overflow-x-hidden sm:items-center">
        <div className="fixed hidden h-desktop w-desktop justify-end sm:flex">
          <div className="relative w-full max-w-xl shrink-0">
            <div className="absolute top-1/2 h-dvh w-screen -translate-y-1/2 bg-salmon-09" />
            {customShapes ?? (
              <>
                <shapes.PartialDoughnut
                  direction={DIRECTION.BOTTOM}
                  shapeColor={VARIANT.SECONDARY}
                  className="absolute left-16 top-48 scale-[2.64]"
                />
                <shapes.PartialDoughnut2
                  shapeVariant={VARIANT.QUATERNARY}
                  direction={DIRECTION.RIGHT}
                  shapeColor={VARIANT.SECONDARY}
                  className="absolute bottom-0 right-16 scale-x-[2.64] scale-y-[-2.64]"
                />
              </>
            )}
          </div>
        </div>
        <div className="z-10 flex max-w-4xl flex-col gap-6 px-6 pt-12 text-salmon-10 sm:gap-8 sm:p-20 sm:pb-0">
          <header className="relative flex flex-col items-center gap-2 pb-16 text-salmon-01 sm:items-start sm:gap-0 sm:pb-0 sm:text-inherit">
            {customIcon ?? (
              <illustratedIcons.HeartsCircle className="h-16 w-16" />
            )}
            <h1 className="text-center font-serif text-4xl leading-snug sm:text-left sm:text-6xl sm:leading-snug">
              {title}
            </h1>

            <div className="absolute bottom-0 -z-10 h-219 w-219 rounded-full bg-salmon-09 sm:hidden" />
          </header>

          <p className="max-h-72 grow text-xl sm:max-h-fit sm:grow-0">
            {description}
          </p>
          {subtext && (
            <p className="max-h-72 grow text-xl sm:max-h-fit sm:grow-0">
              {subtext}
            </p>
          )}

          <div className="pb-10 sm:pb-20">
            {customAction ?? (
              <Button asChild size="lg" className="w-full sm:w-fit">
                <Link to={ROUTES.BASE}>{t`Back to home`}</Link>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
