import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { t } from "ttag";

import { setPassword } from "@/api";
import { OnboardingHeader, PasswordTips } from "@/components";
import { OnboardingLayout } from "@/domains/onboarding/components";
import { getConfirmPasswordSchema } from "@/shared.schemas";
import type { PasswordFormValues } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { Button, errorToast, icons, IconWrapper, Input } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

interface HealthiePasswordFormProps {
  onSubmit: () => void;
}
export const HealthiePasswordForm = ({
  onSubmit,
}: HealthiePasswordFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const user = useOnboardingStore((state) => state.user);
  const healthieUserToken = useOnboardingStore(
    (state) => state.healthieUserToken,
  );
  const setPatientId = useOnboardingStore((state) => state.setPatientId);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<PasswordFormValues>({
    resolver: zodResolver(getConfirmPasswordSchema()),
  });

  const { mutate: resetPasswordMutation, isPending } = useMutation({
    mutationFn: setPassword.mutation,
    onSuccess: (data) => {
      setPatientId(data.patientId);
      onSubmit();
    },
    onError: (error) => {
      handleAxiosFieldErrors(error, setError);
      errorToast(error);
    },
  });

  return (
    <OnboardingLayout>
      <div className="flex w-full max-w-xl flex-col gap-8 pb-10 sm:pb-20">
        <OnboardingHeader
          title={t`Create your password`}
          description={
            <p className="text-lg font-medium text-brown-08">
              {t`You are creating an account for the email`}
              <br />
              {user?.email}
            </p>
          }
        />

        <form
          onSubmit={handleSubmit((values) =>
            resetPasswordMutation({ ...values, token: healthieUserToken }),
          )}
          className="flex flex-col gap-1.5"
        >
          <Input
            readOnly
            id="email"
            type="text"
            name="email"
            value={user?.email}
            autoComplete="email"
            className="hidden"
          />
          <Input
            compact={false}
            id="password"
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
            label={t`Password *`}
            placeholder={t`Password`}
            left={<icons.LockOpen />}
            right={
              showPassword ? (
                <icons.OpenedEye
                  onClick={() => setShowPassword(false)}
                  className="cursor-pointer"
                />
              ) : (
                <icons.ClosedEye
                  onClick={() => setShowPassword(true)}
                  className="cursor-pointer"
                />
              )
            }
            {...register("password")}
            error={errors.password?.message}
          />
          <Input
            compact={false}
            id="password-confirmation"
            autoComplete="new-password"
            type={showPasswordConfirmation ? "text" : "password"}
            label={t`Confirm password *`}
            placeholder={t`Confirm password`}
            left={<icons.LockOpen />}
            right={
              showPasswordConfirmation ? (
                <icons.OpenedEye
                  onClick={() => setShowPasswordConfirmation(false)}
                  className="cursor-pointer"
                />
              ) : (
                <icons.ClosedEye
                  onClick={() => setShowPasswordConfirmation(true)}
                  className="cursor-pointer"
                />
              )
            }
            {...register("passwordConfirmation")}
            error={errors.passwordConfirmation?.message}
          />

          <PasswordTips />

          <div className="flex items-center justify-end pt-7">
            <Button disabled={isPending} type="submit" size="lg">
              {t`Next`}
              <IconWrapper size="sm">
                <icons.ChevronRight />
              </IconWrapper>
            </Button>
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};
