import type { FieldArrayWithId, UseFieldArrayAppend } from "react-hook-form";

import type { OnboardingImageUploadsValues } from "@/shared.types";
import { isSameFile } from "@/utils";

export const handleLocalUploads = (
  files: File[],
  fieldArray: FieldArrayWithId<
    OnboardingImageUploadsValues,
    "uploadedFiles.labs" | "uploadedFiles.other"
  >[],
  append: UseFieldArrayAppend<
    OnboardingImageUploadsValues,
    "uploadedFiles.labs" | "uploadedFiles.other"
  >,
) => {
  for (const file of files) {
    if (
      !fieldArray.some((field) => field.file && isSameFile(field.file, file))
    ) {
      append({ file, name: file.name, id: "" });
    }
  }
};
