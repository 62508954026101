import type { SVGProps } from "@/shared.types";

export const UserIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.00041 0.833984C6.25151 0.833984 4.83375 2.25175 4.83375 4.00065C4.83375 5.74955 6.25151 7.16732 8.00041 7.16732C9.74932 7.16732 11.1671 5.74955 11.1671 4.00065C11.1671 2.25175 9.74932 0.833984 8.00041 0.833984ZM5.83375 4.00065C5.83375 2.80403 6.8038 1.83398 8.00041 1.83398C9.19703 1.83398 10.1671 2.80403 10.1671 4.00065C10.1671 5.19727 9.19703 6.16732 8.00041 6.16732C6.8038 6.16732 5.83375 5.19727 5.83375 4.00065Z"
    />
    <path
      fillRule="evenodd"
      d="M8.00041 8.16732C6.45805 8.16732 5.03703 8.51791 3.98405 9.11021C2.94674 9.6937 2.16708 10.578 2.16708 11.6673L2.16704 11.7353C2.16628 12.5099 2.16534 13.482 3.01802 14.1763C3.43767 14.5181 4.02473 14.7611 4.81789 14.9216C5.61326 15.0826 6.6499 15.1673 8.00041 15.1673C9.35093 15.1673 10.3876 15.0826 11.1829 14.9216C11.9761 14.7611 12.5632 14.5181 12.9828 14.1763C13.8355 13.482 13.8345 12.5099 13.8338 11.7353L13.8337 11.6673C13.8337 10.578 13.0541 9.6937 12.0168 9.11021C10.9638 8.51791 9.54278 8.16732 8.00041 8.16732ZM3.16708 11.6673C3.16708 11.0998 3.58133 10.4841 4.47431 9.98178C5.35161 9.4883 6.59726 9.16732 8.00041 9.16732C9.40357 9.16732 10.6492 9.4883 11.5265 9.98178C12.4195 10.4841 12.8337 11.0998 12.8337 11.6673C12.8337 12.5392 12.8069 13.03 12.3514 13.4009C12.1043 13.6021 11.6914 13.7984 10.9846 13.9415C10.2799 14.0841 9.31657 14.1673 8.00041 14.1673C6.68426 14.1673 5.72091 14.0841 5.01628 13.9415C4.30943 13.7984 3.89649 13.6021 3.64947 13.4009C3.19396 13.03 3.16708 12.5392 3.16708 11.6673Z"
    />
  </svg>
);
