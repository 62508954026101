import { CardSkeleton } from "@/ui";

export const PurposeOptionsSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2">
      <CardSkeleton className="h-40" />
      <CardSkeleton className="h-40" />
    </div>
  );
};
