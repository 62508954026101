import { zodResolver } from "@hookform/resolvers/zod";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { t } from "ttag";

import { editWeightEntry } from "@/api";
import { FullScreenLoader } from "@/components";
import { ROUTES } from "@/router";
import { MAX_NOTE_CHARACTERS } from "@/shared.constants";
import { getWeightEntrySchema } from "@/shared.schemas";
import type { ModalProps, WeightEntry } from "@/shared.types";
import {
  Button,
  Dialog,
  errorToast,
  Form,
  GraphIconMono,
  Input,
  TextArea,
  useToastStore,
} from "@/ui";
import { formatBackendDate, handleAxiosFieldErrors } from "@/utils";
import type { LocationState } from "./progress-tracker.types";

export const EditWeightEntry = ({ show, onClose }: ModalProps) => {
  const { pushToast } = useToastStore();

  const { entryId } = useParams();
  const { weightEntry } = useLocation().state as LocationState;

  const form = useForm<WeightEntry>({
    resolver: zodResolver(getWeightEntrySchema()),
    defaultValues: weightEntry,
  });

  const queryClient = useQueryClient();
  const { mutate: editWeightEntryMutation, isPending } = useMutation({
    mutationFn: editWeightEntry.mutation,
    onSuccess: () => {
      onClose();
      editWeightEntry.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Weight entry successfully updated!`,
      });
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, form.setError);
    },
  });

  if (entryId !== weightEntry?.id) {
    return <Navigate to={ROUTES.HOME} />;
  }

  const updateDisabled = !form.formState.isDirty || isPending;

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal">
        <div className="flex size-full flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <GraphIconMono />
            </Dialog.Icon>
            <Dialog.Title>{t`Edit entry`}</Dialog.Title>

            <VisuallyHidden asChild>
              <Dialog.Description>
                {t`This modal allows you to update an existing weight entry. Modify the weight value, date, or note as needed.`}
              </Dialog.Description>
            </VisuallyHidden>
          </Dialog.Header>

          <Form.Provider {...form}>
            <form
              onSubmit={form.handleSubmit((values) =>
                editWeightEntryMutation(values),
              )}
              id="edit-entry-form"
              className="grow"
            >
              <Input
                disabled
                readOnly
                label={t`Date *`}
                id="date"
                value={formatBackendDate(form.getValues("date"))}
                compact={false}
              />

              <Input
                label={t`Weight (lbs) *`}
                placeholder={t`Your weight`}
                id="weight"
                type="number"
                step=".01"
                {...form.register("weight", { setValueAs: Number })}
                error={form.formState.errors.weight?.message}
                compact={false}
              />

              <Form.Field
                control={form.control}
                name="note"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`Note (optional)`}</Form.Label>
                    <Form.Control>
                      <TextArea
                        placeholder={t`Optional note`}
                        rows={4}
                        {...field}
                      />
                    </Form.Control>
                    <div className="-mt-2 flex h-fit justify-between gap-2">
                      <Form.Message compact={false} className="mt-0" />
                      <Form.CharCounter max={MAX_NOTE_CHARACTERS} />
                    </div>
                  </Form.Item>
                )}
              />
            </form>
          </Form.Provider>

          <Dialog.Footer variant="sticky">
            <Button
              onClick={onClose}
              variant="secondary"
              size="lg"
            >{t`Cancel`}</Button>
            <Button
              disabled={updateDisabled}
              type="submit"
              form="edit-entry-form"
              size="lg"
            >{t`Save changes`}</Button>
          </Dialog.Footer>
        </div>

        {isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
