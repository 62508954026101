import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { useBoolean } from "@/hooks";
import { EyeClosedIcon, EyeIcon } from "../icons";
import { LockPasswordUnlockedIcon } from "../icons/security/LockIcon";
import { Input } from "./Input";

export const PasswordInput = forwardRef<
  ElementRef<typeof Input>,
  ComponentPropsWithoutRef<typeof Input>
>((props, ref) => {
  const {
    value: passwordVisible,
    setTrue: showPassword,
    setFalse: hidePassword,
  } = useBoolean(false);

  return (
    <Input
      ref={ref}
      type={passwordVisible ? "text" : "password"}
      left={<LockPasswordUnlockedIcon />}
      right={
        passwordVisible ? (
          <EyeIcon onClick={hidePassword} className="cursor-pointer" />
        ) : (
          <EyeClosedIcon onClick={showPassword} className="cursor-pointer" />
        )
      }
      {...props}
    />
  );
});
PasswordInput.displayName = "PasswordInput";
