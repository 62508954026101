import type { ServiceResponse } from "@/api";
import { publicAPI } from "@/api";
import type {
  OnboardingPatientFormValues,
  OnboardingUser,
} from "@/shared.types";

interface createOnboardingParams {
  values: OnboardingPatientFormValues;
  id: number;
}

export const createOnboardingUser = {
  mutation: async ({ values, id }: createOnboardingParams) => {
    const { emailConfirmation: _, ...restParams } = values;

    const { data } = await publicAPI.put<ServiceResponse<OnboardingUser>>(
      `/users/${id}`,
      restParams,
    );

    return data.data;
  },
};
