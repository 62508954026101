import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { t } from "ttag";

import { AnswerCheckboxGroup } from "@/components";
import { AnswerRadioGroup } from "@/components/AnswerRadioGroup";
import { FIELD_TYPE } from "@/shared.constants";
import type { DomainRoute } from "@/shared.types";
import { BasicTooltip, Button, icons, IconWrapper, Label } from "@/ui";
import { getMedicationRequestQuestions } from "./questions";

const isVisible = (currentPath: DomainRoute, routes?: DomainRoute[]) =>
  !routes || routes.includes(currentPath);

export const MedicationRequestFilter = ({
  onBack,
  onNext,
}: {
  onBack: () => void;
  onNext: (isDisqualified: boolean) => void;
}) => {
  const [selectedValue, setSelectedValue] = useState<
    Record<string, string | string[]>
  >({});
  const { pathname: currentPath } = useLocation();

  const questions = useMemo(
    () =>
      getMedicationRequestQuestions().filter((question) =>
        isVisible(currentPath as DomainRoute, question.onlyVisibleIn),
      ),
    [currentPath],
  );

  const continueDisabled = questions.some(
    (question) => !Object.keys(selectedValue).includes(question.id),
  );

  const handleNext = () => {
    const isDisqualified = Object.keys(selectedValue).some((questionId) => {
      const question = questions.find((q) => q.id === questionId);
      const selectedAnswers = question?.answers.filter((answer) =>
        selectedValue[question.id]?.includes(answer.value),
      );
      return selectedAnswers?.some((answer) => answer.disqualifier);
    });

    onNext(isDisqualified);
  };

  return (
    <div className="flex flex-col gap-6">
      {questions.map((question) => (
        <div key={question.id} className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <Label size="lg" htmlFor={question.id} className="text-salmon-10">
              {question.question}
            </Label>
            {isVisible(
              currentPath as DomainRoute,
              question.clarificationOnlyVisibleIn,
            ) &&
              question.clarification && (
                <Label
                  size="md"
                  htmlFor={question.id}
                  className="text-salmon-08"
                >
                  {question.clarification}
                </Label>
              )}
          </div>

          {question.type === FIELD_TYPE.RADIO && (
            <AnswerRadioGroup
              id={question.id}
              onValueChange={(value) =>
                setSelectedValue({ ...selectedValue, [question.id]: value })
              }
              options={question.answers}
              warning={
                <BasicTooltip
                  triggerContent={
                    <IconWrapper className="fill-salmon-01 p-1.5">
                      <icons.VideoCamera />
                    </IconWrapper>
                  }
                  content={t`This answer divert to video consultation`}
                />
              }
            />
          )}

          {question.type === FIELD_TYPE.CHECKBOX && (
            <AnswerCheckboxGroup
              id={question.id}
              options={question.answers}
              value={selectedValue[question.id] as string[]}
              onChange={(value) =>
                setSelectedValue({
                  ...selectedValue,
                  [question.id]: value,
                })
              }
            />
          )}
        </div>
      ))}

      <div className="flex items-center justify-between gap-4 pb-10 pt-6 sm:pb-20">
        <Button onClick={onBack} variant="secondary" size="lg">
          <IconWrapper size="sm">
            <icons.ChevronLeft />
          </IconWrapper>
          {t`Back`}
        </Button>
        <Button
          onClick={handleNext}
          type="submit"
          size="lg"
          disabled={continueDisabled}
        >
          {t`Next`}
          <IconWrapper size="sm">
            <icons.ChevronRight />
          </IconWrapper>
        </Button>
      </div>
    </div>
  );
};
