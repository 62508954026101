import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { Button, Dialog, HeartsCircleFixedIconMono } from "@/ui";

export const ReactivateSubscriptionModal = ({ show, onClose }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal" className="h-fit">
        <div className="flex flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <HeartsCircleFixedIconMono />
            </Dialog.Icon>
            <Dialog.Title>{t`Welcome back!`}</Dialog.Title>

            <Dialog.Description>
              {t`We're thrilled to have you with us again. To reactivate your account, we'll redirect you to a chat with Customer Support from the Messages tab.`}
            </Dialog.Description>
          </Dialog.Header>

          <Dialog.Footer>
            <Button variant="secondary" onClick={onClose} size="lg">
              {t`Go back`}
            </Button>
            <Button
              onClick={() => navigate(ROUTES.MESSAGES)}
              size="lg"
            >{t`Go to Messages`}</Button>
          </Dialog.Footer>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
