import { t } from "ttag";

import { Alert, Label } from "@/ui";
import { StepCard } from ".";

export const Step3 = () => (
  <div className="flex flex-col gap-2">
    <Label htmlFor="step-3-checklist" size="md">{t`Third step:`}</Label>

    <StepCard.Root id="step-3-checklist">
      <StepCard.Title>{t`💊 Continuing Your Current GLP-1 Medication`}</StepCard.Title>

      <StepCard.Content>
        <p>{t`If you're switching to Fridays and want to continue your current GLP-1 medication at a higher dose, please upload a clear image of your current prescription label, ensuring it shows:`}</p>
        <ul className="list-inside list-disc pl-2">
          <li>{t`Your name`}</li>
          <li>{t`Date of birth`}</li>
          <li>{t`Medication`}</li>
          <li>{t`Dose`}</li>
          <li>{t`Date the Rx was filled`}</li>
        </ul>
      </StepCard.Content>

      <Alert className="bg-transparent">
        {t`Important: Your last dose must be within 2 weeks of receiving your new medication from us.`}
      </Alert>
    </StepCard.Root>
  </div>
);
