import { FIELD_TYPE } from "@/shared.constants";
import type { HealthieField } from "@/shared.types";
import { InfoCircleIcon, Label } from "@/ui";

interface ReadOnlyFieldProps {
  healthieField: HealthieField;
}

export const ReadOnlyField = ({ healthieField }: ReadOnlyFieldProps) => {
  switch (healthieField.type) {
    case FIELD_TYPE.READ_ONLY:
      return (
        <div className="flex gap-2 p-0.5">
          <InfoCircleIcon className="mt-1 size-4 shrink-0" />

          <div className="text-nature-10">
            <Label htmlFor={healthieField.id} className="font-bold">
              {healthieField.label}
            </Label>
            <div id={healthieField.id}>
              {healthieField.options.map((text) => text)}
            </div>
          </div>
        </div>
      );

    // TODO: Add field when added in the BE
    case FIELD_TYPE.READ_ONLY_LINK:
      return null;
  }
};
