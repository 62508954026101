import type { SVGProps } from "@/shared.types";

export const HeartIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M5.62436 4.4242C3.96537 5.18252 2.75 6.98623 2.75 9.1371C2.75 11.3345 3.64922 13.0282 4.93829 14.4798C6.00072 15.6761 7.28684 16.6676 8.54113 17.6346C8.83904 17.8643 9.13515 18.0926 9.42605 18.3219C9.95208 18.7366 10.4213 19.1005 10.8736 19.3648C11.3261 19.6293 11.6904 19.75 12 19.75C12.3096 19.75 12.6739 19.6293 13.1264 19.3648C13.5787 19.1005 14.0479 18.7366 14.574 18.3219C14.8649 18.0926 15.161 17.8643 15.4589 17.6346C16.7132 16.6676 17.9993 15.6761 19.0617 14.4798C20.3508 13.0282 21.25 11.3345 21.25 9.1371C21.25 6.98623 20.0346 5.18252 18.3756 4.4242C16.7639 3.68748 14.5983 3.88258 12.5404 6.02074C12.399 6.16763 12.2039 6.25064 12 6.25064C11.7961 6.25064 11.601 6.16763 11.4596 6.02074C9.40166 3.88258 7.23607 3.68748 5.62436 4.4242ZM12 4.45882C9.68795 2.39024 7.09896 2.10087 5.00076 3.05996C2.78471 4.07293 1.25 6.42503 1.25 9.1371C1.25 11.8026 2.3605 13.8361 3.81672 15.4758C4.98287 16.7889 6.41022 17.888 7.67083 18.8586C7.95659 19.0786 8.23378 19.292 8.49742 19.4999C9.00965 19.9037 9.55954 20.3343 10.1168 20.6599C10.6739 20.9854 11.3096 21.25 12 21.25C12.6904 21.25 13.3261 20.9854 13.8832 20.6599C14.4405 20.3343 14.9903 19.9037 15.5026 19.4999C15.7662 19.292 16.0434 19.0786 16.3292 18.8586C17.5898 17.888 19.0171 16.7889 20.1833 15.4758C21.6395 13.8361 22.75 11.8026 22.75 9.1371C22.75 6.42503 21.2153 4.07293 18.9992 3.05996C16.901 2.10087 14.3121 2.39024 12 4.45882Z"
    />
  </svg>
);
