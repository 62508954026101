import type { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

interface CoachingOnboardingContextProps {
  subscriptionPriceId: string;

  actions: {
    setSubscriptionPriceId: Dispatch<SetStateAction<string>>;
  };
}

export const CoachingOnboardingContext =
  createContext<CoachingOnboardingContextProps | null>(null);

export const CoachingOnboardingContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [subscriptionPriceId, setSubscriptionPriceId] = useState("");

  return (
    <CoachingOnboardingContext.Provider
      value={{ subscriptionPriceId, actions: { setSubscriptionPriceId } }}
    >
      {children}
    </CoachingOnboardingContext.Provider>
  );
};

export const useCoachingOnboardingContext = () => {
  const context = useContext(CoachingOnboardingContext);

  if (!context) {
    throw new Error(
      "useCoachingOnboardingContext must be used within a <CoachingOnboardingContextProvider />",
    );
  }

  return context;
};
