import { useEffect } from "react";
import { useShallow } from "zustand/shallow";

import { SubscriptionRadioGroup } from "@/components";
import { useOnboardingPatientPlans } from "@/domains/billing/hooks/useOnboardingPatientPlans";
import { PLAN_DURATION } from "@/domains/billing/models";
import type { Plan } from "@/domains/billing/models";
import { env } from "@/env";
import { useOnboardingStore } from "@/stores";

export const OnboardingPatientPlans = ({
  durationInMonths,
}: Pick<Plan, "durationInMonths">) => {
  const [selectedPlan, setSelectedPlan] = useOnboardingStore(
    useShallow((state) => [state.subscriptionPlan, state.setSubscriptionPlan]),
  );
  const couponCode = useOnboardingStore((state) => state.limitedCouponCode);

  const {
    data: onboardingPlans,
    isSuccess,
    isLoading,
  } = useOnboardingPatientPlans({
    durationInMonths,
    couponCode,
  });

  const handleValueChange = (
    subscriptionPriceId: Plan["subscriptionPriceId"],
  ) => {
    const updatedPlan = onboardingPlans?.find(
      (plan) => plan.subscriptionPriceId === subscriptionPriceId,
    );

    if (updatedPlan) {
      setSelectedPlan(updatedPlan);
    }
  };

  useEffect(() => {
    const hasNoPlanSelected = !selectedPlan && onboardingPlans;

    if (hasNoPlanSelected) {
      switch (durationInMonths) {
        case PLAN_DURATION.MONTHLY.VALUE:
          return handleValueChange(
            env.VITE_STRIPE_SEMAGLUTIDE_MONTHLY_PRICE_ID,
          );
        case PLAN_DURATION.QUARTERLY.VALUE:
          return handleValueChange(
            env.VITE_STRIPE_SEMAGLUTIDE_QUARTERLY_PRICE_ID,
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingPlans]);

  useEffect(() => {
    if (selectedPlan && onboardingPlans) {
      const updatedSelectedPlan =
        onboardingPlans.find(
          (plan) =>
            plan.subscriptionPriceId === selectedPlan.subscriptionPriceId,
        ) ?? null;

      setSelectedPlan(updatedSelectedPlan);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SubscriptionRadioGroup.Root
      value={selectedPlan?.subscriptionPriceId}
      onValueChange={handleValueChange}
    >
      {isLoading &&
        Array.from({ length: 3 }, (_, index) => `${index}`).map((i) => (
          <SubscriptionRadioGroup.ItemSkeleton
            key={`subscription-card-skeleton-${i}`}
          />
        ))}

      {isSuccess &&
        onboardingPlans.map((plan) => (
          <SubscriptionRadioGroup.Item
            key={plan.subscriptionPriceId}
            value={plan.subscriptionPriceId}
            plan={plan}
          />
        ))}
    </SubscriptionRadioGroup.Root>
  );
};
