import type { MedicationRequest } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const MEDICATIONS_DOMAIN = "medications";

export const getCurrentMedicationRequestQuery = () => ({
  queryKey: [MEDICATIONS_DOMAIN, "getCurrentMedicationRequestQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<MedicationRequest>>(
      "/patients/current-medication-request",
    );

    return data.data;
  },
});
