import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import type { ChangeSubscription } from "@/domains/billing/models";
import { getChangeSubscriptionSchema } from "@/domains/billing/models";

export const useChangeSubscriptionForm = () => {
  return useForm<ChangeSubscription>({
    resolver: zodResolver(getChangeSubscriptionSchema()),
  });
};
