import { useState } from "react";
import { Link } from "react-router-dom";
import { t } from "ttag";

import { FullScreenAlert } from "@/components";
import { ROUTES } from "@/router";
import { useOnboardingStore } from "@/stores";
import { Button, illustratedIcons } from "@/ui";
import { IntakeFormCover } from "./Cover";
import { HealthieFormSection } from "./HealthieForm";
import { ImageUploads } from "./ImageUploads";

const STEP = {
  COVER: "cover",
  FORM: "form",
  IMAGE_UPLOADS: "image-uploads",
  SUCCESS_SCREEN: "success-screen",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const IntakeForm = () => {
  const [step, setStep] = useState<Step>(STEP.COVER);
  const clearAll = useOnboardingStore((state) => state.clearAll);

  return (
    <div>
      {step === STEP.COVER && (
        <IntakeFormCover onContinue={() => setStep(STEP.FORM)} />
      )}

      {step === STEP.FORM && (
        <HealthieFormSection onSubmit={() => setStep(STEP.IMAGE_UPLOADS)} />
      )}

      {step === STEP.IMAGE_UPLOADS && (
        <ImageUploads
          onGoBack={() => setStep(STEP.FORM)}
          onNext={() => setStep(STEP.SUCCESS_SCREEN)}
        />
      )}

      {step === STEP.SUCCESS_SCREEN && (
        <FullScreenAlert
          customIcon={<illustratedIcons.Success />}
          title={t`You're officially part of the Fridays Crew!`}
          description={t`Congratulations! You are now part of Fridays. We're excited to join you on this wellness journey. Let's make every day feel like a Friday! 🎉`}
          customAction={
            <Button asChild size="lg" className="w-full sm:w-fit">
              <Link to={ROUTES.LOGIN} onClick={clearAll}>{t`Log in`}</Link>
            </Button>
          }
        />
      )}
    </div>
  );
};
