import { env } from "@/env";
import { AVAILABLE_LANGUAGE, HEALTHIE_FORM_TYPE } from "@/shared.constants";
import type { HealthieFormType } from "@/shared.types";
import { useAppSettingsStore } from "@/stores";

export const useFormId = (formType: HealthieFormType) => {
  const isSpanishSelected = useAppSettingsStore(
    (state) => state.language === AVAILABLE_LANGUAGE.ES,
  );

  const formId = {
    [HEALTHIE_FORM_TYPE.FOLLOW_UP_VIDEO_CONSULTATION]: isSpanishSelected
      ? env.VITE_HEALTHIE_FOLLOW_UP_FORM_ID_SPANISH
      : env.VITE_HEALTHIE_FOLLOW_UP_FORM_ID,
    [HEALTHIE_FORM_TYPE.FOLLOW_UP_REFILL]: isSpanishSelected
      ? env.VITE_HEALTHIE_REFILL_FORM_ID_SPANISH
      : env.VITE_HEALTHIE_REFILL_FORM_ID,
    [HEALTHIE_FORM_TYPE.ONBOARDING_VIDEO_CONSULTATION]: isSpanishSelected
      ? env.VITE_HEALTHIE_ONBOARDING_SYNC_FORM_ID_SPANISH
      : env.VITE_HEALTHIE_ONBOARDING_SYNC_FORM_ID,
    [HEALTHIE_FORM_TYPE.ONBOARDING_REFILL]: isSpanishSelected
      ? env.VITE_HEALTHIE_ONBOARDING_ASYNC_FORM_ID_SPANISH
      : env.VITE_HEALTHIE_ONBOARDING_ASYNC_FORM_ID,
    // TODO: Check if embeddables formIds are the same as onboarding formIds
    [HEALTHIE_FORM_TYPE.EMBEDDABLES_ONBOARDING_VIDEO_CONSULTATION]:
      isSpanishSelected
        ? env.VITE_HEALTHIE_ONBOARDING_SYNC_FORM_ID_SPANISH
        : env.VITE_HEALTHIE_ONBOARDING_SYNC_FORM_ID,
    [HEALTHIE_FORM_TYPE.EMBEDDABLES_ONBOARDING_REFILL]: isSpanishSelected
      ? env.VITE_HEALTHIE_ONBOARDING_ASYNC_FORM_ID_SPANISH
      : env.VITE_HEALTHIE_ONBOARDING_ASYNC_FORM_ID,
  };

  return { formId: formId[formType] };
};
