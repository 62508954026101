import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  CoachingHubOverview,
  DoctorNotes,
  DocumentCenterOverview,
  EditProfile,
  FridaysResources,
  Home,
  LabsDetails,
  MedicalRecordsDetails,
  Messages,
  MissingIntakeForm,
  MyProfile,
  PastSessions,
  PrescriptionsDetails,
} from "@/screens";
import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import { ROUTES } from "../constants";
import { ModalsRoutesWrapper } from "./ModalsRoutesWrapper";
import { PortalRoutesWrapper } from "./PortalRoutesWrapper";

/**
 * @deprecated These are the pending routes to move to their own router in the `domains` folder.
 */
export const LegacyRouter = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  const activeSubscription = usePortalUserStore(
    ({ portalUser }) =>
      portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );
  const canAccessMissingIntakeForm = usePortalUserStore(({ portalUser }) =>
    Boolean(!portalUser?.intakeFormCompleted && !portalUser?.isFromEmbeddables),
  );

  return (
    <>
      <Routes location={previousLocation ?? location}>
        <Route element={<PortalRoutesWrapper />}>
          <Route element={<MyProfile />} path={ROUTES.PROFILE.OVERVIEW} />
          <Route element={<EditProfile />} path={ROUTES.PROFILE.EDIT} />
          <Route element={<Home />} path={ROUTES.HOME} />
          <Route
            element={<CoachingHubOverview />}
            path={ROUTES.COACHING_HUB.OVERVIEW}
          />
          <Route
            element={<PastSessions />}
            path={`${ROUTES.COACHING_HUB.PAST_SESSIONS}/:sessionType`}
          />
          <Route element={<Messages />} path={ROUTES.MESSAGES} />

          {canAccessMissingIntakeForm && (
            <Route element={<MissingIntakeForm />} path={ROUTES.INTAKE_FORM} />
          )}

          <Route
            element={<DocumentCenterOverview />}
            path={ROUTES.DOCUMENT_CENTER.OVERVIEW}
          />
          <Route element={<LabsDetails />} path={ROUTES.DOCUMENT_CENTER.LABS} />

          <Route
            element={<DoctorNotes />}
            path={ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES}
          />
          <Route
            element={<PrescriptionsDetails />}
            path={ROUTES.DOCUMENT_CENTER.PRESCRIPTIONS}
          />
          <Route
            element={<MedicalRecordsDetails />}
            path={ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS}
          />

          {activeSubscription && (
            <Route
              element={<FridaysResources />}
              path={ROUTES.DOCUMENT_CENTER.FRIDAYS_RESOURCES}
            />
          )}
        </Route>

        <Route path="*" element={<Navigate replace to={ROUTES.BASE} />} />
      </Routes>

      <ModalsRoutesWrapper />
    </>
  );
};
