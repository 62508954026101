import { createContext, useContext } from "react";
import { motion, useReducedMotion } from "framer-motion";

import type { Direction } from "@/shared.types";

export interface FadeInProps
  extends React.ComponentPropsWithoutRef<typeof motion.div> {
  direction?: Direction;
}

const FadeInStaggerContext = createContext(false);

const viewport = { once: true, margin: "0px 0px -200px" };

const getVariants = (direction: Direction, translation: number) => {
  switch (direction) {
    case "top":
      return {
        hidden: { opacity: 0, y: translation },
        visible: { opacity: 1, y: 0 },
      };
    case "right":
      return {
        hidden: { opacity: 0, x: -translation },
        visible: { opacity: 1, x: 0 },
      };
    case "bottom":
      return {
        hidden: { opacity: 0, y: -translation },
        visible: { opacity: 1, y: 0 },
      };
    case "left":
      return {
        hidden: { opacity: 0, x: translation },
        visible: { opacity: 1, x: 0 },
      };
    default:
      return {
        hidden: { opacity: 0, y: translation },
        visible: { opacity: 1, y: 0 },
      };
  }
};

export const FadeIn = ({ direction = "top", ...rest }: FadeInProps) => {
  const shouldReduceMotion = useReducedMotion();
  const isInStaggerGroup = useContext(FadeInStaggerContext);

  return (
    <motion.div
      variants={getVariants(direction, shouldReduceMotion ? 0 : 24)}
      transition={{ duration: 0.5 }}
      {...(isInStaggerGroup
        ? {}
        : {
            initial: "hidden",
            viewport,
            whileInView: "visible",
          })}
      {...rest}
    />
  );
};

export const FadeInStagger = ({
  faster = false,
  ...props
}: React.ComponentPropsWithoutRef<typeof motion.div> & {
  faster?: boolean;
}) => (
  <FadeInStaggerContext.Provider value={true}>
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={viewport}
      transition={{ staggerChildren: faster ? 0.12 : 0.2 }}
      {...props}
    />
  </FadeInStaggerContext.Provider>
);
