import { t } from "ttag";

import type { Conversation } from "@/shared.types";
import { icons, IconWrapper } from "@/ui";
import { ChatTitle } from "./ChatTitle";

export const ChatTitleList = ({
  conversations,
  selectedConversation,
  onSelect,
}: {
  conversations?: Conversation[];
  selectedConversation: Conversation | null;
  onSelect: (conversation: Conversation) => void;
}) => {
  if (!conversations) {
    return (
      <div className="flex flex-col items-center px-10 text-center text-xl font-medium text-brown-05">
        <IconWrapper className="h-40 w-40">
          <icons.NoMessages className="h-40 w-40 stroke-brown-01" />
        </IconWrapper>
        {t`No messages yet`}
      </div>
    );
  }
  return (
    <>
      {conversations?.map((chat) => (
        <ChatTitle
          isSelected={selectedConversation?.id === chat.id}
          key={chat.id}
          conversation={chat}
          onClick={() => onSelect(chat)}
        />
      ))}
    </>
  );
};
