import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { icons } from "./Icons";

export const GoBackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="mt-1.5 text-brown-09 md:hidden"
      onClick={() => navigate(-1)}
      title={t`Go back`}
    >
      <icons.ChevronLeft className="h-5 w-5 stroke-2" aria-hidden="true" />
    </button>
  );
};
