import { Skeleton } from "@/ui";

export const NewChatSkeleton = () => {
  return (
    <div className="flex h-full flex-col gap-4">
      <Skeleton className="h-6 w-2/3" />
      <Skeleton className="h-6 w-1/3" />
      <Skeleton className="h-6 w-2/5" />
      <Skeleton className="h-6 w-1/4" />
      <Skeleton className="h-6 w-4/5" />
    </div>
  );
};
