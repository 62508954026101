import { icons } from "./Icons";

interface PagelessPaginationProps {
  changeBeforePage: () => void;
  changeAfterPage: () => void;
  hasMorePagesBefore: boolean;
  hasMorePagesAfter: boolean;
}

export const PagelessPagination = ({
  changeBeforePage,
  changeAfterPage,
  hasMorePagesBefore,
  hasMorePagesAfter,
}: PagelessPaginationProps) => {
  return (
    <div className="text-neutrals-dark-300 mb-3 mt-3 flex items-center justify-center rounded-md border border-brown-05 px-6 py-4 md:mt-0 md:justify-between md:rounded-none md:rounded-b-2xl md:border-t-0">
      <div className="flex items-center">
        <button
          disabled={!hasMorePagesBefore}
          onClick={changeBeforePage}
          className="p-3 leading-tight hover:opacity-80 disabled:opacity-30"
        >
          <icons.ChevronLeft className="h-4 w-4 text-brown-08" />
        </button>
        <button
          disabled={!hasMorePagesAfter}
          onClick={changeAfterPage}
          className="p-3 leading-tight hover:opacity-80 disabled:opacity-30"
        >
          <icons.ChevronRight className="h-4 w-4 text-brown-08" />
        </button>
      </div>
    </div>
  );
};
