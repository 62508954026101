import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const Shape8 = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 178 179"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path d="M121.53 73.4335C104.96 73.4335 91.53 86.8635 91.53 103.433C91.53 112.123 84.46 119.193 75.77 119.193C67.08 119.193 60.01 112.123 60.01 103.433C60.01 94.7435 67.08 87.6735 75.77 87.6735C92.34 87.6735 105.77 74.2435 105.77 57.6735C105.77 41.1035 92.34 27.6735 75.77 27.6735C33.99 27.6635 0 61.6535 0 103.433C0 145.213 33.99 179.193 75.76 179.193C117.53 179.193 151.52 145.203 151.52 103.433C151.52 86.8635 138.09 73.4335 121.52 73.4335H121.53Z" />
    <path d="M168.507 52.1305C180.129 40.5085 180.129 21.6655 168.507 10.0435C156.885 -1.5785 138.042 -1.5785 126.42 10.0435C114.798 21.6655 114.798 40.5085 126.42 52.1305C138.042 63.7525 156.885 63.7525 168.507 52.1305Z" />
  </svg>
);
