import { t } from "ttag";

import { FullScreenAlert } from "@/components";
import { Button } from "@/ui";

interface FullScreenErrorAlertProps {
  onTryAgain?: () => void;
}

export const FullScreenErrorAlert = ({
  onTryAgain,
}: FullScreenErrorAlertProps) => {
  return (
    <FullScreenAlert
      title={t`Oops seems like something went wrong`}
      description={t`Oops! Something went wrong. Our team is already working on fixing it. If you'd like, you can try again. Thank you for your patience!`}
      customAction={
        onTryAgain && (
          <Button onClick={onTryAgain} size="lg" className="w-full sm:w-fit">
            {t`Try again`}
          </Button>
        )
      }
    />
  );
};
