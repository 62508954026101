import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { illustratedIcons, Modal } from "@/ui";

export const BestFitModal = ({ show, onClose }: ModalProps) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<illustratedIcons.Warning />}
      title={t`It appears that Fridays may not be the best fit for you at the moment`}
      description={t`While Fridays may not align with your current needs, it's just the beginning of discovering what works best for you. Get in touch with us so we can provide some guidance.`}
    >
      <Link
        to={ROUTES.MESSAGES}
        className="rounded-lg bg-nature-08 p-2 text-xs font-medium text-salmon-01"
      >
        {t`Contact Fridays support`}
      </Link>
    </Modal>
  );
};
