import { tw } from "@/utils";
import { IsoLogo } from "./IsoLogo";

interface LoaderProps {
  className?: string;
  iconClassName?: string;
}

export const Loader = ({ className, iconClassName }: LoaderProps) => {
  return (
    <div
      className={tw(
        "absolute left-1/2 top-1/2 -translate-x-1/2 animate-bounce fill-nature-05",
        className,
      )}
    >
      <IsoLogo
        className={tw("h-12 animate-pulse fill-inherit", iconClassName)}
      />
    </div>
  );
};
