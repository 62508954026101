import { SIZE, VARIANT } from "@/shared.constants";
import { Button, icons, IconWrapper } from "@/ui";

interface CallToActionProps {
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}

export const CallToAction = ({
  title,
  description,
  buttonText,
  onClick,
}: CallToActionProps) => {
  return (
    <section className="flex flex-col justify-between gap-3 rounded-2xl bg-brown-02 p-4 text-brown-10 lg:flex-row lg:items-center">
      <div className="flex flex-col md:text-center lg:gap-2.5 lg:text-left lg:text-lg">
        <h3 className="font-bold">{title}</h3>
        <p>{description}</p>
      </div>

      <Button
        variant={VARIANT.SECONDARY}
        size={SIZE.LARGE}
        onClick={onClick}
        className="w-full lg:w-fit"
      >
        {buttonText}
        <IconWrapper size={SIZE.SMALL}>
          <icons.ArrowRight />
        </IconWrapper>
      </Button>
    </section>
  );
};
