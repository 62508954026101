import { tw } from "@/utils";
import { icons } from "./Icons";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
}: PaginationProps) => {
  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  return (
    <div className="text-neutrals-dark-300 flex items-center justify-between rounded-xl border border-brown-05 px-6 py-4 md:rounded-b-2xl md:rounded-t-none md:border-t-0">
      {totalPages > 1 && (
        <div className="flex items-center">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="p-3 leading-tight hover:opacity-80 disabled:opacity-30"
          >
            <icons.ChevronLeft className="h-4 w-4 text-brown-08" />
          </button>
          <ul className="flex items-center gap-2">
            {Array.from(Array(totalPages).keys()).map((idx) => {
              const pageNumber = idx + 1;
              const isLimitPage = pageNumber === 1 || pageNumber === totalPages;
              const isSurroundingCurrentPage =
                pageNumber > currentPage - 2 && pageNumber < currentPage + 2;
              if (
                (pageNumber === currentPage - 2 ||
                  pageNumber === currentPage + 2) &&
                !isLimitPage
              ) {
                return (
                  <li
                    key={idx}
                    className="w-8 select-none border-2 border-transparent py-2 text-center font-medium"
                  >
                    ...
                  </li>
                );
              }
              if (!isSurroundingCurrentPage && !isLimitPage) {
                return null;
              }
              return (
                <li key={idx}>
                  <button
                    aria-current="page"
                    onClick={() => handlePageChange(pageNumber)}
                    className={tw(
                      "hover:text-neutrals-dark-900 h-10 w-10 rounded-md border border-transparent font-medium text-brown-09",
                      pageNumber === currentPage &&
                        "hover:border-neutrals-dark-200 border-salmon-09",
                    )}
                  >
                    {pageNumber}
                  </button>
                </li>
              );
            })}
          </ul>

          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="p-3 leading-tight hover:opacity-80 disabled:opacity-30"
          >
            <icons.ChevronRight className="h-4 w-4 text-brown-08" />
          </button>
        </div>
      )}
    </div>
  );
};
