import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPaymentHistoryQuery } from "@/domains/billing/api";
import { icons, IconWrapper } from "@/ui";
import { PagelessPagination } from "@/ui/common/PagelessPagination";
import { PaymentHistoryCards, PaymentHistoryTable } from "./components";

export const PaymentHistory = () => {
  const [lastPaymentInformation, setLastPaymentInformation] =
    useState<string>();
  const [beforeLastPaymentInformation, setBeforeLastPaymentInformation] =
    useState<string>();

  const { data } = useQuery(getPaymentHistoryQuery(lastPaymentInformation));

  const changeBeforePage = () => {
    setLastPaymentInformation(beforeLastPaymentInformation);
    setBeforeLastPaymentInformation(undefined);
  };

  const changeAfterPage = () => {
    setBeforeLastPaymentInformation(lastPaymentInformation);
    setLastPaymentInformation(data?.data[data.data.length - 1]?.id);
  };

  return (
    <>
      <h3 className="flex items-center gap-2 text-lg font-bold">
        <IconWrapper size="sm">
          <icons.Clock />
        </IconWrapper>
        {t`Payment history`}
      </h3>
      <div>
        <PaymentHistoryTable payments={data?.data} />
        <PaymentHistoryCards payments={data?.data} />

        {data?.pagination && data?.pagination.totalPages > 1 && (
          <PagelessPagination
            changeBeforePage={changeBeforePage}
            changeAfterPage={changeAfterPage}
            hasMorePagesBefore={data?.pagination?.hasMorePagesBefore ?? false}
            hasMorePagesAfter={data?.pagination?.hasMorePagesAfter ?? false}
          />
        )}
      </div>
    </>
  );
};
