export const PORTAL_USER_TYPE = {
  PATIENT: "healthie-patient-user",
  COACHING: "coaching-user",
} as const;

export const APPOINTMENT_REQUIRED_STATES = [
  "AR",
  "DC",
  "DE",
  "MS",
  "NM",
  "RI",
  "WV",
] as const;
