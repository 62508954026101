import { ScheduleContextProvider } from "@/contexts";
import { usePatientQuery } from "@/hooks";
import { ScreenLayout } from "@/layouts";
import { useAppSettingsStore } from "@/stores";
import {
  AvailableDays,
  AvailableTimes,
  LanguagePreference,
  ProviderPreference,
  ScheduleConsultationHeader,
} from "./sections";

export const ConsultationSchedule = () => {
  const language = useAppSettingsStore((state) => state.language);
  const { data } = usePatientQuery();
  const state = data?.location?.state;

  return (
    <ScreenLayout variant="secondary">
      <ScheduleConsultationHeader />

      <ScheduleContextProvider defaultLanguage={language} state={state}>
        <LanguagePreference />
        <ProviderPreference />
        <div className="grid gap-x-3 gap-y-8 xl:grid-cols-2">
          <AvailableDays />
          <AvailableTimes />
        </div>
      </ScheduleContextProvider>
    </ScreenLayout>
  );
};
