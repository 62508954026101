import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { checkNewMessagesQuery, getConversationsQuery } from "@/api/messages";
import { ROUTES } from "@/router";
import type { Conversation } from "@/shared.types";
import { icons, ScreenHeader } from "@/ui";
import { tw } from "@/utils";
import Chat from "./Chat";
import ChatsList from "./ChatsList";

export const Messages = () => {
  const queryClient = useQueryClient();
  const { data: conversationsWithNewMessages } = useQuery(
    checkNewMessagesQuery(),
  );
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const { data: conversations } = useQuery(getConversationsQuery());

  useEffect(() => {
    if (
      conversationsWithNewMessages &&
      conversationsWithNewMessages.conversationsWithNewMessages.length > 0
    ) {
      void queryClient.invalidateQueries({ queryKey: ["messages"] });
    }
  }, [
    conversationsWithNewMessages,
    conversationsWithNewMessages?.conversationsWithNewMessages,
    queryClient,
  ]);

  return (
    <div className="flex max-h-full w-full flex-col px-0 md:px-8 md:pb-4 md:pt-12 lg:px-16">
      <div className="mb-4 hidden md:block">
        <ScreenHeader
          title={t`Messages`}
          paths={[
            {
              href: ROUTES.MESSAGES,
              icon: icons.Envelope,
              label: t`Messages`,
            },
          ]}
          hidden={selectedConversation !== null}
        />
      </div>
      <div
        className={tw(
          "mx-4 mt-4 flex flex-col gap-2 rounded-2xl bg-nature-08 pb-4 pl-4 pr-8 pt-6 md:mx-0 md:hidden",
          Boolean(selectedConversation) && "hidden",
        )}
      >
        <div className="flex items-center justify-start md:justify-between">
          <div className="flex w-full items-center justify-between gap-2 text-lg font-bold text-nature-01">
            <p className="font-serif text-2xl font-normal">{t`Messages`}</p>
          </div>
        </div>
      </div>
      <div className="h-full max-h-full grid-cols-5 gap-3 overflow-auto md:grid">
        <ChatsList
          conversations={conversations}
          setSelectedConversation={setSelectedConversation}
          selectedConversation={selectedConversation}
        />
        <Chat
          conversation={selectedConversation}
          goBack={() => {
            setSelectedConversation(null);
          }}
        />
      </div>
    </div>
  );
};
