import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type { AvailableLanguage } from "@/shared.types";

interface CreateHealthieUserParams {
  userId: number;
  providerId?: string;
  appointmentDate?: string;
  line1: string;
  line2?: string;
  zip: string;
  state: string;
  city: string;
  phoneNumber: string;
  language?: AvailableLanguage;
}

export const createHealthieUser = {
  mutation: async (params: CreateHealthieUserParams) => {
    const { userId, ...userParams } = params;
    const { data } = await privateAPI.post<ServiceResponse<{ token: string }>>(
      `/patients/${userId}/create-healthie-user`,
      userParams,
    );

    return data.data;
  },
};
