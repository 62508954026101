import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { AlertDialog, Button, illustratedIcons } from "@/ui";

interface ConsultationExistsAlertProps {
  show: boolean;
}

export const ConsultationExistsAlert = ({
  show,
}: ConsultationExistsAlertProps) => {
  return (
    <AlertDialog
      show={show}
      icon={<illustratedIcons.Video />}
      title={t`You have a previous consultation already scheduled`}
      description={t`It looks like you already have a consultation scheduled. To continue, please cancel your existing appointment first. We're here to help if you need any assistance!`}
      renderActions={() => (
        <Button asChild className="mx-auto w-full sm:w-fit">
          <Link
            to={ROUTES.CONSULTATIONS.OVERVIEW}
          >{t`Review consultations`}</Link>
        </Button>
      )}
    />
  );
};
