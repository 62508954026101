import type { ComponentPropsWithoutRef } from "react";
import { useEffect, useRef, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import type { DPDatesConfig, DPYearsConfig } from "@rehookify/datepicker";
import { useDatePicker } from "@rehookify/datepicker";
import { Controller } from "react-hook-form";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

import { useAppSettingsStore } from "@/stores";
import { icons, IconWrapper, MonthSelect, YearSelect } from "@/ui/common";
import {
  formatBackendDate,
  formatToBackendDate,
  parseBackendDate,
  tw,
} from "@/utils";
import type {
  BaseFieldProps,
  MultiSelectProps,
  SingleSelectProps,
} from "./form.types";
import { Label } from "./Label";
import { Message } from "./Message";

type BaseProp = Omit<ComponentPropsWithoutRef<"input">, "value" | "onChange"> &
  BaseFieldProps;
interface CommonProps extends BaseProp {
  config?: { dates?: Partial<DPDatesConfig>; years?: Partial<DPYearsConfig> };
}

type DatePickerProps = CommonProps &
  (SingleSelectProps<string> | MultiSelectProps<string>);

export const DatePicker = ({
  className,
  compact = true,
  config,
  containerClassName,
  disabled,
  error,
  id,
  label,
  left,
  message,
  multiple,
  placeholder,
  value,
  onChange,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);
  const [offsetDate, onOffsetChange] = useState(new Date());

  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!multiple && value) {
      onOffsetChange(parseBackendDate(value));
    }
  }, [value, multiple]);

  const selectedDates = !value?.length
    ? [new Date()]
    : multiple
      ? value.map((v) => parseBackendDate(v))
      : [parseBackendDate(value)];

  const onDatesChange = (dates: Date[]) => {
    if (multiple) {
      const updatedValues = dates.map((date) => formatToBackendDate(date));
      onChange(updatedValues);
    } else {
      const updatedValue = dates[0] ? formatToBackendDate(dates[0]) : "";

      onChange(updatedValue);
      setOpen(false);
    }
  };

  const locale = useAppSettingsStore((state) => state.language);

  const {
    data: { weekDays, calendars, years, months },
    propGetters: { dayButton, addOffset, subtractOffset },
  } = useDatePicker({
    locale: { locale },
    selectedDates,
    onDatesChange,
    offsetDate,
    onOffsetChange,
    ...config,
  });
  const days = calendars[0]?.days;

  const onMoveToNewYear = (year: number) => {
    onOffsetChange((prev) => new Date(year, prev.getMonth(), prev.getDate()));
  };
  const onMoveToNewMonth = (month: number) => {
    onOffsetChange(
      (prev) => new Date(prev.getFullYear(), month, prev.getDate()),
    );
  };

  return (
    <div className={tw("flex flex-col gap-1.5", containerClassName)}>
      {label && (
        <Label
          htmlFor={id}
          onClick={(e) => {
            e.preventDefault();
            triggerRef.current?.focus();
          }}
        >
          {label}
        </Label>
      )}

      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger
          id={id}
          ref={triggerRef}
          className={tw(
            "flex w-full items-center gap-2 rounded-lg border border-brown-05 bg-salmon-01 px-4 py-3 text-sm text-brown-09 focus:border-brown-08 focus:outline-none focus:ring-1 focus:ring-salmon-03",

            error && "border-red-07 focus:ring-red-01",

            className,
          )}
          disabled={disabled}
        >
          {left && (
            <div className="pointer-events-none">
              <IconWrapper
                size="sm"
                className={tw("fill-brown-06", !!error && "fill-red-03")}
              >
                {left}
              </IconWrapper>
            </div>
          )}
          <div className="flex min-w-0 grow items-center gap-3 text-left">
            {!multiple && value ? (
              <span className="truncate">{formatBackendDate(value)}</span>
            ) : (
              <span className="truncate text-brown-06">
                {placeholder ?? "Select..."}
              </span>
            )}
          </div>

          <IconWrapper
            size="sm"
            className={tw(
              "cursor-pointer fill-brown-09 stroke-brown-09 duration-150",
              open && "rotate-180",
              disabled && "cursor-not-allowed",
            )}
          >
            <icons.ChevronDown />
          </IconWrapper>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            side="bottom"
            sideOffset={8}
            className="z-50 min-w-[var(--radix-popover-trigger-width)] rounded-md border bg-salmon-01 p-5 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            <header className="flex flex-col gap-2">
              <div className="flex items-center">
                <button
                  type="button"
                  className="flex items-center justify-center rounded-full focus:border-salmon-09 focus:outline-salmon-09 enabled:hover:stroke-2 disabled:cursor-not-allowed disabled:opacity-60"
                  {...subtractOffset({ months: 1 })}
                  aria-label="Previous month"
                >
                  <IconWrapper size="md">
                    <icons.ChevronLeft />
                  </IconWrapper>
                </button>
                <div className="flex grow items-center justify-center gap-2">
                  <MonthSelect
                    months={months}
                    month={offsetDate.getMonth()}
                    onChange={onMoveToNewMonth}
                  />
                  <YearSelect
                    years={years}
                    year={offsetDate.getFullYear()}
                    onChange={onMoveToNewYear}
                  />
                </div>
                <button
                  type="button"
                  className="flex items-center justify-center rounded-full focus:border-salmon-09 focus:outline-salmon-09 enabled:hover:stroke-2 disabled:cursor-not-allowed disabled:opacity-60"
                  {...addOffset({ months: 1 })}
                  aria-label="Next month"
                >
                  <IconWrapper size="md">
                    <icons.ChevronRight />
                  </IconWrapper>
                </button>
              </div>
              <ul className="grid grid-cols-7 py-4 text-nature-09">
                {weekDays.map((day) => (
                  <li
                    key={`${offsetDate.getMonth()}-${day}`}
                    className="flex items-center justify-center text-xs"
                  >
                    {day}
                  </li>
                ))}
              </ul>
            </header>
            <ul className="grid grid-cols-7 gap-0.5">
              {days?.map((dpDay) => (
                <li key={dpDay.$date.toDateString()}>
                  <button
                    type="button"
                    {...dayButton(dpDay)}
                    className={tw(
                      "h-11 w-11 rounded-full border-2 border-transparent text-sm font-semibold text-brown-10 hover:border-nature-07",
                      !dpDay.inCurrentMonth && "text-nature-03",
                      dpDay.selected && "bg-nature-07 text-nature-01",
                      dpDay.now && "border-salmon-06",
                      dpDay.disabled &&
                        "cursor-not-allowed text-brown-02 hover:border-none",
                    )}
                  >
                    {dpDay.day}
                  </button>
                </li>
              ))}
            </ul>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      {(!compact || !!message || !!error) && (
        <Message message={message} error={error} />
      )}
    </div>
  );
};

interface HookedDatePickerProps<TFieldValues extends FieldValues>
  extends Omit<DatePickerProps, "onChange" | "value"> {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}
export const HookedDatePicker = <TFieldValues extends FieldValues>({
  name,
  control,
  ...props
}: HookedDatePickerProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field;
        return <DatePicker {...props} value={value} onChange={onChange} />;
      }}
    />
  );
};
