import type { HTMLAttributes } from "react";
import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";

import { PAYMENT_STATUS } from "@/shared.constants";
import type { PaymentStatus } from "@/shared.types";
import {
  CheckCircleIcon,
  CloseCircleIcon,
  IconWrapper,
  ReloadCircleIcon,
} from "@/ui";

const chipVariants = cva(
  "inline-flex items-center justify-center rounded-3xl px-2.5 py-0.5 gap-1 text-sm w-fit text-brown-01",
  {
    variants: {
      status: {
        [PAYMENT_STATUS.PROCESSING]: "bg-yellow-07",
        [PAYMENT_STATUS.FAILED]: "bg-red-07",
        [PAYMENT_STATUS.SUCCESS]: "bg-green-07",
      },
    },
    defaultVariants: {
      status: PAYMENT_STATUS.PROCESSING,
    },
  },
);

const statusIcon = {
  [PAYMENT_STATUS.PROCESSING]: <ReloadCircleIcon />,
  [PAYMENT_STATUS.FAILED]: <CloseCircleIcon />,
  [PAYMENT_STATUS.SUCCESS]: <CheckCircleIcon />,
};

const statusLabel = {
  [PAYMENT_STATUS.PROCESSING]: "Processing",
  [PAYMENT_STATUS.FAILED]: "Failed",
  [PAYMENT_STATUS.SUCCESS]: "Completed",
};

interface PaymentStatusChipProps
  extends HTMLAttributes<HTMLDivElement>,
    Omit<VariantProps<typeof chipVariants>, "status"> {
  status: PaymentStatus;
}

export const PaymentStatusChip = ({
  className,
  status,
  ...props
}: PaymentStatusChipProps) => {
  return (
    <div className={chipVariants({ status, className })} {...props}>
      {statusLabel[status]}
      <IconWrapper size="sm">{statusIcon[status]}</IconWrapper>
    </div>
  );
};
