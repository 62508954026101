import { Link } from "react-router-dom";
import { t } from "ttag";

import { FridaysCard } from "@/components";
import { ROUTES } from "@/router";
import { Button } from "@/ui";

export const FridaysResources = () => {
  return (
    <FridaysCard.Root>
      <FridaysCard.Content>
        <FridaysCard.Title>{t`Fridays' Resources`}</FridaysCard.Title>
        <FridaysCard.Description>
          {t`Stay informed with the latest news and updates from Fridays.`}
          <br />
          {t`Access valuable resources, articles, and company announcements here.`}
        </FridaysCard.Description>
      </FridaysCard.Content>
      <FridaysCard.Actions>
        <Button size="lg" asChild>
          <Link
            to={ROUTES.DOCUMENT_CENTER.FRIDAYS_RESOURCES}
          >{t`Access resources`}</Link>
        </Button>
      </FridaysCard.Actions>
    </FridaysCard.Root>
  );
};
