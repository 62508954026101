import type { ComponentPropsWithoutRef } from "react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { forwardRef, tw } from "@/utils";
import { Label } from "../form";

// https://www.figma.com/design/wuTj0UhW8hs2D9S6ubvrSo/Fridays-Components?node-id=1311-5674&t=D82tooBwJ9xvAQDI-4
const loaderVariance = cva("animate-spin", {
  variants: {
    variant: {
      light: "fill-salmon-07 stroke-salmon-03",
      dark: "fill-salmon-01 stroke-salmon-07",
    },
    size: {
      xs: "size-5",
      sm: "size-8",
      md: "size-11",
      lg: "size-16",
    },
  },
  defaultVariants: {
    variant: "light",
    size: "xs",
  },
});

interface LoaderProps
  extends ComponentPropsWithoutRef<"svg">,
    VariantProps<typeof loaderVariance> {
  label?: string;
}

export const Loader = forwardRef<SVGSVGElement, LoaderProps>(
  ({ className, variant, size, label, ...props }, ref) => {
    return (
      <div className="flex flex-col items-center gap-2">
        <svg
          id="loader-spinner"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          className={tw(loaderVariance({ variant, size, className }))}
          {...props}
          ref={ref}
        >
          <circle cx="10" cy="10" r="8.5" strokeWidth="3" fill="none" />
          <path
            d="M10 1.49418C10 0.668967 10.6726 -0.0114274 11.4887 0.111421C12.8078 0.310009 14.079 0.771343 15.225 1.4736C16.7974 2.4372 18.0728 3.81688 18.9101 5.46009C19.7473 7.10331 20.1139 8.94605 19.9692 10.7846C19.8637 12.1245 19.4897 13.4241 18.875 14.608C18.4948 15.3404 17.549 15.4846 16.8814 14.9996C16.2137 14.5145 16.083 13.5835 16.4159 12.8284C16.7326 12.1099 16.9279 11.34 16.99 10.5501C17.0915 9.26101 16.8345 7.96895 16.2474 6.81678C15.6604 5.66462 14.7661 4.69724 13.6636 4.0216C12.988 3.60762 12.2504 3.31304 11.4829 3.14697C10.6764 2.97244 10 2.31939 10 1.49418Z"
            stroke="none"
          />
        </svg>

        {label && (
          <Label size="lg" htmlFor="loader-spinner">
            {label}
          </Label>
        )}
      </div>
    );
  },
);
