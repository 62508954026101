import { t } from "ttag";

import { ROUTES } from "@/router";
import { SUBSCRIPTION_STATUS } from "@/shared.constants";
import type { DomainRoute, SubscriptionStatus } from "@/shared.types";
import { getEnumValues } from "@/utils";

interface NavigationItem {
  path: DomainRoute;
  label: string;
  subscriptionStatus: SubscriptionStatus[];
  needsIntakeForm?: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
}

export const getCoachingNavigation = () =>
  [
    {
      path: ROUTES.COACHING_HUB.OVERVIEW,
      label: t`Coaching Hub`,
      subscriptionStatus: [SUBSCRIPTION_STATUS.ACTIVE],
    },
    {
      path: ROUTES.MESSAGES,
      label: t`Messages`,
      subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
    },
    {
      path: ROUTES.BILLING.OVERVIEW,
      label: t`Billing`,
      subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
    },
  ] as NavigationItem[];

export const getNavigation = () =>
  [
    {
      path: ROUTES.HOME,
      label: t`Home`,
      subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
    },
    {
      path: ROUTES.MY_MEDS,
      label: t`My Meds`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
      needsIntakeForm: true,
    },
    {
      path: ROUTES.CONSULTATIONS.OVERVIEW,
      label: t`Consultations`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
      needsIntakeForm: true,
    },
    {
      path: ROUTES.COACHING_HUB.OVERVIEW,
      label: t`Coaching Hub`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
    },
    {
      path: ROUTES.DOCUMENT_CENTER.OVERVIEW,
      label: t`Document center`,
      subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
      needsIntakeForm: false,
    },
    {
      path: ROUTES.MESSAGES,
      label: t`Messages`,
      subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
    },
    {
      path: ROUTES.BILLING.OVERVIEW,
      label: t`Billing`,
      subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
    },
  ] as NavigationItem[];
