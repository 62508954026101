import { AVAILABLE_LANGUAGE } from "@/shared.constants";
import type { AvailableLanguage } from "@/shared.types";
import { Alert } from "@/ui";
import { TimeSlots } from "./schedule-consultation";

interface TimeSlotsEmptyStateProps {
  language: AvailableLanguage;
}

export const TimeSlotsEmptyState = ({ language }: TimeSlotsEmptyStateProps) => {
  if (language === AVAILABLE_LANGUAGE.ES) {
    return (
      <Alert>
        Si no puedes encontrar disponibilidad en español, por favor contacta a
        nuestro equipo de soporte para recibir ayuda.
      </Alert>
    );
  }

  return <TimeSlots.Error className="shadow-none" />;
};
