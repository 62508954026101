import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import { HomeTabs, InactiveSubscriptionAlert } from "./components";
import { IncompleteIntakeFormAlert } from "./components/IncompleteIntakeFormAlert";

export const HomeContent = () => {
  const portalUser = usePortalUserStore((state) => state.portalUser);

  if (!portalUser) {
    return null;
  }

  if (isInactiveSubscription(portalUser.subscriptionStatus)) {
    return (
      <InactiveSubscriptionAlert
        subscriptionStatus={portalUser.subscriptionStatus}
      />
    );
  }

  if (!portalUser.intakeFormCompleted) {
    return <IncompleteIntakeFormAlert portalUser={portalUser} />;
  }

  return <HomeTabs />;
};
