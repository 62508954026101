import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { Button, Dialog, HeartsCircleFixedIconMono } from "@/ui";

export const ErrorModal = ({ show, onClose }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal" className="h-fit">
        <div className="flex h-full flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <HeartsCircleFixedIconMono />
            </Dialog.Icon>

            <Dialog.Title>
              {t`Oops seems like something went wrong`}
            </Dialog.Title>

            <Dialog.Description>
              {t`Please try again or reach out to support if this issue continue.`}
            </Dialog.Description>
            <Dialog.Description>
              {t`Thank you for your patience!`}
            </Dialog.Description>
          </Dialog.Header>

          <Dialog.Footer variant="sticky">
            <Button onClick={() => navigate(ROUTES.MESSAGES)} size="lg">
              {t`Contact support`}
            </Button>
          </Dialog.Footer>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
