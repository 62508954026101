import type { TickItem } from "recharts/types/util/types";

interface CustomYAxisTickProps {
  payload?: TickItem;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export const CustomYAxisTick = (props: CustomYAxisTickProps) => {
  const { payload, x, y, width, height } = props;
  if (!payload || !x || !y || !width || !height) {
    return <></>;
  }
  return (
    <g>
      <foreignObject x={x} y={+y - 12} height={height} width={width}>
        <div className="flex justify-center">
          <span className="mx-auto bg-salmon-02 px-2 py-1 text-xs">
            {payload.value}
          </span>
        </div>
      </foreignObject>
    </g>
  );
};
