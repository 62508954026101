import { Navigate, Route } from "react-router-dom";

import { ROUTES } from "@/router";
import { GuestRoutesWrapper } from "@/router/components/GuestRoutesWrapper";
import {
  ForgotPassword,
  Login,
  ResetPassword,
  ResetPasswordLinkUsed,
} from "./screens";

export const GuestRouter = () => {
  return (
    <GuestRoutesWrapper>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route
        path={ROUTES.RESET_PASSWORD_USED}
        element={<ResetPasswordLinkUsed />}
      />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />

      <Route path="*" element={<Navigate replace to={ROUTES.LOGIN} />} />
    </GuestRoutesWrapper>
  );
};
