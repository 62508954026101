import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { t } from "ttag";

import { MEDICATIONS_DOMAIN } from "@/api";
import { FullScreenAlert } from "@/components";
import { HealthieFormSection } from "@/domains/consultations/components";
import { useBoolean } from "@/hooks";
import { ROUTES } from "@/router";
import { HEALTHIE_FORM_TYPE } from "@/shared.constants";
import { Button, illustratedIcons } from "@/ui";

interface RefillHealthieFormProps {
  onCancel: () => void;
}
export const RefillHealthieForm = ({ onCancel }: RefillHealthieFormProps) => {
  const { value: showSuccessAlert, setTrue: openSuccessAlert } =
    useBoolean(false);
  const queryClient = useQueryClient();

  return (
    <>
      <HealthieFormSection
        formType={HEALTHIE_FORM_TYPE.FOLLOW_UP_REFILL}
        onCancel={onCancel}
        onSubmit={openSuccessAlert}
      />

      {showSuccessAlert && (
        <FullScreenAlert
          customIcon={<illustratedIcons.Success />}
          title={t`Submission Successful!`}
          description={t`We have received your medication request. Please allow 2-3 business days for processing. If we need any more information, our team will be in touch!`}
          customAction={
            <Button
              asChild
              size="lg"
              className="md:w-fit"
              onClick={() =>
                void queryClient.invalidateQueries({
                  queryKey: [MEDICATIONS_DOMAIN],
                })
              }
            >
              <Link to={ROUTES.MY_MEDS}>{t`Back to home`}</Link>
            </Button>
          }
        />
      )}
    </>
  );
};
