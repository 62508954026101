import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { t } from "ttag";

import type { ModalProps } from "@/shared.types";
import { Dialog, LightbulbFixedIconMono, Stepper } from "@/ui";
import { Step1, Step2, Step3, Step4 } from "./components";

const STEPS = [
  { value: "step-1", children: <Step1 /> },
  { value: "step-2", children: <Step2 /> },
  { value: "step-3", children: <Step3 /> },
  { value: "step-4", children: <Step4 /> },
] as const;

export const NextStepsModal = ({ show, onClose }: ModalProps) => {
  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal">
        <div className="-mx-6 flex h-full flex-col sm:m-0">
          <Dialog.Header>
            <Dialog.Icon>
              <LightbulbFixedIconMono />
            </Dialog.Icon>
            <Dialog.Title>{t`Next steps`}</Dialog.Title>

            <VisuallyHidden asChild>
              <Dialog.Description>
                {t`This modal guides users through the essential actions needed to start using the app effectively.`}
              </Dialog.Description>
            </VisuallyHidden>
          </Dialog.Header>

          <Stepper.Root steps={STEPS} className="flex grow flex-col gap-6">
            <div className="flex h-full w-full grow flex-col items-center px-6">
              <Stepper.Bar />

              <Stepper.Content className="h-full grow" />
            </div>

            <Dialog.Footer variant="sticky" className="w-full">
              <Stepper.Actions onComplete={onClose} completeLabel={t`Close`} />
            </Dialog.Footer>
          </Stepper.Root>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
