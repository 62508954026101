import { MODAL_ROUTES, useNavigateModal } from "@/router";
import { READ_ONLY_DOCUMENT_FOLDER } from "@/shared.constants";
import type {
  Folder,
  ReadOnlyDocumentFolder,
  UploadedFile as UploadedFileType,
} from "@/shared.types";
import {
  CalendarMinimalisticIcon,
  EyeIcon,
  IconWrapper,
  UploadedFileCard,
} from "@/ui";
import { formatBackendDate } from "@/utils";
import { DOCUMENT_ICON } from "../components.constants";
import { EditActions } from "./EditActions";

interface UploadedDocumentProps {
  folder: Folder;
  file: UploadedFileType;
  readOnly?: boolean;
}

const isReadOnlyType = (x: Folder): x is ReadOnlyDocumentFolder => {
  return Object.values(READ_ONLY_DOCUMENT_FOLDER).includes(
    x as ReadOnlyDocumentFolder,
  );
};

export const UploadedDocument = ({
  folder,
  file,
  readOnly,
}: UploadedDocumentProps) => {
  const navigateModal = useNavigateModal();

  return (
    <UploadedFileCard.Container
      key={file.id}
      onClick={() =>
        navigateModal(
          `${MODAL_ROUTES.DOCUMENT_CENTER.PREVIEW_DOCUMENT}/${folder}/${file.id}`,
        )
      }
      className="cursor-pointer border-salmon-03"
    >
      <UploadedFileCard.Content className="w-full max-w-full flex-row items-center justify-between md:max-w-full">
        <div className="flex max-w-36 items-center gap-2 md:max-w-96">
          <IconWrapper size="sm" className="text-salmon-09">
            {DOCUMENT_ICON[folder]}
          </IconWrapper>
          <UploadedFileCard.Title>{file.name}</UploadedFileCard.Title>
        </div>

        <div className="flex items-center gap-2">
          <IconWrapper size="sm" className="text-brown-06">
            <CalendarMinimalisticIcon />
          </IconWrapper>
          <UploadedFileCard.Description>
            {formatBackendDate(file.createdAt)}
          </UploadedFileCard.Description>
        </div>
      </UploadedFileCard.Content>

      <UploadedFileCard.Actions onClick={(e) => e.stopPropagation()}>
        {isReadOnlyType(folder) || readOnly ? (
          <IconWrapper
            as="button"
            type="button"
            size="sm"
            onClick={() =>
              navigateModal(
                `${MODAL_ROUTES.DOCUMENT_CENTER.PREVIEW_DOCUMENT}/${folder}/${file.id}`,
              )
            }
            className="text-nature-06 focus:outline-nature-04"
          >
            <EyeIcon />
          </IconWrapper>
        ) : (
          <EditActions folder={folder} fileId={file.id} />
        )}
      </UploadedFileCard.Actions>
    </UploadedFileCard.Container>
  );
};
