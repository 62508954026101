import { useFormContext, useWatch } from "react-hook-form";
import { t } from "ttag";

import { FIELD_TYPE } from "@/shared.constants";
import type { HealthieField } from "@/shared.types";
import { Form } from "@/ui";
import { evaluateFilterCondition } from "@/utils";
import { Field, ReadOnlyField } from "./components";
import {
  isHiddenFieldByLabel,
  isInvalidFieldType,
  isReadOnlyType,
} from "./utils";

interface HealthieFormFieldProps {
  healthieField: HealthieField;
}

export const HealthieFormField = ({
  healthieField,
}: HealthieFormFieldProps) => {
  const fieldCondition = healthieField.customModuleCondition;
  const fieldConditionName = fieldCondition?.conditionalCustomModuleId ?? "";

  const { control } = useFormContext();
  const conditionFieldValue = useWatch({
    control,
    defaultValue: "",
    name: fieldConditionName,
  }) as string;

  const showConditionedField =
    !!fieldCondition &&
    evaluateFilterCondition(
      fieldCondition.filterType,
      conditionFieldValue,
      fieldCondition.valueToFilter,
    );

  const showField = !fieldCondition || showConditionedField;
  if (!showField) {
    return null;
  }

  if (
    (isReadOnlyType(healthieField.type) &&
      isHiddenFieldByLabel(healthieField.label)) ||
    isInvalidFieldType(healthieField.type) ||
    healthieField.type === FIELD_TYPE.HIDDEN
  ) {
    return null;
  }

  const defaultValue = healthieField.type === FIELD_TYPE.FILE ? undefined : "";
  const label = healthieField.label
    ? `${healthieField.label}${healthieField.required ? "*" : ""}`
    : "";

  return (
    <fieldset className="flex flex-col gap-3.5 rounded-2xl bg-brown-01 p-3.5 text-salmon-10">
      {isReadOnlyType(healthieField.type) ? (
        <ReadOnlyField healthieField={healthieField} />
      ) : (
        <Form.Field
          control={control}
          defaultValue={healthieField.value ?? defaultValue}
          name={healthieField.id}
          rules={{
            required: healthieField.required && t`This field is required`,
          }}
          render={(renderProps) => (
            <Form.Item>
              <Form.Label>{label}</Form.Label>
              <Form.Control>
                <Field healthieField={healthieField} {...renderProps} />
              </Form.Control>
              <Form.Message />
            </Form.Item>
          )}
        />
      )}
    </fieldset>
  );
};
