import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";

export const useSubscriptionStatus = () => {
  const portalUser = usePortalUserStore((state) => state.portalUser);
  const isActiveUser = Boolean(
    portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );

  return {
    isActiveUser,
  };
};
