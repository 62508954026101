import type { QueryClient } from "@tanstack/react-query";

import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "labs";

export interface LabResult {
  id: string;
  name: string;
  createdAt: string;
}

export interface LabRequisitionForm {
  id: string;
  name: string;
  createdAt: string;
}

export const getLabsQuery = () => ({
  queryKey: [DOMAIN, "getLabsQuery"],
  queryFn: async () => {
    const { data } =
      await privateAPI.get<ServiceResponse<LabResult[]>>(`/labs`);

    return data.data;
  },
});

export const getLabRequisitionFormsQuery = () => ({
  queryKey: [DOMAIN, "getLabRequisitionFormsQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<
      ServiceResponse<LabRequisitionForm[]>
    >(`/labs/requisition-forms`);

    return data.data;
  },
});

export const uploadLabResult = {
  mutation: async ({ file }: { file?: string }) => {
    const { data } = await privateAPI.post<
      ServiceResponse<{ documentId: string }>
    >("/labs/results", {
      file,
    });

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};
