import { motion } from "framer-motion";

import { IsoLogo, Logo } from "@/components";

export const AnimatedLogo = () => {
  return (
    <div className="pointer-events-none absolute z-20 flex h-full w-full p-14">
      <div className="relative h-full w-full">
        {/* Mobile */}
        <motion.div
          animate={{
            top: ["50%", "0%"],
            left: ["50%", "50%"],
            translateX: ["-50%", "-50%"],
            translateY: ["-50%", "0%"],
            transition: {
              duration: 1.5,
              delay: 2,
              ease: "backInOut",
            },
          }}
          className="absolute sm:hidden"
        >
          <motion.div
            animate={{
              width: ["2.5rem", "18rem"],
              scale: [1.2, 1, 1],
              transition: {
                duration: 1.3,
                delay: 0.7,
                ease: "circOut",
              },
            }}
            className="w-fit overflow-x-hidden"
          >
            <motion.div
              animate={{
                opacity: [0, 1, 1, 0.8],
                scale: [0.4, 0.602, 0.602, 0.602],
                transition: {
                  duration: 2,
                  times: [0, 0.35, 0.4, 1],
                  ease: "backInOut",
                },
                transitionEnd: { display: "none" },
              }}
              className="absolute -left-2.5 -top-5"
            >
              <IsoLogo />
            </motion.div>
            <motion.div
              animate={{
                width: ["18rem", "18rem", "17.75rem", "17.75rem"],
                fill: ["#FFFAF800", "#FFFAF8", "#F5ECE3", "#F5ECE3"],
                transition: {
                  duration: 3.5,
                  times: [0.15, 0.57, 0.9, 1],
                  ease: ["easeIn", "easeIn", "easeOut"],
                },
              }}
            >
              <Logo className="fill-inherit" />
            </motion.div>
          </motion.div>
        </motion.div>

        {/* Desktop */}
        <motion.div
          animate={{
            top: ["50%", "0%"],
            left: ["50%", "0%"],
            translateX: ["-50%", "0%"],
            translateY: ["-50%", "0%"],
            transition: {
              duration: 1.5,
              delay: 2,
              ease: "backInOut",
            },
          }}
          className="absolute hidden sm:block"
        >
          <motion.div
            animate={{
              width: ["3.5rem", "28.5rem"],
              scale: [1.2, 1],
              transition: {
                duration: 1.3,
                delay: 0.7,
                ease: "circOut",
              },
            }}
            className="w-fit overflow-x-hidden"
          >
            <motion.div
              animate={{
                opacity: [0, 1, 1, 0],
                scale: [0.5, 0.9, 0.9, 0.9],
                transition: {
                  duration: 2,
                  times: [0, 0.35, 0.9, 1],
                  ease: "backInOut",
                },
                transitionEnd: { display: "none" },
              }}
              className="absolute -left-0.5 top-0 scale-90"
            >
              <IsoLogo />
            </motion.div>
            <motion.div
              animate={{
                width: ["28rem", "28rem", "17.75rem", "17.75rem"],
                fill: ["#FFFAF800", "#FFFAF8", "#4B5647", "#4B5647"],
                transition: {
                  duration: 3.5,
                  times: [0.15, 0.57, 0.9, 1],
                  ease: ["easeIn", "easeIn", "easeOut"],
                },
              }}
            >
              <Logo className="fill-inherit" />
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};
