import { UploadedFileCard } from ".";

interface UploadedFilesSkeletonProps {
  filesCount?: number;
}

export const UploadedFilesSkeleton = ({
  filesCount = 5,
}: UploadedFilesSkeletonProps) => {
  return (
    <div className="flex flex-col gap-4">
      {Array.from({ length: filesCount }, (_, index) => `${index}`).map((i) => (
        <UploadedFileCard.Skeleton key={i} />
      ))}
    </div>
  );
};
