import { jt, t } from "ttag";

import { Alert, LikeIcon } from "@/ui";
import { usePatientPlanQuery } from "../../../hooks";

export const CurrentRequestAlert = () => {
  const {
    patientPlanQuery: { data, isSuccess },
  } = usePatientPlanQuery();

  if (!isSuccess || !data.nextPeriodPlan) {
    return null;
  }

  const subscriptionType =
    Number(data.nextPeriodPlan.durationInMonths) > 1 ? t`bundle` : t`monthly`;

  const nextPeriodPlanChangeStrong = (
    <strong key="next-period-plan-alert-strong">
      {t`change to a ${subscriptionType} ${data.nextPeriodPlan.name} subscription`}
    </strong>
  );

  return (
    <Alert customIcon={<LikeIcon />}>
      {jt`You've already requested a ${nextPeriodPlanChangeStrong}. If you'd like to make another change, just go ahead!`}
    </Alert>
  );
};
