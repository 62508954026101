import { Route, Routes, useLocation } from "react-router-dom";

import { ModalRouter } from "./ModalRouter";

export const ModalsRoutesWrapper = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  return (
    <Routes>
      <Route
        path="*"
        element={<ModalRouter showModal={!!previousLocation} />}
      />
    </Routes>
  );
};
