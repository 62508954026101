import { Link } from "react-router-dom";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { Button, PenIcon } from "@/ui";

const buttonVariance = tv({
  slots: {
    container: "",
    button: "",
  },
  variants: {
    position: {
      header: {
        container: "hidden md:flex",
        button: "bg-salmon-01 text-salmon-09 hover:bg-salmon-02",
      },
      body: {
        container: "sticky bottom-0 -m-6 mt-0 bg-salmon-01 p-6 py-5 md:hidden",
        button: "w-full",
      },
    },
  },
});
type ButtonVariance = VariantProps<typeof buttonVariance>;

export const EditProfileButton = ({ position = "body" }: ButtonVariance) => {
  const { container, button } = buttonVariance({ position });
  return (
    <div className={container()}>
      <Button size="lg" asChild className={button()}>
        <Link to={ROUTES.PROFILE.EDIT}>
          <PenIcon className="size-4" />
          {t`Edit profile`}
        </Link>
      </Button>
    </div>
  );
};
