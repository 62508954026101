import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { t } from "ttag";

import { LanguageSwitch } from "@/components";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import type { Variant } from "@/shared.types";
import { StepProgressBar } from "@/ui";

const getSteps = () =>
  [
    {
      value: EMBEDDABLES_ONBOARDING_ROUTE.ACCOUNT,
      label: t`Account`,
    },
    {
      value: EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO,
      label: t`Main info`,
    },
    {
      value: EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY,
      label: t`Choose journey`,
    },
    {
      value: EMBEDDABLES_ONBOARDING_ROUTE.INTAKE,
      label: t`Intake form`,
    },
  ] as const;

export const EmbeddablesOnboardingProgressBar = ({
  variant,
}: {
  variant?: Variant;
}) => {
  const { pathname: currentPath } = useLocation();
  const queryClient = useQueryClient();

  return (
    <div className="flex w-full flex-col-reverse items-center justify-center gap-9 md:relative md:flex-row">
      <StepProgressBar
        steps={getSteps()}
        currentStep={currentPath}
        variant={variant}
      />

      <LanguageSwitch
        onCheckedChange={() => queryClient.invalidateQueries()}
        className="right-0 self-end md:absolute md:-top-2"
      />
    </div>
  );
};
