import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { t } from "ttag";

import { useBoolean } from "@/hooks";
import { CONSULTATION_PURPOSE } from "@/shared.constants";
import { useConsultationStore } from "@/stores";
import { Badge, DocumentAddIcon, RadioIndicator, VideoCameraIcon } from "@/ui";
import { tw } from "@/utils";
import { MedicationRefillModal, VideoConsultationModal } from "./components";

interface PurposeOptionsProps {
  isRefillDisabled: boolean;
}

export const PurposeOptions = ({ isRefillDisabled }: PurposeOptionsProps) => {
  const purpose = useConsultationStore((state) => state.purpose);
  const setPurpose = useConsultationStore((state) => state.setPurpose);

  const {
    value: showVideoConsultationModal,
    setTrue: openVideoConsultationModal,
    setFalse: closeVideoConsultationModal,
  } = useBoolean(false);
  const {
    value: showMedicationRefillModal,
    setTrue: openMedicationRefillModal,
    setFalse: closeMedicationRefillModal,
  } = useBoolean(false);

  const defaultCheckedValue = isRefillDisabled
    ? CONSULTATION_PURPOSE.CONSULTATION
    : CONSULTATION_PURPOSE.REFILL;

  return (
    <RadioGroupPrimitive.Root
      className="flex flex-col gap-4 lg:grid lg:grid-cols-2"
      defaultValue={defaultCheckedValue}
      value={purpose}
      onValueChange={setPurpose}
    >
      <RadioGroupPrimitive.Item
        asChild
        value={CONSULTATION_PURPOSE.REFILL}
        disabled={isRefillDisabled}
      >
        <div
          className={tw(
            "group flex h-full flex-col gap-8 rounded-2xl border border-brown-05 bg-brown-01 p-4 hover:bg-brown-03/80 disabled:bg-black data-[disabled]:pointer-events-none data-[disabled]:cursor-not-allowed data-[state=checked]:border-brown-06 data-[state=checked]:bg-brown-04 data-[disabled]:opacity-50 lg:w-full",
          )}
        >
          <div className="flex grow flex-col gap-3">
            <div className="flex justify-between">
              <DocumentAddIcon className="size-9 text-nature-08" />

              <div className="flex items-center gap-2">
                <Badge size="lg">{t`Fastest`}</Badge>
                <RadioIndicator size="md" />
              </div>
            </div>
            <div className="flex flex-col gap-2 text-left text-brown-09">
              <h4 className="text-lg font-medium text-brown-10">{t`Order medication`}</h4>
              <p className="text-sm font-bold">{t`Low on your meds? you can have your prescription within 2-3 days`}</p>
              <p className="text-sm">{t`No need for a video consultation! Just fill out the form, and we'll take care of everything. We're here to ensure the smoothest experience.`}</p>
            </div>
          </div>
          <button
            type="button"
            onClick={openMedicationRefillModal}
            className="self-start text-sm text-nature-10 underline underline-offset-2"
          >{t`More info on medication refills`}</button>
        </div>
      </RadioGroupPrimitive.Item>
      <RadioGroupPrimitive.Item
        asChild
        value={CONSULTATION_PURPOSE.CONSULTATION}
      >
        <div className="group flex flex-col gap-8 rounded-2xl border border-brown-05 bg-brown-01 p-4 hover:bg-brown-03/80 data-[state=checked]:border-brown-06 data-[state=checked]:bg-brown-04 lg:w-full">
          <div className="flex grow flex-col gap-3">
            <div className="flex justify-between">
              <VideoCameraIcon className="size-9 text-nature-08" />

              <div className="flex items-center gap-2">
                <RadioIndicator size="md" />
              </div>
            </div>
            <div className="flex grow flex-col gap-2 text-left text-brown-09">
              <h4 className="text-lg font-medium text-brown-10">{t`Video consultation`}</h4>
              <p className="text-sm font-bold">{t`Looking for some one-on-one time with a provider?`}</p>
              <p className="text-sm">{t`By opting for a video consultation, you can schedule an appointment to see a provider. Don't worry about the details; we'll be here to help you find the perfect time slot for your visit.`}</p>
            </div>
          </div>
          <button
            type="button"
            onClick={openVideoConsultationModal}
            className="self-start text-sm text-nature-10 underline underline-offset-2"
          >{t`More info on video consultations`}</button>
        </div>
      </RadioGroupPrimitive.Item>

      <MedicationRefillModal
        show={showMedicationRefillModal}
        onClose={closeMedicationRefillModal}
      />
      <VideoConsultationModal
        show={showVideoConsultationModal}
        onClose={closeVideoConsultationModal}
      />
    </RadioGroupPrimitive.Root>
  );
};
