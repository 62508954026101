import { t } from "ttag";

import { ROUTES } from "@/router";
import { Label } from "@/ui";
import { StepCard } from ".";

export const Step2 = () => (
  <div className="flex flex-col gap-2">
    <Label htmlFor="step-2-checklist" size="md">{t`Second step:`}</Label>

    <StepCard.Root id="step-2-checklist">
      <StepCard.Title>{t`💳 We also need your Insurance Card for Prior Authorization:`}</StepCard.Title>

      <StepCard.Content>
        <p>
          {t`To request prior authorization for branded medications, please upload the front and back of your insurance card directly in `}{" "}
          <StepCard.Link
            to={ROUTES.PROFILE.OVERVIEW}
          >{t`your profile`}</StepCard.Link>
          .
        </p>
      </StepCard.Content>
    </StepCard.Root>
  </div>
);
