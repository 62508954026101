import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const Shape9 = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 675 675"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path d="M339.038 669.107C276.293 669.107 225.439 618.252 225.439 555.508C225.439 492.763 276.293 441.908 339.038 441.908C395.989 441.908 442.338 395.56 442.338 338.609C442.338 281.658 395.989 235.309 339.038 235.309C282.087 235.309 235.738 281.658 235.738 338.609C235.738 401.353 184.884 452.208 122.139 452.208C59.3942 452.208 8.53955 401.353 8.53955 338.647C8.53955 156.395 156.787 8.14819 339.038 8.14819C521.289 8.14819 669.536 156.395 669.536 338.647C669.536 520.898 521.289 669.145 339.038 669.145V669.107Z" />
  </svg>
);
