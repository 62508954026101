import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getUpcomingConsultationsQuery } from "@/api";
import { ConsultationCard } from "@/components";
import { CardPrimitive as Card, VideoCameraIcon } from "@/ui";

export const UpcomingConsultation = () => {
  const { data: upcomingConsultation } = useQuery({
    ...getUpcomingConsultationsQuery(),
    select: (data) => data[0],
  });

  if (!upcomingConsultation) {
    return null;
  }

  return (
    <Card.Root className="bg-salmon-01">
      <Card.Title>
        <VideoCameraIcon className="size-4" />
        {t`Upcoming video consultation`}
      </Card.Title>

      <Card.Content>
        <ConsultationCard
          consultation={upcomingConsultation}
          isFirstConsultation={true}
        />
      </Card.Content>
    </Card.Root>
  );
};
