import { useUserSubscription } from "@/domains/billing/hooks";
import type { SubscriptionFormValues } from "../shared";

export const usePatientPlanQuery = () => {
  const patientPlanQuery = useUserSubscription();

  const patientSubscription = patientPlanQuery.data;

  const formValues = patientSubscription?.nextPeriodPlan
    ? {
        values: {
          subscriptionPriceId:
            patientSubscription.nextPeriodPlan.subscriptionPriceId,
          hasConsent: false,
        } as SubscriptionFormValues,
      }
    : {};

  return {
    patientPlanQuery,
    formValues,
  };
};
