import { t } from "ttag";
import { z } from "zod";

const integerRegex = /^\d+$/;

export const getBMIFormSchema = () =>
  z.object({
    heightFeet: z
      .string()
      .min(1, { message: t`Height is required` })
      .refine((val) => integerRegex.test(val), {
        message: t`Height must be an integer`,
      })
      .pipe(
        z.coerce
          .number({ invalid_type_error: t`Height must be a number` })
          .int({ message: t`Height must be an integer` })
          .nonnegative(),
      ),
    heightInches: z
      .string()
      .min(1, { message: t`Height is required` })
      .refine((val) => integerRegex.test(val), {
        message: t`Height must be an integer`,
      })
      .pipe(
        z.coerce
          .number({ invalid_type_error: t`Height must be a number` })
          .int({ message: t`Height must be an integer` })
          .nonnegative(),
      ),
    weight: z
      .string()
      .min(1, { message: t`Weight is required` })
      .refine((val) => integerRegex.test(val), {
        message: t`Weight must be an integer`,
      })
      .pipe(
        z.coerce
          .number({ invalid_type_error: t`Weight must be a number` })
          .int({ message: t`Weight must be an integer` })
          .nonnegative(),
      ),
  });
