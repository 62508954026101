import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getUpcomingConsultationsQuery } from "@/api";
import { Loader, MedicationRequestFilter } from "@/components";
import { useBoolean } from "@/hooks";
import { RedirectToSync } from "@/modals";
import { ROUTES } from "@/router";
import { CONSULTATION_PURPOSE } from "@/shared.constants";
import { useConsultationStore } from "@/stores";
import { UpcomingConsultationModal } from "./components";

interface FilterForm {
  onContinue: () => void;
}

export const FilterForm = ({ onContinue }: FilterForm) => {
  const navigate = useNavigate();
  const {
    value: showRedirectModal,
    setTrue: openRedirectModal,
    setFalse: closeRedirectModal,
  } = useBoolean(false);
  const {
    value: showUpcomingConsultationModal,
    setTrue: openUpcomingConsultationModal,
    setFalse: closeUpcomingConsultationModal,
  } = useBoolean(false);

  const setPurpose = useConsultationStore((state) => state.setPurpose);

  const {
    data: hasUpcomingConsultations,
    isLoading: isLoadingHasUpcomingConsultations,
  } = useQuery({
    ...getUpcomingConsultationsQuery(),
    select: (data) => !!data.length,
  });

  if (isLoadingHasUpcomingConsultations) {
    return <Loader />;
  }

  const handleFilterResult = (isDisqualified: boolean) => {
    if (!isDisqualified) {
      return onContinue();
    }

    if (hasUpcomingConsultations) {
      return openUpcomingConsultationModal();
    }

    openRedirectModal();
  };

  return (
    <>
      <MedicationRequestFilter
        onBack={() => navigate(ROUTES.CONSULTATIONS.PURPOSE)}
        onNext={handleFilterResult}
      />
      <RedirectToSync
        show={showRedirectModal}
        onClose={closeRedirectModal}
        onConfirm={() => {
          setPurpose(CONSULTATION_PURPOSE.CONSULTATION);
          navigate(ROUTES.CONSULTATIONS.FOLLOW_UP_FORM);
        }}
      />

      <UpcomingConsultationModal
        show={showUpcomingConsultationModal}
        onClose={closeUpcomingConsultationModal}
      />
    </>
  );
};
