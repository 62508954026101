import { useMutation, useQueryClient } from "@tanstack/react-query";

import { submitHealthieForm } from "@/api";
import { errorToast } from "@/ui";

export const useSubmitMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: submitHealthieForm.mutation,
    onSuccess: () => {
      submitHealthieForm.invalidates(queryClient);
    },
    onError: errorToast,
  });
};
