import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { usePatientConditions } from "@/domains/embeddables-onboarding/hooks";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { useBoolean } from "@/hooks";
import { MIN_BMI_VALUE } from "@/shared.constants";
import type { Answer, Question } from "@/shared.types";
import { extractAnswerDetails } from "@/utils";
import { useAnswerHistory } from "./useAnswerHistory";
import { useQuestionFetcher } from "./useQuestionFetcher";

export const useQuestions = () => {
  const [selectedAnswerIds, setSelectedAnswerIds] = useState<Answer["id"][]>();
  const [nextQuestionId, setNextQuestionId] = useState<Question["id"]>();
  const { bmi, isLoadingQuestion, question } =
    useQuestionFetcher(nextQuestionId);

  const {
    value: isDisqualified,
    setTrue: disqualifyUser,
    setFalse: enableUser,
  } = useBoolean(bmi < MIN_BMI_VALUE);
  const {
    value: showSubscriptionUpdateModal,
    setTrue: openSubscriptionUpdateModal,
    setFalse: closeSubscriptionUpdateModal,
  } = useBoolean(false);

  const { isOverAge, hasBundleSubscription } = usePatientConditions();

  const {
    answersHistory,
    actions: { addNewAnswersHistory, removeLastAnswerHistory },
  } = useAnswerHistory();

  const navigate = useNavigate();

  const answerOptions =
    question?.onboardingAnswers.map((answer) => ({
      ...extractAnswerDetails(answer),
      value: answer.id,
      unique: answer.unique,
    })) ?? [];

  const goNext = () => {
    const selectedAnswers = question?.onboardingAnswers.filter((answer) =>
      selectedAnswerIds?.includes(answer.id),
    );

    if (!selectedAnswers?.length) {
      return;
    }

    if (question?.id && selectedAnswerIds?.length) {
      addNewAnswersHistory({ questionId: question.id, selectedAnswerIds });
    }

    if (selectedAnswers?.some((answer) => answer.disqualifier)) {
      return disqualifyUser();
    }

    const nextAnswerGroupId = selectedAnswers?.find(
      (answer) => answer.nextAnswerGroupId,
    )?.nextAnswerGroupId;

    if (nextAnswerGroupId) {
      setSelectedAnswerIds(undefined);
      return setNextQuestionId(nextAnswerGroupId);
    }

    if (isOverAge && hasBundleSubscription) {
      return openSubscriptionUpdateModal();
    }

    navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY);
  };

  const goBack = () => {
    if (!answersHistory.length) {
      return navigate(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS);
    }

    const lastAnswerHistory = removeLastAnswerHistory();

    setNextQuestionId(lastAnswerHistory?.questionId);
    setSelectedAnswerIds(lastAnswerHistory?.selectedAnswerIds);
  };

  const closeDisqualifyAlert = () => {
    if (bmi < MIN_BMI_VALUE) {
      goBack();
    }
    enableUser();
  };

  return {
    question,
    answerOptions,
    selectedAnswerIds,
    isLoadingQuestion,
    isDisqualified,
    showSubscriptionUpdateModal,

    actions: {
      setSelectedAnswerIds,
      goNext,
      goBack,
      closeDisqualifyAlert,
      closeSubscriptionUpdateModal,
    },
  };
};
