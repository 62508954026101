import { t } from "ttag";

import { useScheduleConsultationContext } from "@/contexts";
import { useProvidersQuery } from "@/hooks";
import {
  CalendarMinimalisticIcon,
  CardPrimitive as Card,
  ClockIcon,
  Label,
  UserIcon,
} from "@/ui";
import { formatAvailableTime, formatBackendDate } from "@/utils";

export const AppointmentSum = () => {
  const {
    scheduleDate: { timeSlot },
  } = useScheduleConsultationContext();

  const { data } = useProvidersQuery();

  if (!timeSlot) {
    return null;
  }

  return (
    <Card.Root className="border border-brown-05 bg-transparent">
      <div className="flex gap-1">
        <Label
          htmlFor={`${timeSlot.dateTime}-${timeSlot.providerId}-date`}
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <CalendarMinimalisticIcon className="size-4" />
          {t`Chosen date:`}
        </Label>

        <Card.Description
          id={`${timeSlot.dateTime}-${timeSlot.providerId}-date`}
        >
          {formatBackendDate(timeSlot.dateTime)}
        </Card.Description>
      </div>

      <div className="flex gap-1">
        <Label
          htmlFor={`${timeSlot.dateTime}-${timeSlot.providerId}-time`}
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <ClockIcon className="size-4" />
          {t`Chosen time:`}
        </Label>
        <Card.Description
          id={`${timeSlot.dateTime}-${timeSlot.providerId}-time`}
        >
          {`${formatAvailableTime(timeSlot.dateTime)} ${timeSlot.timezone}`}
        </Card.Description>
      </div>

      <div className="flex gap-1">
        <Label
          htmlFor={`${timeSlot.dateTime}-${timeSlot.providerId}-provider-name`}
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <UserIcon className="size-4" />
          {t`Provider:`}
        </Label>
        <Card.Description
          id={`${timeSlot.dateTime}-${timeSlot.providerId}-provider-name`}
        >
          {data?.find((provider) => provider.id === timeSlot.providerId)
            ?.fullName ?? "Earliest available"}
        </Card.Description>
      </div>
    </Card.Root>
  );
};
