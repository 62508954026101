import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { getDocumentQuery, updateDocument } from "@/api";
import { DOCUMENT_ICON, getDocumentEditNameLabel } from "@/components";
import type { DocumentFolder, InferSchema, ModalProps } from "@/shared.types";
import {
  Button,
  DocumentAddFixedIconMono,
  errorToast,
  IconWrapper,
  Input,
  Loader,
  Modal,
  useToastStore,
} from "@/ui";
import { tw } from "@/utils";

const getEditSchema = () =>
  z.object({
    name: z.string().min(1, { message: "Name is required" }),
  });
type EditValues = InferSchema<typeof getEditSchema>;

export const EditDocument = ({ show, onClose }: ModalProps) => {
  const { pushToast } = useToastStore();
  const { documentId, folder } = useParams<{
    documentId: string;
    folder: DocumentFolder;
  }>();
  const { data, isLoading } = useQuery(getDocumentQuery(documentId));

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<EditValues>({
    resolver: zodResolver(getEditSchema()),
    values: { name: data?.name ?? "" },
  });

  const queryClient = useQueryClient();
  const { mutate: updateDocumentMutation, isPending } = useMutation({
    mutationFn: updateDocument.mutation,
    onSuccess: () => {
      onClose();
      updateDocument.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Document successfully updated!`,
      });
    },
    onError: errorToast,
  });

  if (!folder || !documentId) {
    return null;
  }

  const lowerCaseFolderLabel = getDocumentEditNameLabel(folder).toLowerCase();

  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<DocumentAddFixedIconMono />}
      title={t`Edit ${lowerCaseFolderLabel}`}
      className="min-h-80 text-salmon-10"
    >
      {isLoading && <Loader className="mx-auto mt-5" />}

      {data && (
        <form
          onSubmit={handleSubmit((value) =>
            updateDocumentMutation({ ...value, id: documentId }),
          )}
          className="flex flex-col gap-6"
        >
          <Input
            id={"file-name"}
            label={getDocumentEditNameLabel(folder)}
            left={DOCUMENT_ICON[folder]}
            {...register("name")}
          />

          <div className="flex items-center justify-center gap-3.5">
            <Button onClick={onClose} size="lg" variant="secondary">
              {isDirty ? t`Discard change` : t`Cancel`}
            </Button>

            <Button
              disabled={!isDirty || isPending}
              size="lg"
              type="submit"
              className={tw("relative", isPending && "text-transparent")}
            >
              {t`Save change`}
              {isPending && (
                <IconWrapper className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
                  <Loader variant="dark" />
                </IconWrapper>
              )}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
