import { Link } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { Button, illustratedIcons, Modal } from "@/ui";

export const UpcomingConsultationModal = ({ show, onClose }: ModalProps) => (
  <Modal
    show={show}
    onClose={onClose}
    icon={<illustratedIcons.Video />}
    title={t`Video-consultation Needed`}
    description={
      <p>
        {t`Hi there! Based on your responses, we can't process your refill request right now. You'll need to have a video consultation with a provider.`}
        <br className="block py-3 content-['']" />
        {t`But good news! You already have a video consultation scheduled for this week. You can discuss your situation during that appointment.`}
      </p>
    }
  >
    <Button asChild>
      <Link to={ROUTES.CONSULTATIONS.OVERVIEW}>{t`Review consultations`}</Link>
    </Button>
  </Modal>
);
