import type { QueryClient } from "@tanstack/react-query";

import type { HealthieField, Patient } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { publicAPI } from "./axios";
import { PATIENT_DOMAIN } from "./patient";
import { PORTAL_DOMAIN } from "./portal";

const DOMAIN = "forms";

export interface HealthieForm {
  formId: string;
  fields: HealthieField[];
}

export const getFormQuery = (formId: string, patientId: Patient["id"]) => ({
  queryKey: [DOMAIN, "getFormQuery", formId],
  queryFn: async () => {
    const { data } = await publicAPI.get<ServiceResponse<HealthieForm>>(
      `/forms/?patientId=${patientId}&formId=${formId}`,
    );

    return data.data;
  },
});

type FieldValues = Record<string, string | string[]>;
interface SubmitField {
  value: string | string[] | undefined;
  fieldId: string;
}

const parseFormFields = (params: FieldValues): SubmitField[] => {
  return Object.keys(params)
    .map((key) => ({
      value: Array.isArray(params[key]) ? params[key].join("\n") : params[key],
      fieldId: key,
    }))
    .filter((field) => !!field.value);
};

interface SubmitFormParams {
  formId: string;
  patientId: string;
  answers: FieldValues;
}

export const submitHealthieForm = {
  mutation: async (params: SubmitFormParams) => {
    const { data } = await publicAPI.post<ServiceResponse<unknown>>(
      "/forms",
      {
        formId: params.formId,
        patientId: params.patientId,
        answers: parseFormFields(params.answers),
      },
      { headers: { "Content-Type": "multipart/form-data" } },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [PATIENT_DOMAIN] });
    void queryClient.invalidateQueries({
      queryKey: [PORTAL_DOMAIN],
    });
  },
};
