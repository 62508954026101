import type { SVGProps } from "@/shared.types";

export const GenderIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M3.47845 6.89783C3.47845 4.95347 5.05467 3.37725 6.99903 3.37725C8.94339 3.37725 10.5196 4.95347 10.5196 6.89783C10.5196 8.84217 8.94328 10.4184 6.99889 10.4184C5.05456 10.4184 3.47845 8.84222 3.47845 6.89783ZM6.99903 2.19631C4.40245 2.19631 2.29751 4.30125 2.29751 6.89783C2.29751 9.29443 4.09049 11.2721 6.40843 11.5626V12.5533H5.53082C5.20471 12.5533 4.94035 12.8177 4.94035 13.1438C4.94035 13.4699 5.20471 13.7342 5.53082 13.7342H6.40847L6.40858 15.1993C6.4086 15.5255 6.67299 15.7898 6.99909 15.7898C7.3252 15.7897 7.58954 15.5254 7.58952 15.1993L7.58941 13.7342H8.46728C8.79339 13.7342 9.05775 13.4699 9.05775 13.1438C9.05775 12.8177 8.79339 12.5533 8.46728 12.5533H7.58936V11.5626C9.90729 11.2721 11.7005 9.29447 11.7005 6.89783C11.7005 4.30125 9.59561 2.19631 6.99903 2.19631Z" />
    <path
      fillRule="evenodd"
      d="M10.2124 0.745546C10.2124 0.532708 10.385 0.360168 10.5978 0.360168H13.167C13.3798 0.360168 13.5524 0.532708 13.5524 0.745546V3.31473C13.5524 3.52757 13.3798 3.70011 13.167 3.70011C12.9542 3.70011 12.7816 3.52757 12.7816 3.31473V1.67593L10.441 4.01652C11.1 4.7987 11.497 5.80878 11.497 6.91159C11.497 9.3947 9.48407 11.4077 7.00096 11.4077C4.51784 11.4077 2.50488 9.3947 2.50488 6.91159C2.50488 4.42848 4.51784 2.41552 7.00096 2.41552C8.10377 2.41552 9.11384 2.81257 9.89603 3.47151L12.2366 1.13092H10.5978C10.385 1.13092 10.2124 0.958384 10.2124 0.745546ZM7.00096 3.18627C4.94352 3.18627 3.27564 4.85415 3.27564 6.91159C3.27564 8.96902 4.94352 10.6369 7.00096 10.6369C9.05839 10.6369 10.7263 8.96902 10.7263 6.91159C10.7263 4.85415 9.05839 3.18627 7.00096 3.18627Z"
    />
  </svg>
);
