import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { uploadInsuranceCard } from "@/api";
import { IMAGE_FILE_TYPES } from "@/shared.constants";
import type { Policy } from "@/shared.types";
import {
  CardImageItem,
  Dropzone,
  errorToast,
  OverlayLoader,
  useToastStore,
} from "@/ui";
import { ProfileCard } from "../components";

export const ACCEPT_IMAGE_FILE = {
  [IMAGE_FILE_TYPES.PNG]: [],
  [IMAGE_FILE_TYPES.JPEG]: [],
};

interface EditInsuranceProps {
  policy?: Policy;
}

export const InsuranceCard = ({ policy }: EditInsuranceProps) => {
  const [insuranceCard, setInsuranceCard] = useState({
    cardFrontUrl: policy?.cardFrontUrl,
    cardBackUrl: policy?.cardBackUrl,
  });

  const { pushToast } = useToastStore();

  const queryClient = useQueryClient();
  const {
    mutate: uploadInsuranceCardMutation,
    isPending,
    variables,
  } = useMutation({
    mutationFn: uploadInsuranceCard.mutation,
    onSuccess: (data) => {
      setInsuranceCard((prev) => ({ ...prev, ...data }));
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Insurance card successfully updated!`,
      });
      uploadInsuranceCard.invalidates(queryClient);
    },
    onError: errorToast,
  });

  return (
    <ProfileCard.Root>
      <ProfileCard.Header>
        <ProfileCard.Title>{t`Insurance`}</ProfileCard.Title>
      </ProfileCard.Header>

      <ProfileCard.Content className="grid gap-4 text-sm text-salmon-10 lg:grid-cols-2">
        {insuranceCard.cardFrontUrl ? (
          <CardImageItem
            src={insuranceCard.cardFrontUrl ?? policy?.cardFrontUrl}
            alt={t`Insurance card front`}
            emptyMessage={
              <p className="-mt-4 px-4 text-center text-sm text-salmon-10">
                {t`No photo uploaded yet.`}
                <br />
                {t`This area is for the front side of your insurance card.`}
              </p>
            }
          />
        ) : (
          <div className="relative flex overflow-hidden rounded-2xl">
            <Dropzone
              onDrop={(file) =>
                uploadInsuranceCardMutation({ insuranceCardFront: file[0] })
              }
              compact
              accept={ACCEPT_IMAGE_FILE}
              label={t`Drop your front card photo here`}
              placeholder={t`Maximum size: 2MB`}
            />

            {Boolean(isPending && variables.insuranceCardFront) && (
              <OverlayLoader />
            )}
          </div>
        )}

        {insuranceCard.cardBackUrl ? (
          <CardImageItem
            src={insuranceCard.cardBackUrl ?? policy?.cardBackUrl}
            alt={t`Insurance card back`}
            emptyMessage={
              <p className="-mt-4 px-4 text-center text-sm text-salmon-10">
                {t`No photo uploaded yet.`}
                <br />
                {t`This area is for the back side of your insurance card.`}
              </p>
            }
          />
        ) : (
          <div className="relative flex overflow-hidden rounded-2xl">
            <Dropzone
              onDrop={(file) =>
                uploadInsuranceCardMutation({ insuranceCardBack: file[0] })
              }
              compact
              accept={ACCEPT_IMAGE_FILE}
              label={t`Drop your back card photo here`}
              placeholder={t`Maximum size: 2MB`}
            />

            {Boolean(isPending && variables.insuranceCardBack) && (
              <OverlayLoader />
            )}
          </div>
        )}
      </ProfileCard.Content>
    </ProfileCard.Root>
  );
};
