import { useQuery } from "@tanstack/react-query";

import { billingQueriesStore } from "@/domains/billing/api";
import type { PlansParams } from "@/domains/billing/models";

export const useOnboardingPatientPlans = ({
  durationInMonths,
  couponCode,
}: PlansParams) => {
  return useQuery({
    ...billingQueriesStore.stripe.plans._ctx.onboarding._ctx.patient({
      durationInMonths,
      couponCode,
    }),
    staleTime: Infinity,
  });
};
