import { t } from "ttag";

import { SeshLogo } from "@/assets";
import { EXTERNAL_LINK } from "@/router";

export const SeshLinkCard = () => {
  return (
    <a
      target="_blank"
      href={EXTERNAL_LINK.SESH}
      rel="noreferrer"
      className="flex items-center gap-6 rounded-2xl border border-[#FFA5E8] px-7 py-4 md:gap-4 lg:max-w-44 lg:flex-col lg:justify-center lg:p-2"
    >
      <img src={SeshLogo} alt="Sesh Logo" className="w-20 lg:w-28" />
      <span className="text-center leading-5 text-[#641450]">
        {t`Sesh Fitness `}
        <strong>{t`FREE`}</strong>
        {t` Program for Fridays Members`}
      </span>
    </a>
  );
};
