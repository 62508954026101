import { t } from "ttag";

import { ConsultationLanguagePreference } from "@/components/schedule-consultation/ConsultationLanguagePreference";
import { CardPrimitive as Card } from "@/ui";

export const LanguagePreference = () => {
  return (
    <Card.Root className="gap-6 bg-brown-01">
      <Card.Title>{t`1. Language preference`}</Card.Title>

      <ConsultationLanguagePreference />
    </Card.Root>
  );
};
