import { Link } from "react-router-dom";
import { t } from "ttag";

import { MedicationRequestCard } from "@/components";
import { ROUTES } from "@/router";
import { usePortalUserStore } from "@/stores";
import { CardPrimitive as Card, PillIcon } from "@/ui";

export const CurrentMedicationRequest = () => {
  const intakeFormCompleted = usePortalUserStore(({ portalUser }) =>
    Boolean(portalUser?.intakeFormCompleted),
  );

  if (!intakeFormCompleted) {
    return null;
  }

  return (
    <Card.Root className="flex-1 bg-salmon-01">
      <Card.Header className="items-center gap-2 gap-x-4 md:flex-row">
        <Card.Title>
          <PillIcon className="size-4" />
          {t`Medication refill requests`}
        </Card.Title>
        <Link
          to={ROUTES.MY_MEDS}
          className="hidden font-medium text-nature-08 md:block"
        >
          {t`View all requests`}
        </Link>
      </Card.Header>

      <Card.Content className="text-center md:text-left">
        <MedicationRequestCard className="border-transparent md:border-salmon-07" />

        <div className="flex w-full items-center justify-center pb-2 md:hidden">
          <Link to={ROUTES.MY_MEDS} className="font-medium text-nature-08">
            {t`View all requests`}
          </Link>
        </div>
      </Card.Content>
    </Card.Root>
  );
};
