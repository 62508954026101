import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { tw } from "@/utils";

const { Root, Trigger } = TooltipPrimitive;

const Provider = (
  props: ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>,
) => <TooltipPrimitive.Provider delayDuration={100} {...props} />;
Provider.displayName = "Tooltip.Provider";

const Content = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={tw(
      "max-w-60 rounded-2xl border border-salmon-05 bg-salmon-10 p-3.5 text-xs text-brown-01 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=top]:slide-in-from-bottom-2",
      className,
    )}
    {...props}
  >
    {children}
    <TooltipPrimitive.Arrow
      className="z-10 -translate-y-0.5 fill-salmon-10"
      width={23}
      height={12}
    />
  </TooltipPrimitive.Content>
));
Content.displayName = "Tooltip.Content";

export { Root, Trigger, Content, Provider };
