import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const Shape10 = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 178 178"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path d="M93.11 3V134.6C93.11 157.08 111.34 175.31 133.82 175.31C156.3 175.31 174.53 157.08 174.53 134.6V3H93.11Z" />
    <path d="M4 130.315C4 152.795 22.23 171.025 44.71 171.025C69.3419 171.025 89.31 151.057 89.31 126.425L89.31 89.605L44.71 89.605C22.23 89.605 4 107.835 4 130.315Z" />
    <path d="M3 43.71C3 66.19 21.23 84.42 43.71 84.42H89.15V3H43.71C21.23 3 3 21.23 3 43.71Z" />
  </svg>
);
