import { t } from "ttag";

import { HealthieForm } from "@/components";
import { usePatientQuery } from "@/hooks";
import type { HealthieFormType } from "@/shared.types";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";

interface HealthieFormProps {
  formType: HealthieFormType;
  onCancel: () => void;
  onSubmit: () => void;
}

export const HealthieFormSection = ({
  formType,
  onCancel,
  onSubmit,
}: HealthieFormProps) => {
  const { data: patient } = usePatientQuery();
  if (!patient?.id) {
    return null;
  }

  return (
    <HealthieForm
      onSubmit={onSubmit}
      formType={formType}
      patientId={patient.id}
    >
      <div className="flex flex-col-reverse items-stretch justify-between gap-4 md:flex-row md:items-center">
        <Button onClick={onCancel} variant="secondary" size="lg">
          <AltArrowLeftIcon className="hidden size-4 md:block" />

          {t`Back`}
        </Button>
        <Button type="submit" size="lg">
          {t`Next`}
          <AltArrowRightIcon className="hidden size-4 md:block" />
        </Button>
      </div>
    </HealthieForm>
  );
};
