import { t } from "ttag";

import { ConsultationLanguagePreference } from "@/components";
import { CardPrimitive as Card } from "@/ui";

export const LanguagePreference = () => {
  return (
    <Card.Root className="gap-6 bg-brown-01 lg:flex-row lg:items-center">
      <Card.Title>{t`Consultation language preference`}</Card.Title>

      <ConsultationLanguagePreference />
    </Card.Root>
  );
};
