import { Loader } from "@/components";
import { usePatientQuery } from "@/hooks";
import { ScreenLayout } from "@/layouts";
import { EditProfileButton } from "./components";
import { Contact, InsuranceCard, ProfileOverviewHeader } from "./sections";
import { GeneralInformation } from "./sections/GeneralInformation";

export const MyProfile = () => {
  const { data: patient, isLoading } = usePatientQuery();

  return (
    <ScreenLayout variant="secondary">
      <ProfileOverviewHeader />

      {isLoading && <Loader />}

      {patient && (
        <div className="grid gap-4 xl:grid-cols-2">
          <GeneralInformation patient={patient} />

          <Contact patient={patient} />

          <div className="xl:col-span-2">
            <InsuranceCard policy={patient.policy} />
          </div>
        </div>
      )}

      <EditProfileButton />
    </ScreenLayout>
  );
};
