import { t } from "ttag";

import { Badge, CardPrimitive as Card, Tooltip } from "@/ui";
import { getSubscriptionBenefits } from "../../../utils";

export const SubscriptionInfo = () => {
  return (
    <Card.Root className="w-full border border-salmon-07 bg-transparent">
      <Card.Title className="self-center md:self-start">{t`All subscriptions include:`}</Card.Title>
      <Card.Content className="flex flex-wrap items-center justify-center gap-x-1 gap-y-2 md:items-start md:justify-start">
        <Tooltip.Provider>
          {getSubscriptionBenefits().map((benefit) => (
            <Tooltip.Root key={`subscription-benefit-${benefit.label}`}>
              <Tooltip.Trigger
                asChild
                className="border-transparent bg-brown-01 text-salmon-10 duration-100 data-[state=delayed-open]:bg-salmon-10 data-[state=instant-open]:bg-salmon-10 data-[state=delayed-open]:text-salmon-01 data-[state=instant-open]:text-salmon-01"
              >
                <Badge variant="secondary" size="md">
                  {benefit.label}
                </Badge>
              </Tooltip.Trigger>
              <Tooltip.Content>{benefit.detail}</Tooltip.Content>
            </Tooltip.Root>
          ))}
        </Tooltip.Provider>
      </Card.Content>
    </Card.Root>
  );
};
