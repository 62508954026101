import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { useShallow } from "zustand/shallow";

import { uploadOnboardingImages } from "@/api";
import { FullScreenLoader } from "@/components";
import {
  OnboardingActionButtons,
  OnboardingLayout,
} from "@/domains/onboarding/components";
import { getOnboardingImageUploadsSchema } from "@/shared.schemas";
import type { OnboardingImageUploadsValues } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { errorToast, Form } from "@/ui";
import {
  GLPMedicationUploads,
  InsuranceCardUpload,
  LabTestUploads,
} from "./sections";

interface ImageUploadsProps {
  onGoBack: () => void;
  onNext: () => void;
}

const defaultValues: OnboardingImageUploadsValues = {
  insuranceCardBack: undefined,
  insuranceCardFront: undefined,
  uploadedFiles: { labs: [], other: [] },
};

export const ImageUploads = ({ onGoBack, onNext }: ImageUploadsProps) => {
  const { userId, code } = useOnboardingStore(
    useShallow((state) => ({
      userId: state.user?.id,
      code: state.user?.code,
    })),
  );

  const queryClient = useQueryClient();
  const { mutate: uploadOnboardingImagesMutation, isPending } = useMutation({
    mutationFn: uploadOnboardingImages.mutation,
    onSuccess: () => {
      uploadOnboardingImages.invalidates(queryClient);
      onNext();
    },
    onError: errorToast,
  });

  const form = useForm<OnboardingImageUploadsValues>({
    resolver: zodResolver(getOnboardingImageUploadsSchema()),
    defaultValues,
  });

  const handleCancel = () => {
    form.reset(defaultValues);
    onGoBack();
  };

  const handleContinue = form.handleSubmit((values) => {
    if (userId && code) {
      uploadOnboardingImagesMutation({ userId, code, ...values });
    }
  });

  return (
    <OnboardingLayout showProgressBar={false}>
      <Form.Provider {...form}>
        <form
          onSubmit={form.handleSubmit((values) =>
            alert(JSON.stringify(values)),
          )}
          className="flex max-w-3xl flex-col gap-4.5 pb-10 text-center sm:gap-12 sm:px-7 sm:pb-20"
        >
          <LabTestUploads />

          <InsuranceCardUpload />

          <GLPMedicationUploads />

          <OnboardingActionButtons
            continueLabel={form.formState.isDirty ? t`Submit` : t`Skip`}
            onCancel={handleCancel}
            onContinue={handleContinue}
          />
        </form>
      </Form.Provider>

      {isPending && <FullScreenLoader />}
    </OnboardingLayout>
  );
};
