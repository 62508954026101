import React from "react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { ErrorBoundaryFallback } from "../../resources/js/screens/ErrorBoundaryFallback";
import { env } from "./env";
import { Router } from "./router";
import { Toasts } from "./ui";

import "../css/app.css";
import "./bootstrap";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { addLocale, useLocale } from "ttag";

import translationEs from "../lang/es/es.po.json";
import { useAppSettingsStore } from "./stores";

const queryClient = new QueryClient();

Sentry.init({
  dsn: env.VITE_SENTRY_DSN_PUBLIC,
  environment: env.VITE_SENTRY_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

addLocale("es", translationEs);
const stripePromise = loadStripe(env.VITE_STRIPE_PUBLIC_KEY);

function AppWrapper() {
  const language = useAppSettingsStore((state) => state.language);
  useLocale(language);

  return (
    <Elements stripe={stripePromise} options={{ locale: language }}>
      <Router />
    </Elements>
  );
}

let container: ReturnType<typeof document.getElementById> = null;

document.addEventListener("DOMContentLoaded", () => {
  if (!container) {
    container = document.getElementById("app")!;
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
            <AppWrapper />
          </Sentry.ErrorBoundary>

          {ReactDOM.createPortal(<Toasts />, document.body)}

          {env.VITE_APP_ENV === "local" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </React.StrictMode>,
    );
  }
});
