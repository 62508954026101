import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { PATIENT_DOMAIN, PORTAL_DOMAIN, STRIPE_DOMAIN } from "@/api";
import { ROUTES } from "@/router";
import { errorToast } from "@/ui";
import { refundAndCancelSubscription } from "../api";

export const useRefundAndCancelSubscription = () => {
  const queryClient = useQueryClient();

  const domainsToInvalidate = [STRIPE_DOMAIN, PATIENT_DOMAIN, PORTAL_DOMAIN];

  const navigate = useNavigate();

  return useMutation({
    mutationFn: refundAndCancelSubscription,
    onSuccess: () => {
      domainsToInvalidate.forEach((domain) => {
        void queryClient.invalidateQueries({ queryKey: [domain] });
      });
      navigate(ROUTES.BASE);
    },
    onError: errorToast,
  });
};
