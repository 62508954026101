export const bytesToMegabytes = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);

  return `${megabytes.toFixed(2)}MB`;
};

export const formatBytes = (
  bytes: number,
  opts: {
    decimals?: number;
    sizeType?: "accurate" | "normal";
  } = {},
) => {
  if (bytes === 0) {
    return "0 Byte";
  }

  const { decimals = 0, sizeType = "normal" } = opts;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === "accurate"
      ? (accurateSizes[i] ?? "ByTest")
      : (sizes[i] ?? "Bytes")
  }`;
};
