import { t } from "ttag";

import { ROUTES } from "@/router";
import { Header, UserIcon } from "@/ui";

export const MyMedsHeader = () => {
  return (
    <Header.Root>
      <Header.Background />

      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.MY_MEDS,
              icon: UserIcon,
              label: t`My Meds`,
            },
          ]}
        />

        <Header.Title>{t`My Meds`}</Header.Title>
      </Header.Content>
    </Header.Root>
  );
};
