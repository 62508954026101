import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  AnswerCheckboxGroup,
  AnswerRadioGroup,
  Loader,
  OnboardingHeader,
} from "@/components";
import { SubscriptionUpdateModal } from "@/domains/embeddables-onboarding/components";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { FIELD_TYPE } from "@/shared.constants";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";
import { tw } from "@/utils";
import { BMISurveyDQScreenAlert } from "../BMISurveyDQScreenAlert";
import { useQuestions } from "./hooks";

export const Questions = () => {
  const {
    question,
    answerOptions,
    selectedAnswerIds,
    isLoadingQuestion,
    isDisqualified,
    showSubscriptionUpdateModal,
    actions: {
      setSelectedAnswerIds,
      goNext,
      goBack,
      closeDisqualifyAlert,
      closeSubscriptionUpdateModal,
    },
  } = useQuestions();

  const navigate = useNavigate();

  if (isDisqualified) {
    return <BMISurveyDQScreenAlert onGoBack={closeDisqualifyAlert} />;
  }

  if (isLoadingQuestion) {
    return <Loader />;
  }

  if (!question) {
    return (
      <Navigate
        replace
        to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS}
      />
    );
  }

  return (
    <>
      <OnboardingHeader title={question.question} />

      <div className="grow">
        {question.type === FIELD_TYPE.CHECKBOX && (
          <AnswerCheckboxGroup
            id={`${question.id}-${question.type}`}
            options={answerOptions}
            value={selectedAnswerIds}
            onChange={setSelectedAnswerIds}
            className={tw(
              "gap-4 sm:gap-2",
              answerOptions.length < 4 && "xl:grid-cols-3",
            )}
          />
        )}

        {question.type === FIELD_TYPE.RADIO && (
          <AnswerRadioGroup
            id={`${question.id}-${question.type}`}
            options={answerOptions}
            value={selectedAnswerIds?.[0]}
            onValueChange={(value) => setSelectedAnswerIds([value])}
            className={tw(
              "gap-4 sm:gap-2",
              answerOptions.length < 4 && "xl:grid-cols-3",
              answerOptions.length === 2 && "lg:grid-cols-2 xl:grid-cols-2",
            )}
          />
        )}
      </div>

      <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
        <Button
          onClick={goBack}
          variant="secondary"
          size="lg"
          className="w-full sm:w-fit"
        >
          <AltArrowLeftIcon className="hidden size-4 sm:block" />
          {t`Back`}
        </Button>

        <Button
          onClick={goNext}
          disabled={!selectedAnswerIds?.length}
          size="lg"
          className="w-full sm:w-fit"
        >
          {t`Next`}
          <AltArrowRightIcon className="hidden size-4 sm:block" />
        </Button>
      </div>

      <SubscriptionUpdateModal
        show={showSubscriptionUpdateModal}
        onClose={closeSubscriptionUpdateModal}
        onContinue={() => navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY)}
      />
    </>
  );
};
