import type { PaymentMethod } from "@/shared.types";
import { CreditCard } from ".";

interface PaymentMethodCardProps {
  paymentMethod: PaymentMethod;
}
export const PaymentMethodCard = ({
  paymentMethod,
}: PaymentMethodCardProps) => (
  <CreditCard.Root>
    <CreditCard.Chip />
    <CreditCard.Content>
      <div className="flex grow flex-col justify-between gap-2 pb-1 pt-12 xl:pt-14">
        <CreditCard.Title className="text-2xl font-medium text-salmon-10">
          **** **** **** {paymentMethod.lastFourDigits}
        </CreditCard.Title>

        <CreditCard.Description className="text-xs text-salmon-08">
          {paymentMethod.expireDate}
        </CreditCard.Description>
      </div>
    </CreditCard.Content>

    <CreditCard.Background>
      <CreditCard.Shapes />
    </CreditCard.Background>
  </CreditCard.Root>
);
