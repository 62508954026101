import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

import { tw } from "@/utils";
import { SubscriptionItem, SubscriptionItemSkeleton } from "./sections";

const Root = forwardRef<
  ElementRef<typeof RadioGroup.Root>,
  ComponentPropsWithoutRef<typeof RadioGroup.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroup.Root
      className={tw("flex w-full flex-wrap justify-center gap-4", className)}
      {...props}
      ref={ref}
    />
  );
});
Root.displayName = "SubscriptionRadioGroup.Root";

export {
  Root,
  SubscriptionItem as Item,
  SubscriptionItemSkeleton as ItemSkeleton,
};
