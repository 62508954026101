import { COACHING_SESSION_TYPE, PROVIDER_FOLDER } from "@/shared.constants";
import type {
  CoachingSessionType,
  Folder,
  ProviderFolder,
} from "@/shared.types";

export const isSameFile = (file1: File, file2: File) =>
  JSON.stringify(file1) === JSON.stringify(file2);

export const isProviderFile = (x: Folder): x is ProviderFolder => {
  return Object.values(PROVIDER_FOLDER).includes(x as ProviderFolder);
};

export const isCoachingSessionFile = (x: Folder): x is CoachingSessionType => {
  return Object.values(COACHING_SESSION_TYPE).includes(
    x as CoachingSessionType,
  );
};
