import { Loader } from "./Loader";

export const OverlayLoader = () => {
  return (
    <div className="absolute left-0 top-0 z-50 flex size-full items-center justify-center">
      <div className="absolute size-full bg-black/20 backdrop-blur-sm" />
      <Loader size="md" />
    </div>
  );
};
