import { t } from "ttag";

import { GraphPenIcon } from "@/ui/illustrated-icons/business/GraphPenIcon";

export const ChartEmpty = () => {
  return (
    <div className="flex h-[400px] flex-col items-center justify-center gap-4 p-4 text-center">
      <GraphPenIcon className="-mt-16 w-20" />

      <h4 className="text-lg font-bold leading-6 text-brown-09">{t`No entries for this period yet`}</h4>

      <p className="leading-5 text-brown-08">{t`Record your weight entry with the “Log data” button to begin tracking your progress.`}</p>
    </div>
  );
};
