import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from "react";
import { createContext, forwardRef, useContext } from "react";

import { VARIANT } from "@/shared.constants";
import { tw } from "@/utils";
import { AltArrowLeftIcon } from "../icons";
import { Shape7, Shape8 } from "../shapes";
import type { BreadcrumbProps } from "./Breadcrumb";
import { Breadcrumb as BaseBreadcrumb } from "./Breadcrumb";
import { Button as BaseButton } from "./Button";
import { IconWrapper } from "./Icons";

type HeaderVariant = typeof VARIANT.PRIMARY | typeof VARIANT.SECONDARY;

const HeaderVariantContext = createContext<HeaderVariant>(VARIANT.PRIMARY);

const Root = forwardRef<
  HTMLElement,
  HTMLAttributes<HTMLElement> & { variant?: HeaderVariant }
>(({ className, variant = VARIANT.PRIMARY, ...props }, ref) => {
  return (
    <HeaderVariantContext.Provider value={variant}>
      <header
        ref={ref}
        className={tw(
          "relative z-10 w-full shrink-0 overflow-hidden",
          variant === VARIANT.PRIMARY && "rounded-2xl",
          variant === VARIANT.SECONDARY && "px-0",
          className,
        )}
        {...props}
      />
    </HeaderVariantContext.Provider>
  );
});
Root.displayName = "Header.Root";

const Background = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "absolute inset-0 -z-10 flex h-full w-full justify-end gap-2.5 bg-nature-08 text-nature-05",
        className,
      )}
      {...props}
    >
      {children ?? (
        <>
          <div className="w-64 -translate-y-24 -scale-x-100 opacity-30">
            <Shape8 />
          </div>
          <div className="-mr-8 w-60 opacity-30">
            <Shape7 />
          </div>
        </>
      )}
    </div>
  ),
);
Background.displayName = "Header.Background";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const variant = useContext(HeaderVariantContext);
    return (
      <div
        ref={ref}
        className={tw(
          "flex flex-col gap-0.5 md:gap-3",
          variant === VARIANT.PRIMARY && "p-5",
          variant === VARIANT.SECONDARY && "p-0 md:py-5",
          className,
        )}
        {...props}
      />
    );
  },
);
Content.displayName = "Header.Content";

const Breadcrumb = ({ className, ...props }: BreadcrumbProps) => {
  const variant = useContext(HeaderVariantContext);

  return (
    <BaseBreadcrumb
      color={variant}
      className={tw("hidden md:block", className)}
      {...props}
    />
  );
};
Breadcrumb.displayName = "Header.Breadcrumb";

const Title = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => {
  const variant = useContext(HeaderVariantContext);
  return (
    <h3
      ref={ref}
      className={tw(
        "font-serif text-3xl leading-normal",
        variant === VARIANT.PRIMARY && "text-brown-01",
        variant === VARIANT.SECONDARY && "text-brown-09",
        className,
      )}
      {...props}
    >
      {children}
    </h3>
  );
});
Title.displayName = "Header.Title";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const variant = useContext(HeaderVariantContext);
  return (
    <p
      ref={ref}
      className={tw(
        "text-sm font-medium",
        variant === VARIANT.PRIMARY && "text-nature-02",
        variant === VARIANT.SECONDARY && "text-brown-09",
        className,
      )}
      {...props}
    />
  );
});
Description.displayName = "Header.Description";

const Actions = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex items-center gap-3 self-start", className)}
      {...props}
    />
  ),
);
Actions.displayName = "Header.Actions";

const GoBackButton = (props: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <IconWrapper size="sm" as="button" type="button" {...props}>
      <AltArrowLeftIcon />
    </IconWrapper>
  );
};
GoBackButton.displayName = "Header.GoBackButton";

const Button = forwardRef<
  ElementRef<typeof BaseButton>,
  ComponentPropsWithoutRef<typeof BaseButton>
>(({ className, ...props }, ref) => {
  const variant = useContext(HeaderVariantContext);
  return (
    <BaseButton
      ref={ref}
      size="lg"
      className={tw(
        "text-sm disabled:text-opacity-60 md:text-base",
        variant === VARIANT.PRIMARY &&
          "bg-salmon-01 text-salmon-09 hover:bg-salmon-02",
        className,
      )}
      {...props}
    />
  );
});
Button.displayName = "Header.Button";

export {
  Root,
  Background,
  Content,
  Breadcrumb,
  Title,
  Description,
  Actions,
  Button,
  GoBackButton,
};
