import type { SVGProps } from "@/shared.types";

export const DollarIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.00004 1.83337C4.59428 1.83337 1.83337 4.59428 1.83337 8.00004C1.83337 11.4058 4.59428 14.1667 8.00004 14.1667C11.4058 14.1667 14.1667 11.4058 14.1667 8.00004C14.1667 4.59428 11.4058 1.83337 8.00004 1.83337ZM0.833374 8.00004C0.833374 4.042 4.042 0.833374 8.00004 0.833374C11.9581 0.833374 15.1667 4.042 15.1667 8.00004C15.1667 11.9581 11.9581 15.1667 8.00004 15.1667C4.042 15.1667 0.833374 11.9581 0.833374 8.00004ZM8.00004 3.50004C8.27618 3.50004 8.50004 3.7239 8.50004 4.00004V4.2112C9.587 4.40582 10.5 5.22245 10.5 6.33337C10.5 6.60952 10.2762 6.83337 10 6.83337C9.7239 6.83337 9.50004 6.60952 9.50004 6.33337C9.50004 5.88107 9.12398 5.4023 8.50004 5.23169V7.54453C9.58699 7.73915 10.5 8.55578 10.5 9.66671C10.5 10.7776 9.587 11.5943 8.50004 11.7889V12C8.50004 12.2762 8.27618 12.5 8.00004 12.5C7.7239 12.5 7.50004 12.2762 7.50004 12V11.7889C6.41309 11.5943 5.50004 10.7776 5.50004 9.66671C5.50004 9.39057 5.7239 9.16671 6.00004 9.16671C6.27618 9.16671 6.50004 9.39057 6.50004 9.66671C6.50004 10.119 6.87611 10.5978 7.50004 10.7684V8.45555C6.41309 8.26093 5.50004 7.4443 5.50004 6.33337C5.50004 5.22245 6.41309 4.40582 7.50004 4.2112V4.00004C7.50004 3.7239 7.7239 3.50004 8.00004 3.50004ZM7.50004 5.23169C6.87611 5.4023 6.50004 5.88107 6.50004 6.33337C6.50004 6.78568 6.87611 7.26445 7.50004 7.43505V5.23169ZM8.50004 8.56503V10.7684C9.12398 10.5978 9.50004 10.119 9.50004 9.66671C9.50004 9.2144 9.12398 8.73564 8.50004 8.56503Z"
    />
  </svg>
);

export const DollarMinimalisticIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V6.31673C14.3804 6.60867 15.75 7.83361 15.75 9.5C15.75 9.91421 15.4142 10.25 15 10.25C14.5858 10.25 14.25 9.91421 14.25 9.5C14.25 8.65573 13.3765 7.75 12 7.75C10.6235 7.75 9.75 8.65573 9.75 9.5C9.75 10.3443 10.6235 11.25 12 11.25C13.9372 11.25 15.75 12.5828 15.75 14.5C15.75 16.1664 14.3804 17.3913 12.75 17.6833V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.6833C9.61957 17.3913 8.25 16.1664 8.25 14.5C8.25 14.0858 8.58579 13.75 9 13.75C9.41421 13.75 9.75 14.0858 9.75 14.5C9.75 15.3443 10.6235 16.25 12 16.25C13.3765 16.25 14.25 15.3443 14.25 14.5C14.25 13.6557 13.3765 12.75 12 12.75C10.0628 12.75 8.25 11.4172 8.25 9.5C8.25 7.83361 9.61957 6.60867 11.25 6.31673V6C11.25 5.58579 11.5858 5.25 12 5.25Z"
    />
  </svg>
);
