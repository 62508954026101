import { useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { Navigate } from "react-router-dom";
import { t } from "ttag";

import {
  HealthieForm,
  OnboardingHeader,
  OpenLoopConsentCheckbox,
} from "@/components";
import { OnboardingLayout } from "@/domains/onboarding/components";
import { ROUTES } from "@/router";
import { HEALTHIE_FORM_TYPE } from "@/shared.constants";
import { useOnboardingStore } from "@/stores";
import { AltArrowRightIcon, Button, Checkbox } from "@/ui";

interface HealthieFormProps {
  onSubmit: () => void;
}

export const HealthieFormSection = ({ onSubmit }: HealthieFormProps) => {
  const [openLoopConsent, setOpenLoopConsent] = useState<CheckedState>();
  const [medicationConsent, setMedicationConsent] = useState<CheckedState>();

  const patientId = useOnboardingStore((state) => state.patientId);
  const appointment = useOnboardingStore((state) => state.appointment);

  const formType = appointment
    ? HEALTHIE_FORM_TYPE.ONBOARDING_VIDEO_CONSULTATION
    : HEALTHIE_FORM_TYPE.ONBOARDING_REFILL;

  if (!patientId) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <OnboardingLayout showProgressBar={false}>
      <div className="relative flex flex-col gap-6 pb-10 sm:gap-12 sm:pb-20">
        <OnboardingHeader
          title={t`Intake form questions`}
          description={t`(*) Mandatory field`}
        />

        <HealthieForm
          onSubmit={onSubmit}
          patientId={patientId}
          formType={formType}
        >
          <div className="flex flex-col gap-3 text-brown-10">
            <OpenLoopConsentCheckbox
              checked={openLoopConsent}
              onCheckedChange={setOpenLoopConsent}
            />
            <Checkbox
              checked={medicationConsent}
              onCheckedChange={setMedicationConsent}
              id="medication-consent-checkbox"
              label={t`I consent to only get weight-loss medication from this platform`}
            />
          </div>

          <div className="flex items-center justify-end pt-7">
            <Button
              disabled={!(openLoopConsent && medicationConsent)}
              type="submit"
              size="lg"
              className="w-full sm:w-fit"
            >
              {t`Next`}
              <AltArrowRightIcon className="hidden size-4 sm:block" />
            </Button>
          </div>
        </HealthieForm>
      </div>
    </OnboardingLayout>
  );
};
