import { extendTailwindMerge } from "tailwind-merge";

import tailwindConfig from "../../../tailwind.config.js";

const theme = Object.fromEntries(
  Object.entries(tailwindConfig.theme.extend).map((entry) => [
    entry[0],
    Object.keys(entry[1]),
  ]),
);

const extendedTailwindMerge = extendTailwindMerge({
  extend: { theme },
});
export const tw = extendedTailwindMerge;
