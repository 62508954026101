import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from "react";
import { forwardRef } from "react";

import { CardPrimitive, Label } from "@/ui";
import { tw } from "@/utils";

const Title = CardPrimitive.Title;
const Content = CardPrimitive.Content;

const Root = forwardRef<
  ElementRef<typeof CardPrimitive.Root>,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <CardPrimitive.Root
    ref={ref}
    className={tw("z-0 bg-brown-01", className)}
    {...props}
  />
));
Root.displayName = "ProfileCard.Root";

const Header = forwardRef<
  ElementRef<typeof CardPrimitive.Header>,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <CardPrimitive.Header
    ref={ref}
    className={tw("min-h-10 flex-row justify-between", className)}
    {...props}
  />
));
Header.displayName = "ProfileCard.Header";

const Item = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex flex-col gap-1 rounded-lg bg-brown-02 px-4 py-5 text-salmon-10",
        className,
      )}
      {...props}
    />
  ),
);
Item.displayName = "ProfileCard.Item";

const ItemLabel = forwardRef<
  ElementRef<typeof Label>,
  ComponentPropsWithoutRef<typeof Label>
>(({ className, size = "md", ...props }, ref) => (
  <Label
    ref={ref}
    size={size}
    className={tw("flex items-center gap-1", className)}
    {...props}
  />
));
ItemLabel.displayName = "ProfileCard.ItemLabel";

const ItemValue = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p
      ref={ref}
      className={tw("truncate pl-5 text-sm", className)}
      {...props}
    />
  ),
);
ItemValue.displayName = "ProfileCard.ItemValue";

export { Root, Header, Title, Content, Item, ItemLabel, ItemValue };
