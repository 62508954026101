import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { Shape10, Shape11 } from "@/ui";
import { tw } from "@/utils";

const Root = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "relative z-10 flex grow overflow-hidden rounded-lg bg-brown-02 shadow",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "CreditCard.Root";

const Content = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex grow flex-col justify-center gap-4 p-3", className)}
      {...props}
    />
  ),
);
Content.displayName = "CreditCard.Content";

const Title = forwardRef<ElementRef<"h3">, ComponentPropsWithoutRef<"h3">>(
  ({ className, children, ...props }, ref) => (
    <h3
      ref={ref}
      className={tw("text-2xl font-medium text-salmon-10", className)}
      {...props}
    >
      {children}
    </h3>
  ),
);
Title.displayName = "CreditCard.Title";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p
      ref={ref}
      className={tw("text-xs text-salmon-08", className)}
      {...props}
    />
  ),
);
Description.displayName = "CreditCard.Description";

const Footer = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("flex items-center", className)} {...props} />
  ),
);
Footer.displayName = "CreditCard.Footer";

const Background = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={tw(
      "pointer-events-none absolute right-0 -z-10 flex h-full w-1/5 flex-1 overflow-hidden rounded-r-lg bg-salmon-07 text-salmon-05 sm:w-28 sm:flex-none",
      className,
    )}
    {...props}
  />
));
Background.displayName = "CreditCard.Background";

const Shapes = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "absolute right-0 top-0 -z-10 flex h-full flex-col text-salmon-05",
        className,
      )}
      {...props}
    >
      <Shape10 className="size-28 -translate-y-5 translate-x-5" />
      <Shape11 className="size-28 -translate-x-2.5 translate-y-2 -scale-x-100 self-end" />
    </div>
  ),
);
Shapes.displayName = "CreditCard.Shapes";

const Chip = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "absolute left-4 top-4 h-5 w-7 rounded-md bg-yellow-05",
        className,
      )}
      {...props}
    />
  ),
);
Chip.displayName = "CreditCard.Chip";

export { Root, Footer, Title, Description, Content, Shapes, Chip, Background };
