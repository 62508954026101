import { useState } from "react";

import {
  Delivery,
  JourneyBeginCover,
  OnboardingScheduleVisit,
} from "./sections";

const STEP = {
  COVER: "cover",
  APPOINTMENT: "appointment",
  DELIVERY: "delivery",
  ERROR: "error",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const JourneyBegin = () => {
  const [step, setStep] = useState<Step>(STEP.COVER);

  return (
    <>
      {step === STEP.COVER && <JourneyBeginCover onContinue={setStep} />}

      {step === STEP.APPOINTMENT && (
        <OnboardingScheduleVisit onGoBack={() => setStep(STEP.COVER)} />
      )}

      {step === STEP.DELIVERY && (
        <Delivery
          onGoBack={() => setStep(STEP.COVER)}
          onDisqualified={() => setStep(STEP.APPOINTMENT)}
        />
      )}
    </>
  );
};
