import { useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { updatePaymentMethod } from "@/api/stripe";
import { FullScreenAlert } from "@/components";
import type { ModalProps } from "@/shared.types";
import { Button, Checkbox, errorToast, illustratedIcons, Modal } from "@/ui";

export const EditPaymentMethodModal = ({ show, onClose }: ModalProps) => {
  const [hasConsent, setHasConsent] = useState<CheckedState>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const queryClient = useQueryClient();

  const { mutate: updatePaymentMethodMutation } = useMutation({
    mutationFn: updatePaymentMethod.mutation,
    onSuccess: () => {
      updatePaymentMethod.invalidates(queryClient);
      setUpdatingPaymentMethod(false);
      setShowSuccessAlert(true);
    },
    onError: () => {
      errorToast(
        t`Error updating payment method, try again later or use a different card.`,
      );
      setUpdatingPaymentMethod(false);
    },
  });

  const handleChangePaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    setUpdatingPaymentMethod(true);

    await stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((result) => {
        updatePaymentMethodMutation({
          paymentMethodId: result.paymentMethod?.id,
        });
      })
      .catch(() => {
        errorToast(
          t`Error updating payment method, try again later or use a different card.`,
        );
        setUpdatingPaymentMethod(false);
      });
  };

  const handleClose = () => {
    setHasConsent(false);
    onClose();
  };

  if (showSuccessAlert) {
    return (
      <FullScreenAlert
        customIcon={<illustratedIcons.Success />}
        title={t`Card info updated`}
        description={t`Your card information has been successfully updated`}
        customAction={
          <Button
            size="lg"
            className="md:w-fit"
            onClick={() => {
              setShowSuccessAlert(false);
              handleClose();
            }}
          >
            {t`Go to Billing`}
          </Button>
        }
      />
    );
  }

  return (
    <Modal
      show={show}
      title={t`Edit your payment method`}
      description={
        <p className="flex flex-col gap-6">
          <span>{t`Update the details for your preferred payment method`}</span>
        </p>
      }
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-1">
        <label className="text-sm font-medium text-salmon-10">
          {t`Card number *`}
        </label>
        <CardElement className="w-full rounded-lg border border-brown-09 p-3" />
      </div>
      <Checkbox
        checked={hasConsent}
        onCheckedChange={setHasConsent}
        id=""
        label={
          <span className="text-sm text-brown-10">
            {t`I authorize the use of this credit card for subscriptions, medications and lab charges`}
          </span>
        }
      />
      <div className="flex w-full flex-col gap-2 md:flex-row-reverse md:justify-center">
        <Button
          onClick={handleChangePaymentMethod}
          disabled={updatingPaymentMethod || !hasConsent}
        >{t`Save changes`}</Button>

        <Button variant="secondary" onClick={onClose}>
          {t`Cancel`}
        </Button>
      </div>
    </Modal>
  );
};
