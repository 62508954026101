import { motion } from "framer-motion";

import { AnimatedBackground } from "./AnimatedBackground";

export const Splash = () => {
  return (
    <motion.div
      animate={{ opacity: 0, transitionEnd: { display: "none" } }}
      transition={{ duration: 0, delay: 3.5 }}
      className="fixed z-20 h-dvh w-screen"
    >
      <motion.div
        animate={{
          opacity: 0,
          transitionEnd: { display: "none" },
          transition: { duration: 1.5, ease: "easeInOut", delay: 2 },
        }}
        className="absolute h-full w-full bg-nature-10"
      />

      <AnimatedBackground />
    </motion.div>
  );
};
