import * as Dialog from "@radix-ui/react-dialog";

import { tw } from "@/utils";
import type { DialogProps } from "./common.types";
import { icons, IconWrapper } from "./Icons";

/**
 * @deprecated Use the new `Dialog` component for V2 features instead.
 */
export const Modal = ({
  show,
  title,
  children,
  className,
  description,
  icon,
  onClose,
}: DialogProps) => (
  <Dialog.Root open={show} onOpenChange={onClose}>
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-50 bg-black/60 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <Dialog.Content
        className={tw(
          "fixed left-1/2 top-1/2 z-50 flex max-h-[calc(100dvh-2rem)] w-[calc(100vw-2rem)] min-w-min max-w-modal -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-3xl bg-salmon-01 text-gray-50 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-2xl",
          className,
        )}
      >
        <div className="flex grow flex-col gap-6 overflow-y-auto px-5 py-10 sm:px-8">
          {(icon ?? title ?? description) && (
            <div className="flex flex-col items-center gap-2 text-center">
              {icon && <IconWrapper className="h-20 w-20">{icon}</IconWrapper>}

              {title && (
                <Dialog.Title className="font-serif text-3xl leading-10 text-salmon-10">
                  {title}
                </Dialog.Title>
              )}

              {description && (
                <Dialog.Description
                  asChild={typeof description !== "string"}
                  className="font-medium leading-4.5 text-brown-09"
                >
                  {description}
                </Dialog.Description>
              )}
            </div>
          )}

          {children}

          <Dialog.Close asChild>
            <button
              className="absolute right-4 top-4 rounded-sm opacity-70 ring-gray-400 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-gray-800 data-[state=open]:text-gray-50"
              aria-label="Close"
            >
              <IconWrapper size="md">
                <icons.XMark className="stroke-nature-10" />
              </IconWrapper>

              <span className="sr-only">Close</span>
            </button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);
