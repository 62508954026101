import type { ControllerProps } from "react-hook-form";

import {
  ACCEPT_IMAGE_FILE,
  FIELD_TYPE,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
} from "@/shared.constants";
import type { HealthieField } from "@/shared.types";
import {
  CheckboxGroup,
  Dropzone,
  Input,
  RadioGroup,
  Select,
  TextArea,
} from "@/ui";

type FieldProps = Parameters<ControllerProps["render"]>[number] & {
  healthieField: HealthieField;
  id?: string;
};

export const Field = ({ field, healthieField, id }: FieldProps) => {
  const options = healthieField.options.map((option) => ({
    value: option,
    label: option,
  }));

  const props = { ...field, id };

  switch (healthieField.type) {
    case FIELD_TYPE.FILE:
      return (
        <Dropzone
          {...props}
          onDrop={(acceptedFiles) => props.onChange(acceptedFiles[0])}
          accept={ACCEPT_IMAGE_FILE}
          placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
        />
      );

    case FIELD_TYPE.HORIZONTAL_RADIO:
      return (
        <RadioGroup orientation="horizontal" options={options} {...props} />
      );

    case FIELD_TYPE.RADIO:
      return <RadioGroup options={options} {...props} />;

    case FIELD_TYPE.CHECKBOX:
      return <CheckboxGroup options={options} {...props} />;

    case FIELD_TYPE.DROPDOWN:
      return <Select options={options} {...props} />;

    case FIELD_TYPE.TEXT:
      return <Input {...props} />;

    case FIELD_TYPE.TEXTAREA:
      return <TextArea {...props} />;
  }
};
