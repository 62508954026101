import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { errorToast, useToastStore } from "@/ui";
import { acceptRequestedPlanChange, billingQueriesStore } from "../api";

export const useAcceptRequestedPlanChange = () => {
  const pushToast = useToastStore((state) => state.pushToast);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: acceptRequestedPlanChange,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: billingQueriesStore.stripe.userSubscription._def,
      });

      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Subscription plan successfully updated!`,
      });
    },
    onError: errorToast,
  });
};
