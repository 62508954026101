import { useLocation } from "react-router-dom";

import { PORTAL_USER_TYPE } from "@/shared";
import type { PortalUserType } from "@/shared";
import { CoachingSuccessScreenAlert } from "./sections";
import { PatientSuccessScreenAlert } from "./sections/PatientSuccessScreenAlert";

export const SuccessScreen = () => {
  const location = useLocation();

  const portalUserType = location.state as PortalUserType;

  if (portalUserType === PORTAL_USER_TYPE.COACHING) {
    return <CoachingSuccessScreenAlert />;
  }

  return <PatientSuccessScreenAlert />;
};
