import { Link } from "react-router-dom";
import { t } from "ttag";

import { EXTERNAL_LINK } from "@/router";
import { VARIANT } from "@/shared.constants";
import { Button } from "@/ui";

interface DisqualifiedActionButtonsProps {
  continueLabel?: string;
  onGoBack: () => void;
  onContinue?: () => void;
}
export const DisqualifiedActionButtons = ({
  continueLabel,
  onGoBack,
  onContinue,
}: DisqualifiedActionButtonsProps) => {
  return (
    <div className="flex flex-col-reverse items-center gap-2.5 sm:flex-row">
      <Button
        variant={VARIANT.SECONDARY}
        onClick={onGoBack}
        size="lg"
        className="w-full sm:w-fit"
      >
        {t`Go Back`}
      </Button>

      <Button
        asChild={!onContinue}
        onClick={onContinue}
        size="lg"
        className="w-full sm:w-fit"
      >
        {onContinue ? (
          (continueLabel ?? t`Continue`)
        ) : (
          <Link to={EXTERNAL_LINK.HOME}>{t`Back to home`}</Link>
        )}
      </Button>
    </div>
  );
};
