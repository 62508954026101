import type { DPYear } from "@rehookify/datepicker";

import { tw } from "@/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./DPSelect";

interface YearSelectProps {
  years: DPYear[];
  year: number;
  onChange: (year: number) => void;
  className?: string;
}

export const YearSelect = ({
  years,
  year,
  onChange,
  className,
}: YearSelectProps) => {
  return (
    <Select
      value={year.toString()}
      onValueChange={(value) => onChange(parseInt(value))}
    >
      <SelectTrigger
        id="year-selector-trigger"
        className={tw("w-20", className)}
      >
        <SelectValue placeholder="Select year..." />
      </SelectTrigger>
      <SelectContent variant="grid" align="center">
        {years.map((dPYear) => (
          <SelectItem
            key={dPYear.$date.toDateString()}
            value={dPYear.year.toString()}
          >
            {dPYear.year}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
